import { Component, OnInit } from '@angular/core';
import { AuthnService } from '@Common';
import {
  HealthStatus,
  LoginInfo,
  RoleGroups,
  ServerStatus,
} from 'company-finder-common';
import { DeploymentContext } from '@Common';
import { UserService } from '@Common';
import { ApplicationContext } from '@Common';
import { AuthnStrings } from '@Common';
import { WebAnalyticsService } from '@Common';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment.js';

@Component({
  selector: 'app-health-check',
  templateUrl: './health-check.component.html',
  styleUrls: ['./health-check.component.scss'],
  standalone: false
})
export class HealthCheckComponent implements OnInit {
  public ServerStatus = ServerStatus;
  public AuthnStrings = AuthnStrings;

  constructor(
    private deploymentContext: DeploymentContext,
    public appContext: ApplicationContext,
    private userService: UserService,
    private authnService: AuthnService,
    private webAnalyticsService: WebAnalyticsService,
    private router: Router
  ) { }

  public ngOnInit() {
    if (this.versionMismatchWarning) {
      environment.apiInfo?.warnings?.push(
        'WARNING: The app and api versions differ. This may result in errors and may indicate deployment issues.'
      );
    }
  }

  public get apiInfo() {
    return environment.apiInfo;
  }

  public get showDebug(): boolean {
    return this.deploymentContext.debug.showHealthInfo ?? false;
  }

  public get persistenceConnection(): string {
    return `${this.apiInfo?.dbConnected ? 'Connected' : 'Not Connected'
      } to ${this.apiInfo?.database}`;
  }

  public get appVersion(): string {
    return this.deploymentContext.appVersion;
  }

  public get serverWarnings(): string[] {
    return this.apiInfo?.warnings;
  }

  public get loginInfo(): LoginInfo {
    return this.authnService.loginInfo;
  }

  public get expiryDate(): Date {
    if (!this.loginInfo) {
      return undefined;
    }

    // A JWT is seconds since epoch, not miliseconds
    return new Date(this.loginInfo.exp * 1000);
  }

  public get siteHeadCompanies(): string {
    return this.userService.getCompanyListsSync().isSiteHeadFor.toString();
  }

  public get coOwnerCompanies(): string {
    return this.userService.getCompanyListsSync().isCoOwnerFor.toString();
  }

  public get companyContactCompanies(): string {
    return this.userService
      .getCompanyListsSync()
      .isCompanyContactFor.toString();
  }

  public get versionMismatchWarning(): boolean {
    return this.appVersion !== this.apiInfo?.apiVersion;
  }

  public get showCompanyWarning(): boolean {
    return (
      (this.userService.getCompanyListsSync().isCompanyContact &&
        !RoleGroups.CompanyUsers.containsRole(this.loginInfo.role)) ||
      ((this.userService.getCompanyListsSync().isSiteHead ||
        this.userService.getCompanyListsSync().isCoOwner) &&
        !RoleGroups.InternalSuperUsers.containsRole(this.loginInfo.role))
    );
  }

  public throwError(): void {
    console.log('User requested an error be thrown');
    throw new Error(
      'Error intentionally triggered from the Health Check Component'
    );
  }

  public clearStorage(key: string): void {
    localStorage.removeItem(key);
  }

  public setStorage(key: string, value = 'true'): void {
    localStorage.setItem(key, value);
  }

  public loginInternal(): void {
    this.login(AuthnStrings.INTERNAL_OVERRIDE_TOKEN);
  }

  public loginCompany(): void {
    this.login(AuthnStrings.COMPANY_OVERRIDE_TOKEN);
  }

  public loginInternalSuper(): void {
    this.login(AuthnStrings.INTERNAL_SUPER_OVERRIDE_TOKEN);
  }

  public loginBarda(): void {
    this.login(AuthnStrings.BARDA_OVERRIDE_TOKEN);
  }

  public loginPartner(): void {
    this.login(AuthnStrings.PARTNER_OVERRIDE_TOKEN);
  }

  public loginInvalid(): void {
    this.login(AuthnStrings.INVALID_OVERRIDE_TOKEN);
  }

  public logOut(): void {
    this.clearTokenAndUser();
    this.router.navigate(['/']);
  }

  private clearTokenAndUser(): void {
    this.authnService.clearToken();
    this.userService.clearCachedUser();
  }

  private login(tokenToSend: string): void {
    this.authnService.attemptJuniverseAuth(
      this.webAnalyticsService,
      true,
      tokenToSend
    );
  }
}
