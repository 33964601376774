import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { ComponentBase } from '@Common';
import { LocalizedTextIds, NameValuePair, NameWithChildNameValues, PrimarySectorCount, SectorCounts } from 'company-finder-common';
import { DeploymentContext } from '@Common';
import {
  Summary,
} from '@Common';

@Component({
    selector: 'deals',
    templateUrl: './deals.component.html',
    styleUrls: ['./deals.component.scss'],
    standalone: false
})
export class DealsComponent extends ComponentBase implements OnChanges {
  // public properties
  public title = this.Loc(LocalizedTextIds.DealsDealsAndPartnerships);
  @Input()
  public summary: Summary;
  public sortedPrimarySectorCounts: SectorCounts[];

  // private properties
  private _sumSiblings: number;

  public constructor(dc: DeploymentContext) {
    super(dc);
  }

  public get totalDealsAndPartnerships(): number {
    return this._sumSiblings;
  }

  public get localizedCountString(): string {
    const companyCount = this.summary.totalUniqueCompaniesWithDeals;
    const partnerCount = this.totalDealsAndPartnerships;

    return this.Loc(
      LocalizedTextIds.DealsCompaniesDealsAndPartnerships,
      companyCount,
      this.LocPluralize(LocalizedTextIds.DealsJLABSCompanies, companyCount),
      partnerCount,
      this.LocPluralize(LocalizedTextIds.DealsDealsPartnerships, partnerCount)
    );
  }

  // public methods
  public calculateSectorBarPercentage(
    primarySectorCount: SectorCounts
  ): number {
    // Cap it at 100, just in case.
    return Math.min((100 * primarySectorCount.dealCount) / this._sumSiblings, 100);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.summary && this.summary) {
      this.sortedPrimarySectorCounts =
        this.summary.descendingPrimarySectorCounts;
      this._sumSiblings = this.sumSiblings();
    }
  }

  // private methods
  private sumSiblings(): number {
    return this.summary.sectorGroups.reduce(
      (total, item) => total + item.dealCount,
      0
    );
  }
}
