@import "site";

locations-picker-modal {
    .modal-title-icon {
        @include iconAsBackgroundImage(url("/assets/common/gray-locations.svg"), 24px, 20px);
    }

    .item-name-and-company-count {
        flex: auto;
        margin: 0;
        padding: 0;

        .item-name {
            color: $black;
            font-size: $font-size-normal;
            font-weight: 300;
            line-height: 22px;
        }
        .company-count {
            color: $neutral-08;
            font-size: $font-size-small;
            font-weight: 400;
            line-height: 17px;
            margin-top: 2px;
        }
    }

    .bottom-form label.item-info {
        padding-left: 0px !important;
    }
}
