<div class="cf_overview">
  <summary [summary]="summary">
  </summary>
  <explore [summary]="summary"></explore>
  <div *ngIf="narrowScreen">
    <swiper-container swiperElement class="swiper-container middle-swiper">
      <swiper-slide *ngIf="showDeals">
        <deals [summary]="summary" ></deals>
      </swiper-slide>
      <swiper-slide *ngIf="showNewCompanies">
        <new-companies [tagCounts]="tagCounts" ></new-companies></swiper-slide>
      <swiper-slide *ngIf="featureSwitches.enableLeadProductStage">
        <stage [summary]="summary"></stage>
      </swiper-slide>
    </swiper-container>
  </div>
  <div *ngIf="!narrowScreen" class="cf_overview_deals-and-stage">
    <deals [summary]="summary" *ngIf="showDeals" class="slider-widget"></deals>
    <new-companies *ngIf="showNewCompanies" [tagCounts]="tagCounts" class="slider-widget"></new-companies>
    <stage *ngIf="featureSwitches.enableLeadProductStage" [summary]="summary" class="slider-widget"></stage>
  </div>
</div>
<div class="cf_main_spacer"></div>
<by-the-numbers *ngIf="featureSwitches.enableByTheNumbers"></by-the-numbers>
