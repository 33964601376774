import { ICompanyLists } from "../model/companyLists.js";
import { EmailFrequency } from "../model/emailFrequency.js";
import { IEntityBase } from "./entity-base.interface.js";

export interface IUserCompany {
    companyId: string;
}

export interface IUserSector {
    sectorId: string;
    parentSectorId?: string;
    parentSearchId?: string;
}

export interface IUserLocation {
    locationId: string;
    parentSearchId?: string;
}

export interface IUserSearch extends IEntityBase {
    sectors: IUserSector[];
    locations: IUserLocation[];
    statuses: string;
    name: string;
}

export interface ICompany extends IEntityBase {
    lastReminderSent?: Date;
}

export interface IUserNewsUpdatePreferences {
    emailUpdates: boolean;
    emailNews: boolean;
    emailNewCompanies: boolean;
}

export interface IUser {
    id: number;
    userName: string;
    nickname: string;
    locationsFollowed: string[];
    followCompaniesImResponsibleFor?: boolean;
    timeZoneOffsetInHours?: number;
    lastNewsletterEmailSentTimestamp: Date;
    emailNewsletterFrequency: EmailFrequency;
    lastViewedMyUpdatesTimestamp: Date;
    viewedStayInTheLoop: boolean;
    followBlueKnightCompanies: boolean;
    companyLists?: ICompanyLists;
    createdDate: Date;
    updatedDate: Date;

    // FUTURE - these should be refactored into cleaner objects
    // for using via the API, similar to how locations were handled
    // in ADJQ-1701, but time contraints did not allow for that at
    // this time.
    companiesFollowed: any[];
    sectorsFollowed: any[];
    userSearches: any[];
    userNewsUpdatePreferences: any;
}

export function hasUserPreferences(user: IUser): boolean {
    return (
        user?.companiesFollowed?.length > 0 ||
        user?.locationsFollowed?.length > 0 ||
        user?.sectorsFollowed.length > 0 ||
        user?.userSearches?.length > 0 ||
        user?.followBlueKnightCompanies ||
        user?.followCompaniesImResponsibleFor
    );
}
