<div class="locations" *ngIf="featureSwitches.enableShowLocations">
  <status-list
    *ngIf="includeCompanyStatusRow"
    [statusItems]="companyStatusItems"
  ></status-list>
  <div class="location" *ngFor="let loc of locationsToDisplay">
    <div class="location-text" [ngClass]="{ 'no-margin': !useSeparator }">
      <div *ngIf="isUpdateScreen">
        <span class="location-text-bold">{{loc.city}}</span>&nbsp;
        <span *ngIf="loc.city !== loc.name">({{loc.name}})</span>
      </div>
      <div *ngIf="!isUpdateScreen">
        <div *ngIf="singleLine">
          {{getLocationDisplayValue(loc)}}          
        </div>
        <div *ngIf="!singleLine">     
          <div>{{loc.name}}</div>
          <div *ngIf="loc.city !== loc.name" class="city">{{loc.city}}</div>    
        </div>        
      </div>
      <div *ngIf="showSeparator" class="separator" [ngClass]="{narrowScreen: narrowScreen}"></div>
      <status-list
        [statusItems]="statusItems(loc)"
      ></status-list>
    </div>    
  </div>
</div>

