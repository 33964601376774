import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { RoleGroup, UserRoles } from 'company-finder-common';
import { AuthnService } from '../services/authn/authn.service.js';
import { DeploymentContext } from '../utilities/deployment-context/deployment-context.js';
import { getUrlFromRoute } from './guard-utils.js';
import { Logger } from '../utilities/logger/logger.js';

@Injectable({providedIn: 'root'})
export class RoleCanActivateRouteGuard  {
  protected logger = new Logger(this.constructor.name);
  constructor(
    private auth: AuthnService,
    private router: Router,
    private _deploymentContext: DeploymentContext
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    const permittedRoleGroup = route.data.permittedRoleGroup as RoleGroup;

    if (!permittedRoleGroup) {
      throw new Error(
        `Invalid configuration: RoleCanActivateRouteGuard requires permittedRoleGroup to be passed in via route data.`
      );
    }

    if (!this.auth.isAuthenticated) {
      if (
        !this._deploymentContext.featureSwitches.enablePaywall &&
        permittedRoleGroup.containsRole(UserRoles.Partner)
      ) {
        return true;
      }

      // They are not logged in. Redirect to the login flow,
      // but preserve where they are trying to go
      const loginUrl = this.router.parseUrl(
        this._deploymentContext.ssoConfig?.enabled ? '/ping' : '/login'
      );
      loginUrl.queryParams['returnUrl'] = getUrlFromRoute(route);

      this.logger.warn(
        `The user is not authenticaed and this route requires an authenticated user who belongs to ${permittedRoleGroup}`
      );

      return loginUrl;
    }

    if (permittedRoleGroup.containsRole(this.auth.currentRole)) {
      return true;
    }

    this.logger.warn(
      `${this.auth?.currentUsername} has a role of ${
        this.auth?.currentRole ?? 'unauthenticated'
      }, which is not part of ${permittedRoleGroup}`
    );
    return this.router.parseUrl('/');
  }
}
