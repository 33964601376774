import { Component, Input } from '@angular/core';

import { ComponentBase } from '../_component.base.js';
import { StatusItem } from './StatusItem.js';

@Component({
    selector: 'status-list',
    templateUrl: './status-list.component.html',
    styleUrls: ['./status-list.component.scss'],
    standalone: false
})
export class StatusListComponent extends ComponentBase {
  @Input()
  public statusItems: StatusItem[] = [];
  public tooltipStatuses: Record<string, boolean> = {};

  public toggleTooltip(status: StatusItem): void {
    this.tooltipStatuses[status.displayText] =
      !this.tooltipStatuses[status.displayText];
  }

  public isShowingTooltip(status: StatusItem): boolean {
    return (
      this.tooltipStatuses[status.displayText] && status.tooltip?.length > 0
    );
  }

  public statusItemTrackBy(_index: number, property: StatusItem): string {
    return property.displayText;
  }
}
