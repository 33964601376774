<div *ngIf="selfUpdateMode === SelfUpdateModes.Review" class="header review">
  <search-bar
    [searchOnFilterChange]="false"
    [navigateToResultsOnSearch]="true"
    #searchBar
  >
  </search-bar>
</div>
<div *ngIf="selfUpdateMode === SelfUpdateModes.Edit" class="header">
  <div class="header-logo-container">
    <img class="header-logo" [src]="themeSettings.logoImgSrc" />
    <div class="header-controls">
      <div class="header-controls-go-to-navigator" (click)="goHome()">
        <div class="header-controls-go-to-navigator-text">{{Loc(LocalizedTextIds.UpdateHeaderGoToNavigator)}}</div>
        <div class="red-arrow"></div>
      </div>
      <div class="header-controls-user-info">{{Loc(LocalizedTextIds.UpdateHeaderAuthenticatedAs, userId)}}</div>
    </div>
  </div>
</div>