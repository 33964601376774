import { IDealUpdate } from "./deal-update.interface.js";
import { IFundingUpdate } from "./funding-update.interface.js";
import { IModelEntity } from "./model-entity.interface.js";
import { ProcessedStatus, UpdateStatus, IUpdateField } from "./update-field.interface.js";

export interface ICompanyUpdate extends IModelEntity {
    approverEmailAddress: string;
    comments: string;
    isNoteworthy: boolean;
    processed: ProcessedStatus;
    status: UpdateStatus;
    dealUpdates: IDealUpdate[];
    fundingUpdates: IFundingUpdate[];
    updateFields: IUpdateField[];
}

export enum EditItemType {
    Default,
    Logo,
    MultiChoice,
    Multiline,
    ConstrainedValue,
    Numeric,
    MultiModal,
    Year,
    Currency,
}

export enum DynamicChoiceKey {
    LeadershipDiversity,
    BoardAdvisorDiversity,
    RDStage,
    PrimarySubsector,
    SecondarySubsector,
    Tags,
}

export enum UpdateConversion {
    BooleanToYesNo,
    DelimitedString,
    CountryPrefixedDelimitedString,
}

export const ArrayConversions = [
    UpdateConversion.DelimitedString,
    UpdateConversion.CountryPrefixedDelimitedString,
];
