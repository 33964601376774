<div class="cf_jnj-toast-title-bar">
    <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
        {{title}}
    </div>
    <button class="toast-close-button" *ngIf="options.closeButton" (click)="remove()">
        <span>x</span>
    </button>
</div>
<div class="cf_jnj-toast-handler-message">
    <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite" [class]="options.messageClass" [innerHTML]="message">
    </div>
    <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite" [class]="options.messageClass" [attr.aria-label]="message">
        {{message}}
    </div>
    <div class="cf_jnj-toast-message" *ngIf="!isWarn()">
        <localized-text-snippet (clickHandler)="reload()"
            text="{{Loc(LocalizedTextIds.JnjToastClickHereToReload)}}">
       </localized-text-snippet>
    </div>
</div>
