import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { type EditablePropertyGroup } from '../../company-update.interface.js';
import { UpdateComponentBase } from '../../UpdateComponentBase.js';
import { EditItemComponent } from '../edit-item/edit-item.component.js';
import { UntypedFormGroup } from '@angular/forms';
@Component({
    selector: 'edit-item-group',
    templateUrl: './edit-item-group.component.html',
    styleUrls: ['./edit-item-group.component.scss'],
    standalone: false
})
export class EditItemGroupComponent extends UpdateComponentBase {
  @Input()
  public propertyGroup: EditablePropertyGroup;
  @Input()
  public companyEditForm: UntypedFormGroup;

  @ViewChildren(EditItemComponent)
  public editItemComponents: QueryList<EditItemComponent>;
  public get editItemArray(): EditItemComponent[] {
    return this.editItemComponents?.toArray() || [];
  }

  public get header(): string {
    return this.Loc(this.propertyGroup?.header);
  }

  public get subheader(): string {
    return this.Loc(this.propertyGroup?.subheader?.subheaderId);
  }

  public get href(): string {
    return this.propertyGroup?.subheader?.url?.target ?? '';
  }

  public get urlDisplayText(): string {
    return this.propertyGroup?.subheader?.url?.display ?? '';
  }

  public get displayUrl(): boolean {
    return this.href.length > 0 && this.urlDisplayText.length > 0;
  }
}
