<div class="status">
  <div class="info">
    <ng-container *ngFor="let status of statusItems; first as isFirst; trackBy: statusItemTrackBy">
      <div class="item"
        (mouseenter)="toggleTooltip(status)"
        (mouseleave)="toggleTooltip(status)"
      >        
        <div *ngIf="!isFirst" class="dot"></div>
        <div *ngIf="!isFirst" class="spacer" [ngStyle]="status.spacerCss"></div>
        <div *ngIf="isShowingTooltip(status)" class="tooltip">{{status.tooltip}}</div>
        <span class="text">{{status.displayText}}</span>
      </div>
    </ng-container>
  </div>
</div>