import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { Datasource, IDatasource } from 'ngx-ui-scroll';

import {
  EventFilterType,
  EventOfInterest,
  EventOfInterestType,
  IUser,
  LocalizedTextIds,
  MatchedCompanyInfo,
} from 'company-finder-common';
import { 
  ApplicationContext,
  DeploymentContext,
  EventService,
  UserService,
  ComponentBase } from '@Common';
import { SearchBarComponent } from '../search-bar/search-bar.component.js';
import { UpdateSummaryComponent } from './components/update-summary/update-summary.component.js';

interface InterestType {
  name: string;
  value: EventOfInterestType;
}

interface FilterSwitch {
  displayName: string;
  type: EventFilterType;
  isOn: boolean;
  isEnabled: boolean;
}

@Component({
    selector: 'my-updates',
    templateUrl: './my-updates.component.html',
    styleUrls: ['./my-updates.component.scss'],
    standalone: false
})
export class MyUpdatesComponent
  extends ComponentBase
  implements AfterViewInit, OnDestroy, OnInit {
  // public properties
  public lastViewedMyUpdatesTimestampForSummaryCards: Date;
  public companies: MatchedCompanyInfo[] = [];
  public interestTypes: InterestType[] = [
    {
      name: this.Loc(LocalizedTextIds.MyUpdatesAllUpdates),
      value: EventOfInterestType.AllUpdates,
    },
  ];
  public filters: FilterSwitch[] = [
    {
      displayName: this.Loc(LocalizedTextIds.MyUpdatesMySearchQueries),
      isOn: false,
      type: EventFilterType.SavedSearches,
      isEnabled: true,
    },
    {
      displayName: this.Loc(LocalizedTextIds.MyUpdatesSectorsSubSectors),
      isOn: false,
      type: EventFilterType.SectorsSubSectors,
      isEnabled: this.featureSwitches.enableSectors,
    },
    {
      displayName: this.Loc(LocalizedTextIds.Companies),
      isOn: false,
      type: EventFilterType.Companies,
      isEnabled: true,
    },
    {
      displayName: this.Loc(LocalizedTextIds.MyUpdatesJLABSLocations),
      isOn: false,
      type: EventFilterType.JLABSLocation,
      isEnabled: true,
    },
    {
      displayName: this.Loc(LocalizedTextIds.MyUpdatesBlueKnightCompanies),
      isOn: false,
      type: EventFilterType.BlueKnight,
      isEnabled: this.featureSwitches.enableBlueKnight,
    },
  ];
  public enabledFilters: FilterSwitch[] = this.filters.filter(
    (f) => f.isEnabled
  );
  public filtersVisible: boolean =
    window.innerWidth >
    this._deploymentContext.responsiveConfig.followListBreakpoint;
  // controls whether filters should be automatically hidden when resizing below breakpoint
  public prevWidthWide: boolean =
    window.innerWidth >
    this._deploymentContext.responsiveConfig.followListBreakpoint;

  public selectedUpdateType: EventOfInterestType =
    EventOfInterestType.AllUpdates;
  public EventOfInterestTypes = EventOfInterestType;
  @ViewChild('searchBar', { static: true })
  public searchBar: SearchBarComponent;
  @ViewChildren(UpdateSummaryComponent)
  public updateSummaries: QueryList<UpdateSummaryComponent>;
  @ViewChild('viewport')
  public viewport: ElementRef;

  // counts
  public totalUniqueCompanyCount = 0;

  // private properties
  private _datasource: IDatasource;
  private _routerSubscription: Subscription;
  private _user: IUser;

  public constructor(
    dc: DeploymentContext,
    private _applicationContext: ApplicationContext,
    private _eventService: EventService,
    private _router: Router,
    private _userService: UserService
  ) {
    super(dc);

    this._routerSubscription = this._router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this._applicationContext.unseenEventCounts = null;
        this.lastViewedMyUpdatesTimestampForSummaryCards =
          this._applicationContext.lastViewedMyUpdatesTimestamp;
      }
    });

    window.addEventListener('resize', () => {
      if (
        window.innerWidth <=
        this._deploymentContext.responsiveConfig.followListBreakpoint &&
        this.prevWidthWide
      ) {
        this.filtersVisible = false;
        this.prevWidthWide = false;
      } else if (
        window.innerWidth >
        this._deploymentContext.responsiveConfig.followListBreakpoint
      ) {
        this.filtersVisible = true;
        this.prevWidthWide = true;
      }
    });
  }

  // public getters/setters
  public get allUpdatesCount(): number {
    return this.unseenEventCounts
      ? this.unseenEventCounts[EventOfInterestType.AllUpdates]
      : undefined;
  }

  public get companyUpdateCount(): number {
    return this.unseenEventCounts
      ? this.unseenEventCounts[EventOfInterestType.CompanyUpdate]
      : undefined;
  }

  public get datasource(): IDatasource {
    return this._datasource;
  }

  public get inTheNewsCount(): number {
    return this.unseenEventCounts
      ? this.unseenEventCounts[EventOfInterestType.InTheNews]
      : undefined;
  }

  public get lastViewedMyUpdatesTimestamp(): Date {
    return this._applicationContext.lastViewedMyUpdatesTimestamp;
  }

  public set lastViewedMyUpdatesTimestamp(d: Date) {
    this._applicationContext.lastViewedMyUpdatesTimestamp = d;
  }

  public get newCompanyCount(): number {
    return this.unseenEventCounts
      ? this.unseenEventCounts[EventOfInterestType.NewCompany]
      : undefined;
  }

  public get unseenEventCounts(): number[] {
    return this._applicationContext.unseenEventCounts;
  }

  public get user(): IUser {
    return this._user;
  }

  public get countOfCompaniesForWhichWereShowingUpdates(): number {
    return this.totalUniqueCompanyCount;
  }

  // public methods
  public ngAfterViewInit(): void {
    const sub = this.updateSummaries.changes.subscribe(() => {
      const rowsShown = this.updateSummaries.toArray().length;
      if (this.viewport && rowsShown) {
        this.scrollToTop();
        sub.unsubscribe();
      }
    });
  }

  public ngOnDestroy(): void {
    this._routerSubscription.unsubscribe();
    super.ngOnDestroy();
  }

  public async ngOnInit(): Promise<void> {

    // The resizer doesn't play nice with the virtual scroll ngx-ui-scroll
    // creates and just hangs. Fortunately, we are not using any of the extra
    // resizing in this component and the page contues to work and resize
    // properly without it. The OnDestroy of ComponentBase will handle
    // re-enabling this when we move away.
    // If this changes we can find a new solution similar to the SearchResultsComponent,
    // but it is felt that is not worth the effort at the current time
    this.disableResizer();
    // Check the Date of the user's most recent visit to the My Updates screen
    this._user = this._userService.getCachedUserSync();

    if (!this._user) {
      this._logger.warn(`No user available for my updates`);
      return;
    }

    if (this._user?.lastViewedMyUpdatesTimestamp) {
      // If the user has ever visited this screen, set the app context's value to the Date of that visit.
      this._applicationContext.lastViewedMyUpdatesTimestamp = new Date(
        this._user.lastViewedMyUpdatesTimestamp
      );
    } else {
      // Otherwise, limit how far back we go for last viewed time if the user has never been here.
      // This is so we don't indicate as new some outrageous number of events.
      this._applicationContext.lastViewedMyUpdatesTimestamp =
        this._deploymentContext.lastViewedMyUpdatesTimestampDefault;
    }

    this.interestTypes.push({
      name: this.Loc(LocalizedTextIds.MyUpdatesCoProvidedUpdates),
      value: EventOfInterestType.CompanyUpdate,
    });

    if (this.featureSwitches.enableNews) {
      this.interestTypes.push({
        name: this.Loc(LocalizedTextIds.MyUpdatesInTheNews),
        value: EventOfInterestType.InTheNews,
      });
    }

    this.interestTypes.push({
      name: this.Loc(LocalizedTextIds.MyUpdatesNewCompanies),
      value: EventOfInterestType.NewCompany,
    });

    this.lastViewedMyUpdatesTimestampForSummaryCards =
      this._applicationContext.lastViewedMyUpdatesTimestamp;

    this.initializeEventData();

    // Now update the user's last visit, and the app context's cache of same.
    this._user.lastViewedMyUpdatesTimestamp = new Date();
    await this._userService.save(this._user);
    this._applicationContext.lastViewedMyUpdatesTimestamp = new Date(
      this._user.lastViewedMyUpdatesTimestamp
    );
    this._eventService.clearUnseenCounts();

    this.selectedUpdateType = EventOfInterestType.AllUpdates;
  }

  public getCompanyForUpdate(update: EventOfInterest): MatchedCompanyInfo {
    return this.companies.find((company) => company.id === update.companyId);
  }

  public getUnreadLabel(type: InterestType): string {
    const count = this.getCountByInterestType(type.value);
    const countLabel = count > 0 ? ` (${count})` : ``;

    return `${type.name}${countLabel}`;
  }

  public getCountByInterestType(type: EventOfInterestType): number {
    switch (type) {
      case EventOfInterestType.AllUpdates:
        return this.allUpdatesCount;
      case EventOfInterestType.CompanyUpdate:
        return this.companyUpdateCount;
      case EventOfInterestType.InTheNews:
        return this.inTheNewsCount;
      case EventOfInterestType.NewCompany:
        return this.newCompanyCount;
    }
  }

  public isFilterSelected(eventFilterType: EventFilterType): boolean {
    return !!this.filters.find(
      (filter) => filter.type === eventFilterType && filter.isOn
    );
  }

  public isNewUpdate(update: EventOfInterest): boolean {
    if (!this.lastViewedMyUpdatesTimestampForSummaryCards) {
      return true;
    }
    return (
      new Date(update.eventDate) >
      this.lastViewedMyUpdatesTimestampForSummaryCards
    );
  }

  public managePreferences(): void {
    if (this._deploymentContext.featureSwitches.enableUserPrefs) {
      this._router.navigate(['user', 'preferences']);
    }
  }

  public setType(type: EventOfInterestType): void {
    this.selectedUpdateType = type;
    this.categorizeAndFilter();
  }

  public toggleFilterSelection(eventFilterType: EventFilterType): void {
    const filter = this.filters.find((f) => f.type === eventFilterType);
    filter.isOn = !filter.isOn;
    this.categorizeAndFilter();
  }

  public toggleFilters(): void {
    this.filtersVisible = !this.filtersVisible;
  }

  // private methods
  private categorizeAndFilter(): void {
    this._datasource.adapter.reload();
    this.scrollToTop();
  }

  private async initializeEventData(): Promise<void> {
    // Prepare the DataSource for scrolling
    this.setupDatasource();
  }

  private scrollToTop(): void {
    setTimeout(() => {
      this.viewport.nativeElement.scrollTop = 0;
    }, this._deploymentContext.virtualScrollToTopTimeout);
  }

  private setupDatasource(): void {

    this._datasource = new Datasource({
      get: async (index, count, success) => {
        const data = [];
        const filterTypes = this.filters
          .filter((filter) => filter.isOn)
          .map((filter) => filter.type);
        const resp = await this._eventService.getUserEventsOfInterestDigest(
          index,
          count,
          this.selectedUpdateType,
          filterTypes
        );
        data.push(...resp.events);
        this.companies.push(...resp.companies);
        this.totalUniqueCompanyCount = resp.totalUniqueCompanyCount;
        success(data);
        return data;
      },
      settings: {
        minIndex: 0,
        startIndex: 0,
        bufferSize: 20,
      },
    });
  }
}
