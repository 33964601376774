<div #summary
  class="cf_company-summary"
  (click)="onClick()">
  <div class="cf_company-summary-content">
    <div class="cf_company-summary-content-text">
      <div class="cf_company-summary-content-new-company-indicator nologo" *ngIf="isNew && !featureSwitches.enableShowCompanyLogos">
        {{Loc(LocalizedTextIds.New)}}
      </div>
      <div class="cf_company-summary-content-text_company-name">
        <div>{{company.name}}</div>
        <company-status-icon
          [statuses]="company.statuses"
          tooltipTop="-32px"
          tooltipLeft="88px"
        >
        </company-status-icon>
      </div>
      <company-location-status
        *ngIf="featureSwitches.enableShowLocationStatus"
        class="cf_company-summary-content-text_location"
        [company]="company"
      ></company-location-status>
      <div class="cf_company-summary-content-text_description">
        {{company.description}}
      </div>
    </div>

    <div class="cf_company-summary-content-logo-outer">
      <div class="cf_company-summary-content-logo">
        <company-logo [company]="company" [size]="logoSize"></company-logo>
        <div class="cf_company-summary-content-new-company-indicator" *ngIf="isNew && featureSwitches.enableShowCompanyLogos">
          {{Loc(LocalizedTextIds.New)}}
        </div>
      </div>
    </div>
  </div>

  <company-tags
    *ngIf="featureSwitches.enableShowTags"
    [ngClass]="tagsToDisplay.length > 0 ? 'cf_company-summary-content-text_tags': ['cf_company-summary-content-text_tags', 'empty']"
    [company]="company"
    [tags]="tagsToDisplay">
  </company-tags>
</div>
