import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DeploymentContext } from '../../../utilities/deployment-context/deployment-context.js';
import { SectorWithCountIdCanonicalString } from 'company-finder-common';
import { WebAnalyticsService } from '../../../services/web-analytics/web.analytics.js';
import { SearchService } from '../../../services/search/search.service.js';

@Component({
    selector: 'sector-dropdown',
    templateUrl: './sector-dropdown.component.html',
    styleUrls: ['../base-selector.scss'],
    standalone: false
})
export class SectorDropdownComponent {
  @Input()
  label = 'Sector & Sub-Sector';

  @Input()
  public isPrimary = true;

  public constructor(
    private deploymentContext: DeploymentContext,
    private webAnalyticsService: WebAnalyticsService,
    private searchService: SearchService
  ) {}

  public get sectorData(): SectorWithCountIdCanonicalString[] {
    return (
      this.deploymentContext.comprehensiveSummary.allSectorsWithCounts?.map(
        (x) => ({
          ...x,
          sectorId: x.sector,
          parentSectorId: x.parentSector,
          displayName: x.sector,
        })
      ) ?? []
    );
  }

  public get criteria(): SectorWithCountIdCanonicalString[] {
    return this.isPrimary
      ? [
          ...this.sectorData.filter((s1) =>
            this.searchService.filter.primarySectors.some(
              (s2) => s1.sector === s2
            )
          ),
          ...this.sectorData.filter((s1) =>
            this.searchService.filter.primarySubSectors.some(
              (s2) => s1.sector === s2
            )
          ),
        ]
      : [
          ...this.sectorData.filter((s1) =>
            this.searchService.filter.secondarySectors.some(
              (s2) => s1.sector === s2
            )
          ),
          ...this.sectorData.filter((s1) =>
            this.searchService.filter.secondarySubSectors.some(
              (s2) => s1.sector === s2
            )
          ),
        ];
  }

  // Don't use the output name "select", otherwise text selection in the filter
  // control also propagates up through this with an undesired event object
  @Output() optionsSelected = new EventEmitter<unknown>();
  public relaySelect(event: unknown): void {
    this.webAnalyticsService.trackEvent('filter', {
      category: `Filter by sectorSubSector`,
      label: `${event}`,
    });
    this.optionsSelected.emit(event);
  }
}
