import { HttpClient, HttpHeaders } from '@angular/common/http';

// utility imports
import { DeploymentContext } from '../utilities/deployment-context/deployment-context.js';
import { Logger } from '../utilities/logger/logger.js';

export abstract class ServiceBase {
  // private & protected properties
  protected _apiUrl: string;
  protected _unhostedApiUrl: string;
  protected _logger: Logger;
  protected _standardHeaders: HttpHeaders;

  // constructor
  public constructor(
    protected _httpClient: HttpClient,
    protected _context: DeploymentContext,
    restApiRelativePath: string
  ) {
    this._apiUrl = _context.buildApiUrl(restApiRelativePath);
    this._logger = new Logger(this.constructor.name);
    this._standardHeaders = _context.standardHttpHeaders;
  }
}
