import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SelfUpdateMode } from '../../company-update.interface.js';
import { ActionResult } from '@Common';
import { LocalizedTextIds, UpdateStatus } from 'company-finder-common';
import { CompanyUpdateService } from '../../services/company-update.service.js';
import { ComponentBase } from '@Common';
import { DeploymentContext } from '@Common';

@Component({
    selector: 'company-update-modal',
    templateUrl: './company-update-modal.component.html',
    styleUrls: ['./company-update-modal.component.scss'],
    standalone: false
})
export class CompanyUpdateModalComponent extends ComponentBase {
  @Input()
  public isItemDeleted: boolean;
  @Input()
  public isItemNew: boolean;
  @Input()
  public isSaveDisabled: boolean;
  @Input()
  public showModal: boolean;
  @Input()
  public modalTitle: string;
  @Input()
  public maxHeight: number;
  @Input()
  public heightPercent = 80;
  @Input()
  public updateStatus: UpdateStatus;
  @Output()
  public action = new EventEmitter<ActionResult>();

  public actionResult = ActionResult;
  public selfUpdateMode = SelfUpdateMode;
  public updateStatuses = UpdateStatus;

  public constructor(
    deploymentContext: DeploymentContext,
    private _companyUpdateService: CompanyUpdateService
  ) {
    super(deploymentContext);
  }

  public get mode(): SelfUpdateMode {
    return this._companyUpdateService.selfUpdateMode;
  }

  public get title(): string {
    if (this.mode === SelfUpdateMode.Review) {
      return this.modalTitle;
    }

    return `${this.Loc(
      this.isItemNew ? LocalizedTextIds.New : LocalizedTextIds.Edit
    )} ${this.modalTitle}`;
  }

  public close(action: ActionResult): void {
    this.action.emit(action);
  }
}
