<div class="cf_overview_explore">
  <div class="cf_overview_explore_title-bar">
    <div class="cf_overview_explore_title-bar-title">
      {{title}}
    </div>
    <div class="cf_overview_explore_title-bar-visualization-type-container">
      <div *ngIf="featureSwitches.enableSectors" class="cf_overview_explore_title-bar-visualization-type-item" (click)="setVisualizationType(VisualizationTypes.Sector)">
        <div [ngClass]="{'cf_overview_explore_title-bar-visualization-type-item-selected': isSector}">{{Loc(LocalizedTextIds.Sector)}}</div>
        <div class="cf_overview_explore_title-bar-visualization-type-indicator"  [ngStyle]="{'visibility':isSector ? 'visible' : 'hidden'}"></div>
      </div>
      <div *ngIf="featureSwitches.enableShowTags" class="cf_overview_explore_title-bar-visualization-type-item" (click)="setVisualizationType(VisualizationTypes.Tags)">
        <div [ngClass]="{'cf_overview_explore_title-bar-visualization-type-item-selected': isTags}">{{Loc(LocalizedTextIds.Tags)}}</div>
        <div class="cf_overview_explore_title-bar-visualization-type-indicator" [ngStyle]="{'visibility':isTags ? 'visible' : 'hidden'}"></div>
      </div>
      <div *ngIf="featureSwitches.enableShowGeoLocationMap" class="cf_overview_explore_title-bar-visualization-type-item" (click)="setVisualizationType(VisualizationTypes.Geography)">
        <div [ngClass]="{'cf_overview_explore_title-bar-visualization-type-item-selected': isGeography}">{{Loc(LocalizedTextIds.Geography)}}</div>
        <div class="cf_overview_explore_title-bar-visualization-type-indicator"  [ngStyle]="{'visibility':isGeography ? 'visible' : 'hidden'}"></div>
      </div>
    </div>
  </div>
  <div class="cf_overview_explore-tooltip" [ngStyle]="tooltipStyle" *ngIf="featureSwitches.enableShowGeoLocationMap && tooltipHandler?.locationIndicator?.locationMarker as loc">
    <div class="cf_overview_explore-tooltip-name">{{loc.name}}</div>
    <div class="cf_overview_explore-tooltip-city">{{loc.city}}</div>
    <div class="cf_overview_explore-tooltip-counts" *ngIf="loc.counts?.length > 1">
      <ng-container *ngFor="let sector of tooltipHandler.locationIndicator.sectors; let i = index">
        <div class="sector" *ngIf="loc.counts[i] > 0">
          <div class="label">
            <div class="dot" [ngStyle]="{'background-color': tooltipHandler.locationIndicator.colors[i]}"></div>
            {{sector}}
          </div>
          <div class="value">
            {{loc.counts[i]}}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="cf_overview_explore_content">
    <div class="cf_overview_explore_content-sector" *ngIf="featureSwitches.enableSectors && visualizationType === VisualizationTypes.Sector">
      <sector-map [summary]="summary"></sector-map>
    </div>
    <div class="cf_overview_explore_content-tags" *ngIf="featureSwitches.enableShowTags && visualizationType === VisualizationTypes.Tags">
      <tag-cloud [summary]="summary"></tag-cloud>
    </div>
    <div class="cf_overview_explore_content-geography" *ngIf="featureSwitches.enableShowGeoLocationMap && visualizationType === VisualizationTypes.Geography">
      <geo-visualizer [locationInfos]="locationInfos" [tooltipHandler]="tooltipHandler"></geo-visualizer>
  </div>
  <div class="geo-footer" [ngStyle]="{maxWidth: this.themeSettings.map.maxWidth, width: '90vw'}" 
      *ngIf="featureSwitches.enableShowGeoLocationMap" >
    <div class="virtual-annulus" *ngIf="visualizationType === 'Geography' && virtualLocationInfo?.length > 0">
      <location-indicator 
        class="virtual-indicator"
        [locationMarker]="virtualLocationInfo"
        [summary]="summary"
        [tooltipHandler]="tooltipHandler"
        [invertLabel]="true">
      </location-indicator>
      <div class="label">{{Loc(LocalizedTextIds.CompanyDetailsVirtual)}}</div>
    </div>

    <!-- Only the Geography (visualizationType === 'Geography') display has a legend. -->
    <div class="cf_overview_explore_legend" *ngIf="visualizationType === 'Geography'">    
      <div class="cf_overview_explore_legend-container">
        <div class="cf_overview_explore_legend-item" *ngFor="let sector of _deploymentContext.sectorNames; let i = index">
          <div class="cf_overview_explore_legend-dot"></div>
            <div class="dot" [ngStyle]="{'background-color': themeSettings.sectorColors[i]}"></div>
            {{sectorShortName(sector)}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
