import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Environment as Environments } from 'company-finder-common';
import { AppModule } from './app/app.module.js';
import { environment } from './environments/environment.js';

// ADJQ-1768: improve performance for upper level environments based on the target api
// Since this is the entry point of the whole application, it wasn't straightforward to use the async/await pattern.
fetch(environment.apiBaseURL, {
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
})
  .then(response => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error(response.statusText);
    } 
  })
  .then(result => {
    environment.apiInfo = result;

    // Allow manually setting a local storage entry to enable/disable prod mode for upper environments to allow for temporary improved debugging
    const enableProdModeByLocalStorage = localStorage['enableProdMode'];
    if (enableProdModeByLocalStorage == undefined ? [Environments.Production, Environments.Staging].includes(environment.apiInfo.environment) : enableProdModeByLocalStorage === 'true') {
      enableProdMode();
    }

  })
  .catch( 
    reason => console.log(`WARNING: Unable to get info from server prior to bootstrapping: ${reason}`))
  .finally(() => {
    // Start the Angular application...
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
    }
  );