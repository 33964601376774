@import "site";

tags-picker-modal {
    .modal-title-icon {
        @include iconAsBackgroundImage(url("/assets/common/tags-double.svg"), 24px, 24px);
    }
    picker-modal.CompanyUpdate.Edit {
        .search-icon-img {
            display: none;
        }
        .create-custom-tag-text {
            visibility: hidden;
        }
     }

    .tag-name-and-company-count {
        flex: auto;
        margin: 0;
        padding: 0;

        .tag-name {
            color: $neutral-08;
            font-size: $font-size-normal;
            font-weight: bold;
            line-height: 20px;
        }
        .company-count {
            color: $neutral-08;
            font-size: $font-size-small;
            line-height: 18px;
            margin-top: 2px;
        }
    }
}
