<div class="contact">
  <div class="contact-role">{{role}}</div>
  <div class="contact-content">
      <div class="contact-content-details">
        <div class="contact-content-details-name detail">{{contact.name}}</div>
        <div *ngIf="showTitle && contact.title" class="contact-content-details-title detail">{{contact.title}}</div>
        <a *ngIf="showEmailLink && contact.email" href="mailto:{{contact.email}}" class="detail"><div class="contact-content-details-email">{{Loc(LocalizedTextIds.ContactSendAMessage)}}</div></a>
        <div *ngIf="showHubSpotLink && contact.email" class="contact-content-details-hub-spot detail" (click)="showHubSpotForm()">{{Loc(LocalizedTextIds.ContactSendAMessage)}}</div>
      </div>
  </div>
</div>
<div class="contact-hub-spot-overlay" *ngIf="isShowingHubSpotForm">
  <div class="contact-hub-spot-overlay-content-dialog" [ngClass]="dialogCss">
    <div class="contact-hub-spot-overlay-content-dialog-title-bar" *ngIf="!hasBeenSubmitted">
      <localized-text-snippet text="{{Loc(LocalizedTextIds.ContactGetInTouchWith, contact.name, company.name)}}"></localized-text-snippet>
      <div class="contact-hub-spot-overlay-content-dialog-title-bar-close-button" (click)="hideHubSpotForm(false)"></div>
    </div>
    <div class="contact-hub-spot-overlay-content-form-outer">
      <div class="contact-hub-spot-overlay-content-form" id="hubSpotForm">
      </div>
    </div>
    <div class="contact-hub-spot-overlay-content-dialog-ok-button" *ngIf="hasBeenSubmitted" (click)="hideHubSpotForm(true)">{{Loc(LocalizedTextIds.OK)}}</div>
  </div>
</div>
