import { Component, Input } from '@angular/core';
import { ComponentBase } from '@Common';
import { Company, CompanyKey } from 'company-finder-common';
import { isEmptyInputValue } from '../../../../_common/utilities/forms/form-util';

@Component({
    selector: 'blue-knight-milestones',
    templateUrl: './blue-knight-milestones.component.html',
    styleUrls: ['./blue-knight-milestones.component.scss'],
    standalone: false
})
export class BlueKnightMilestonesComponent extends ComponentBase {
  @Input()
  public company: Company;

  public properties: CompanyKey[] = [
    'commercialPartnerships',
    'rAndDPartnerships',
    'otherPartnerships',
    'challenges',
    'priorities',
    'compositionOfMatter',
    'securedIP',
    'filedIP',
    'addtlIPDetails',
    'fundingStage',
    'fundingStageDetails',
    'nonDilutiveFunding',
    'dilutiveFunding',
    'majorMilestones',
    'currentPharmaStage',
    'currentMedTechStage',
    'rndStageDetails',
    'regulatoryStatus',
    'anticipatedCommercialProductYr',
    'nonLeadRegStatus',
    'entryExitStrategy',
  ];

  public get hasData(): boolean {
    return this.properties.some(
      (property) => !isEmptyInputValue(this.company[property])
    );
  }
}
