import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  NgZone,
  Renderer2,
} from '@angular/core';
import { SwiperContainer } from 'swiper/element';
import { type SwiperOptions } from 'swiper/types'; 

const defaultSwiperOptions: Partial<SwiperOptions> = {
  centeredSlides: true,
  noSwiping: true,
  noSwipingSelector: '.dropdown-menu-content',
  pagination: {
    enabled: true,
    clickable: true,
  },
  injectStylesUrls: ['/assets/css/swiper.css'],
  slidesPerView: 1,
};
@Directive({
    selector: '[swiperElement]',
    standalone: false
})
export class SwiperDirective implements AfterViewInit {
  @Input() swiperConfig?: any;

  constructor(
    private _el: ElementRef<SwiperContainer>,
    private _renderer: Renderer2,
    private _ngZone: NgZone
  ) {
    this._renderer.setAttribute(this._el.nativeElement, 'init', 'false');
  }

  ngAfterViewInit(): void {
    const nativeElement = this._el.nativeElement;
    Object.assign(nativeElement, defaultSwiperOptions, this.swiperConfig);

    // swiper processing interferes with click events, so take it out of Angular zone. ADJQ-1501
    this._ngZone.runOutsideAngular(() => nativeElement.initialize());
  }
}
