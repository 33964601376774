import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ActionModalComponent,
  ActionOptions,
  ActionResult,
} from '@Common';
import { ComponentBase } from '@Common';
import { LocalizedTextIds } from 'company-finder-common';

@Component({
    selector: 'large-download-modal',
    templateUrl: './large-download-modal.component.html',
    styleUrls: ['./large-download-modal.component.scss'],
    standalone: false
})
export class LargeDownloadModalComponent
  extends ComponentBase
  implements OnChanges
{
  @Input()
  showModal: boolean;

  @ViewChild(ActionModalComponent, { static: true })
  modal: ActionModalComponent;

  @Output() close = new EventEmitter<boolean>();

  public ngOnChanges(): void {
    if (this.showModal) {
      this.modal.open();
    }
  }

  public handleAction(actionResult: ActionResult): void {
    this.close.emit(actionResult === ActionResult.Continue);
  }

  public get ActionOptions(): ActionOptions[] {
    return [
      {
        actionResult: ActionResult.Continue,
        displayText: this.LocPluralize(
          LocalizedTextIds.LargeDownloadModalDownload,
          this._deploymentContext.documentGenerationConfig
            .maxCompanyCountAllowedForPdf,
          this._deploymentContext.documentGenerationConfig
            .maxCompanyCountAllowedForPdf
        ),
        disabled: false,
      },
      {
        actionResult: ActionResult.Cancel,
        displayText: this.Loc(LocalizedTextIds.Cancel),
        disabled: false,
      },
    ];
  }
}
