import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { hasUserPreferences } from 'company-finder-common';
import { UserService } from '../services/user/user.service.js';

@Injectable({providedIn: 'root'})
export class CanActivateMyUpdatesRouteGuard  {
  constructor(private _userService: UserService, private router: Router) {}

  async canActivate(): Promise<boolean | UrlTree> {
    const user = await this._userService.getCachedUser();
    return hasUserPreferences(user)
      ? true
      : this.router.parseUrl('user/preferences');
  }
}
