import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
  SimpleChanges,
  OnChanges,
  QueryList,
} from '@angular/core';
import { map } from 'lodash-es';
import {
  determineSectorsAndSubsectors,
  DisplayItem,
  Filter,
  IUserSearch,
  LocalizedTextIds,
  Location,
  StatusDisplayItem,
  LocationWithCountAndCanonicalString,
  SectorDisplayItem,
  SectorWithCountAndCanonicalString,
  StatusWithCount,
} from 'company-finder-common';
import { CompanyService, ViewType } from '@Common';
import { SearchService } from '@Common';
import { SavedSearchBaseComponent } from '../saved-search/saved-search-base.component.js';
import {
  ActionModalComponent,
  ActionResult,
  ActionOptions,
} from '@Common';
import { SavedSearchCriteriaComponent } from './saved-search-criteria/saved-search-criteria.component.js';
import { DeploymentContext } from '@Common';

type CreationMode = 'new' | 'edit';

@Component({
    selector: 'saved-search-modal',
    templateUrl: './saved-search-modal.component.html',
    styleUrls: ['./saved-search-modal.component.scss'],
    standalone: false
})
export class SavedSearchModalComponent
  extends SavedSearchBaseComponent
  implements OnChanges
{
  @Input() defaultSearchName: string;
  @Output() add = new EventEmitter<IUserSearch>();
  @Output() delete = new EventEmitter<IUserSearch>();
  @ViewChild(ActionModalComponent, { static: true })
  modal: ActionModalComponent;
  @ViewChildren(SavedSearchCriteriaComponent)
  public savedSearchCriteriaComponents!: QueryList<
    SavedSearchCriteriaComponent<DisplayItem>
  >;
  public ViewTypes = ViewType;

  private _filter = Filter.emptyFilter;
  private _creationMode: CreationMode = 'new';
  // NOTE _roughEstimateOfFontWidth is an estimate derived, roughly, empirically
  private readonly _roughEstimateOfFontWidth = 7;

  public constructor(
    dc: DeploymentContext,
    companyService: CompanyService,
    searchService: SearchService
  ) {
    super(dc, searchService, companyService);
  }

  public get modalTitle(): string {
    return this._creationMode === 'new'
      ? this.Loc(LocalizedTextIds.SavedSearchModalCreate)
      : this.Loc(LocalizedTextIds.SavedSearchModalEdit, this.search.name);
  }

  public get actionOptions(): ActionOptions[] {
    return [
      {
        actionResult: ActionResult.Submit,
        displayText: this.Loc(LocalizedTextIds.Submit),
        disabled: false,
      },
      {
        actionResult: ActionResult.Cancel,
        displayText: this.Loc(LocalizedTextIds.Cancel),
        disabled: false,
      },
      {
        actionResult: ActionResult.Delete,
        displayText: this.Loc(LocalizedTextIds.Delete),
        disabled: this._creationMode === 'new',
      },
    ];
  }

  public get locationData(): LocationWithCountAndCanonicalString[] {
    return this.allLocationsWithCounts;
  }

  public get savedLocations(): Location[] {
    return this.locationSummary;
  }

  public set savedLocations(locationDisplayItems: Location[]) {
    if (this.search) {
      this.locationSummary = locationDisplayItems;
      this._filter.locations = map(
        locationDisplayItems,
        (locationData) => locationData.name
      );
      this.updateCount();
    }
  }

  public get sectorData(): SectorWithCountAndCanonicalString[] {
    return this.allSectorsWithCountsAndIds;
  }

  public get savedSectors(): SectorDisplayItem[] {
    return this.sectorSummary;
  }

  public set savedSectors(sectorDisplayItems: SectorDisplayItem[]) {
    if (this.search) {
      this.sectorSummary = sectorDisplayItems;
      const [primarySectors, primarySubSectors] =
        determineSectorsAndSubsectors(sectorDisplayItems);
      this._filter.primarySectors = primarySectors;
      this._filter.primarySubSectors = primarySubSectors;
      this.updateCount();
    }
  }

  public get statusData(): StatusWithCount[] {
    return this.allStatusesWithCounts;
  }

  public get savedStatuses(): StatusDisplayItem[] {
    return this.statusSummary;
  }

  public set savedStatuses(statuses: StatusDisplayItem[]) {
    if (this.search) {
      this.statusSummary = statuses;
      this._filter.locationStatuses = this.search.statuses;
      this.updateCount();
    }
  }

  public get currentSearchName(): string {
    return (this.search && this.search.name) || this.defaultSearchName;
  }

  public set currentSearchName(name: string) {
    if (this.search) {
      this.search.name = name;
    }
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.search) {
      this.reset();
    }
  }

  public getInputWidth(): unknown {
    const width =
      this.currentSearchName.length * this._roughEstimateOfFontWidth;
    return {
      width: `${width}px`,
    };
  }

  public async open(mode: CreationMode): Promise<void> {
    this.updateCount();
    this._creationMode = mode;
    this.modal.open();
    this.savedSearchCriteriaComponents.forEach((comp) => {
      comp.isOpen = false;
    });
  }

  public handleAction(actionResult: ActionResult): void {
    switch (actionResult) {
      case ActionResult.Submit:
        this.search.name = this.currentSearchName;
        this.add.emit(this.search);
        break;
      case ActionResult.Delete:
        this.delete.emit(this.search);
        break;
      default:
        break;
    }
  }

  // private methods
  private reset(): void {
    this._filter = Filter.emptyFilter;
    this.matchedCompaniesCount = undefined;
  }

  //TODO CAB - called in template, did not exist
  public editSavedSearch(search: any) {
    
  }


  private updateCount(): void {
    this.matchedCompaniesCount = undefined;
    // The Input value for this.search isn't available immediately, but the count
    // needs it. The setTimeout() apparently gives time for it to become available.
    setTimeout(async () => {
      await this.getCountOfCompaniesForSavedSearch();
    }, 0);
  }
}
