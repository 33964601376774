<div class="cf_overview_explore_sector-map" [ngClass]="{narrowScreen: narrowScreen}">
  <swiper-container swiperElement *ngIf="narrowScreen" [swiperConfig]="swiperConfig" class="swiper-container sector-map">
    <ng-container *ngFor="let data of sectorList">
      <swiper-slide *ngIf="sectorHasData(data.sector)">
        <div class="cf_overview_explore_sector-map-sector" [ngStyle]="{color: data.color}">
          <div class="cf_overview_explore_sector-map-section-label">
            <div class="cf_overview_explore_sector-map-section-title">{{data.label}}</div>
          </div>
          <div class="cf_overview_explore_sector-map-svg-outer" touchTooltip>
            <svg id="{{buildSvgId(data.sector)}}" class="cf_overview_explore_sector-map-svg"></svg>
          </div>
        </div>
      </swiper-slide>
    </ng-container>
  </swiper-container>

  <ng-container *ngIf="!narrowScreen">
    <div *ngFor="let data of sectorList" class="cf_overview_explore_sector-map-sector"
      [ngStyle]="{color: data.color}">
      <div class="cf_overview_explore_sector-map-section-label">
        <div class="cf_overview_explore_sector-map-section-title">{{data.label}}</div>
      </div>
      <div class="cf_overview_explore_sector-map-svg-outer" touchTooltip>
        <div class="cf_overview_explore_sector-map-svg-empty" *ngIf="!sectorHasData(data.sector)">
          {{Loc(LocalizedTextIds.SectorMapNoCompanies)}}
        </div>
        <svg id="{{buildSvgId(data.sector)}}" class="cf_overview_explore_sector-map-svg"></svg>
      </div>
    </div>
  </ng-container>
</div>