import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, Provider, NgModule, ErrorHandler, inject, provideAppInitializer } from '@angular/core';

import { ToastrModule } from 'ngx-toastr';
import { UiScrollModule } from 'ngx-ui-scroll';

import { AppRoutingModule } from './app-routing.module.js';
import { AppComponent } from './app.component.js';

import { ApplicationContext } from '@Common';
import { AuthnInterceptor } from '@Common';
import { VersionCheckInterceptor } from '@Common';
import { HttpErrorInterceptor } from '@Common';
import { AuthnService } from '@Common';
import { TokenService } from '@Common';
import { BreadcrumbsService } from '@Common';
import { CanActivateUpdateRouteGuard } from '@Common';
import { RoleCanActivateRouteGuard } from '@Common';
import { CanActivateMyUpdatesRouteGuard } from '@Common';
import { RemoveOldInternalRouteGuard } from '@Common';
import { PendingChangesGuard } from '@Common';
import { CompanyService } from '@Common';
import { DeploymentContext } from '@Common';
import { EventService } from '@Common';
import { LogService } from '@Common';
import { JnjErrorHandler } from '@Common';
import { LargeNumberCurrencyPipe } from '@Common';
import { Nl2BrPipe } from '@Common';
import { ProgressIndicatorService } from '@Common';
import { SearchService } from '@Common';
import { WebAnalyticsService } from '@Common';
import { TitleAndMetadata } from '@Common';

// top-level components
import { AcsComponent } from './sso/acs.component.js';
import { ByTheNumbersComponent } from './by-the-numbers/by-the-numbers.component.js';
import { CompanyDetailsComponent } from './company-details/company-details.component.js';
import { InvestmentSummaryComponent } from './company-details/components/investment-summary/investment-summary.component.js';
import { DealsTableComponent } from './company-details/components/deals-table/deals-table.component.js';
import { DetailSectionComponent } from './company-details/components/detail-section/detail-section.component.js';
import { FundingTableComponent } from './company-details/components/funding-table/funding-table.component.js';

import { DownloadComponent } from './company-details/components/download/download.component.js';
import { CompanyUpdateComponent } from './company-update/company-update.component.js';
import { FilterComponent } from './filter/filter.component.js';
import { StatusSelectorComponent } from './filter/components/status-selector/status-selector.component.js';
import { SectorDropdownComponent } from '@Common';
import { SimpleSelectorComponent } from '@Common';
import { HealthCheckComponent } from './health-check/health-check.component.js';
import { LoginFooterComponent } from './login-footer/login-footer.component.js';
import { LoginComponent } from './login/login.component.js';
import { MainComponent } from './main/main.component.js';
import { MyUpdatesComponent } from './my-updates/my-updates.component.js';
import { OverviewComponent } from './overview/overview.component.js';
import { PreferencesComponent } from './user/components/prefereneces/preferences.component.js';
import { SearchBarComponent } from './search-bar/search-bar.component.js';
import { SsoComponent } from './sso/sso.component.js';
import { UpdateComponent } from './update-generic/update.component.js';

// shared components
import { BreadcrumbsComponent } from '@Common';
import { CompanyLocationStatusComponent } from '@Common';
import { StatusListComponent } from '@Common';
import { CompanyLogoComponent } from '@Common';
import { CompanyTagsComponent } from '@Common';
import { DisclaimerComponent } from './main/disclaimer/disclaimer.component.js';
// eslint-disable-next-line max-len
import { JnjLogoutSuccessToastComponent } from '@Common';
import { JnjToastComponent } from '@Common';
import { LogInOutBannerComponent } from '@Common';
import { ProgressIndicatorComponent } from '@Common';
import { ShowMoreLessComponent } from '@Common';
import { SimpleTabUIComponent } from '@Common';
import { SiteHeaderComponent } from '@Common';
import { PickerModalComponent } from '@Common';
import { TagsPickerModalComponent } from '@Common';
import { MultiPickerModalComponent } from '../app/company-update/components/multi-picker-modal/multi-picker-modal.component.js';
import { CreateCustomTagComponent } from '@Common';
import { MutableCollectionComponent } from '@Common';
import { ActionModalComponent } from '@Common';
import { NotificationsModalComponent } from './user/components/notifications-modal/notifications-modal.component.js';
import { DropdownMenuComponent } from '@Common';
import { ModalOverlayComponent } from '@Common';
import { StayInTheLoopComponent } from '@Common';
import { ActionButtonComponent } from '@Common';
import { ViewportContainerComponent } from '@Common';
import { SelectInputComponent } from '@Common';
import { ToggleInputComponent } from '@Common';
import { StickyOnScrollComponent } from '@Common';
import { LocalizedTextSnippetComponent } from '@Common';
import { CompanyStatusIconComponent } from '@Common';

// by-the-numbers components
import { AnnulusComponent } from './by-the-numbers/components/community-and-diversity/components/annulus/annulus.component.js';
import { CommunityAndDiversityComponent } from './by-the-numbers/components/community-and-diversity/community-and-diversity.component.js';
import { StrategicCollaborationComponent } from './by-the-numbers/components/strategic-collaboration/strategic-collaboration.component.js';

// overview components
import { DealsComponent } from './overview/components/deals/deals.component.js';
import { ExploreComponent } from './overview/components/explore/explore.component.js';
import { LocationIndicatorComponent } from './overview/components/explore/components/location-indicator/location-indicator.component.js';
import { GeoVisualizerComponent } from './overview/components/explore/components/geo-visualizer/geo-visualizer.component.js';
import { NewCompaniesComponent } from './overview/components/new-companies/new-companies.component.js';
import { SectorDetailsComponent } from './overview/components/deals/components/sector-details/sector-details.component.js';
import { SectorMapComponent } from './overview/components/explore/components/sector-map/sector-map.component.js';
import { StageComponent } from './overview/components/stage/stage.component.js';
import { SummaryComponent } from './overview/components/summary/summary.component.js';
import { TagCloudComponent } from './overview/components/explore/components/tag-cloud/tag-cloud.component.js';

// search bar components
import { NavigationPanelComponent } from './search-bar/components/navigation-panel/navigation-panel.component.js';

// search result components
import { CompanySummaryComponent } from './search-results/components/company-summary/company-summary.component.js';
import { SearchResultsComponent } from './search-results/search-results.component.js';
import { SortControlComponent } from './search-results/components/sort-control/sort-control.component.js';
import { LargeDownloadModalComponent } from './search-results/components/large-download-modal/large-download-modal.component.js';

// company-details components
import { ContactComponent } from './company-details/components/contact/contact.component.js';
import { BlueKnightInformationComponent } from './company-details/components/blue-knight-information/blue-knight-information.component.js';
import { PropertyDetailListComponent } from './company-details/components/property-detail-list/property-detail-list.component.js';
import { BlueKnightMilestonesComponent } from './company-details/components/blue-knight-milestones/blue-knight-milestones.component.js';
import { JnjInformationComponent } from './company-details/components/jnj-information/jnj-information.component.js';
import { PublicInformationComponent } from './company-details/components/public-information/public-information.component.js';
import { NgxPaginationModule } from 'ngx-pagination';

// my-updates components
import { PillComponent } from './my-updates/components/pill/pill.component.js';
import { UpdateSummaryComponent } from './my-updates/components/update-summary/update-summary.component.js';

// self-update components
import { ApproveDeclineComponent } from './company-update/components/approve-decline/approve-decline.component.js';
// eslint-disable-next-line max-len
import { BlueKnightInformationUpdateComponent } from './company-update/components/blue-knight-information-update/blue-knight-information-update.component.js';
// eslint-disable-next-line max-len
import { BlueKnightMilestonesUpdateComponent } from './company-update/components/blue-knight-milestones-update/blue-knight-milestones-update.component.js';
// eslint-disable-next-line max-len
import { CompanyUpdateConfirmationComponent } from './company-update/components/company-update-confirmation/company-update-confirmation.component.js';
import { CompanyUpdateModalComponent } from './company-update/components/company-update-modal/company-update-modal.component.js';
import { DealModalComponent } from './company-update/components/deal-modal/deal-modal.component.js';
import { EditItemComponent } from './company-update/components/edit-item/edit-item.component.js';
import { EditItemGroupComponent } from './company-update/components/edit-item-group/edit-item-group.component.js';
import { EditsSummaryComponent } from './company-update/components/edits-summary/edits-summary.component.js';
import { FundingModalComponent } from './company-update/components/funding-modal/funding-modal.component.js';
import { JnJInformationUpdateComponent } from './company-update/components/jnj-information-update/jnj-information-update.component.js';
import { NotificationsComponent } from './company-update/components/notifications/notifications.component.js';
// eslint-disable-next-line max-len
import { PublicInformationUpdateComponent } from './company-update/components/public-information-update/public-information-update.component.js';
import { ReviewDealFundingComponent } from './company-update/components/review-deal-funding/review-deal-funding.component.js';
import { ReviewModalComponent } from './main/review-modal/review-modal.component.js';
import { UpdateHeaderComponent } from './company-update/components/header/update-header.component.js';
import { UserService } from '@Common';

// user preferences components
import { CompaniesPickerModalComponent } from './user/components/companies-picker-modal/companies-picker-modal.component.js';
import { SectorsPickerModalComponent } from './user/components/sectors-picker-modal/sectors-picker-modal.component.js';
import { LocationsPickerModalComponent } from './user/components/locations-picker-modal/locations-picker-modal.component.js';
import { SavedSearchModalComponent } from './user/components/saved-search-modal/saved-search-modal.component.js';
import { SavedSearchSummaryComponent } from './user/components/saved-search/saved-search-summary.component.js';
import { PreferencesSectionComponent } from './user/components/preferences-section/preferences-section.component.js';
// eslint-disable-next-line max-len
import { SavedSearchCriteriaBaseComponent } from './user/components/saved-search-modal/saved-search-criteria-base/saved-search-criteria-base.component.js';
import { SavedSearchCriteriaComponent } from './user/components/saved-search-modal/saved-search-criteria/saved-search-criteria.component.js';
// eslint-disable-next-line max-len
import { SectorSelectorComponent } from './user/components/saved-search-modal/sector-selector/sector-selector.component.js';
// eslint-disable-next-line max-len
import { SavedSearchSimpleSelectorComponent } from './user/components/saved-search-modal/saved-search-simple-selector/saved-search-simple-selector.component.js';
import { SavedSearchBaseComponent } from './user/components/saved-search/saved-search-base.component.js';
import { KeepInsideViewportDirective } from '@Common';
import { SwiperDirective } from '@Common';
import { TouchTooltipDirective } from '@Common';
import { NewsComponent } from './company-details/components/news/news/news.component.js';
import { NgxD3Service } from '@Common';
import { CompanyUpdateService } from './company-update/services/company-update.service.js';
import { ReviewEditsService } from './company-update/services/review-edits.service.js';
import { TrimModule } from '@Common';

const providers: any[] = [ // TODO CAB
  provideAppInitializer(() => {
        const initializerFn = ((ctx: DeploymentContext) => () => ctx.initialize())(inject(DeploymentContext));
        return initializerFn();
      }),
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthnInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: VersionCheckInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true,
  },
  {
    provide: ErrorHandler,
    useClass: JnjErrorHandler,
  },
  ApplicationContext,
  AuthnService,
  BreadcrumbsService,
  CanActivateUpdateRouteGuard,
  CanActivateMyUpdatesRouteGuard,
  RemoveOldInternalRouteGuard,
  RoleCanActivateRouteGuard,
  PendingChangesGuard,
  CompanyService,
  CompanyUpdateService,
  CurrencyPipe,
  DatePipe,
  DeploymentContext,
  NgxD3Service,
  EventService,
  LogService,
  ProgressIndicatorService,
  ReviewEditsService,
  SearchService,
  TitleAndMetadata,
  TokenService,
  UserService,
  WebAnalyticsService,
];

@NgModule({ declarations: [
        AcsComponent,
        AppComponent,
        ApproveDeclineComponent,
        AnnulusComponent,
        BlueKnightInformationComponent,
        PropertyDetailListComponent,
        BlueKnightInformationUpdateComponent,
        BlueKnightMilestonesComponent,
        BlueKnightMilestonesUpdateComponent,
        BreadcrumbsComponent,
        ByTheNumbersComponent,
        CommunityAndDiversityComponent,
        CompanyDetailsComponent,
        InvestmentSummaryComponent,
        DealsTableComponent,
        EditItemGroupComponent,
        FundingTableComponent,
        CompanyUpdateComponent,
        CompanyLocationStatusComponent,
        CompanyLogoComponent,
        CompaniesPickerModalComponent,
        CompanySummaryComponent,
        CompanyTagsComponent,
        CompanyUpdateConfirmationComponent,
        CompanyUpdateModalComponent,
        ContactComponent,
        CreateCustomTagComponent,
        DealModalComponent,
        DealsComponent,
        DisclaimerComponent,
        DownloadComponent,
        EditItemComponent,
        EditsSummaryComponent,
        ExploreComponent,
        FilterComponent,
        StatusSelectorComponent,
        SectorSelectorComponent,
        SectorDropdownComponent,
        SavedSearchSimpleSelectorComponent,
        DetailSectionComponent,
        SectorSelectorComponent,
        SimpleSelectorComponent,
        StatusListComponent,
        HealthCheckComponent,
        JnjInformationComponent,
        JnJInformationUpdateComponent,
        JnjLogoutSuccessToastComponent,
        JnjToastComponent,
        KeepInsideViewportDirective,
        LargeDownloadModalComponent,
        LargeNumberCurrencyPipe,
        LocationIndicatorComponent,
        GeoVisualizerComponent,
        LocationsPickerModalComponent,
        LoginComponent,
        LoginFooterComponent,
        LogInOutBannerComponent,
        MainComponent,
        MyUpdatesComponent,
        NavigationPanelComponent,
        NewCompaniesComponent,
        NewsComponent,
        Nl2BrPipe,
        NotificationsComponent,
        FundingModalComponent,
        OverviewComponent,
        PickerModalComponent,
        PillComponent,
        PublicInformationComponent,
        PublicInformationUpdateComponent,
        ProgressIndicatorComponent,
        ReviewDealFundingComponent,
        ReviewModalComponent,
        SwiperDirective,
        SearchBarComponent,
        SearchResultsComponent,
        SectorDetailsComponent,
        SectorMapComponent,
        SectorsPickerModalComponent,
        ShowMoreLessComponent,
        SimpleTabUIComponent,
        SiteHeaderComponent,
        SortControlComponent,
        SsoComponent,
        StageComponent,
        StayInTheLoopComponent,
        StrategicCollaborationComponent,
        SummaryComponent,
        TagCloudComponent,
        TagsPickerModalComponent,
        MultiPickerModalComponent,
        TouchTooltipDirective,
        UpdateHeaderComponent,
        UpdateSummaryComponent,
        PreferencesComponent,
        NotificationsModalComponent,
        MutableCollectionComponent,
        PreferencesSectionComponent,
        SavedSearchSummaryComponent,
        ActionModalComponent,
        SavedSearchModalComponent,
        DropdownMenuComponent,
        SavedSearchCriteriaBaseComponent,
        SavedSearchCriteriaComponent,
        SavedSearchBaseComponent,
        ModalOverlayComponent,
        ActionButtonComponent,
        ViewportContainerComponent,
        SelectInputComponent,
        ToggleInputComponent,
        StickyOnScrollComponent,
        UpdateComponent,
        LocalizedTextSnippetComponent,
        CompanyStatusIconComponent,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        FormsModule,
        NgxPaginationModule,
        ReactiveFormsModule,
        ToastrModule.forRoot({
            closeButton: true,
            extendedTimeOut: 0,
            positionClass: 'toast-center-center', // Ensure this positioning is overridden in src/styles.scss
            tapToDismiss: false,
            preventDuplicates: true,
            countDuplicates: true,
            resetTimeoutOnDuplicate: true,
            timeOut: 0,
            toastComponent: JnjToastComponent,
        }),
        TrimModule,
        UiScrollModule,
        CommonModule], providers: [...providers, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule {}
