import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RelayState } from 'company-finder-common';

import { AuthnService } from '@Common';
import { DeploymentContext } from '@Common';
import { ComponentBase } from '@Common';

@Component({
    selector: 'sso',
    templateUrl: './sso.component.html',
    styleUrls: ['./sso.component.scss'],
    standalone: false
})
export class SsoComponent extends ComponentBase implements OnInit {
  // public properties
  public showProgressIndicator = false;

  /**
   * Returns the URL the user was attempting
   * to access before being redirected to log in.
   * If there is no return URL, return '/'
   * to route the user to the Explore page
   */
  private get returnUrl(): string {
    return this.route.snapshot.queryParams['returnUrl'] ?? '/';
  }

  /**
   * Returns the current return URL for the the Return Url,
   * including encoding any existing query parameters so they
   * are persistited.
   */
  private get encodedReturnUrl(): string {
    if (!this.returnUrl) {
      return '/';
    }

    const returnUrlSplit = this.returnUrl.split('?');

    return returnUrlSplit[1]
      ? `targetQueryParams=${encodeURIComponent(returnUrlSplit[1])}`
      : `${returnUrlSplit[0]}`;
  }

  /**
   * Returns the URL of the ACS page, hosted or unhosted, to redirect
   * the user to after our API has authenticated them
   */
  private get appAcsUrl(): string {
    return `${
      this.context.hosted()
        ? this.context.hostingSiteUrl
        : window.location.origin
    }/acs`;
  }

  /**
   * The URL of the API for Ping to redirect to with
   * authentication detail
   */
  private get acsApiEndpoint(): string {
    return this.context.buildApiUrl('/authn/sso/acs');
  }

  constructor(
    deploymentContext: DeploymentContext,
    private authnService: AuthnService,
    private context: DeploymentContext,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super(deploymentContext);
  }

  // public methods
  public async ngOnInit(): Promise<void> {
    this.authnService.clearJnjLoggedOut();
    if (this.authnService.isAuthenticated) {
      this.router.navigateByUrl(this.returnUrl, { replaceUrl: true });
      return;
    }

    this.showProgressIndicator = true;

    const relayState = new RelayState(
      this.acsApiEndpoint,
      `${this.appAcsUrl}?returnUrl=${this.encodedReturnUrl}`
    );

    setTimeout(async () => {
      await this.initiateSamlFlow(relayState);
    }, this.context.progressIndicator.pauseForStaticIndicator);
  }

  // private methods
  private async initiateSamlFlow(relayState: RelayState): Promise<void> {
    const idpLoginUrl = await this.authnService.sso(relayState);
    this.context.setSsoHref(idpLoginUrl);
  }
}
