<ng-template #statusTemplate>
  <div *ngFor="let statusGroup of menuOptions" class="group">
      <label class="group-name">{{statusGroup.groupName}}</label>
      <div *ngFor="let menuOption of statusGroup.options" class="status-container">
        <input
        type="checkbox"
        class="status-checkbox"
        [attr.id]="menuOption.id"
        [attr.name]="menuOption.id"
        [(ngModel)]="menuOption.value"
        (ngModelChange)="relaySelect(menuOption)"
        />
        <label 
          [for]="menuOption.id"          
          class="status-label"
        >
          {{menuOption.label}}
        </label>       
      </div>
    </div>
</ng-template>

<dropdown-menu 
    class="statuses"
    label="{{label}}"
    [menuOptions]="menuOptions"
    [menuStructureTemplate]="statusTemplate"
    (select)="relaySelect($event)"
    (afterSelect)="relayAfterSelect($event)">>
</dropdown-menu>