declare let hbspt: {
  forms: {
    create: (opts: {
      portalId: string;
      formId: string;
      target: string;
    }) => unknown;
  };
};
import { Component, Input } from '@angular/core';
import { Company, Contact, LocalizedTextIds } from 'company-finder-common';
import { ComponentBase } from '@Common';
import { DeploymentContext } from '@Common';
import { WebAnalyticsService } from '@Common';

@Component({
    selector: 'contact-component',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
    standalone: false
})
export class ContactComponent extends ComponentBase {
  // public properties
  @Input()
  public role: string;
  @Input()
  public company: Company;
  @Input()
  public contact: Contact;
  @Input()
  public showTitle: boolean;
  @Input()
  public showEmailLink: boolean;
  @Input()
  public showHubSpotLink = false;
  public hasBeenSubmitted = false;
  public isDirty = false;
  public isShowingHubSpotForm = false;

  // private properties
  private _hubSpotFormId = '';
  private _hubSpotPortalId = '';
  private readonly _maxTimerRetries = 10;
  private _timerRetries = 0;

  constructor(
    deploymentContext: DeploymentContext,
    private _webAnalyticsService: WebAnalyticsService
  ) {
    super(deploymentContext);
    const hubSpotConfig = this._deploymentContext.hubSpotIntegrationConfig;
    if (hubSpotConfig) {
      this._hubSpotFormId = hubSpotConfig.formId;
      this._hubSpotPortalId = hubSpotConfig.portalId;
    }
  }

  // public getters
  public get dialogCss(): string {
    if (this.isDirty) {
      if (this.hasBeenSubmitted) {
        return 'contact-hub-spot-overlay-content-dialog-success';
      } else {
        return 'contact-hub-spot-overlay-content-dialog-error';
      }
    } else {
      return 'contact-hub-spot-overlay-content-dialog-pristine';
    }
  }

  // public methods
  public hideHubSpotForm(submitted: boolean): void {
    this.isShowingHubSpotForm = false;
    if (submitted) {
      this._webAnalyticsService.trackEvent('submitted-contact-form', {
        label: this.Loc(
          LocalizedTextIds.ContactFor,
          this.contact.name,
          this.company.name
        ),
      });
    }
  }

  public showHubSpotForm(): void {
    this._webAnalyticsService.trackEvent('show-contact-form', {
      label: this.Loc(
        LocalizedTextIds.ContactFor,
        this.contact.name,
        this.company.name
      ),
    });
    this.isShowingHubSpotForm = true;
    this.hasBeenSubmitted = false;
    this.isDirty = false;
    hbspt.forms.create({
      portalId: this._hubSpotPortalId,
      formId: this._hubSpotFormId,
      target: '#hubSpotForm',
    });
    const timer = setInterval(() => {
      const form = document.getElementById(
        `hsForm_${this._hubSpotFormId}`
      ) as HTMLFormElement;
      if (form) {
        clearInterval(timer);
        this._timerRetries = 0;
        form.onsubmit = (_ev: Event) => {
          this.hasBeenSubmitted = form.checkValidity();
          this.isDirty = true;
        };
        // FUTURE: This is just a proof-of-concept at the moment, to show that we can populate a field in the form,
        // so when a HubSpot form is delivered to us with hidden fields for recipient's name, and company to inquire about,
        // we'll just target the appropriate hidden inputs.
        this.updateHubSpotForm();
      } else {
        ++this._timerRetries;
        if (this._timerRetries >= this._maxTimerRetries) {
          clearInterval(timer);
          this._timerRetries = 0;
          throw new Error('Unable to create HubSpot form');
        }
      }
    }, 500);
  }

  // private methods
  private updateHubSpotForm(): void {
    // NOTE: There are a few issues at play here. First, according to the HubSpot documentation, we are supposed to call
    // change() on pre-populated fields, as described here: https://developers.hubspot.com/manipulating-forms-with-jquery
    // It describes, however, the use of jQuery in an onFormReady handler in the HubSpot create() call. We could pull in jQuery
    // if we had to, but I'm choosing not to do so. More problematic, though, is that the onFormReady handler fails due to an
    // iFrame cross-origin problem we've elected not to investigate at this time. The next problem is that the change event is
    // not enough by itself. The workaround below was suggested through the following HubSpot forum posts:
    // https://integrate.hubspot.com/t/embedded-form-clears-prepopulated-fields-on-focus-submit/2701/2
    // https://integrate.hubspot.com/t/hubspot-form-programmatically-set-field-value-via-jquery/2662/4
    const recipientField = document.getElementById(
      `recipient-${this._hubSpotFormId}`
    ) as HTMLInputElement;
    const companyToContactField = document.getElementById(
      `company_of_interest-${this._hubSpotFormId}`
    ) as HTMLInputElement;
    const pushValueToHubSpotForm = (
      value: string,
      inputField: HTMLInputElement
    ) => {
      inputField.value = value;
      const event = document.createEvent('HTMLEvents');
      event.initEvent('change', true, true);
      inputField.dispatchEvent(event);
      event.initEvent('input', true, true);
      inputField.dispatchEvent(event);
      event.initEvent('blur', true, true);
      inputField.dispatchEvent(event);
    };
    pushValueToHubSpotForm(this.contact.name, recipientField);
    pushValueToHubSpotForm(this.company.name, companyToContactField);
  }
}
