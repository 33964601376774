<div class="cf_search-results-sort-by" *ngIf="companies?.length > 0">
  <div class="cf_search-results-sort-by-content">
    <div class="cf_search-results-sort-by-content-label">{{Loc(LocalizedTextIds.SortControlSortBy)}}</div>
    <div class="cf_search-results-sort-by-content-dropdown">
      <div class="cf_search-results-sort-by-content-dropdown-value" (click)="setShowMenu($event)">{{Loc(sortType)}}</div>
      <div class="cf_search-results-sort-by-content-dropdown-content" *ngIf="showMenu">
        <a *ngFor="let sortTypeValue of sortTypes" (click)="sort(sortTypeValue)">{{Loc(sortTypeValue)}}</a>
      </div>
    </div>
    <div class="cf_search-results-sort-by-content-dropdown-caret" (click)="setShowMenu($event)"></div>
  </div>
</div>