<action-modal
  [modalTitle]="modalTitle"
  [actionOptions]="isOnBoardingWizard ? wizardActionOptions : actionOptions"
  [isOnBoardingWizard]="isOnBoardingWizard"
  [user]="user"
  [wizardStep]="wizardStep"
  [totalMatchedCompanies]="totalMatchedCompanies"
  (action)="onClose($event)">
  <p class="picker-modal-blurb">{{ blurb }}</p>
  <div class="search-bar">
    <span class="search-icon-img"></span>
    <input #searchText autofocus id="search-text" name="search" placeholder="{{ filterPlaceholder }}" type="text"
      [value]="initialSearchText || ''" (input)="changeSearch($event)" />
      <span class="match-count">{{Loc(LocalizedTextIds.PickerModalMatches)}}{{matchedNonCustomItems.length}}</span>
      <div class="clear" (click)="onClearClicked()"></div>
  </div>
  <div class="bottom-form">
    <form class="modalForm">
      <ng-template #noItemMatchDefaultTemplate let-searchTerm="lastSearchTermSubmitted">
        <div class="no-matches">
          <div class="no-matches-img"></div>
          <h2 class="no-matches-title">{{Loc(LocalizedTextIds.PickerModalNoObjects, objectsTypeName)}}</h2>
          <p class="no-matches-blurb">{{Loc(LocalizedTextIds.PickerModalWeCouldntFind, objectsTypeName)}}<br>{{Loc(LocalizedTextIds.PickerModalPleaseCheckYour)}}</p>
        </div>
      </ng-template>
      <ng-template *ngIf="noItemMatched" [ngTemplateOutlet]="noItemMatchTemplate || noItemMatchDefaultTemplate"
        [ngTemplateOutletContext]="{lastSearchTermSubmitted: lastSearchTermSubmitted}"></ng-template>
      <ng-container *ngFor="let item of matchedNonCustomItems" >
        <label *ngIf="isItemVisible(item)" class="item-info" [ngClass]="{ 'selected': isSelected(item) }">
          <ng-template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{item: item}">
          </ng-template>
          <div class="select-checkbox">
            <input type="checkbox" class='checkbox' [checked]="isSelected(item)" (click)="toggleSelection(item)" />
          </div>
        </label>
      </ng-container>
      <div *ngIf="customItems.length > 0 && !noItemMatched">
        <div class="custom-items-label">{{customItemsTitle}}:</div>
        <label *ngFor="let item of customItems" class="item-info" [ngClass]="{ 'selected': isSelected(item) }">
          <ng-template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{item: item}">
          </ng-template>
          <div class="select-checkbox">
            <input type="checkbox" class='checkbox' [checked]="isSelected(item)" (click)="toggleSelection(item)" />
          </div>
        </label>
      </div>
    </form>
  </div>
</action-modal>
