import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { IframeResizerService } from '../../services/iframe/iframe.resizer.service.js';
import { DeploymentContext } from '../../utilities/deployment-context/deployment-context.js';
import { iframeResizer } from '@iframe-resizer/child';

/**
 * Encapsulates logic needed to calculate the size of the viewport when the app is hosted in an iframe.
 * If you wish for an element to be positioned with respect to the viewport, render it
 * from inside this component.
 */
@Component({
    selector: 'viewport-container',
    templateUrl: './viewport-container.component.html',
    styleUrls: ['./viewport-container.component.scss'],
    standalone: false
})
export class ViewportContainerComponent implements OnInit, OnDestroy {
  public yOffset: number;
  public windowHeight: number;
  private _scrollEventSubscription: Subscription;

  constructor(private _scrollingService: IframeResizerService, private context: DeploymentContext) { }

  ngOnInit(): void {
    if (this.context.hosted()) {
      this._scrollEventSubscription =
        this._scrollingService.iframeSubject.subscribe((event) =>
          this.handleScrollEvent(event)
        );
    }
  }

  ngOnDestroy(): void {
    this._scrollEventSubscription?.unsubscribe();
  }

  private handleScrollEvent(windowProperties: iframeResizer.ParentProps) {
    this.yOffset = -1 * windowProperties.iframe.top;
    this.windowHeight = windowProperties.viewport.height;
  }
}
