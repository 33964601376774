<ng-template #locationItemTemplate let-locationAndCountData="optionData">
  <ng-container *ngFor="let locationAndCount of locationAndCountData">
    <label class="item-info">
      <div class="item-name-and-company-count">
        <div class="item-name">{{getDisplayName(locationAndCount)}}</div>
        <div class="company-count">{{LocPluralize(LocalizedTextIds.CompanyCount, locationAndCount.count, locationAndCount.count)}} | {{locationAndCount.location.city}}</div>
      </div>
      <div class="select-checkbox">
        <input type="checkbox" class='checkbox' [checked]="isSelected(locationAndCount.location)" (click)="updateSelection(locationAndCount.location)"/>
      </div>
    </label>
  </ng-container>
</ng-template>
<ng-template #statusItemTemplate let-statusAndCountData="optionData">
  <ng-container *ngFor="let statusAndCount of statusAndCountData">
    <label class="item-info">
      <div class="item-name-and-company-count">
        <div class="item-name">{{getDisplayName(statusAndCount)}}</div>
        <div class="company-count">{{LocPluralize(LocalizedTextIds.CompanyCount, statusAndCount.count, statusAndCount.count)}}</div>
      </div>
      <div class="select-checkbox">
        <input type="checkbox" class='checkbox' [checked]="isSelected(statusAndCount.statusItem)" (click)="updateSelection(statusAndCount.statusItem)"/>
      </div>
    </label>
  </ng-container>
</ng-template>

<div class="saved-search-simple-selector">
  <ng-container *ngIf="compareProperty === 'location'">
    <ng-container *ngTemplateOutlet="locationItemTemplate; context: { optionData: optionData }"></ng-container>
  </ng-container>
  <ng-container *ngIf="compareProperty === 'status'">
    <ng-container *ngTemplateOutlet="statusItemTemplate; context: { optionData: optionData }"></ng-container>
  </ng-container>
</div>
