<picker-modal
    (action)="doAction($event)"
    (wizardAction)="doWizardAction($event)"
    (select)="doSelect($event)"
    [user]="user"
    [totalMatchedCompanies]="totalMatchedCompanies"
    [(showModal)]="showModal"  
    modalTitle={{Loc(LocalizedTextIds.LocationsPickerModalAddLocations)}}
    blurb={{Loc(LocalizedTextIds.LocationsPickerModalOurLocation)}}
    objectsTypeName="locations"
    filterPlaceholder={{Loc(LocalizedTextIds.LocationsPickerModalFilterList)}}
    [compareFunc]="compareItemsWithCanonicalString"
    [filterFunc]="doFilter"
    [isOnBoardingWizard]="isOnBoardingWizard"
    [wizardStep]="2">
    <ng-template #itemTemplate let-locationAndCount="item">
        <div class="item-name-and-company-count">
            <div class="item-name selected-text-color">{{locationAndCount.location.name}}</div>
            <div class="company-count">{{LocPluralize(LocalizedTextIds.CompanyCount, locationAndCount.count, locationAndCount.count)}} | {{locationAndCount.location.city}}</div>
        </div>
    </ng-template>
</picker-modal>