<div class="saved-search-summary" [ngClass]="{ 'is-modal': isModalView }">
  <div *ngIf="!isModalView" class="title">
    <div>{{searchTitle}}</div>
    <div class="edit" (click)="editQuery()">+ {{Loc(LocalizedTextIds.Edit)}}</div>
  </div>
  <div [ngClass]="{ 'scroll-area' : isModalView }">
    <ng-container *ngFor="let criteria of summary">
      <span class="saved-search-and" *ngIf="criteria.length > 0">
        <span class="saved-search-or" *ngFor="let term of criteria">
          {{term.displayName}}
        </span>
      </span>
    </ng-container>
  </div>
  <div *ngIf="!isModalView && matchedCompaniesCount" class="matched-companies-count">{{LocPluralize(LocalizedTextIds.SavedSearchSummaryCompaniesMatched, matchedCompaniesCount, matchedCompaniesCount)}}</div>
</div>
