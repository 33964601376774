<ng-template #listMenuStructureTemplate let-dropdownMenuComponent="dropdownMenuComponent" let-menuOptions="menuOptions"
    let-parent="parent" , let-columns="columns" let-itemsPerColumn="itemsPerColumn">
    <ng-container *ngFor="let column of columns">
        <div class="column">
            <ng-container *ngFor="let menuOption of menuOptions; let i = index">
                <ng-container *ngIf="includeMenuOptionInColumn(column, itemsPerColumn, i)">
                    <div class="dropdown-menu-option" [ngClass]="{ 'parent': parent }">
                        <ng-container *ngTemplateOutlet="menuOptionDisplayTemplate;
                            context: { menuOption: menuOption, dropdownMenuComponent: dropdownMenuComponent }">
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
</ng-template>

<ng-template #menuOptionDisplayTemplate let-menuOption="menuOption" let-dropdownMenuComponent="dropdownMenuComponent">
    <input type="checkbox" class="checkbox"
        [ngClass]="{ 'indeterminate': dropdownMenuComponent.indeterminateOptions[menuOption.id] }"
        [attr.id]="menuOption.id" [attr.name]="menuOption.id" [(ngModel)]="menuOption.value"
        (change)="dropdownMenuComponent.updateSelection(menuOption)">
    <label [for]="menuOption.id" [ngClass]="{'selected': menuOption.value}">{{menuOption.label}}</label>
</ng-template>

<dropdown-menu [ngClass]="class" [label]="label" defaultValue="All" [menuOptions]="menuOptions"
    [menuStructureTemplate]="listMenuStructureTemplate"
    [optionDisplayTemplate]="menuOptionDisplayTemplate" (select)="relaySelect($event)"
    (afterSelect)="relayAfterSelect($event)">
</dropdown-menu>