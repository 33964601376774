<div class="update-summary link" [ngClass]="{narrowScreen: narrowScreen}" (click)="handleClick()">
  <div class="content">
    <div *ngIf="isNewUpdate" class="is-new-dot"></div>
    <div class="inner">
      <div class="company-name">{{company && company.name}}</div>
      <ng-container *ngIf="update.eventType === EventOfInterestTypes.CompanyUpdate">
        <div *ngIf="fieldsUpdated" class="title">{{fieldsUpdated}} on {{dateDisplay}}.</div>
        <div *ngIf="sharedUpdate" class="title">Shared an update on {{dateDisplay}}.</div>
      </ng-container>
      <div *ngIf="update.eventType === EventOfInterestTypes.NewCompany" class="title">
        Joined {{locationDisplay}} on {{dateDisplay}}
      </div>
      <div *ngIf="sharedUpdate" class="sharedUpdate">
        <div class="value">{{sharedUpdate}}</div>
      </div>
      <div *ngIf="update.eventType === EventOfInterestTypes.InTheNews" class="news">
        <div class="article-metadata">
          <div class="source">{{update.articleSource}}</div>
          <div class="separator">|</div>
          <div class="date">{{dateDisplay}}</div>
        </div>
        <div class="article-cta" (click)="openLink($event)">
          <div class="article-data">
            <div class="article-title">{{update.articleTitle}}</div>
            <div class="excerpt">{{update.excerpt}}</div>
          </div>
          <div class="cta"></div>
        </div>
      </div>
    </div>
    <div class="logo">
      <company-logo [company]="company" [size]="logoSize" show="true"></company-logo>
    </div>
  </div>
  <div class="pills">
    <ng-container *ngFor="let pill of pills">
      <pill [type]="pill.type" [value]="pill.value"></pill>
    </ng-container>
  </div>
</div>
