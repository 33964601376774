<div class="title">
    {{Loc(LocalizedTextIds.CompanyDetailsInvestmentSummary)}}
  </div>
  <table class="investment">
    <tr>
      <th class="header">{{Loc(LocalizedTextIds.CompanyDetailsHighestLevel)}}</th>
      <td>{{highestLevelOfFundingSecured}}</td>
    </tr>
    <tr>
      <th class="header">{{Loc(LocalizedTextIds.CompanyDetailsTotalDollars)}}</th>
      <td>{{totalSecuredAndContingentAmount | currency:'USD':'symbol-narrow':'1.0-0'}}</td>
    </tr>
  </table>