<div class="cf_overview_deals_sector-details" *ngIf="primarySectorCount">
  <div class="cf_overview_deals_sector-details-heading" [ngClass]="{ 'cf_overview_deals_sector-details-heading-border': !isOpen }" (click)="isOpen = !isOpen">
    <div class="cf_overview_deals_sector-details-heading-sector-display">
      <div class="cf_overview_deals_sector-details-heading-caret-closed" *ngIf="!isOpen"></div>
      <div class="cf_overview_deals_sector-details-heading-caret-open" *ngIf="isOpen"></div>
      <div>{{sectorDisplayName}}</div>
    </div>
    <div class="cf_overview_deals_sector-details-heading-graphics">
      <div class="cf_overview_deals_sector-details-heading-graphics-bar-container">
        <div class="cf_overview_deals_sector-details-heading-graphics-bar" [ngStyle]="barStyle()"></div>
      </div>
      <div class="cf_flex-center-center">
        <div class="cf_overview_deals_sector-details-heading-graphics-count">{{primarySectorCount.dealCount}}</div>
      </div>
    </div>
  </div>
  <div class="cf_overview_deals_sector-details-sub-sectors" *ngIf="isOpen">
    <div class="cf_overview_deals_sector-details-sub-sector" *ngFor="let primarySubSectorCount of sortedPrimarySubSectorCounts">
      <div class="cf_overview_deals_sector-details-sub-sector-graphics">
          <div class="cf_overview_deals_sector-details-sub-sector-graphics-name" [title]="primarySubSectorCount.primarySubSector">{{primarySubSectorCount.name}}</div>
          <div class="cf_overview_deals_sector-details-sub-sector-graphics-bar-container">
            <div class="cf_overview_deals_sector-details-sub-sector-graphics-bar" [ngStyle]="subSectorBarStyle(primarySubSectorCount)"></div>
          </div>
        <div class="cf_overview_deals_sector-details-sub-sector-graphics-count">{{primarySubSectorCount.dealCount}}</div>
      </div>
    </div>
  </div>
</div>
