<app-modal-overlay [showModal]="showModal" [width]="width" [maxHeight]="maxHeight">
  <div class="modal-header">
    <div class="modal-title">
      <div *ngIf="isOnBoardingWizard" class="wizard-indicator">
        <div class="wizard-dot" [ngClass]="{ 'current': wizardStep === 0 }"></div>
        <div class="wizard-dot" [ngClass]="{ 'current': wizardStep === 1 }"></div>
        <div class="wizard-dot" [ngClass]="{ 'current': wizardStep === 2 }"></div>
        <div class="wizard-dot" [ngClass]="{ 'current': wizardStep === 3 }"></div>
      </div>
      <div class="title">{{modalTitle}}</div>
    </div>
    <img
      *ngIf="!isOnBoardingWizard && !hideClose"
      src="/assets/common/CloseX.svg"
      class="modal-close"
      alt="Close"
      (click)="closeModal(actionResult.Close)" />
  </div>
  <div class="modal-content" [ngClass]="{ 'no-footer': actionOptions.length === 0 }">
    <ng-content></ng-content>
  </div>
  <div *ngIf="actionOptions.length > 0" 
    class="modal-footer"
    [ngClass]="{ 'onboarding-wizard': isOnBoardingWizard }">
    <div *ngIf="isOnBoardingWizard" class="company-count">
      <span>{{LocPluralize(LocalizedTextIds.ActionModalReceiveUpdates, totalMatchedCompanies, totalMatchedCompanies)}}</span>
    </div>
    <div class="buttons">
      <action-button *ngFor="let option of actionOptions; trackBy:actionObjectTrackBy"
        [ngClass]="option.actionResult"
        [type]="option.actionResult"
        [disabled]="option.disabled"
        [hidden]="option.actionResult === 'cancel'"
        (buttonClick)="closeModal(option.actionResult)">
        {{option.displayText}}
        <div *ngIf="option.actionResult !== 'cancel' && option.actionResult !== 'skip' && !option.disabled" class="right-arrow"></div>
      </action-button>
    </div>
  </div>
</app-modal-overlay>
