<ul #tagList 
    [class]="tagDisplayType === 'list' ? 'tags-list-no-bubble' : 'tags-list'">
    <li [class]="tagDisplayType === 'list' ? 'plain-tag' : 'bubble-tag'"
        [ngClass]="{ 'white-background': useWhiteBackground }"
        [ngStyle]="marginForWrapping"
        *ngFor="let tag of displayedTags" title="{{tag}}"
    >
        <ng-template [ngIf]="elideSet" [ngIfElse]="allTags">
            <span [ngClass]="elisionClasses(tag)"
            (click)="tagClicked(tag)">{{tag}}</span>
        </ng-template>
        <ng-template #allTags>
            <span class="tag-if-all"
            (click)="tagClicked(tag)">{{tag}}</span>
        </ng-template>
    </li>
    <li class="bubble-tag-more" 
        *ngIf="elideSet && hasHiddenTags" (click)="handleClick(company)"
        title="and {{hiddenTagsCount}} more tags">
        +{{hiddenTagsCount}}...
    </li>
</ul>