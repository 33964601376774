import { Component, Input } from '@angular/core';
import { ComponentBase } from '@Common';
import {
  ArrayConversions,
  convertToYesNo,
  UpdateConversion,
  ValueWithHeader,
} from 'company-finder-common';

export class DetailValue extends ValueWithHeader {
  public conversion?: UpdateConversion;
}

@Component({
    selector: 'detail-section',
    templateUrl: './detail-section.component.html',
    styleUrls: ['./detail-section.component.scss'],
    standalone: false
})
export class DetailSectionComponent extends ComponentBase {
  @Input()
  public detailValue: DetailValue;

  @Input()
  public columns = false;

  public get maxHeight(): number {
    return this.columns ? 280 : 96;
  }

  public get hasValue(): boolean {
    return this.value?.length > 0;
  }

  public get header(): string {
    return this.detailValue.header;
  }

  public get isMultiValue(): boolean {
    return ArrayConversions.includes(this.detailValue?.conversion);
  }

  public get value(): string {
    switch (this.detailValue?.conversion) {
      case UpdateConversion.BooleanToYesNo:
        return convertToYesNo(this.detailValue.value);
        break;
      case UpdateConversion.DelimitedString:
      case UpdateConversion.CountryPrefixedDelimitedString:
        // We handle these at ingest
        break;
    }
    return this.detailValue?.value?.toString() ?? '';
  }

  public get values(): string[] {
    return this.isMultiValue
      ? (this.detailValue?.value as string[]) ?? []
      : [this.value];
  }
}
