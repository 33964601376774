<div [hidden]="!showDebug">
    <div class="infoBox">
        <div>
            Client application version: {{appVersion}}
        </div>
        <div>
            API application version: {{apiInfo?.apiVersion}}
        </div>
        <div>
            Server status: {{ServerStatus[apiInfo?.status ?? 0]}}
        </div>        
        <div *ngFor="let warning of apiInfo?.warnings" class="warning">
            {{warning}}
        </div>
        <div>
            Server environment: {{apiInfo?.environment }}
        </div>
        <div>
            DataLake instance: {{apiInfo?.dataLake}}
        </div>    
        <div>
            Master Data Instance: {{apiInfo?.masterData}}
        </div>
        <div>
            Persistence data: {{persistenceConnection}}
        </div>
        <div>
            Debug message: {{apiInfo?.message }}
        </div>
    </div>
    <div class="infoBox" *ngIf="loginInfo">
        <div>
            Logged in as: {{loginInfo.id }}
        </div>
        <div>
            User role: {{loginInfo.role }}
        </div>
        <div>
            Site Head companies: {{siteHeadCompanies}}
        </div>
        <div>
            CoOwner companies: {{coOwnerCompanies}}
        </div>
        <div>
            Company contact companies: {{companyContactCompanies}}
        </div>
        <div *ngIf="showCompanyWarning" class="warning">
            WARNING: The company associations do not match the UserRole. This may result in errors.
        </div>
        <div>
            Token expires (Raw exp value):  {{loginInfo.exp }}
        </div>
        <div>
            Token expires: {{expiryDate }}
        </div>
        <div>            
            <button (click)="logOut()">Log out</button>
        </div> 
    </div>
    
    <div class="infoBox" *ngIf="showDebug && !loginInfo">
        Login via a juniverse token:
        <div>            
            <button (click)="loginInternal()">Internal user</button>
        </div> 
        <div>            
            <button (click)="loginInternalSuper()">Internal Super user</button>
        </div> 
        <div>            
            <button (click)="loginCompany()">Company user</button>
        </div> 
        <div>            
            <button (click)="loginBarda()">BARDA user</button>
        </div> 
        <div>            
            <button (click)="loginPartner()">Paid Partner user</button>
        </div> 
        <div>            
            <button (click)="loginInvalid()">Invalid JWT</button>
        </div> 

    </div>
    <div class="infoBox" *ngIf="showDebug">
        <div>
            jnjEverLoggedIn: {{appContext.jnjEverLoggedIn}}                
            <button (click)="setStorage(AuthnStrings.KEY_JNJ_EVER_LOGGED_IN)">Set to true</button>
            <button (click)="setStorage(AuthnStrings.KEY_JNJ_EVER_LOGGED_IN, 'false')">Set To false</button>
            <button (click)="clearStorage(AuthnStrings.KEY_JNJ_EVER_LOGGED_IN)">Clear</button>
        </div>
        <div>
            jnjWasLoggedIn: {{appContext.jnjWasLoggedIn}}                
            <button (click)="setStorage(AuthnStrings.KEY_JNJ_WAS_LOGGED_IN)">Set to true</button>
            <button (click)="setStorage(AuthnStrings.KEY_JNJ_WAS_LOGGED_IN, 'false')">Set To false</button>
            <button (click)="clearStorage(AuthnStrings.KEY_JNJ_WAS_LOGGED_IN)">Clear</button>
        </div>
        <div>
            jnjLoggedOut: {{appContext.jnjLoggedOut}}                
            <button (click)="setStorage(AuthnStrings.KEY_JNJ_LOGGED_OUT)">Set to true</button>
            <button (click)="setStorage(AuthnStrings.KEY_JNJ_LOGGED_OUT, 'false')">Set To false</button>
            <button (click)="clearStorage(AuthnStrings.KEY_JNJ_LOGGED_OUT)">Clear</button>
        </div>
        
    </div>
    <div class="infoBox" *ngIf="showDebug">
        <button (click)="throwError()">Cause exception</button>
    </div>
</div>
