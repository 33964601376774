<div class="info">
    <p>
        {{Loc(LocalizedTextIds.BlueKnightInformationInformation)}}
    </p>
  </div>
<div class="cf_blue-knight-milestones-update">  
    <!--FUTURE I considered making this a component since we use it multiple times,
    but this would also require more mapping of multi-level ViewChildren so it wasn't
    tackled at this time. We may want to revisit in the future-->
    <ng-container *ngFor="let propertyGroup of propertyGroups; trackBy: trackBy">
        <edit-item-group
            [propertyGroup]="propertyGroup"            
            [companyEditForm]="companyEditForm">
        </edit-item-group>
    </ng-container> 
</div>
