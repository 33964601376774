<div class="cf_overview_new-companies">
  <div class="cf_overview_new-companies-title">
    <label>{{title}}</label>
    <div class="cf_overview_new-companies-title-view-more" (click)="handleViewMoreClick()">
      {{Loc(LocalizedTextIds.ViewMore)}}
      <div class="cf_overview_new-companies-title-view-more-arrow"></div>
    </div>
  </div>
  <div class="cf_overview_new-companies-detail-scroll-area-wrapper">
    <div class="cf_overview_new-companies-detail-scroll-area">
      <div *ngFor="let company of newCompanies" class="cf_overview_new-companies-detail"
        (click)="handleArrowClick(company)">
        <div class="cf_overview_new-companies-detail-inner">
          <div class="cf_overview_new-companies-detail-inner-logo">
            <company-logo [company]="company" [size]="logoSize"></company-logo>
          </div>
          <div class="cf_overview_new-companies-detail-inner-info">
            <div class="cf_overview_new-companies-detail-inner-info-name">
              {{company.name}}
            </div>
            <company-tags class="cf_overview_new-companies-detail-inner-info-tags" [company]="company"
             [tags]="tagsToDisplay(company)" tagDisplayType="list"></company-tags>
          </div>
        </div>
        <div class="cf_overview_new-companies-detail-goto"></div>
      </div>
    </div>
  </div>
</div>