<div class="cf_progress-indicator" *ngIf="visible || isStatic">
    <viewport-container>
        <div class="loading_text">{{description}}</div>
        <div class="progress-animation">
            <span class="progress-animation-span animated"></span>
            <span class="progress-animation-span animated"></span>
            <span class="progress-animation-span animated"></span>
            <span class="progress-animation-span animated"></span>
        </div>
        <div class="loading_overlay"></div>
    </viewport-container>
</div>
