import { Component } from '@angular/core';
import { Router } from '@angular/router';

// vendor imports
import { ToastrService } from 'ngx-toastr';

// component imports
import { ComponentBase } from '../_component.base.js';
import { JnjLogoutSuccessToastComponent } from './components/jnj-logout-success-toast/jnj-logout-success-toast.component.js';

// service/utility imports
import { ApplicationContext } from '@Common';
import { AuthnService } from '@Common';
import { UserService } from '@Common';
import { WebAnalyticsService } from '@Common';
import { TokenService } from '../../services/token/token.service.js';
import { DeploymentContext } from '../../utilities/deployment-context/deployment-context.js';
import { AuthnSource, LocalizedTextIds } from 'company-finder-common';

@Component({
    selector: 'log-in-out-banner',
    templateUrl: './log-in-out-banner.component.html',
    styleUrls: ['./log-in-out-banner.component.scss'],
    standalone: false
})
export class LogInOutBannerComponent extends ComponentBase {
  // private properties
  /** See documentation where this is referenced */
  public constructor(
    dc: DeploymentContext,
    private _applicationContext: ApplicationContext,
    private _authnService: AuthnService,
    private _router: Router,
    private _toastrService: ToastrService,
    private _tokenService: TokenService,
    private _userService: UserService,
    private _webAnalyticsService: WebAnalyticsService
  ) {
    super(dc);
  }

  // public getters
  public get jnjEverLoggedIn(): boolean {
    return !!this._applicationContext.jnjEverLoggedIn;
  }

  public get isInternal(): boolean {
    return this._authnService.isInternal;
  }

  public get name(): string {
    return this._userService.getCachedUserSync()?.nickname;
  }

  public get showBanner(): boolean {
    return (
      this.isInternal ||
      (!this._authnService.isAuthenticated && this.jnjEverLoggedIn)
    );
  }

  // public methods
  public login(): void {
    // You generally shouldn't have an auth token if the login option is visible
    // But clearing handles a potential Company User bypass login token for a user with "jnjEverLoggedIn = true"
    // Note that if they are logged into JUniverse, they will likely just be automatically reauthenticated against that.
    this.clearTokenAndUser();

    const route = this._deploymentContext.ssoConfig?.enabled
      ? '/ping'
      : '/login';
    this._router.navigate([route]);
  }

  public logout(): void {
    const userBeingLoggedOut = this._authnService.loginInfo;

    this.clearTokenAndUser();
    if (userBeingLoggedOut?.origin !== AuthnSource.JUniverse) {
      this._tokenService.setLoggedOutFlags(true);
    }
    this._router.navigate(['/']);
    const title = this.Loc(LocalizedTextIds.LogInOutBannerSuccessfully);

    this._webAnalyticsService.trackEvent('logout', {
      category: 'logout',
      label: userBeingLoggedOut?.id,
    });

    // NOTE: Piece of the message need to be styled, so the message is
    //       hardcoded in the custom JnJLogoutSuccessToast component.
    this._toastrService.success(undefined, title, {
      positionClass: 'toast-top-right',
      tapToDismiss: true,
      timeOut: this._deploymentContext.logoutSuccessToastTimeout,
      toastComponent: JnjLogoutSuccessToastComponent,
    });
  }
  // private methods
  private clearTokenAndUser(): void {
    this._authnService.clearToken();
    this._authnService.clearJnjWasLoggedIn();
    this._userService.clearCachedUser();
  }
}
