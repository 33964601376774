import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ComponentBase } from '@Common';
import { DeploymentContext } from '@Common';

@Component({
    selector: 'preferences-section',
    templateUrl: './preferences-section.component.html',
    styleUrls: ['./preferences-section.component.scss'],
    standalone: false
})
export class PreferencesSectionComponent extends ComponentBase {
  @Input() sectionTitle: string;
  // Event emitted when the add item button is clicked. Emits nothing if addItemTemplate is used
  @Output() addItem = new EventEmitter<void>();

  constructor(deploymentContext: DeploymentContext) {
    super(deploymentContext);
  }

  public addNewItem(): void {
    this.addItem.emit();
  }
}
