import {
  determineSectorsAndSubsectors,
  Filter,
  IUser,
  IUserSearch,
  LocationWithCountAndCanonicalString,
  MatchedCompanyCounts,
  MatchedCompanyInfo,
  SectorWithCountAndCanonicalString,
} from 'company-finder-common';
import { UserService } from '../../services/user/user.service.js';
import _ from 'lodash-es';
import { CompanyService } from '../../services/company/company.service.js';

export enum ViewType {
  Summary = 'summary',
  Modal = 'modal',
}

export async function getCountOfCompaniesForSavedSearchUtil(
  savedSearch: IUserSearch,
  companyService: CompanyService
): Promise<number> {
  const locations = savedSearch.locations.map(
    (location) => location.locationId
  );
  const [primarySectors, primarySubSectors] = determineSectorsAndSubsectors(
    savedSearch.sectors
  );
  const filter = new Filter({
    locations,
    primarySectors,
    primarySubSectors,
    locationStatuses: savedSearch.statuses,
  });
  const searchResult = await companyService.searchAndFilterAggregate(
    '',
    0,
    0,
    filter
  );
  return searchResult.numberOfCompanies;
}

export async function updateCountOfMatchedCompanies(
  userService: UserService
): Promise<MatchedCompanyCounts> {
  const matchedCompanies = await userService.getMatchedCompanies();
  return updateCountOfMatchedCompaniesUser(matchedCompanies);
}

export async function updateCountOfMatchedCompaniesForWizard(
  userService: UserService,
  user: IUser
): Promise<MatchedCompanyCounts> {
  const matchedCompanies = await userService.getMatchedCompaniesForWizard(user);
  return updateCountOfMatchedCompaniesUser(matchedCompanies);
}

export async function updateCountOfMatchedCompaniesUser(
  matchedCompanies: MatchedCompanyInfo[]
): Promise<MatchedCompanyCounts> {
  let matchedLocationsCount = 0;
  let matchedSectorsCount = 0;
  let matchedSearchesCount = 0;
  let matchedBlueKnightCount = 0;
  let matchedTotal = 0;

  matchedCompanies?.forEach((company: MatchedCompanyInfo) => {
    matchedTotal += 1;
    matchedLocationsCount += company.matchedLocations.length > 0 ? 1 : 0;
    matchedSectorsCount += company.matchedSectors.length > 0 ? 1 : 0;
    matchedSearchesCount += company.matchedSearches.length > 0 ? 1 : 0;
    matchedBlueKnightCount += company.matchedBlueKnight ? 1 : 0;
  });

  return {
    matchedLocationsCount: matchedLocationsCount,
    matchedSectorsCount: matchedSectorsCount,
    matchedSearchesCount: matchedSearchesCount,
    matchedBlueKnightCount: matchedBlueKnightCount,
    matchedTotal: matchedTotal,
  } as MatchedCompanyCounts;
}

export function addSectorsToUser(
  sectorData: SectorWithCountAndCanonicalString[],
  user: IUser
): void {
  sectorData.forEach(({ sector, parentSector }) => {
    const existingSector = _.find(
      user.sectorsFollowed,
      (userSector) =>
        userSector.sectorId === sector &&
        userSector.parentSectorId === parentSector
    );
    if (!existingSector) {
      user.sectorsFollowed.push({
        sectorId: sector,
        parentSectorId: parentSector,
      });
    }
  });
}

export function addCompaniesToUser(
  companyIdsToAdd: string[],
  user: IUser
): void {
  const followedCompanyIds = user.companiesFollowed.map((uc) => uc.companyId);
  companyIdsToAdd.forEach((companyId: string) => {
    if (!followedCompanyIds.includes(companyId)) {
      user.companiesFollowed.push({
        companyId: companyId,
      });
    }
  });
}

export function addLocationsToUser(
  locationData: LocationWithCountAndCanonicalString[],
  user: IUser
): void {
  const locationIdsToAdd = locationData.map(
    (locationWithCount) => locationWithCount.location.name
  );

  locationIdsToAdd.forEach((name: string) => {
    if (!user.locationsFollowed.includes(name)) {
      user.locationsFollowed.push(name);
    }
  });
}
