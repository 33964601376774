import { Component, Input } from '@angular/core';
import { ComponentBase } from '@Common';
import { Company, CompanyKey, getUpdateOptions } from 'company-finder-common';
import { DetailValue } from '../detail-section/detail-section.component.js';

@Component({
    selector: 'property-detail-list',
    templateUrl: './property-detail-list.component.html',
    styleUrls: ['./property-detail-list.component.scss'],
    standalone: false
})
export class PropertyDetailListComponent extends ComponentBase {
  @Input()
  public company: Company;

  @Input()
  public properties: CompanyKey[] = [];

  @Input()
  public columns = false;

  public get detailClass(): string {
    return this.columns ? 'flexed' : '';
  }

  private _tabDetails: DetailValue[] | null = null;

  public get tabDetails(): DetailValue[] {
    if (!this._tabDetails) {
      this._tabDetails = this.properties?.map((property) => {
        const updateOptions = getUpdateOptions(property);
        return {
          ...this.getValueWithHeader(property, this.company),
          conversion: updateOptions?.updateConversion,
        };
      });
    }

    return this._tabDetails;
  }
}
