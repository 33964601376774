<div 
    *ngIf="showStatusIcon"
    [ngStyle]="companyStatusIcon"
    (mouseenter)="showTooltip()"
    (mouseleave)="hideTooltip()"
>
    <div *ngIf="isShowingTooltip"
        class="tooltip"
        [ngStyle]="tooltipPosition"
    >
        {{iconTooltip}}
    </div>
</div>