
import { defineMetadata, getMetadata } from 'reflect-metadata/no-conflict';
import { Company, CompanyKey, DynamicChoiceKey, EditItemType, LocalizedTextIds, ReferenceValueKey, UpdateConversion } from '../index.js';

export const BlueKnightKey = Symbol('BlueKnight');
export const MetadataKey = Symbol('Metadata');
export const UpdateableKey = Symbol('Updateable');
export const UpdateableOptionsKey = Symbol('UpdateableOptions');

// In the future there could be more than one currency per company,
// so it seemed easier to just wire it in now to load it from a dynamic field
export type CurrencyKey = 'currencyBlueKnight';

export interface UpdateOptions {
    editType?: EditItemType;
    promptTextId?: LocalizedTextIds;
    labelTextId?: LocalizedTextIds;
    footnoteId?: LocalizedTextIds;
    footnoteCountries?: string[];
    tooltipId?: LocalizedTextIds;
    subtitleId?: LocalizedTextIds;
    isJnjConfidentialInfo?: boolean;
    isReadOnly?: boolean;
    maxLength?: number;
    applyDeiCountryPrefix?: boolean;
    referenceDataKey?: ReferenceValueKey;
    showLength?: boolean;
    required?: boolean;
    preconditionProp?: CompanyKey;
    updateConversion?: UpdateConversion;
    delimiter?: string;
    currencyKey?: CurrencyKey;
    updateHeaderKey?: LocalizedTextIds;

    /** Some items have choices that cannot be defined at transpile time.
     * Too add one of these, establish a new DynamicChoiceKey, decorate the
     * property with it and add the code to return the choices in edit-item.component.ts
     *
     * Future - It is possible these could be implemented with a decorator member that
     * is a function with a Company param (and maybe a config object?), but that was not attempted at the initial
     * decorator implementation
     */
    dynamicChoiceKey?: DynamicChoiceKey;
}

export interface Metadata {
    header?: LocalizedTextIds;
}

export function BlueKnight() {
    return function (target: Company, propertyKey: CompanyKey): void {
        const properties: CompanyKey[] = getMetadata(
            BlueKnightKey,
            target
        );

        if (properties) {
            properties.push(propertyKey);
        } else {
            defineMetadata(BlueKnightKey, [propertyKey], target);
        }
    };
}

export function getBlueKnightFieldsRaw(target: Company): CompanyKey[] {
    return getMetadata(BlueKnightKey, target);
}

export function Updateable(options: UpdateOptions = {}) {
    return function (target: Company, propertyKey: CompanyKey): void {
        const properties: CompanyKey[] = getMetadata(
            UpdateableKey,
            target
        );

        if (properties) {
            properties.push(propertyKey);
        } else {
            defineMetadata(UpdateableKey, [propertyKey], target);
        }

        defineMetadata(
            UpdateableOptionsKey,
            options,
            target,
            propertyKey
        );
    };
}

export function getUpdateableFieldsRaw(target: Company): CompanyKey[] {
    return getMetadata(UpdateableKey, target);
}

export function Metadata(options: Metadata) {
    return function (target: Company, propertyKey: CompanyKey): void {
        defineMetadata(MetadataKey, options, target, propertyKey);
    };
}
export function getUpdateOptionsRaw(
    propertyKey: CompanyKey,
    target: Company
): UpdateOptions {
    if (propertyKey === 'companyContactTitle') {
        return {
            maxLength: 255,
            showLength: true,
        };
    }

    if (propertyKey === 'contactName') {
        return {
            isReadOnly: true,
            subtitleId: LocalizedTextIds.PublicInformationReachOut,
        };
    }

    return getMetadata(UpdateableOptionsKey, target, propertyKey);
}

export function getMetadataRaw(
    propertyKey: CompanyKey,
    target: Company
): Metadata {
    // companyContactTitle and contactName arent "real" properties, so they need
    // to have metadata defined here
    if (propertyKey === 'companyContactTitle') {
        return {
            header: LocalizedTextIds.ContactTitle,
        };
    }

    if (propertyKey === 'contactName') {
        return {
            header: LocalizedTextIds.CompanyContact,
        };
    }

    return getMetadata(MetadataKey, target, propertyKey);
}
