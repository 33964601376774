import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// service imports;
import { AuthnService } from '@Common';
import { WebAnalyticsService } from '@Common';
import { urlIdentifierForCompany } from 'company-finder-common';
import { UserService } from '@Common';
import { ComponentBase } from '@Common';
import { DeploymentContext } from '@Common';

@Component({
    selector: 'update',
    templateUrl: './update.component.html',
    styleUrls: ['./update.component.scss'],
    standalone: false
})
export class UpdateComponent extends ComponentBase implements OnInit {
  // public properties
  public showProgressIndicator = false;

  constructor(
    deploymentContext: DeploymentContext,
    private _authnService: AuthnService,
    private _webAnalyticsService: WebAnalyticsService,
    private _router: Router,
    private userService: UserService
  ) {
    super(deploymentContext);
  }

  // public methods
  public async ngOnInit(): Promise<void> {
    // FUTURE - this really should in a route guard
    const companyLists = await this.userService.getCompanyLists();
    if (companyLists?.isCompanyContactFor?.length > 0) {
      const route = `/company/update/${urlIdentifierForCompany(
        companyLists?.isCompanyContactFor[0]
      )}`;
      this._router.navigate([route]);
    } else {
      this._router.navigate(['/']);
    }
  }
}
