import { Component, Input } from '@angular/core';
import { ComponentBase } from '@Common';
@Component({
    selector: 'investment-summary',
    templateUrl: './investment-summary.component.html',
    styleUrls: ['./investment-summary.component.scss'],
    standalone: false
})
export class InvestmentSummaryComponent extends ComponentBase {
  @Input()
  public highestLevelOfFundingSecured: string;

  @Input()
  public totalSecuredAndContingentAmount: string;
}
