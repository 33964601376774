import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component } from '@angular/core';

import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { DeploymentContext } from '../../utilities/deployment-context/deployment-context.js';
import { LocalizedTextIds } from 'company-finder-common';

@Component({
    selector: '[jnj-toast]',
    templateUrl: './jnj-toast.component.html',
    styleUrls: ['./jnj-toast.component.scss'],
    animations: [
        trigger('flyInOut', [
            state('inactive', style({
                opacity: 0,
            })),
            transition('inactive => active', animate('40ms ease-out', keyframes([
                style({
                    transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
                    opacity: 0,
                }),
                style({
                    transform: 'skewX(20deg)',
                    opacity: 1,
                }),
                style({
                    transform: 'skewX(-5deg)',
                    opacity: 1,
                }),
                style({
                    transform: 'none',
                    opacity: 1,
                }),
            ]))),
            transition('active => removed', animate('40ms ease-out', keyframes([
                style({
                    opacity: 1,
                }),
                style({
                    transform: 'translate3d(100%, 0, 0) skewX(30deg)',
                    opacity: 0,
                }),
            ]))),
        ]),
    ],
    preserveWhitespaces: false,
    standalone: false
})
export class JnjToastComponent extends Toast {
  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    private _dc: DeploymentContext
  ) {
    super(toastrService, toastPackage);
  }

  public isWarn(): boolean {
    const toastClasses = this.toastClasses.split(' ');
    const type = toastClasses.length > 0 ? toastClasses[0] : '';
    return type === 'toast-warning';
  }

  public reload(): void {
    this.remove();
    setTimeout(() => window.location.reload());
  }

  public get LocalizedTextIds(): typeof LocalizedTextIds {
    return this._dc.LocalizedTextIds;
  }

  public Loc(
    key: string,
    ...replacements: (string | number | boolean)[]
  ): string {
    return this._dc.Loc(key, ...replacements);
  }
}
