<div class="info">
    <p>
        {{Loc(LocalizedTextIds.BlueKnightInformationInformation)}}
    </p>
  </div>
<div class="cf_blue-knight-information-update">
    <ng-container *ngFor="let propertyGroup of propertyGroups; trackBy: trackBy">
        <edit-item-group
            [propertyGroup]="propertyGroup"            
            [companyEditForm]="companyEditForm">
        </edit-item-group>
    </ng-container>
</div>
