<div class="my-updates" [ngClass]="{narrowScreen: narrowScreen}">
  <search-bar
    [searchOnFilterChange]="false"
    [navigateToResultsOnSearch]="true"
    #searchBar
  >
  </search-bar>
  <div class="content"> 
    <div class="sidebar">
      <div class="control" (click)="toggleFilters()">Filter
        <span class="caret" [ngClass]="{'open': filtersVisible, 'closed': !filtersVisible}"></span>
      </div>
      <div class="types" *ngIf="filtersVisible">
        <div class="title">{{Loc(LocalizedTextIds.Following)}}</div>
        <div *ngFor="let interestType of interestTypes" class="radio-item">
          <input
            type="radio"
            name="{{interestType.name}}"
            value="{{interestType.value}}"
            [checked]="selectedUpdateType === interestType.value"
            (change)="setType(interestType.value)"/>
            <div class="text">{{getUnreadLabel(interestType)}}</div>
        </div>
      </div>
      <div class="filters" *ngIf="filtersVisible">
        <div class="title">{{Loc(LocalizedTextIds.Preferences)}}</div>
        <ng-container>
          <div *ngFor="let filter of enabledFilters" class="filter">
            <input
              type="checkbox"
              class="checkbox"
              [checked]="isFilterSelected(filter.type)"
              (click)="toggleFilterSelection(filter.type)"
            />
            <div class="name">
              {{ filter.displayName }}
            </div>
          </div>
        </ng-container>
      </div>
    </div>    
    <div class="main">
      <div class="title">
        <localized-text-snippet text="{{LocPluralize(LocalizedTextIds.MyUpdatesShowingUpdatesFor, countOfCompaniesForWhichWereShowingUpdates, countOfCompaniesForWhichWereShowingUpdates)}}">
        </localized-text-snippet>
        <div class="preferences" (click)="managePreferences()">+{{Loc(LocalizedTextIds.Edit)}}</div>
      </div>
      <div *ngIf="datasource" class="updates" #viewport>
        <div *uiScroll="let item of datasource">
          <update-summary
            #updateSummaries
            [update]="item"
            [company]="getCompanyForUpdate(item)"
            [isNewUpdate]="isNewUpdate(item)"
          ></update-summary>
        </div>
      </div>
    </div>
  </div>
</div>
