<div class="cf_by-the-numbers">
  <div class="cf_by-the-numbers_title-bar">
    <div class="cf_by-the-numbers_title-bar-title">
      {{title}}
    </div>
    <div class="cf_by-the-numbers_title-bar-filter">
      <div class="cf_by-the-numbers_title-bar-filter-dropdown" (click)="setShowMenu($event, 'main')">
        <div class="cf_by-the-numbers_title-bar-filter-dropdown-value">
          {{dropdownValue}}
        </div>
        <div class="cf_by-the-numbers_icon-caret main" *ngIf="!showMenu.main"></div>
        <div class="cf_by-the-numbers_icon-caret-open main" *ngIf="showMenu.main"></div>
        <div class="cf_by-the-numbers_title-bar-filter-menu" *ngIf="showMenu.main" (click)="stopPropagation($event)">
          <div class="cf_by-the-numbers_title-bar-filter-menu-item"
               [ngClass]="{'cf_by-the-numbers_title-bar-filter-menu-item-current' : activeFilterType === menuItems.acrossJlabs}"
               (click)="updateFilter('main');hideMenus($event);"
               (mouseenter)="hideMenu('location');hideMenu('sector');">
            {{menuItems.acrossJlabs}}
          </div>
          <div class="cf_by-the-numbers_title-bar-filter-menu-item" *ngIf="featureSwitches.enableSectors"
               [ngClass]="{'cf_by-the-numbers_title-bar-filter-menu-item-current' : activeFilterType === menuItems.acrossSector}"
               (mouseenter)="setShowMenu($event, 'sector')">
            <div class="cf_by-the-numbers_title-bar-filter-menu-item-title">{{menuItems.acrossSector}}</div>
            <div class="cf_by-the-numbers_icon-caret-open" *ngIf="showMenu.sector"></div>
            <div class="cf_by-the-numbers_icon-caret" *ngIf="!showMenu.sector"></div>
            <div class="cf_by-the-numbers_title-bar-filter-menu-submenu" *ngIf="showMenu.sector">
              <div class="cf_by-the-numbers_title-bar-filter-menu-item"
                   [ngClass]="{'cf_by-the-numbers_title-bar-filter-menu-item-current' : dropdownValue === sectorOption.name}"
                   *ngFor="let sectorOption of sectorOptions"
                   (click)="updateFilter('sector', sectorOption);hideMenus($event);">
                {{sectorOption.name}}
              </div>
            </div>
          </div>
          <div class="cf_by-the-numbers_title-bar-filter-menu-item"
               [ngClass]="{'cf_by-the-numbers_title-bar-filter-menu-item-current' : activeFilterType === menuItems.acrossLocations}"
               (mouseenter)="setShowMenu($event, 'location')">
            <div class="cf_by-the-numbers_title-bar-filter-menu-item-title">{{menuItems.acrossLocations}}</div>
            <div class="cf_by-the-numbers_icon-caret-open" *ngIf="showMenu.location"></div>
            <div class="cf_by-the-numbers_icon-caret" *ngIf="!showMenu.location"></div>
            <div class="cf_by-the-numbers_title-bar-filter-menu-submenu-location" *ngIf="showMenu.location">
              <div class="cf_by-the-numbers_title-bar-filter-menu-submenu-options">
                <div class="cf_by-the-numbers_title-bar-filter-menu-submenu-option" *ngFor="let menuOption of locationOptions">
                  <div class="cf_by-the-numbers_title-bar-filter-menu-checkbox" (click)="menuOption.value = !menuOption.value;updateFilter('location')">
                    <input
                      type="checkbox"
                      class="checkbox"
                      id="{{menuOption.db}}"
                      name="{{menuOption.db}}"
                      [(ngModel)]="menuOption.value"
                      (click)="stopPropagation($event)"
                      (change)="updateFilter('location')">
                      <div class="cf_by-the-numbers_title-bar-filter-menu-submenu-option-label">{{menuOption.name}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cf_by-the-numbers_title-bar-filter-menu-item" *ngIf="featureSwitches.enableQfc||featureSwitches.enableBlueKnight"
               [ngClass]="{'cf_by-the-numbers_title-bar-filter-menu-item-current' : activeFilterType === menuItems.accrossInitiatives}"
               (mouseenter)="setShowMenu($event, 'initiative')">
            <div class="cf_by-the-numbers_title-bar-filter-menu-item-title">{{menuItems.accrossInitiatives}}</div>
            <div class="cf_by-the-numbers_icon-caret-open" *ngIf="showMenu.initiative"></div>
            <div class="cf_by-the-numbers_icon-caret" *ngIf="!showMenu.initiative"></div>
            <div class="cf_by-the-numbers_title-bar-filter-menu-submenu" *ngIf="showMenu.initiative">
              <div class="cf_by-the-numbers_title-bar-filter-menu-item"
                   [ngClass]="{'cf_by-the-numbers_title-bar-filter-menu-item-current' : dropdownValue === initiativeOption.name}"
                   *ngFor="let initiativeOption of initiativeOptions"
                   (click)="updateFilter('initiative', initiativeOption);hideMenus($event);">
                {{initiativeOption.name}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="narrowScreen">
    <swiper-container swiperElement [swiperConfig]="swiperConfig">
        <swiper-slide>
        <strategic-collaboration [summary]="summary"></strategic-collaboration>
      </swiper-slide>
      <swiper-slide>
        <community-and-diversity [summary]="summary" [includesNorthAmericanLocations]="includesNorthAmericanLocations" ></community-and-diversity>
      </swiper-slide>
    </swiper-container>
  </div>
  <div class="cf_by-the-numbers_content" *ngIf="!narrowScreen">
    <div class="cf_by-the-numbers_content-left">
      <strategic-collaboration [summary]="summary"></strategic-collaboration>
    </div>
    <div class="cf_by-the-numbers_content-divider"></div>
    <div class="cf_by-the-numbers_content-right">
      <community-and-diversity [summary]="summary" [includesNorthAmericanLocations]="includesNorthAmericanLocations" ></community-and-diversity>
    </div>
  </div>
</div>
