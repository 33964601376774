import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { ComponentBase } from '@Common';

// model imports
import { LocPrefix, NameValuePair, NameWithChildNameValues, PrimarySectorCount, PrimarySubSectorCount, type SectorCounts, SubsectorCounts } from 'company-finder-common';
import { DeploymentContext } from '@Common';

@Component({
    selector: 'sector-details',
    templateUrl: './sector-details.component.html',
    styleUrls: ['./sector-details.component.scss'],
    standalone: false
})
export class SectorDetailsComponent extends ComponentBase implements OnChanges {
  // public properties
  @Input()
  public primarySectorCount: SectorCounts;
  @Input()
  public barPercentage: number;
  public isOpen = false;
  public sortedPrimarySubSectorCounts: SubsectorCounts[];
  public color: string;
  public sectorDisplayName: string;

  public constructor(dc: DeploymentContext) {
    super(dc);
  }

  // public getters
  public get sector(): string {
    return this.primarySectorCount.name;
  }

  // public methods
  public barStyle(): Partial<CSSStyleDeclaration> {
    return {
      backgroundColor: this.color,
      width: `${this.barPercentage}%`,
    };
  }

  public calculateSubSectorBarPercentage(
    primarySubSectorCount: SubsectorCounts
  ): number {
    // Cap it at 100, just in case.
    return Math.min(
      (100 * primarySubSectorCount.dealCount) / this.primarySectorCount.dealCount,
      100
    );
  }

  public subSectorBarStyle(
    primarySubSectorCount: SubsectorCounts
  ): Partial<CSSStyleDeclaration> {
    return {
      backgroundColor: this.color,
      width: `${this.calculateSubSectorBarPercentage(primarySubSectorCount)}%`,
    };
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.primarySectorCount && this.primarySectorCount) {
      this.sortedPrimarySubSectorCounts =
        this.primarySectorCount.children.sort((a, b) => b.dealCount - a.dealCount);
      this.sectorDisplayName = this.LocWithPrefix(
        this.sector,
        LocPrefix.SectorShortName
      );
      const sectorIndex = this._deploymentContext.getSectorIndex(this.sector);
      this.color = this.themeSettings.sectorColors[sectorIndex];
    }
  }
}
