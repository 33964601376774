<div *ngIf="funding?.length > 0">
  <div class="title">
    {{Loc(LocalizedTextIds.Funding)}}
  </div>
  <table>
    <tr>
      <th>{{Loc(LocalizedTextIds.CompanyDetailsFundingRound)}}</th>
      <th class="currencycolumn">{{Loc(LocalizedTextIds.CompanyDetailsAmount)}}</th>
      <!-- Don't include currency in header, since there can be a mix of currencies -->
      <th>{{Loc(LocalizedTextIds.CompanyDetailsDateRaised)}}</th>
      <th>{{Loc(LocalizedTextIds.CompanyDetailsFundingParty)}}</th>
      <th *ngIf="showConfidentialStatus" class="confidentialcolumn">{{Loc(LocalizedTextIds.CompanyDetailsConfidential)}}</th>
    </tr>
    <tr *ngFor="let item of filteredFunding">
      <td>{{LocDbValue(item.fundingRound)}}</td>
      <td class="currencycolumn">
        <!-- Note that this will be substituted for N/A on the server-side for J&J Deals -->
        <span *ngIf="IsNumberOrNumberString(item.raised); else fundingAmountNotANumber">{{item.raised |
          currency:item.raisedCurrency:'symbol-narrow':'1.0-0'}} {{item.raisedCurrency}}</span>
        <ng-template #fundingAmountNotANumber>
          {{item.raised}}
        </ng-template>
      </td>
      <td>{{item.dateRaised | date : 'mediumDate' : 'GMT'}}</td>
      <td>{{item.fundingParty}}</td>
      <td *ngIf="showConfidentialStatus" class="confidentialcolumn">
        <div *ngIf="item.isConfidential">{{Loc(LocalizedTextIds.Yes)}}</div>
        <div *ngIf="!item.isConfidential">{{Loc(LocalizedTextIds.No)}}</div>
      </td>
    </tr>
  </table>
</div>