import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { ComponentBase } from '@Common';

// model/utility imports
import {
  Company,
  Filter,
  LocalizedTextIds,
  orderByTagCount,
  TagWithCount,
  urlIdentifierForCompany,
} from 'company-finder-common';

// service imports
import { CompanyService } from '@Common';
import { LogoSize } from '@Common';
import { SearchService } from '@Common';
import { WebAnalyticsService } from '@Common';
import { AuthnService } from '@Common';
import { DeploymentContext } from '@Common';

@Component({
    selector: 'new-companies',
    templateUrl: './new-companies.component.html',
    styleUrls: ['./new-companies.component.scss'],
    standalone: false
})
export class NewCompaniesComponent
  extends ComponentBase
  implements OnDestroy, OnInit
{
  // public properties
  public arrow = '&#x009b;';
  public logoSize: LogoSize = LogoSize.Small;
  public newCompanies: Company[];
  @Input()
  public tagCounts: TagWithCount[];
  public title = this.Loc(LocalizedTextIds.MyUpdatesNewCompanies);

  public constructor(
    dc: DeploymentContext,
    private _authnService: AuthnService,
    private _companyService: CompanyService,
    private _router: Router,
    private _searchService: SearchService,
    private _webAnalyticsService: WebAnalyticsService
  ) {
    super(dc);
  }

  // public getters
  public get filterSubject(): Subject<Filter> {
    return this._searchService.filterSubject;
  }

  // public methods
  public async handleArrowClick(company: Company): Promise<void> {
    if (
      this._authnService.isAuthenticated ||
      !this._deploymentContext.featureSwitches.enablePaywall
    ) {
      this.navigateToCompany(company);
    } else {
      this.navigateToSearch();
    }
  }

  public handleViewMoreClick(): void {
    this.navigateToSearch();
  }

  private navigateToSearch(): void {
    const filter = this._searchService.filter;
    filter.isNewInLast90Days = true;

    // FUTURE: This was a quick hack related to ADJQ-210.  There could definitely be a cleaner way of
    // indicating that we should *always* transition to the search results in this case.
    this._searchService.currentSearchPredicate = ' ';
    this._searchService.drilldownSubject.next(filter);
    this._webAnalyticsService.trackEvent('new-companies-more');
  }

  private navigateToCompany(company: Company): void {
    this._router.navigate(['company', urlIdentifierForCompany(company.name)]);
    this._webAnalyticsService.trackEvent('new-companies-drilldown', {
      category: 'Company Details',
      label: company.name,
    });
  }

  public async ngOnInit(): Promise<void> {
    await this.initializeNewCompanies();

    this.addSubscription(
      this.filterSubject.subscribe(async () => {
        await this.initializeNewCompanies();
      })
    );
  }

  public ngOnDestroy(): void {
    if (this._subscriptions) {
      this._subscriptions.unsubscribe();
      this._subscriptions = undefined;
    }
  }

  public tagsToDisplay(company: Company): string[] {
    return orderByTagCount(company, this.tagCounts);
  }

  // private methods
  private async initializeNewCompanies(): Promise<void> {
    // Deep copy the filter, if one exists, so that we preserve existing constraints,
    // and then further narrow the filter to just the "isNewInLast90Days" to reduce amount of data fetched
    const filterCopy = this._searchService.filter
      ? this._searchService.filter.clone()
      : Filter.emptyFilter;
    filterCopy.isNewInLast90Days = true;

    const { companies } = await this._companyService.searchAndFilter(
      '',
      0,
      0,
      filterCopy
    );

    this.newCompanies = companies.sort(
      (a, b) =>
        new Date(b.commencementDate).getTime() -
        new Date(a.commencementDate).getTime()
    );
  }
}
