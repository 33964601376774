<app-modal-overlay [showModal]="showModal" [maxHeight]="680" [heightPercent]="80">
  <div class="review-modal">
    <div class="content">
      <div class="close" (click)="close()"></div>
      <div class="info">
        <div class="header">
          <div class="label">{{Loc(LocalizedTextIds.ReviewModalReview)}}</div>
          <div class="count">{{reviews?.length}}</div>
        </div>
        <div class="review-list" *ngIf="hasReviews">
          <div *ngFor="let review of reviews" class="review" (click)="goToCompanyUpdate(review.urlName)">
            <company-logo [logoBase64]="review.logo" [size]="size" class="logo" show="true"></company-logo>
            <div class="name">
              <div>{{review?.name}}</div>
            </div>
            <div class="date">{{formatDate(review?.date)}}</div>
          </div>
        </div>
        <div class="no-reviews" *ngIf="!hasReviews">
          {{Loc(LocalizedTextIds.ReviewModalNoReviews)}}
        </div>
      </div>
    </div>
  </div>
</app-modal-overlay>