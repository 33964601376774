import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class ProgressIndicatorService {
  // Observable string sources
  private startOperationSubject = new Subject<any>();
  private operationCompleteSubject = new Subject<any>();

  // Observable string streams
  public startOperationObservable = this.startOperationSubject.asObservable();
  public operationCompleteObservable = this.operationCompleteSubject.asObservable();

  // Service message commands
  public startOperation() {
    this.startOperationSubject.next(undefined);
  }

  public completeOperation() {
    this.operationCompleteSubject.next(undefined);
  }
}
