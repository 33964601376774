import { Component, EventEmitter, Input, Output } from '@angular/core';
import { type IUser } from 'company-finder-common';
import { UserService } from '../../services/user/user.service.js';

import { DeploymentContext } from '@Common';
import { ComponentBase } from '../_component.base.js';

// eslint-disable-next-line no-shadow
export enum ActionResult {
  Submit = 'submit',
  Cancel = 'cancel',
  Close = 'close',
  Delete = 'delete',
  Restore = 'restore',
  Approve = 'approve',
  Decline = 'decline',
  Skip = 'skip',
  Continue = 'continue',
}

/**
 * Configure each action button to be displayed in the modal using
 * these options.
 */
export interface ActionOptions {
  actionResult: ActionResult;
  displayText: string;
  disabled: boolean;
}

/**
 * This component presents a partially styled modal with a title at the top and
 * action buttons at the bottom with room for content to be injected in between.
 */
@Component({
    selector: 'action-modal',
    templateUrl: './action-modal.component.html',
    styleUrls: ['./action-modal.component.scss'],
    standalone: false
})
export class ActionModalComponent extends ComponentBase {
  // The title of the modal, rendered at the top
  @Input() modalTitle: string;
  // Configure the available actions for this modal instance
  @Input() actionOptions: ActionOptions[] = [];

  @Input()
  public isOnBoardingWizard: boolean;

  @Input()
  public hideClose = false;

  @Input()
  public user: IUser;

  @Input()
  public wizardStep: number;

  @Input()
  public totalMatchedCompanies: number;

  // Event fired when the modal closes. If payload is true, the modal exited on the submit/success path.
  // If the payload is false, the modal exited on the cancel path
  @Output() action = new EventEmitter<ActionResult>();

  @Input() maxHeight = 680;
  @Input() width = 720;

  // Alias the enum to reference in html template
  public actionResult = ActionResult;

  private _showModal: boolean;

  public actionObjectTrackBy(_index: number, action: ActionOptions): string {
    return action.displayText;
  }

  public get showModal(): boolean {
    return this._showModal;
  }

  public constructor(
    dc: DeploymentContext,
    protected _userService: UserService
  ) {
    super(dc);
  }

  /**
   * Opens the modal. Listen for the close event to handle the action taken inside the modal.
   */
  public open(): void {
    this._showModal = true;
  }

  public closeModal(actionResult: ActionResult): void {
    this._showModal = false;
    this.action.emit(actionResult);
  }
}
