import { state, style, trigger } from '@angular/animations';
import { Component } from '@angular/core';

import { ToastPackage, ToastrService } from 'ngx-toastr';

import { DeploymentContext } from '../../../../utilities/deployment-context/deployment-context.js';
import { JnjToastComponent } from '../../../jnj-toast/jnj-toast.component.js';

@Component({
    selector: '[jnj-logout-success-toast]',
    templateUrl: './jnj-logout-success-toast.component.html',
    styleUrls: ['./jnj-logout-success-toast.component.scss'],
    // toastr complains if we don't define animations, even if we're not really using them,
    // and they don't get inherited from Toast, so defining this seems to keep toastr happy.
    animations: [
        trigger('flyInOut', [
            state('inactive', style({
                display: 'none',
                opacity: 0,
            })),
            state('active', style({})),
            state('removed', style({})),
        ]),
    ],
    standalone: false
})
export class JnjLogoutSuccessToastComponent extends JnjToastComponent {
  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    private dc: DeploymentContext
  ) {
    super(toastrService, toastPackage, dc);
  }
}
