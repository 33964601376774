import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import _ from 'lodash-es';
import {
  LocalizedTextIds,
  TagWithCount,
  TagWithCountAndCanonicalString,
} from 'company-finder-common';
import { PickerModalBaseComponent } from '../picker-modal-base.component.js';
import { CompanyService } from '../../../services/company/company.service.js';
import { UserService } from '../../../services/user/user.service.js';
import { DeploymentContext } from '../../../utilities/deployment-context/deployment-context.js';

@Component({
    selector: 'tags-picker-modal',
    templateUrl: './tags-picker-modal.component.html',
    styleUrls: ['./tags-picker-modal.component.scss'],
    // We want to allow the template to have access to styling we put on the outer component (like .selected),
    // so turn off encapsulation.  We'll be careful not to cause style conflicts.
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class TagsPickerModalComponent
  extends PickerModalBaseComponent<TagWithCount>
  implements OnInit
{
  // Initially only render 20 tags to keep the load time down. Once
  // we have loaded, this is set to the length of the full array.
  private maxTags = 20;

  @Input() mode = TagsPickerMode.CompanyUpdate;
  @Input() modalTitle = this.Loc(LocalizedTextIds.TagsPickerModalAddTags);
  public allTagsWithCounts: TagWithCountAndCanonicalString[] = [];

  public blurb = '';
  public actionVerbText = this.Loc(LocalizedTextIds.Add);
  public createCustomTagActionButtonText: string;
  public isOnBoardingWizard = false;

  public constructor(
    dc: DeploymentContext,
    private _companyService: CompanyService,
    protected _userService: UserService
  ) {
    super(dc, _userService);
  }

  public async ngOnInit(): Promise<void> {
    this.allTagsWithCounts =
      await this._companyService.getTagsWithCountsAndCanonical();
  }

  onShown(): void {
    // The user can see the modal, so render in the rest of the tags.
    this.maxTags = this.allTagsWithCounts.length;
    this.pickerModal.onSearchChange('');

    switch (this.mode) {
      case TagsPickerMode.CompanyUpdate:
        this.blurb = this.Loc(
          LocalizedTextIds.TagsPickerModalBlurbCompanyUpdate
        );
        break;
      case TagsPickerMode.CompanyUpdateEdit:
        this.blurb = this.Loc(LocalizedTextIds.TagsPickerModalBlurbCompanyEdit);
        this.modalTitle = this.Loc(LocalizedTextIds.TagsPickerModalEditCustom);
        this.actionVerbText = this.Loc(LocalizedTextIds.TagsPickerModalApply);
        this.createCustomTagActionButtonText = this.Loc(
          LocalizedTextIds.TagsPickerModalUseThis
        );
        break;
    }
  }

  public async doSearch(newSearchText: string): Promise<TagWithCount[]> {
    if (newSearchText.length === 0) {
      // Only filter down if a filter text was given
      return this.allTagsWithCounts.slice(0, this.maxTags);
    }

    return _.filter(this.allTagsWithCounts, (tag) =>
      tag.canonicalString?.includes(newSearchText.toLowerCase())
    );
  }

  public makeCompanyCountString(count: number): string {
    return count === 0
      ? ''
      : this.LocPluralize(LocalizedTextIds.CompanyCount, count, count);
  }

  public onCustomTagCreated(tagName: string): void {
    const customTag = {
      tag: tagName,
      count: 0,
      canonicalString: tagName.toLowerCase(),
      isCustom: true,
    };
    this.pickerModal.addCustomItem(customTag);
  }
}

export enum TagsPickerMode {
  // Note: these will be added as css style names, so a space in string signifies multiple styles to apply
  CompanyUpdate = 'CompanyUpdate',
  CompanyUpdateEdit = 'CompanyUpdate Edit',
}
