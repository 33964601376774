<div class="approve-decline" *ngIf="!deal && !funding" [ngClass]="{ 'dim': isInactive }">
    <div class="approve-decline-inner" [ngClass]="{'approved': status === statuses.Approved, 'declined': status === statuses.Declined}">
        <div class="buttons">
            <div class="button-decline" [ngClass]="{'approved': status === statuses.Approved, 'declined': status === statuses.Declined}" (click)="declineProperty()">
                <ng-container *ngIf="status !== statuses.Declined">
                    <img src="/assets/update/ic-close-18px-dark.svg" *ngIf="status !== statuses.Approved"/>
                    <img src="/assets/update/ic-close-18px-slate.svg" *ngIf="status === statuses.Approved"/>
                    <div class="button-text-decline" [ngClass]="{'approved': status === statuses.Approved}">{{Loc(LocalizedTextIds.Decline)}}</div>
                </ng-container>
                <div class="button-text-decline declined" *ngIf="status === statuses.Declined">{{Loc(LocalizedTextIds.Declined)}}</div>
            </div>
            <div class="button-approve" [ngClass]="{'approved': status===statuses.Approved, 'declined': status===statuses.Declined}" (click)="approveProperty()">
                <ng-container *ngIf="status !== statuses.Approved">
                    <img src="/assets/update/ic-check-18px-green.svg" *ngIf="status === statuses.Pending"/>
                    <img src="/assets/update/ic-check-18px-grey.svg" *ngIf="status === statuses.Declined"/>
                    <div class="button-text-approve" [ngClass]="{'declined': status === statuses.Declined}">{{Loc(LocalizedTextIds.Approve)}}</div>
                </ng-container>
                <div class="button-text-approve approved" *ngIf="status === statuses.Approved">{{Loc(LocalizedTextIds.Approved)}}</div>
            </div>
        </div>
    </div>
</div>
