@import "site";

$triangle-icon-height: 17px;
$triangle-icon-width: 8px;

sectors-picker-modal {
    .spacer {
      width: 24px;
      height: 100%;
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
      background-color: $neutral-02;
      opacity: 30%;
      margin-left: -32px;
      padding: 18px 0;
    }

    .item-name-and-company-count {
        flex: auto;
        margin: 0;
        padding: 0;

        &.is-sub-sector {
            margin-left: 57px;

            .company-count {
                margin-left: 0px;
            }
        }

        .item-name-expander-and-company-name {
            @extend .cf_flex-center-start;
            flex-direction: row;
        }

        .child-toggle {
            background-color: $white;
            border: none;
            cursor: pointer;
            @include iconAsBackgroundImage(url('/assets/common/carrot-right-light-gray.svg'), $triangle-icon-height, $triangle-icon-width, auto);
            margin-right: 15px;
    
            &.open {
              @include iconAsBackgroundImage(url('/assets/common/Line54.svg'), $triangle-icon-width, $triangle-icon-height, auto);
              margin-right: 10px;
              transform: rotate(180deg);
            }
        }

        .item-name {
            color: $black;
            font-size: $font-size-normal;
            font-weight: 300;
            line-height: 22px;
        }
        .company-count {
            color: $neutral-08;
            font-size: $font-size-small;
            font-weight: 300;
            line-height: 17px;
            margin: 7px 0 0 27px;
        }
    }
}
