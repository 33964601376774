<action-modal [modalTitle]="modalTitle" [actionOptions]="actionOptions" (action)="handleAction($event)">
  <p class="saved-search-modal-description">
    {{Loc(LocalizedTextIds.SavedSearchModalMatch1)}} 
    <b>{{Loc(LocalizedTextIds.SavedSearchModalMatch2)}}</b> 
    {{Loc(LocalizedTextIds.SavedSearchModalMatch3)}}
  </p>
  <div class="metadata">
    <div class="manage">
      <input
          type="text"
          class="name"
          [ngStyle]="getInputWidth()"
          [(ngModel)]="currentSearchName"/>
    </div>
    <div class="rule"></div>
    <div class="summary">
      <saved-search-summary
        *ngIf="search"
        [viewType]="ViewTypes.Modal"
        (editSavedSearch)="editSavedSearch(search)"
        [search]="search"
        [searchTitle]="search.name"
      >
      </saved-search-summary>
    </div>
  </div>
  <div class="saved-search-modal-content">
    <div class="saved-search-modal-criteria">
      <saved-search-criteria
        label={{Loc(LocalizedTextIds.Location)}}
        [(criteria)]="savedLocations"
        [optionData]="locationData"
        selectionType="location">
      </saved-search-criteria>
      <saved-search-criteria  *ngIf="featureSwitches.enableSectors"
        label={{Loc(LocalizedTextIds.SectorAndSubSector)}}
        [(criteria)]="savedSectors"
        [optionData]="sectorData"
        selectionType="sector">
      </saved-search-criteria>
      <saved-search-criteria
        label={{Loc(LocalizedTextIds.Status)}}
        [(criteria)]="savedStatuses"
        [optionData]="statusData"
        selectionType="status">
      </saved-search-criteria>
    </div>
  </div>
  <div class="saved-search-company-count-summary" *ngIf="matchedCompaniesCount !== undefined">
    <span class="saved-search-company-count">{{LocPluralize(LocalizedTextIds.SavedSearchModalCompaniesCurrently, matchedCompaniesCount, matchedCompaniesCount)}}</span>
    {{Loc(LocalizedTextIds.SavedSearchModalThisNumber)}}
  </div>
</action-modal>