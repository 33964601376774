import _ from 'lodash-es';

export type AsyncForeachCallback<T, O> = (obj: T, ...args: any[]) => Promise<O>;

// ES6 forEach doesn't do what you generally want with await; invoke this if you want to
// await each call of the callback
export async function asyncForEach<T>(
    array: T[],
    callback: AsyncForeachCallback<T, void>
): Promise<void> {
    for (let index = 0; index < array?.length; index++) {
        await callback(array[index], index, array);
    }
}
