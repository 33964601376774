<ng-container #viewcontainer *ngFor="let segment of segments">
  <ng-container *ngIf="!segment.tag">{{segment.text}}</ng-container>
  <span *ngIf="segment.tag" [attr.segmentId]="segment.id" class="{{segment.cssClass}}" [attr.href]="segment.href"><!--
  eliminate whitespace -->{{segment.text}}<!--
  eliminate whitespace --><ng-container *ngIf="segment.tooltip">
      <div [hidden]="!segment.tooltipShowing" class="{{segment.cssClass + '-tooltip'}}">
        {{segment.tooltip}}
      </div>
    </ng-container>
  </span>
</ng-container>
