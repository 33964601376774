<picker-modal (action)="doAction($event)" [(showModal)]="showModal" [modalTitle]="modalTitle" objectsTypeName="tags"
    [blurb]="blurb" [initialSearchText]="initialSearchText" customItemsTitle={{Loc(LocalizedTextIds.TagsPickerModalCustomTags)}}
    [actionVerbText]="actionVerbText" filterPlaceholder={{`${Loc(LocalizedTextIds.FilterListBy)} ${Loc(LocalizedTextIds.Tags)...}`}}
    [compareFunc]="compareItemsWithCanonicalString" [filterFunc]="doFilter" [ngClass]="mode.toString()">
    <ng-template #itemTemplate let-tagAndCount="item">
        <div class="tag-name-and-company-count tagItem">
            <div class="tag-name selected-text-color">{{tagAndCount.tag}}</div>
            <div class="company-count">{{makeCompanyCountString(tagAndCount.count)}}</div>
        </div>
    </ng-template>
    <ng-template #noItemMatchTemplate let-tagName="lastSearchTermSubmitted">
        <create-custom-tag [tagName]="tagName"
            [actionButtonText]="createCustomTagActionButtonText || Loc(LocalizedTextIds.TagsPickerModalCreateCustomTag)"
            (tagCreated)="onCustomTagCreated($event)"></create-custom-tag>
    </ng-template>
</picker-modal>