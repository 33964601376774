import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { WebAnalyticsService } from '@Common';
import { ComponentBase } from '../_component.base.js';
import { DeploymentContext } from '../../utilities/deployment-context/deployment-context.js';
import { LocalizedTextIds } from 'company-finder-common';

@Component({
    selector: 'show-more-less',
    templateUrl: './show-more-less.component.html',
    styleUrls: ['./show-more-less.component.scss'],
    standalone: false
})
export class ShowMoreLessComponent
  extends ComponentBase
  implements AfterViewInit
{
  @Input()
  public content: string;
  // Make sure max-height is an integral multiple of line-height to avoid cutting off in the middle of text
  // FUTURE: Rather than relying on a developer to do the right thing, should consider:
  // * Extracting the line-height from the html element if it wasn't specified
  // * Rounding max-height down to the nearest multiple of the lineHeight.
  @Input()
  public maxHeight = 240;
  public isOverflown = false;
  public isShowingMore = false;
  @Input()
  public lineHeight = 28;

  @ViewChild('contentElement', { static: true })
  private _contentElement: ElementRef;
  private _scrollHeight: number;

  public constructor(
    dc: DeploymentContext,
    private _webAnalyticsService: WebAnalyticsService
  ) {
    super(dc);
  }

  // public getters
  public get styles(): Partial<CSSStyleDeclaration> {
    return this.isOverflown
      ? this.isShowingMore
        ? {
            lineHeight: `${this.lineHeight}px`,
          }
        : {
            // Make sure max-height is an integral multiple of line-height to avoid cutting off in the middle of text
            maxHeight: `${this.maxHeight}px`,
            lineHeight: `${this.lineHeight}px`,
            overflow: 'hidden',
          }
      : {
          // Make sure max-height is an integral multiple of line-height to avoid cutting off in the middle of text
          maxHeight: `${this.maxHeight}px`,
          lineHeight: `${this.lineHeight}px`,
        };
  }

  // public methods
  public ngAfterViewInit(): void {
    this._scrollHeight = this._contentElement.nativeElement.scrollHeight;
    this.triggerCDAndDo(() => this.determineIsOverflown());
  }

  public showMore(): void {
    this.toggleExpanded(true);
  }

  public showLess(): void {
    this.toggleExpanded(false);
  }

  // private methods
  private toggleExpanded(expand: boolean) {
    this.isShowingMore = expand;
    this._webAnalyticsService.trackEvent('show-more-or-less', {
      label: expand
        ? this.Loc(LocalizedTextIds.ShowMoreLessMore)
        : this.Loc(LocalizedTextIds.ShowMoreLessLess),
      value: this._scrollHeight,
    });
  }

  private determineIsOverflown(): boolean {
    return (this.isOverflown = this._scrollHeight > this.maxHeight);
  }
}
