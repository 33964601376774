<app-modal-overlay [showModal]="showModal" [borderRadius]="10" [heightPercent]="75" [maxHeight]="500" [width]="600">
    <div class="stay-in-the-loop">
        <div class="content">
            <img [src]="themeSettings.stayInTheLoopImgSrc" class="banner"/>
            <div class="header">{{Loc(LocalizedTextIds.StayInTheLoopWhatsNew)}}</div>
            <div class="carousel-controls">
                <div class="previous" [ngClass]="{ 'hide': currentScreen === 0 }" (click)="previous()"></div>
                <div class="title">{{Loc(LocalizedTextIds.StayInTheLoopCustomize)}}</div>
                <div class="next" [ngClass]="{ 'hide': currentScreen === blurbs.length - 1 }" (click)="next()"></div>
            </div>
            <div class="blurb">
                <div>{{currentBlurb.line1}}</div>
                <div *ngIf="currentBlurb.line2">{{currentBlurb.line2}}</div>
            </div>
            <div *ngIf="dots.length > 1" class="dots" >
                <ng-container *ngFor="let dot of dots">
                    <div class="dot" [ngClass]="{ 'current': dot === currentScreen }"></div>
                </ng-container>
            </div>
        </div>
        <div class="buttons">
            <div class="continue" (click)="getStarted()">
                {{Loc(LocalizedTextIds.StayInTheLoopGetStarted)}}
                <div class="right-arrow"></div>
            </div>
            <div class="later" (click)="close()">{{Loc(LocalizedTextIds.StayInTheLoopLater)}}</div>
        </div>
    </div>
</app-modal-overlay>
