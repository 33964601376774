<div  [ngClass]="'cf_app ' + instanceId">
  <!-- This application will be incorporated into the existing https://jnjinnovation.com/ site under the Companies entry.
       As a result, this site itself won't contain its own header.  However, We'll leave the component as a placeholder in case we
       end up with multiple flavors of the deployment, and maybe one might end up rendering its own header.
    <site-header></site-header>
  -->
  <log-in-out-banner *ngIf="featureSwitches.enableLogin && currentRoute !== '/login'"></log-in-out-banner>
  <div class="cf_app-router-wrapper">
    <router-outlet></router-outlet>
  </div>
  <div class="cf_app-version">Application Version: {{appVersion}}</div>
  <disclaimer></disclaimer>
</div>
<div class="footer-items" #footer>
  <login-footer *ngIf="featureSwitches.enableLogin"></login-footer>
  <app-health-check></app-health-check>
</div>
<ng-container class="overlays">
  <progress-indicator></progress-indicator>
  <stay-in-the-loop [isInternal]="isInternal()" *ngIf="isFollowEnabled"></stay-in-the-loop>
  <viewport-container class="cf_toast-viewport-container">
    <div toastContainer class="cf_toast-container"></div>
  </viewport-container>
</ng-container>