import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';

/**
 * Use this directive to make an element with a tooltip work with touch devices.
 * To use, add the touchTooltip attribute to the element, and if the component has
 * showPopup / hidePopup methods, bind those via (showPopup)='showPopup(...)' instead
 * of binding to mouseenter/mouseexit events.
 * For SVG-based components like Sector and Tag maps, this code will look around near
 * the target element for a <title> element, and if found, will use that if no
 * showPopup/hidePopup are provided.
 * Styling is in app/_style/responsive.scss currently.
 *
 * Note: we have encountered situations where the touchstart events get occluded or otherwise
 * don't come to us.  Notably when the swiper-pagination element is "too close" to the element with
 * touchTooltip, and also in the company update modals (like deal-modal), which we decided to punt on
 * since those don't work on small screens currently.
 */
@Directive({
    selector: '[touchTooltip]',
    standalone: false
})
export class TouchTooltipDirective implements AfterViewInit {
  constructor(private _el: ElementRef) {}

  @Output() showTooltip: EventEmitter<string | Event> = new EventEmitter<
    string | Event
  >();
  @Output() hideTooltip: EventEmitter<void> = new EventEmitter<void>();

  private isTouchscreen = false;
  private isShown = false;

  @HostListener('mouseenter', ['$event']) onMouseEnter(
    event: MouseEvent
  ): void {
    // Only show tooltip on mousenter for non-touchscreen devices, to prevent clicks from triggering this
    if (!this.isTouchscreen) {
      this.show(event);
    }
  }
  @HostListener('mouseleave', ['$event']) onMouseLeave(
    _event: MouseEvent
  ): void {
    this.hide();
  }
  @HostListener('touchstart', ['$event']) onTouchStart(
    event: TouchEvent
  ): void {
    this.handleTouchstart(event);
  }
  @HostListener('touchend', ['$event']) onTouchEnd(event: TouchEvent): void {
    this.handleTouchMoveOrEnd(event);
    this.handleTouchEnd(event);
  }
  @HostListener('touchmove', ['$event']) onTouchMove(event: TouchEvent): void {
    this.handleTouchMoveOrEnd(event);
  }

  ngAfterViewInit(): void {
    const nativeElement = this._el.nativeElement;

    if (nativeElement) {
      nativeElement.classList.add('touchTooltipAnchor');
      this.isTouchscreen = window.matchMedia('(pointer: coarse)').matches;
    }

    if (this.showTooltip.observers.length === 0) {
      this.showTooltip.subscribe((event) => this.tryShowTitle(event as Event));
    }
    if (this.hideTooltip.observers.length === 0) {
      this.hideTooltip.subscribe((_event) => this.hideTitle());
    }
  }

  private tip: HTMLElement;

  public tryShowTitle(event: Event): void {
    const nativeElement = this._el.nativeElement;
    if (nativeElement) {
      const el = event.target as HTMLElement;
      if (el) {
        const title = el.closest('g')?.querySelector('title')?.textContent;
        if (title) {
          this.hideTitle();
          this.tip = document.createElement('div');
          this.tip.innerText = title;
          this.tip.classList.add('touchTooltip');
          nativeElement.appendChild(this.tip);
        }
      }
    }
  }
  public hideTitle(): void {
    if (this.tip) {
      this.tip.remove();
    }
  }

  private _touchTimeout;
  public async handleTouchstart(event: Event): Promise<void> {
    this._touchTimeout = setTimeout(() => {
      this._touchTimeout = undefined;
      this.show(event);
    }, 500);
  }

  private clearTimeout(): void {
    if (this._touchTimeout) {
      clearTimeout(this._touchTimeout);
      this._touchTimeout = undefined;
    }
  }

  private show(event: Event): void {
    this.showTooltip.emit(event);
    this.isShown = true;
  }

  private hide(): void {
    this.clearTimeout();
    this.hideTooltip.emit();
    this.isShown = false;
  }

  public async handleTouchMoveOrEnd(_event: Event): Promise<void> {
    this.clearTimeout();
  }

  public async handleTouchEnd(event: Event): Promise<void> {
    if (this.isShown) {
      this.hide();
      // Prevent the touchEnd from triggering a subsequent click event if it was less
      // than the touch hold (e.g., haptic touch) interval.  Assuming we put up a tooltip,
      // we don't want to do anythign else with this touch end event.
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }
}
