<div class="cf_search-results" [ngClass]="{narrowScreen: narrowScreen}">
  <large-download-modal [showModal]="showLargeDownloadModal" (close)="closeLargePdfModal($event)">
  </large-download-modal>

  <search-bar [searchOnFilterChange]="true"
    [navigateToResultsOnSearch]="false">
  </search-bar>
  <filter [isSearchResultsScreen]="true" #filterComponent></filter>
  <div class="cf_search-results-content">
    <div class="cf_search-results-content-title">
      <div class="cf_search-results-content-title-result">
        <div *ngIf="showAllCompanies">{{Loc(LocalizedTextIds.AllCompanies)}}</div>
        <div *ngIf="!showAllCompanies">{{Loc(LocalizedTextIds.SearchResultsSearch)}}</div>
        <span class="cf_search-results-content-title-result-count">{{totalCompanies}}</span>
      </div>
    </div>

    <localized-text-snippet class="cf_search-results-content-title-result-info"
     text="{{Loc(LocalizedTextIds.SearchResultsOnlyShowingCompanies)}}">
    </localized-text-snippet>
    <div class="cf_search-results-sort-download-group">
      <sort-control [companies]="companies" #sortControl></sort-control>
      <div class="cf_search-results-content-export-section" *ngIf="companies.length > 0">
        <div *ngIf="companies.length > 0" [ngClass]="{'cf_search-results-content-export-button' : !showMenu,
          'cf_search-results-content-export-button-menu-open' : showMenu}" (click)="setShowMenu($event)">
          <div [ngClass]="{'cf_search-results-content-export-button-label' : !showMenu,
            'cf_search-results-content-export-button-label-menu-open' : showMenu}">{{Loc(LocalizedTextIds.Download)}}</div>
          <div [ngClass]="{'cf_search-results-content-export-button-icon' : !showMenu,
            'cf_search-results-content-export-button-icon-menu-open' : showMenu}"></div>
        </div>
        <div class="cf_search-results-content-export-menu" *ngIf="showMenu">
          <div class="cf_search-results-content-export-menu-item-pdf-outer" (click)="downloadPdfClicked()"
            *ngIf="isDownloadDocumentEnabled">
            <div class="cf_search-results-content-export-menu-item-pdf">
              <div class="cf_search-results-content-export-menu-item-icon-pdf"></div>
              <div class="cf_search-results-content-export-menu-item-label">{{Loc(LocalizedTextIds.DownloadPDF)}}</div>
            </div>
            <div class="cf_search-results-content-export-menu-item-label-subtitle" *ngIf="isDownloadDocumentEnabled">{{Loc(LocalizedTextIds.SearchResultsMayTake)}}</div>
          </div>
          <div class="cf_search-results-content-export-menu-item" (click)="downloadWordDocument()"
            *ngIf="isInternalView && isDownloadDocumentEnabled">
            <div class="cf_search-results-content-export-menu-item-icon-word"></div>
            <div class="cf_search-results-content-export-menu-item-label">{{Loc(LocalizedTextIds.DownloadWord)}}</div>
          </div>
          <div class="cf_search-results-content-export-menu-item" (click)="exportAsCsv()">
            <div class="cf_search-results-content-export-menu-item-icon-csv"></div>
            <div class="cf_search-results-content-export-menu-item-label">{{Loc(LocalizedTextIds.DownloadCSV)}}</div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="badSearchPredicate" class="cf_search-results-bad-search">
      {{Loc(LocalizedTextIds.SearchResultsSorry)}}<span
        class="cf_search-results-bad-search-predicate">{{badSearchPredicate}}</span>{{Loc(LocalizedTextIds.SearchResultsSorryEnd)}}
    </div>
      <div *ngFor="let company of companies; let last = last; let i = index">
        <company-summary #summary
          [company]="company"
          [tagCounts]="tagCounts"
        ></company-summary>
        <ng-container *ngIf="last">{{loadCompleted(i)}}</ng-container>
      </div>
  </div>
</div>