import { ICompanyUpdate } from "../entity/company-update.interface.js";
import { IDealUpdate } from "../entity/deal-update.interface.js";
import { IFundingUpdate } from "../entity/funding-update.interface.js";
import { UpdateStatus } from "../entity/update-field.interface.js";
import { Company, convertFieldFromDB, CompanyKey } from "../model/company.js";
import { Deal } from "../model/deal.js";
import { Funding } from "../model/funding.js";
import { ensureValueIsBoolean } from "./misc-util.js";

export function getBooleanAsNumber(value: string): number {
    return ensureValueIsBoolean(value) ? 1 : 0;
}

export function isDealFundBool(propertyName: string): boolean {
    return (
        // FUTURE - If/When we use decorators on deals and fundings, remove the below
        propertyName === 'isConfidential' ||
        propertyName === 'isDeleted' ||
        propertyName === 'isJnjDeal'
    );
}

export function isDealOrFundingUpdateDeclined(
    update: IDealUpdate | IFundingUpdate
): boolean {
    // Deal & funding updates are approved or declined in their entirety,
    // so if one field is declined, the whole update is declined.
    return (
        update?.updateFields?.length &&
        update.updateFields[0].status === UpdateStatus.Declined
    );
}

export function buildDealOrFundingFromUpdate(
    obj: Deal | Funding,
    update: IDealUpdate | IFundingUpdate
): Deal | Funding {
    update.updateFields.map((updateField) => {
        obj[updateField.name] = isDealFundBool(updateField.name)
            ? getBooleanAsNumber(updateField.newValue)
            : updateField.newValue;
    });
    return obj;
}

export function isCompanyContactTitle(propertyName: string): boolean {
    return propertyName === 'companyContactTitle';
}

export function applyUpdate(
    update: ICompanyUpdate,
    company: Company,
    requiresConversion: boolean = true // On the front end, this will already be applied. Modify in ADJQ-1784
): void {
    if (!update) {
        return;
    }

    update.updateFields?.forEach((updateField) => {
        if (updateField.status === UpdateStatus.Declined) {
            return;
        }

        if (isCompanyContactTitle(updateField.name)) {
            if (company.companyContact) {
                company.companyContact.title = updateField.newValue;
            }
            return;
        }

        const newValue = requiresConversion
            ? convertFieldFromDB(
                  updateField.name as CompanyKey,
                  updateField.newValue
              )
            : updateField.newValue;

        company[updateField.name] = newValue;
    });

    company.deals = company.deals ?? [];
    update.dealUpdates
        ?.filter((dealUpdate) => !isDealOrFundingUpdateDeclined(dealUpdate))
        .map((dealUpdate) => {
            const deal = company.deals.find(
                (d) => d.dealId === dealUpdate.modelId
            );
            if (deal) {
                dealUpdate.updateFields.map((updateField) => {
                    deal[updateField.name] = isDealFundBool(updateField.name)
                        ? getBooleanAsNumber(updateField.newValue)
                        : updateField.newValue;
                });
            } else {
                const newDeal = buildDealOrFundingFromUpdate(
                    new Deal(),
                    dealUpdate
                ) as Deal;
                newDeal.dealId = dealUpdate.modelId;
                company.deals.push(newDeal);
            }
        });

    company.funding = company.funding ?? [];
    update.fundingUpdates
        ?.filter((fundingId) => !isDealOrFundingUpdateDeclined(fundingId))
        .map((fundingUpdate) => {
            const funding = company.funding.find(
                (f) => f.fundingId === fundingUpdate.modelId
            );
            if (funding) {
                fundingUpdate.updateFields.map((updateField) => {
                    funding[updateField.name] = isDealFundBool(updateField.name)
                        ? getBooleanAsNumber(updateField.newValue)
                        : updateField.newValue;
                });
            } else {
                const newFunding = buildDealOrFundingFromUpdate(
                    new Funding(),
                    fundingUpdate
                ) as Funding;
                newFunding.fundingId = fundingUpdate.modelId;
                company.funding.push(newFunding);
            }
        });
}

export function hasValue(value: unknown): boolean {
    return value !== undefined && value !== null && value !== '';
}
