import {
  Component,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
} from '@angular/core';

@Component({
    selector: 'mutable-collection',
    templateUrl: './mutable-collection.component.html',
    styleUrls: ['./mutable-collection.component.scss'],
    standalone: false
})
export class MutableCollectionComponent<T> {
  // A collection of items to display
  @Input() items: T[];
  // A property of each item that is used for display. Required if T is an object type
  // Can be a property name or a method yielding the display name
  @Input() displayNameProperty?: (obj: T) => string | keyof T;
  // Specifies if the list should display horizontally or vertically. The default is horizontal
  @Input() displayType?: 'horizontal' | 'vertical';
  // A reference to an Angular template defining markup to display items in the collection
  @Input() itemTemplate?: TemplateRef<T>;
  // Event emitted when the remove icon is closed. Emits nothing if itemTemplate is used
  @Output() removeItem = new EventEmitter<T>();

  constructor() {}

  public get containerClassName(): string {
    return this.displayType || 'horizontal';
  }

  public deleteItem(item: T): void {
    this.removeItem.emit(item);
  }

  public getDisplayName(item: T): unknown {
    if (this.displayNameProperty) {
      if (typeof this.displayNameProperty === 'function') {
        return this.displayNameProperty(item);
      }
      return item[this.displayNameProperty];
    }
    return item;
  }
}
