import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// service imports;
import { AuthnService } from '@Common';
import { DeploymentContext } from '@Common';
import { WebAnalyticsService } from '@Common';
import { getParsedReturnUrl } from '@Common';
import { AuthnStrings } from '@Common';
import { ComponentBase } from '@Common';

@Component({
    selector: 'acs',
    templateUrl: './acs.component.html',
    styleUrls: ['./acs.component.scss'],
    standalone: false
})
export class AcsComponent extends ComponentBase implements OnInit {
  // public properties
  public showProgressIndicator = false;

  constructor(
    deploymentContext: DeploymentContext,
    private _authnService: AuthnService,
    private _webAnalyticsService: WebAnalyticsService,
    private _router: Router,
    private route: ActivatedRoute
  ) {
    super(deploymentContext);
  }

  // public methods
  public async ngOnInit(): Promise<void> {
    this._authnService.clearJnjLoggedOut();

    this.showProgressIndicator = true;
    setTimeout(async () => {
      if (!(await this._authnService.isSSOAuthValid())) {
        this._router.navigate(['']);
        return;
      }
      this._webAnalyticsService.trackEvent('login', {
        category: 'successful-login',
        label: this._authnService.currentUsername ?? '',
      });

      const target = getParsedReturnUrl(this.route);
      this._router.navigateByUrl(target);
    }, this._deploymentContext.progressIndicator.pauseForStaticIndicator);
  }
}
