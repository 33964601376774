import { Component, Input } from '@angular/core';

import { ComponentBase } from '@Common';

// model imports
import { EventFilterType } from 'company-finder-common';
import { DeploymentContext } from '@Common';

@Component({
    selector: 'pill',
    templateUrl: './pill.component.html',
    styleUrls: ['./pill.component.scss'],
    standalone: false
})
export class PillComponent extends ComponentBase {
  // public properties
  @Input()
  public type: EventFilterType;
  @Input()
  public value: string;
  public EventFilterTypes = EventFilterType;

  public constructor(dc: DeploymentContext) {
    super(dc);
  }
}
