<picker-modal
    (action)="addItems($event)"
    [(showModal)]="showModal"
    [modalTitle]="title"
    [blurb]="blurb"
    [objectsTypeName]="itemType"
    [filterPlaceholder]="filterText"
    [filterFunc]="doFilter"
    [compareFunc]="compareOptions">
    <ng-template #itemTemplate let-option="item">
        <div class="item">
            <div class="name">{{option}}</div>
        </div>
    </ng-template>
</picker-modal>
