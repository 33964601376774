<div class="edits-summary" [ngClass]="{ 'showing-submit': !isSubmitBarInvisible }">
    <div class="edits-summary-left">
        <img src="/assets/update/Rectangle-5.svg" class="approve-decline-icon"/>
        <div class="text-container">
            <div class="text" [ngClass]="{'grey': allEditsReviewed}">{{Loc(LocalizedTextIds.EditsSummaryEditsToReview)}}:</div>
            <div class="circle-magenta" [ngClass]="{'circle-grey': allEditsReviewed, 'circle-magenta': !allEditsReviewed}">
                <div class="circle-text">{{editsToReview}}</div>
            </div>
        </div>
    </div>
    <div class="edits-summary-right">
        <div class="edits-approved-and-declined">
            <div class="edits-approved-container">
                <div class="text-container">
                    <div class="text" [ngClass]="{'grey': !editsApproved}">{{Loc(LocalizedTextIds.EditsSummaryEditsApproved)}}:</div>
                    <div [ngClass]="{'circle-grey': !editsApproved, 'circle-green': editsApproved}">
                        <div class="circle-text">{{editsApproved}}</div>
                    </div>
                </div>
            </div>
            <div class="edits-declined-container">
                <div class="text-container">
                    <div class="text" [ngClass]="{'grey': !editsDeclined}">{{Loc(LocalizedTextIds.EditsSummaryEditsDeclined)}}:</div>
                    <div  [ngClass]="{'circle-grey': !editsDeclined, 'circle-red': editsDeclined}">
                        <div class="circle-text">{{editsDeclined}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="buttons">
            <ng-container *ngIf="onLastTab">
                <div class="button" *ngIf="declineButtonOn" (click)="declineAll()">
                    <img src="/assets/update/decline-all.svg"/>
                    <div class="button-text">{{Loc(LocalizedTextIds.EditsSummaryDeclineAll)}}</div>
                </div>
                <div class="button" *ngIf="approveButtonOn" (click)="approveAll()">
                    <img src="/assets/update/approve-all.svg"/>
                    <div class="button-text-approve">{{Loc(LocalizedTextIds.EditsSummaryApproveAll)}}</div>
                </div>
            </ng-container>
            <ng-container *ngIf="!onLastTab">
                <button  
                    *ngIf="isSubmitBarInvisible"
                    class="next"
                    (click)="nextTab()">
                    <div class="submit-edits-label">
                        <div>{{Loc(LocalizedTextIds.EditsSummaryNextTab)}}</div>     
                    <div class="right-arrow"></div>
                    </div>
                </button>
            </ng-container>
        </div>
    </div>
</div> 