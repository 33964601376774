<div class="cf_company-update">
  <update-header></update-header>
  <div class="cf_company-update_content">
    <div>
      <company-update-confirmation 
        [hidden]="hideConfirmation"
        [company]="this.companyBeforeAnyChanges"
        [submittedUpdate]="submittedUpdateForConfirmation"
        [selfUpdateMode]="selfUpdateMode"
        #submissionConfirmation>
      </company-update-confirmation>
    </div>
    <div
      [hidden]="!hideConfirmation">
      <div class="title-bar">
        <div class="title">
          {{Loc(LocalizedTextIds.CompanyProfile)}}
        </div>
        <div class="view-jlabs-page" (click)="goToJlabsPage()">
          <div class="view-jlabs-page-text">{{Loc(LocalizedTextIds.CompanyUpdateViewJLABS)}}</div>
          <div class="red-arrow"></div>
        </div>
      </div>
      <notifications [hidden]="!isEditMode" #notificationsComponent></notifications>
      <div>
        <div class="company-info"
          [ngClass]="{'review': isReviewMode}">
          <div>
            <div class="name">{{companyBeforeAnyChanges.name}}</div>
            <company-location-status
              class="location-status"
              [company]="companyBeforeAnyChanges"
              [isUpdateScreen]="true">
            </company-location-status>
            <div class="data">
              <div *ngIf="featureSwitches.enableSectors" class="sectors">
                <div class="info-icon" (mouseenter)="toggleTooltip('primary')" (mouseleave)="toggleTooltip('primary')">
                  <div class="tooltip"
                    *ngIf="isShowingTooltip['primary']">
                    {{Loc(LocalizedTextIds.CompanyUpdateBasedOn)}}
                  </div>
                </div>
                <div class="headers">
                  <div *ngIf="companyBeforeAnyChanges.primarySector" class="header cell">{{Loc(LocalizedTextIds.PrimarySector)}}</div>
                  <div *ngIf="companyBeforeAnyChanges.primarySubSector?.length > 0 " class="header cell">{{Loc(LocalizedTextIds.SubSector)}}</div>
                  <div *ngIf="companyBeforeAnyChanges.primaryIndication" class="header cell">{{Loc(LocalizedTextIds.Indication)}}</div>
                </div>
                <div class="values">
                  <div *ngIf="companyBeforeAnyChanges.primarySector" class="cell elide">{{companyBeforeAnyChanges.primarySector}}</div>
                  <div *ngIf="companyBeforeAnyChanges.primarySubSector?.length > 0 " class="cell elide">{{companyBeforeAnyChanges.primarySubSector}}</div>
                  <div *ngIf="companyBeforeAnyChanges.primaryIndication" class="cell elide">{{companyBeforeAnyChanges.primaryIndication}}</div>
                </div>
              </div>
              <div *ngIf="companyBeforeAnyChanges.jpalContact && featureSwitches.enableJPAL" class="jpal">
                <div class="header cell">{{Loc(LocalizedTextIds.JPAL)}}</div>
                <div class="cell">{{companyBeforeAnyChanges.jpalContact.name}}</div>
                <div class="item-link cell">
                  <a
                    href="mailto:{{companyBeforeAnyChanges.jpalContact.email}}">
                    {{companyBeforeAnyChanges.jpalContact.email}}
                  </a>
                </div>
              </div>
              <div *ngIf="companyBeforeAnyChanges.siteHead" class="site-head">
                <div class="header cell">{{Loc(LocalizedTextIds.SiteHead)}}</div>
                <div class="cell">{{companyBeforeAnyChanges.siteHead.name}}</div>
                <div class="item-link cell ">
                  <a
                    href="mailto:{{companyBeforeAnyChanges.siteHead.email}}">
                    {{companyBeforeAnyChanges.siteHead.email}}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isEditMode || isReviewPendingProgressUpdate" class="share-update">
          <ng-container>
            <div class="title">
              {{Loc(LocalizedTextIds.CompanyUpdateShareAnUpdate)}}
            </div>
            <div class="blurb">
              {{Loc(LocalizedTextIds.CompanyUpdateTheseNonConfidential)}}
            </div>
            <div class="prompt" [ngClass]="{ 'different': isUpdateFieldSet('progressUpdate') }">
              <div *ngIf="showFlag" class="flag"></div>
              {{Loc(LocalizedTextIds.CompanyUpdateAnyUpdate)}}
            </div>
            <textarea
              id="company-update-progressUpdate"
              name="company-update-progressUpdate"
              [(ngModel)]="progressUpdate"
              (ngModelChange)="synchronizeProgressUpdate($event)"
              placeholder={{Loc(LocalizedTextIds.CompanyUpdateShareCompany)}}
              autofocus>
            </textarea>
            <div *ngIf="isEditMode" class="footer">
              <button 
                class="submit-shared-update"
                [ngClass]="{ 'disabled': isSubmitEditsDisabled }"
                (click)="updateCompany()"
                [disabled]="isSubmitEditsDisabled" >
                  <div>{{Loc(LocalizedTextIds.Submit)}}</div>
                  <div class="right-arrow"></div>
              </button>
            </div>
          </ng-container>
          <div *ngIf="isReviewPendingProgressUpdate && isUpdateFieldSet('progressUpdate')" class="approve-decline-wrapper">
            <approve-decline [includeInSummary]="isUpdateFieldSet('progressUpdate')"
              [propertyName]="'progressUpdate'">
            </approve-decline>
          </div>
        </div>
        <form [formGroup]="companyEditForm">          
          <simple-tab-ui 
            [tabs]="tabs"
            (tabClickEvent)="handleTabClick($event)"
            #tabControl
          ></simple-tab-ui>
          <public-information-update 
            [hidden]="!isShowingPublicInfo"
            [companyEditForm]="companyEditForm"
          ></public-information-update>
          <jnj-information-update 
            [hidden]="!isShowingJnjInfo" 
            [companyEditForm]="companyEditForm"
            [notificationsComponent]="notificationsComponent"
          ></jnj-information-update>
          <blue-knight-information-update
            [hidden]="!isShowingBlueKnightInfo" 
            [companyEditForm]="companyEditForm"
            [notificationsComponent]="notificationsComponent"
          ></blue-knight-information-update>
          <blue-knight-milestones-update
            [hidden]="!isShowingBlueKnightMilestones" 
            [companyEditForm]="companyEditForm"
          ></blue-knight-milestones-update>
          <p class="cf_company-update_disclaimer">
            {{Loc(LocalizedTextIds.CompanyUpdatePleaseNote)}}
          </p>
          <sticky-on-scroll #editsSubmissionSummary screenPosition="bottom">
            <div class="edits-submission-summary">
              <div [hidden]="!isReviewMode || !pendingUpdate"
                [ngClass]="{ 'dim': isEditsSummaryInactive }">
                <edits-summary
                  [onLastTab]="onLastTab"                  
                  (nextTabClickEvent)="nextTab()"
                  [isSubmitBarInvisible]="isSubmitBarInvisible">
                </edits-summary>
              </div>
              <div class="submission-summary" *ngIf="!isSubmitBarInvisible">
                <div *ngIf="isReviewMode" class="comments-section" [ngClass]="!isFollowEnabled ? 'no-share' : ''">
                  <div class="comments-section-prompt">
                    <span>{{Loc(LocalizedTextIds.CompanyUpdateBeforeSubmitting)}}</span>
                  </div>
                  <div class="review-comments">
                    <div
                      *ngIf="reviewComments && !isEditingReviewComments"
                      class="company-update-review-comments"
                      (click)="makeReviewCommentsEditable($event)">
                      <span class="preface">{{Loc(LocalizedTextIds.CompanyUpdateCommentsToCompany)}} </span>{{reviewComments}}
                    </div>
                    <textarea
                      *ngIf="!reviewComments || isEditingReviewComments"
                      id="company-update-review-comments"
                      name="company-update-review-comments"
                      class="review"
                      [(ngModel)]="reviewComments" [ngModelOptions]="{standalone: true}"
                      placeholder={{Loc(LocalizedTextIds.CompanyUpdateAnyComments)}}
                      (click)="makeReviewCommentsEditable($event)">
                    </textarea>
                  </div>
                </div>
                <ng-container *ngIf="isReviewMode">
                  <div *ngIf="isFollowEnabled && hasUpdatesToShareWithFollowers" class="share-with-followers">
                    <div class="icon-and-text-wrapper">
                      <div class="icon-and-text">
                        <div class="text-wrapper">
                          <div class="text">{{Loc(LocalizedTextIds.CompanyUpdateShareWith)}}</div>
                          <div class="prompt">{{Loc(LocalizedTextIds.CompanyUpdateDoYouFeel)}}
                            <span class="italics">{{Loc(LocalizedTextIds.Required)}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="share-buttons">
                      <div class="button" [ngClass]="{'on': isShareWithFollowers === true, 'off': isShareWithFollowers === false}"
                        (click)="isShareWithFollowers = true">{{Loc(LocalizedTextIds.Yes)}}</div>
                      <div class="button no" [ngClass]="{'on': isShareWithFollowers === false, 'off': isShareWithFollowers === true}"
                        (click)="isShareWithFollowers = false">{{Loc(LocalizedTextIds.No)}}</div>
                    </div>
                  </div>
                  <div class="review-buttons">
                    <button 
                      type="button" 
                      class="review-cancel" 
                      (click)="cancelReview()">
                      {{Loc(LocalizedTextIds.Cancel)}}
                    </button>
                    <button 
                      type="button" [disabled]="isSubmitReviewDisabled"
                      class="review-submit"
                      [ngClass]="{'disabled': isSubmitReviewDisabled}"
                      (click)="submitReview()">
                      {{Loc(LocalizedTextIds.Submit)}}
                    </button>
                  </div>
                </ng-container>
              </div>
            </div>
          </sticky-on-scroll>
        </form>
      </div>
      <sticky-on-scroll #submitEditsFooter screenPosition="bottom" *ngIf="isEditMode">
        <div class="cf_company-update_footer edit">      
          <div *ngIf="invalidFieldCount > 0"  class="missing-required-fields-count">
            {{missingWarning}}: <div class="list">{{missingFields}}</div>
          </div>
          <button type="button" class="cf_company-update_footer-revert" (click)="revertChanges()">
            {{Loc(LocalizedTextIds.RevertChanges)}}
          </button>
          <button *ngIf="isEditMode"
            type="button"
            [disabled]="isSaveEditsDisabled"
            class="cf_company-update_footer-submit"
            [ngClass]="{'disabled': isSaveEditsDisabled}"
            (click)="saveEdits()">
            <div>{{Loc(LocalizedTextIds.SaveEdits)}}</div>
          </button>
          <div class="disabled-click-handler" (mousedown)="handleDisabledSubmitClick()">
            <button type="button" 
              [disabled]="onLastTab && isSubmitEditsDisabled" 
              class="cf_company-update_footer-submit"
              [ngClass]="{'disabled': onLastTab && isSubmitEditsDisabled}"
              [title]="nextTabOrSubmitButtonTitle"
              (click)="nextTabOrSubmit()">
                <div class="submit-edits-label">
                <div *ngIf="onLastTab">{{Loc(LocalizedTextIds.SubmitEdits)}}</div>                
                <div *ngIf="!onLastTab">{{Loc(LocalizedTextIds.NextTab)}}</div>
                <div class="right-arrow"></div>
              </div>
            </button>
          </div>
        </div>
      </sticky-on-scroll>
    </div>
  </div>
</div>