import {
  Company,
  CompanyKey,
  Contact,
  Deal,
  Funding,
  IDealUpdate,
  IFundingUpdate,
  IsArrayProperty,
  IUpdateField,
} from 'company-finder-common';
import _ from 'lodash-es';

export class Diff {
  public name: IUpdateField;
  public logoBase64: IUpdateField;
  public website: IUpdateField;
  public currentRdStage: IUpdateField;
  public progressUpdate: IUpdateField;
  public keyMgmtAndAdvBm: IUpdateField;
  public description: IUpdateField;
  public problem: IUpdateField;
  public solution: IUpdateField;
  public womenLed: IUpdateField;
  public minorityLed: IUpdateField;
  public firstTimeEntrepreneur: IUpdateField;
  public countryForDeiReporting: IUpdateField;
  public leadershipDiversity: IUpdateField;
  public boardAdvisorDiversity: IUpdateField;
  public womenLedOrgLeadership: IUpdateField;
  public womenLedBoardOfDirectorsOrEquiv: IUpdateField;
  public companyContactTitle: IUpdateField;
  public tags: IUpdateField;
  public deals: IDealUpdate[];
  public funding: IFundingUpdate[];
  // Blue Knight properties
  public companyObjective: IUpdateField;
  public alignedGoal: IUpdateField;
  public approachUsecase: IUpdateField;
  public entryExitStrategy: IUpdateField;
  public priorities: IUpdateField;
  public challenges: IUpdateField;
  public mentorship: IUpdateField;
  public currentTeamSizeRange: IUpdateField;
  public compositionAndGrowth: IUpdateField;
  public conferencesAndEvents: IUpdateField;
  public commercialPartnerships: IUpdateField;
  public rAndDPartnerships: IUpdateField;
  public otherPartnerships: IUpdateField;
  public overallTRL: IUpdateField;
  public alignedTRL: IUpdateField;
  public compositionOfMatter: IUpdateField;
  public securedIP: IUpdateField;
  public filedIP: IUpdateField;
  public addtlIPDetails: IUpdateField;
  public fundingStage: IUpdateField;
  public fundingStageDetails: IUpdateField;
  public nonDilutiveFunding: IUpdateField;
  public dilutiveFunding: IUpdateField;
  public majorMilestones: IUpdateField;
  public currentPharmaStage: IUpdateField;
  public currentMedTechStage: IUpdateField;
  public stageDetails: IUpdateField;
  public regulatoryStatus: IUpdateField;
  public anticipatedCommercialProductYr: IUpdateField;
  public nonLeadRegStatus: IUpdateField;

  public primarySector: IUpdateField;
  public primarySubSector: IUpdateField;
  public secondarySector: IUpdateField;
  public secondarySubSector: IUpdateField;

  public get hasChanges(): boolean {
    return (
      this.hasPropertyChanges || this.hasDealChanges || this.hasFundingChanges
    );
  }

  public isIUpdateField(obj: Record<string, unknown>): boolean {
    return 'newValue' in obj && 'oldValue' in obj;
  }

  public get hasPropertyChanges(): boolean {
    const updateFields = Object.keys(this) // For all members of this diff ...
      .filter((key) => this.isIUpdateField(this[key])) // That are an IUpdateField
      .map((key) => this[key] as IUpdateField); // Return the object as an IUpdateField

    return updateFields.some((field) => isUpdateAChange(field));
  }

  public get hasDealChanges(): boolean {
    return this.deals?.length > 0;
  }

  public get hasFundingChanges(): boolean {
    return this.funding?.length > 0;
  }
}

export function isUpdateAChange(field?: {
  newValue: unknown;
  oldValue: unknown;
}): boolean {
  if (!field) {
    return false;
  }
  return areObjectsDifferent(field.oldValue, field.newValue);
}

export function areObjectsDifferent(obj1: unknown, obj2: unknown): boolean {
  if (areBothEmptyNullOrUndefined(obj1, obj2)) {
    return false;
  }

  if (areObjectsEquivalentBooleans(obj1, obj2)) {
    return false;
  }

  return obj1 !== obj2;
}

export function itemIsDifferent(
  propertyName: string,
  company: Company,
  companyToCompare: Company
): boolean {
  if (IsArrayProperty(propertyName as CompanyKey)) {
    // NOTE: This assumes they are both sorted in the same order.
    // Any time arrays are modified we should ensure the items remained ordered.
    return !_.isEqual(company[propertyName], companyToCompare[propertyName]);
  }

  if (propertyName === 'companyContactTitle') {
    return company.companyContact == null
      ? false
      : areObjectsDifferent(
          company.companyContact.title,
          companyToCompare.companyContact.title
        );
  }
  return areObjectsDifferent(
    company[propertyName],
    companyToCompare[propertyName]
  );
}

export function areBothEmptyNullOrUndefined(
  obj1: unknown,
  obj2: unknown
): boolean {
  return (
    (obj1 === null ||
      obj1 === undefined ||
      obj1 === '' ||
      (Array.isArray(obj1) && obj1.length === 0)) &&
    (obj2 === null ||
      obj2 === undefined ||
      obj2 === '' ||
      (Array.isArray(obj2) && obj2.length === 0))
  );
}

export type ComplexUpdateObject = Deal | Funding;
export type ComplexUpdateKey = 'deals' | 'funding';
export function getObjectAndType(key: ComplexUpdateKey): {
  typeId: string;
  newItem: ComplexUpdateObject;
} {
  switch (key) {
    case 'deals':
      return { typeId: 'dealId', newItem: new Deal() };
    case 'funding':
      return { typeId: 'fundingId', newItem: new Funding() };
  }
}

export function isComplexUpdateObject(key: string): boolean {
  return key === 'deals' || key === 'funding';
}

// NOTE: Passing ordinary any types here for object & base is causing lodash to match the wrong _.transform() call signature.
//       Using lodash's Dictionary parameterized type instead finds the desired _.transform() method, keeping the compiler happy.
export function findChanges(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: _.Dictionary<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  base: _.Dictionary<any>
): unknown {
  // Extended from original technique found here https://gist.github.com/Yimiprod/7ee176597fef230d1451
  const fullChanges = _.transform(object, (result, value, key: string) => {
    if (isComplexUpdateObject(key)) {
      // value & base[key] are arrays of Deal | Funding
      findComplexUpdateChanges(
        key as ComplexUpdateKey,
        value as ComplexUpdateObject[],
        base[key] as ComplexUpdateObject[],
        result
      );
    } else {
      // NOTE: Dates need special handling because they are modeled as Date objects,
      //       but they are stored as strings, and users provide them as strings.
      if (key === 'announcementDate' || key === 'dateRaised') {
        if (value instanceof Date) {
          value = value.toISOString();
        }
        if (base[key] instanceof Date) {
          base[key] = base[key].toISOString();
        }
      }
      if (!_.isEqual(value, base[key])) {
        if (_.isObject(value) && _.isObject(base[key])) {
          // NOTE: A company has a companyContact Contact object, which reports as different
          //       if the user changes the title (the only thing they are allowed to change).
          //       If we're attempting to compare the companyContact differences here, skip
          //       directly to the title.
          if (key === 'companyContact') {
            // We considered trying to enhance _.isEqual to treat null & undefined (and empty string)
            // as equivalent for properties of objects being compared, but for now, we just did this targeted fix
            if (
              areObjectsDifferent(
                (value as Contact).title,
                (base[key] as Contact).title
              )
            ) {
              result['companyContactTitle'] = {
                newValue: (value as Contact).title || '',
                oldValue: (base[key] as Contact).title || '',
                modelId: base.opportunityIdPrimary,
              };
            }
          } else if (IsArrayProperty(key as CompanyKey)) {
            const newValue = _.cloneDeep(value as string[]);
            const oldValue = _.cloneDeep(base[key] as string[]).filter(
              (val) => !!val
            );

            result[key] = {
              newValue,
              oldValue,
              modelId: base.opportunityIdPrimary,
            };
          } else {
            result[key] = findChanges(value, base[key]);
          }
        } else {
          if (
            !areBothEmptyNullOrUndefined(value, base[key]) &&
            !areObjectsEquivalentBooleans(value, base[key])
          ) {
            result[key] = {
              newValue: value,
              oldValue: base[key],
              modelId:
                (base as Deal).dealId ||
                (base as Funding).fundingId ||
                base.opportunityIdPrimary,
            };
          }
        }
      }
    }
  });

  if (Object.keys(fullChanges).length !== 0) {
    JSON.stringify(fullChanges);
  }

  return fullChanges;
}

export function findComplexUpdateChanges(
  complexKey: ComplexUpdateKey,
  complexValue: ComplexUpdateObject[],
  complexBase: ComplexUpdateObject[],
  result: unknown
): void {
  const { typeId, newItem } = getObjectAndType(complexKey);
  const addedComplexUpdateObject = complexValue?.filter(
    (item: ComplexUpdateObject) =>
      !_.some(
        complexBase,
        (baseItem: ComplexUpdateObject) => baseItem[typeId] === item[typeId]
      )
  );
  const deletedComplexUpdateObject = complexBase?.filter(
    (baseItem: ComplexUpdateObject) =>
      !_.some(
        complexValue,
        (item: ComplexUpdateObject) => item[typeId] === baseItem[typeId]
      )
  );
  const possiblyEditedComplexUpdateObject = [];
  complexValue?.filter((item) => {
    const match = complexBase?.find(
      (baseItem) => baseItem[typeId] === item[typeId]
    );
    if (match) {
      possiblyEditedComplexUpdateObject.push({
        updated: item,
        base: match,
      });
    }
  });

  addedComplexUpdateObject?.forEach((addedThing) => {
    result[complexKey] = result[complexKey] || [];
    newItem[typeId] = addedThing[typeId];
    result[complexKey].push(findChanges(addedThing, newItem));
  });

  deletedComplexUpdateObject?.forEach((deletedThing) => {
    result[complexKey] = result[complexKey] || [];
    const deletion = {
      isDeleted: true,
    } as ComplexUpdateObject;
    deletion[typeId] = deletedThing[typeId];
    result[complexKey].push(findChanges(deletion, deletedThing));
  });

  possiblyEditedComplexUpdateObject?.forEach((possiblyEditedThing) => {
    const changes = findChanges(
      possiblyEditedThing.updated,
      possiblyEditedThing.base
    );
    if (!_.isEmpty(changes)) {
      result[complexKey] = result[complexKey] || [];
      result[complexKey].push(changes);
    }
  });
}

export function compareCompanies(object: Company, base: Company): Diff {
  const newDiff = new Diff();
  const diff = findChanges(object, base);
  const merged = _.merge(newDiff, diff);
  return merged;
}

export function areObjectsEquivalentBooleans(
  obj1: unknown,
  obj2: unknown
): boolean {
  return (
    (valIsTruthy(obj1) && valIsTruthy(obj2)) ||
    (valIsFalsy(obj1) && valIsFalsy(obj2))
  );
}

export function valIsTruthy(value: unknown): boolean {
  return value === '1' || value === 1 || value === true;
}

export function valIsFalsy(value: unknown): boolean {
  return value === '0' || value === 0 || value === false;
}
