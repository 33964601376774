import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { ComponentBase } from '@Common';

// model imports
import {
  Company,
  isNewCompany,
  orderByTagCount,
  TagWithCount,
  urlIdentifierForCompany,
} from 'company-finder-common';

// utility imports
import { LogoSize } from '@Common';
import { WebAnalyticsService } from '@Common';
import { DeploymentContext } from '@Common';

@Component({
    selector: 'company-summary',
    templateUrl: './company-summary.component.html',
    styleUrls: ['./company-summary.component.scss'],
    standalone: false
})
export class CompanySummaryComponent extends ComponentBase implements OnInit {
  // public properties
  @Input()
  public company: Company;
  public isShowingLocationsTooltip = false;
  public logoSize: LogoSize = LogoSize.ExtraLarge;
  @Input()
  public tagCounts: TagWithCount[];
  public isShowingTooltip = false;
  public tagsToDisplay: string[] = [];

  @ViewChild('summary')
  private self: ElementRef;

  public get height(): number {
    return this.self?.nativeElement?.offsetHeight ?? 0;
  }

  public constructor(
    dc: DeploymentContext,
    private _router: Router,
    private _webAnalyticsService: WebAnalyticsService
  ) {
    super(dc);
  }

  ngOnInit(): void {
    this.tagsToDisplay = orderByTagCount(this.company, this.tagCounts);
  }

  // public methods
  public get isNew(): boolean {
    return isNewCompany(this.company);
  }

  public onClick(): void {
    this._router.navigate([
      'company',
      urlIdentifierForCompany(this.company.name),
    ]);
    this._webAnalyticsService.trackEvent('select-company-from-summary', {
      category: 'Company Details',
      label: this.company.name,
    });
  }
}
