<ng-container *ngIf="propertyGroup">
    <div *ngIf="propertyGroup?.header" class="info-section-header">
        {{header}}        
    </div>
    <div *ngIf="propertyGroup?.subheader" class="info-section-subheader">
        {{subheader}} 
        <ng-container *ngIf="displayUrl">&nbsp;
            <a href="{{href}}" target="_blank">{{urlDisplayText}}</a>
        </ng-container>
    </div> 
    <ng-container *ngFor="let property of propertyGroup.properties; trackBy: trackBy">
        <edit-item            
            [parentForm]="companyEditForm"
            [propertyName]="property"
        >
        </edit-item>
    </ng-container>
</ng-container>