<div class="login-box" *ngIf="showLoginButtons">
    <input 
        class="button"
        type="submit"
        value={{Loc(LocalizedTextIds.LoginFooterPartner)}}
        (click)="loginJuniverse()"
    >
    <input
        class="button"
        type="submit"
        value={{Loc(LocalizedTextIds.LoginFooterUsers)}}
        (click)="loginJnJ()"
    >
</div>
