{
  "name": "company-finder-app",
  "private": true,
  "type": "module",
  "module": "src/index.ts",
  "version": "6.2.19-alpha.126.da0d4f60a3b1b593326ecc133d528826bd7fe380",
  "scripts": {
    "start-dev": "TS_NODE_PROJECT=/workspace/company-finder-app/tsconfig.json NODE_OPTIONS='--loader ts-node/esm -r tsconfig-paths/register' ng serve --disable-host-check --proxy-config proxy.conf.json",
    "build": "TS_NODE_PROJECT=./tsconfig.json NODE_OPTIONS='--loader ts-node/esm -r tsconfig-paths/register' ng build"
  },
  "browser": {
    "fs": false,
    "path": false,
    "os": false
  },
  "dependencies": {
    "@angular/animations": "^19.1.4",
    "@angular/cdk": "^19.1.0",
    "@angular/common": "^19.1.4",
    "@angular/compiler": "^19.1.4",
    "@angular/core": "^19.1.4",
    "@angular/forms": "^19.1.4",
    "@angular/platform-browser": "^19.1.4",
    "@angular/platform-browser-dynamic": "^19.1.4",
    "@angular/router": "^19.1.4",
    "@auth0/angular-jwt": "^5.2.0",
    "@iframe-resizer/child": "^5.3.1",
    "core-js": "^3.4.8",
    "d3": "^7.9.0",
    "escape-string-regexp": "^5.0.0",
    "lodash-es": "^4.17.21",
    "mapbox-gl": "^3.4.0",
    "ngx-pagination": "^6.0.3",
    "ngx-toastr": "^19.0.0",
    "ngx-ui-scroll": "^3.2.1",
    "rxjs": "^7.8.1",
    "swiper": "^11.2.1",
    "ts-loader": "^9.5.2",
    "tslib": "^2.8.1",
    "uuid": "^11.0.5",
    "vscroll": "^1.6.1",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-builders/custom-webpack": "^19.0.0",
    "@angular-builders/dev-server": "7.3",
    "@angular-devkit/core": "^19.1.3",
    "@angular/build": "^19.1.5",
    "@angular/cli": "^19.1.3",
    "@angular/compiler-cli": "^19.1.4",
    "@angular/language-service": "^19.1.4",
    "@tsed/barrels": "^6.1.11",
    "@types/lodash": "^4.14.149",
    "@types/mapbox-gl": "3.4",
    "@types/node": "^18.19.1",
    "ts-node": "^10.9.2",
    "typescript": "^5.7.2"
  }
}
