import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { IframeResizerService } from '../../services/iframe/iframe.resizer.service.js';
import { DeploymentContext } from '@Common';
import { iframeResizer } from '@iframe-resizer/child';

@Component({
    selector: 'sticky-on-scroll',
    templateUrl: './sticky-on-scroll.component.html',
    standalone: false
})
export class StickyOnScrollComponent
  implements AfterViewInit, OnDestroy {
  @Input() screenPosition: 'top' | 'bottom' = 'top';
  private _scrollEventSubscription: Subscription;
  private top = 0;
  @ViewChild('elementContainer')
  private _el: ElementRef;

  constructor(
    private _scrollingService: IframeResizerService,
    private _deploymentContext: DeploymentContext,
    private ngZone: NgZone
  ) { }

  public get elementStyleTop(): string {
    if (this._deploymentContext.hosted()) {
      // When hosted, use the top property to move the element according to the calculated
      // yOffset
      return `${this.top}px`;
    }
    if (this.screenPosition === 'top') {
      // Applies when not hosted and screen position is top. Fix element to the top
      // of the viewport.
      return '0px';
    }
    return undefined;
  }

  // Applies when not hosted and screenPosition is bottom. Fix element to the bottom of the viewport
  public get elementStyleBottom(): string {
    return (!this._deploymentContext.hosted() &&
      this.screenPosition === 'bottom')
      ? '0px' :
      undefined;
  }

  private get _elementHeight(): number {
    return this._el?.nativeElement?.offsetHeight ?? 0;
  }

  ngAfterViewInit(): void {
    if (this._deploymentContext.hosted()) {
      // This is an attempt to make sure that the dom content is as close to fully rendered as
      // possible before we try to modify the position of any element. There's no Angular lifecycle
      // event corresponding to when all content is rendered, so by making this an async callback we
      // can hope it at least runs after the next synchronous task
      requestAnimationFrame(() => {
        if (this._scrollEventSubscription) {
          return;
        }
        this._scrollEventSubscription =
          this._scrollingService.iframeSubject.subscribe(
            (value: iframeResizer.ParentProps) => {
              this.handleScrollEvent(value);
            }
          );
      });
    }
  }

  ngOnDestroy(): void {
    this._scrollEventSubscription?.unsubscribe();
  }
  
  // Note: this currently only handles vertical scrolling (as is what most people think of when they refer
  // to "sticky" behavior). But there's no reason we couldn't support horizontal stickiness in the future
  private handleScrollEvent(windowProperties: iframeResizer.ParentProps) {
    this.ngZone.run(() => {
      if (this.screenPosition === 'top') {
        this.top = -1 * windowProperties.iframe.top;
        return;
      }

      const lowestTopVal = windowProperties.iframe.bottom - windowProperties.iframe.top - this._elementHeight;
      const calculatedTop = windowProperties.viewport.height - windowProperties.iframe.top - this._elementHeight;

      this.top = Math.min(calculatedTop, lowestTopVal);
    });
  }
}
