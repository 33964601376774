<div class="cf_geo-visualizer" [ngStyle]="{
        'max-width': themeSettings.map.maxWidth,
        'max-height': themeSettings.map.maxHeight,
        'width': '90vw',
        'height': 'calc(90vw / ' + themeSettings.map.aspectRatio + ')'
    }">
    <div class="cf_geo-visualizer-markers" >
        <location-indicator 
            *ngFor="let locationMarker of locationsToShow"
            [tooltipHandler]="tooltipHandler"
            [locationMarker]="locationMarker"
            [invertLabel]="true">
        </location-indicator>
    </div>
    <div [id]='ID' class="cf_geo-visualizer-map" #map>
    </div>
</div>
