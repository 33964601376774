<div class="cf_search-bar cf_search-bar-border">
  <div class="cf_search-bar-content">
    <img class="cf_search-bar-logo" src="{{logoPath || themeSettings.logoImgSrc}}" (click)="goHome()"/>
    <ng-container  *ngIf="enableSearch">
      <form (ngSubmit)="performSearch()">
        <div class="cf_search-bar-controls">
          <div class="cf_search-bar_input">
            <input type="text"
              id="search"
              name="search"
              [(ngModel)]="currentSearchPredicate"
              autocomplete="off"
              autofocus
              placeholder={{Loc(LocalizedTextIds.SearchBarSearchCompanies)}}
              #searchBox
              (keydown)="inputKeyUpWrapper($event)"
              (keypress)="inputKeyUpWrapper($event)"
              (keyup)="inputKeyUpWrapper($event, true)"/>
          </div>
          <button class="cf_search-bar-search-button"></button>
          <div *ngIf="hasSuggestions" class="autocomplete-menu" #typeaheadMenu>
            <ng-container *ngFor="let suggestion of suggestions; let i = index">
              <div *ngIf="containsSearchTerm(suggestion)" (click)="applySuggestion(suggestion)">
                <div class="autocomplete-menu-item" [ngClass]="{ 'active': typeaheadActiveIndex === i }">
                  <ng-container *ngFor="let piece of getSuggestionInPieces(suggestion)">
                    <span *ngIf="equalsSearchTerm(piece)">{{piece}}</span>
                    <span *ngIf="!equalsSearchTerm(piece)" class="autocomplete-menu-item-piece-bold">{{piece}}</span>
                  </ng-container>
                </div>
              </div>
              <div
                *ngIf="!containsSearchTerm(suggestion)"
                class="autocomplete-menu-item"
                [ngClass]="{ 'active': typeaheadActiveIndex === i }"
                (click)="applySuggestion(suggestion)">
                {{suggestion}}
              </div>
            </ng-container>
          </div>
        </div>
      </form>
      <review-modal #reviewComponent [reviews]='reviews' [showModal]='showReviewModal && !isReviewModalDismissed'></review-modal>
      <navigation-panel
        [isFollowEnabled]="isFollowEnabled"
        [unseenEventCount]="unseenEventCountsForAll"
        [updateCount]="updateCount"
        [reviews]="reviews"
        (goHome)="goHome()"
        (goToCompanyUpdate)="goToCompanyUpdate($event)"
        (goToMyUpdates)="goToMyUpdates()"
        (goToPreferences)="goToPreferences()"
        (showPendingUpdates)="showPendingUpdates()"
      >
      </navigation-panel>
  </ng-container>
  </div>
</div>
