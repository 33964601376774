import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import _ from 'lodash-es';

// utility imports
import { DeploymentContext } from '@Common';
import { ApplicationContext } from '../../utilities/application-context/application-context.js';
import { SearchService } from '../search/search.service.js';
import { WebAnalyticsService } from '../web-analytics/web.analytics.js';
import { LocalizedTextIds } from 'company-finder-common';

export interface Breadcrumb {
  id: string;
  name: string;
  action: any;
}

@Injectable({providedIn: 'root'})
export class BreadcrumbsService {
  // public properties
  public breadcrumbs: Breadcrumb[] = [];
  public rootBreadcrumb: Breadcrumb;

  constructor(
    private _deploymentContext: DeploymentContext,
    private _router: Router,
    private _searchService: SearchService,
    private _webAnalyticsService: WebAnalyticsService,
    private _context: ApplicationContext
  ) {
    this.rootBreadcrumb = {
      id: 'root',
      name: 'Explore',
      action: () => {
        // FUTURE: This action implementation is completely replaced with very similar content
        //  by MainComponent.ngOnInit().  Would be nice to avoid duplicate logic if possible.
        //  This implementation is used when someone uses the breadcrumbs off a deep link
        //  to a Company Details page, since the MainComponent isn't the active View in that situation.
        this._webAnalyticsService.trackBreadcrumbNavigation(
          this.rootBreadcrumb.name,
          this._router
        );

        // Handle navigation to the root from other routes.
        this._router.navigate(['/']);

        // ADJQ-210: based on config value, optionally always clear filter if navigating to the Explore
        if (
          this._deploymentContext.rawConfig.behavior
            .clearFilterUponNavigateToExplore
        ) {
          _searchService.filter.clear();
        }

        // NOTE: This breadcrumb doesn't have access to the MainComponent's
        // methods, but relies on NavigationStart to clear search results.
        // The MainComponent will augment this breadcrumb with a call to
        // clearSearchResults() in its action to handle transitions from
        // overview to search results.

        // Hack off any other crumbs after this one.
        this.setCurrentBreadcrumb(this.rootBreadcrumb);
      },
    };
  }

  // public getters
  public get searchBreadcrumb(): Breadcrumb {
    const title = this._searchService.isAllCompanies
      ? this._deploymentContext.Loc(LocalizedTextIds.AllCompanies)
      : this._deploymentContext.Loc(LocalizedTextIds.BreadcrumbsSearchResults);

    return {
      id: 'search',
      name: title,
      action: async () => {
        this._webAnalyticsService.trackBreadcrumbNavigation(
          title,
          this._router
        );
        // Hack off any other crumbs after this one.
        this.setCurrentBreadcrumb(this.searchBreadcrumb);
        // Handle navigation to the root from other routes.
        this._searchService.navigateToSearchResults();
      },
    };
  }

  public searchPageOffset: number;

  // public methods
  public addBreadcrumb(breadcrumb: Breadcrumb): void {
    this.breadcrumbs.push(breadcrumb);
    this.ensureRootBreadcrumb();
  }

  public addOrReplaceBreadcrumb(breadcrumb: Breadcrumb): void {
    const index = this.findBreadcrumbIndex(breadcrumb);
    if (index > -1) {
      this.updateBreadcrumb(breadcrumb);
    } else {
      this.addBreadcrumb(breadcrumb);
    }
    this.ensureRootBreadcrumb();
  }

  public clearBreadcrumbs(): void {
    this.breadcrumbs = [];
    this.ensureRootBreadcrumb();
  }

  public insertBreadcrumb(index: number, breadcrumb: Breadcrumb): void {
    this.breadcrumbs.splice(index, 0, breadcrumb);
  }

  public popBreadcrumb(): void {
    this.breadcrumbs.pop();
  }

  public setCurrentBreadcrumb(breadcrumb: Breadcrumb): void {
    const index = this.findBreadcrumbIndex(breadcrumb);
    if (index > -1) {
      this.breadcrumbs.length = index + 1;
    }
  }

  public updateBreadcrumb(breadcrumb: Breadcrumb): void {
    const index = this.findBreadcrumbIndex(breadcrumb);
    if (index > -1) {
      this.breadcrumbs.splice(index, 1, breadcrumb);
    }
  }

  public setSearchPageOffset(
    offset: number,
    initialFetchSize: number = undefined
  ): void {
    this.searchPageOffset = offset;
    this._searchService.initialFetchSize = initialFetchSize;
  }

  // private methods
  private ensureRootBreadcrumb(): void {
    if (
      this.breadcrumbs.length === 0 ||
      this.breadcrumbs[0] !== this.rootBreadcrumb
    ) {
      this.insertBreadcrumb(0, this.rootBreadcrumb);
    }
  }

  private findBreadcrumbIndex(breadcrumb: Breadcrumb): number {
    return _.findIndex(this.breadcrumbs, (bc) => bc.id === breadcrumb.id);
  }
}
