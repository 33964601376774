<div class="news" *ngIf="newsItems?.length > 0">
  <div class="news-header">{{Loc(LocalizedTextIds.NewsInTheNews)}}</div>
  <div class="news-block">
    <ng-container
      *ngFor="let news of newsItems | paginate: { itemsPerPage: 6, currentPage: currentPage }; let i = index">
      <div class="news-block-item" (click)="openLink(news.link)" [ngClass]="getBorderClass(i, currentPage)">
        <div class="news-block-item-title">{{news.articleTitle}}</div>
        <div class="news-block-item-source">• {{news.articleSource}}</div>
        <div class="news-block-item-date">{{getDateString(news.eventDate)}}</div>
      </div>
    </ng-container>
  </div>
  <pagination-controls class="pagination" (pageChange)="currentPage = $event"></pagination-controls>
</div>