<div class="saved-search-criteria">
  <div>
    <div class="button-and-label">
      <button class="child-toggle" [ngClass]="{ 'open': isOpen }" (click)="toggleSelector()"></button>
      <h3>{{label}}</h3>
      <div *ngIf="numberSelected" class="number-selected">{{LocPluralize(LocalizedTextIds.SavedSearchCriteriaSelected, numberSelected, numberSelected)}}</div>
    </div>
    <div *ngIf="isOpen">
      <ng-container *ngIf="selectionType === 'location'">
        <saved-search-simple-selector
          [compareProperty]="'location'"
          [(criteria)]="criteria"
          (criteriaChange)="handleSelectionUpdate($event)"
          [optionData]="optionData"
        ></saved-search-simple-selector>
      </ng-container>
      <ng-container *ngIf="selectionType === 'sector'">
        <sector-selector
          [(criteria)]="criteria"
          (criteriaChange)="handleSelectionUpdate($event)"
          [optionData]="optionData"
        ></sector-selector>
      </ng-container>
      <ng-container *ngIf="selectionType === 'status'">
        <saved-search-simple-selector
          [compareProperty]="'status'"
          [(criteria)]="criteria"
          (criteriaChange)="handleSelectionUpdate($event)"
          [optionData]="optionData"
        ></saved-search-simple-selector>
      </ng-container>
    </div>
  </div>
</div>