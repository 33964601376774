import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, Subject } from 'rxjs';
import { LoginInfo, RoleGroups, AuthnSource } from 'company-finder-common';
import { AuthnStrings } from '../../constants/AuthnStrings.js';
import { MsgToParentPage } from '../../constants/MsgToParentPage.js';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private _jwtHelperService: JwtHelperService = new JwtHelperService();
  private _juniverseToken = '';
  public get juniverseToken(): string {
    return this._juniverseToken;
  }

  // Workaround for Blocked 3rd Party Cookies
  //  https://jira.jnj.com/browse/ADJQ-124 & https://jira.jnj.com/browse/ADJQ-131
  private _workaroundForBlockedThirdPartyCookiesStorage: string = null;
  private _workaroundForBlockedThirdPartyCookies =
    new Subject<MsgToParentPage>();

  // This is intended as a low-level service as it is used in our
  // HTTP interceptors. Do *not* inject other standard services into this
  // as the ServiceBase contains an injected HttpClient, which
  // will have references to the interceptors and create a
  // circular dependency
  constructor() {}

  public get token(): string {
    return this.getToken();
  }

  public getToken(): string {
    try {
      return localStorage?.getItem(AuthnStrings.KEY_AUTH_TOKEN);
    } catch {
      // If this approach is needed, we would have already requested/received
      // the access token from the parent page during AppComponent.ngOnInit()
      // and/or had this variable set by an explicit login flow (which then passes it to the parent window).
      return this._workaroundForBlockedThirdPartyCookiesStorage;
    }
  }

  public get isAuthenticated(): boolean {
    try {
      if (!this.token) {
        return false;
      }

      if (!this._jwtHelperService.isTokenExpired(this.token)) {
        return true;
      }
    } catch {
      // If the token is invalid, we don't need to bubble that up.
      // We can just clear the token out and allow normal auth to
      // resume from there
    }

    // Either the token had expired or was otherwise invalid. Clear it
    // out and return false.
    console.log('Clearing an invalid or expired JWT');
    this.clearToken();
    return false;
  }

  /**
   * Sets the token into storage. This should only be called from
   * the authnService setTokenAndScheduleRefresh. See ADJQ-1713
   * @param jwtToken the token to store
   * @param fromParent whether this came from a parent frame into an iframe
   */
  public setToken(jwtToken: string, fromParent?: boolean): void {
    try {
      localStorage.setItem(AuthnStrings.KEY_AUTH_TOKEN, jwtToken);

      // This will clear the was J&J logged in if it is a juniverse user to avoid some confusing behavior in edge cases
      const loginInfo = this._jwtHelperService.decodeToken<LoginInfo>(jwtToken);
      const isInternal = RoleGroups.InternalUsers.containsRole(loginInfo?.role);

      if (loginInfo.origin !== AuthnSource.JUniverse) {
        this.setLoggedInFlags(isInternal);
      }
    } catch {
      this._workaroundForBlockedThirdPartyCookiesStorage = jwtToken;
      if (!fromParent) {
        this._workaroundForBlockedThirdPartyCookies.next(
          MsgToParentPage.SetToken
        );
      }
    }
  }

  public setLoggedInFlags(value: boolean): void {
    const stringValue = value.toString();
    localStorage.setItem(AuthnStrings.KEY_JNJ_EVER_LOGGED_IN, stringValue);
    localStorage.setItem(AuthnStrings.KEY_JNJ_WAS_LOGGED_IN, stringValue);
  }

  public setLoggedOutFlags(value: boolean): void {
    const stringValue = value.toString();
    localStorage.setItem(AuthnStrings.KEY_JNJ_LOGGED_OUT, stringValue);
  }

  public clearToken(): void {
    try {
      localStorage.removeItem(AuthnStrings.KEY_AUTH_TOKEN);
      this._juniverseToken = '';
    } catch {
      this._workaroundForBlockedThirdPartyCookiesStorage = null;
      this._workaroundForBlockedThirdPartyCookies.next(
        MsgToParentPage.ClearToken
      );
    }
  }

  public clearJuniverseToken(): void {
    this._juniverseToken = '';
  }

  public decodeToken(): LoginInfo {
    return this._jwtHelperService.decodeToken<LoginInfo>(this.token);
  }

  public getWorkaroundForBlockedThirdPartyCookies(): Observable<MsgToParentPage> {
    return this._workaroundForBlockedThirdPartyCookies.asObservable();
  }
}
