<form (ngSubmit)="login()">
  <div class="cf_login">
    <div class="cf_login-title">{{Loc(LocalizedTextIds.LoginJJInternal)}}</div>
    <div class="cf_login-label">{{Loc(LocalizedTextIds.Username)}}</div>
    <div>
      <input 
        class="cf_login-input-field"
        type="text" id="username"
        name="username"
        [(ngModel)]="username"
        autofocus
        placeholder={{Loc(LocalizedTextIds.LoginJJNetworkUsername)}}/>
    </div>
    <div class="cf_login-label">{{Loc(LocalizedTextIds.Password)}}</div>
    <div>
      <input 
        class="cf_login-input-field"
        type="password"
        id="password"
        name="password"
        [(ngModel)]="password"
        placeholder={{Loc(LocalizedTextIds.LoginJJNetworkPassword)}}/>
    </div>   
    <div *ngIf="isDebug">
      <div class="cf_login-label">{{Loc(LocalizedTextIds.LoginUserRole)}}</div>
      <select-input  
        name="userRole"
        variant="update"
        fullWidth="true"
        placeholder={{Loc(LocalizedTextIds.LoginUserRole)}}
        [(ngModel)]="userRole"
        [options]="userRoles | keyvalue"
        [getSerializedValue]="getReferenceValueDisplayString">
      </select-input>
    </div>
    <div *ngIf="isDebug">
      <div class="cf_login-label">Associated Company</div>
      <select-input  
        name="company"
        variant="update"
        fullWidth="true"
        placeholder="Associated Company"
        [(ngModel)]="company"
        [options]="companies"
        [getSerializedValue]="getReferenceValueDisplayString">
      </select-input>
    </div>
    <div>
      <input class="cf_login-submit-button" type="submit" value={{Loc(LocalizedTextIds.LogIn)}} />
    </div>
    <div 
      *ngIf="authenticationMessage"
      class="cf_login-authentication-message">
      {{authenticationMessage}}
    </div>
    <div class="cf_login-go-to-public-version-link" (click)="logout()">
      <span>{{Loc(LocalizedTextIds.LoginGoToPublic)}}</span>
    </div>
  </div>
</form>