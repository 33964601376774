<div class="cf_company" *ngIf="company">
  <search-bar [logoPath]="themeSettings.logoImgSrc" [searchOnFilterChange]="false" [navigateToResultsOnSearch]="true">
  </search-bar>
  <div class="cf_company-breadcrumbs-container">
    <breadcrumbs [separatorIconPath]="'/assets/common/Path.svg'"></breadcrumbs>
  </div>
  <div class="cf_company-content">
    <!-- A StealthMode company should never be retrieved by an unauthenticated user, but to be safe, only show the indicator to authenticated users -->
    <div
      [ngClass]="{'cf_company-content-stealthmode-background': true, 'cf_company-content-stealthmode-suppress': suppressStealthMode}">
    </div>
    <div class="cf_company-content-majorsection cf_company-content-header">
      <div class="cf_company-content-header-new-company-indicator nologo" *ngIf="isNew && !featureSwitches.enableShowCompanyLogos">
        {{Loc(LocalizedTextIds.New)}}
      </div>
      <div class="cf_company-content-header-top-row">
        <div class="cf_company-content-header-company-logo">
          <company-logo [company]="company"></company-logo>
          <div class="cf_company-content-header-new-company-indicator" *ngIf="isNew && featureSwitches.enableShowCompanyLogos">
            {{Loc(LocalizedTextIds.New)}}
          </div>
        </div>
        <div
          [ngClass]="{'cf_company-content-stealthmode-text': true, 'cf_company-content-stealthmode-suppress': suppressStealthMode}">
          {{Loc(LocalizedTextIds.CompanyDetailsStealth)}}
        </div>
        <div class="cf_company-content-header-action-wrapper">
          <div *ngIf="isFollowEnabled && isInternalView" class="cf_company-content-header-follow-section">
            <div class="cf_company-content-header-follow-button"
              [ngClass]="{'is-following': isFollowing, 'is-stealthmode': !suppressStealthMode}"
              (click)="setFollowing(!isFollowing)">
              <div class="cf_company-content-header-button-label">{{isFollowing? Loc(LocalizedTextIds.Unfollow) :
                Loc(LocalizedTextIds.Follow)}} {{Loc(LocalizedTextIds.Company)}}</div>
              <div class="cf_company-content-header-follow-button-icon"></div>
            </div>
          </div>
          <download #downloadButton *ngIf="isDownloadDocumentEnabled" [company]="company"></download>
        </div>
      </div>
      <div class="cf_company-content-header-grp1">
        <h1 class="cf_company-content-header-grp1-companyname">{{company.name}}
          <company-status-icon [statuses]="company.statuses" 
            float="right"
            tooltipTop="32px"
            tooltipLeft="188px"
          ></company-status-icon>
        </h1>        
        <div class="cf_company-content-header-grp1-companyurl" *ngIf="validCompanyWebsiteUrl"><a
            href="{{validCompanyWebsiteUrl}}" target="_blank">{{company.website}}</a></div>       
        <status-list
            [statusItems]="statusItems"
          ></status-list> 
        <company-location-status
          class="cf_company-content-header-grp1-locationstatus"
          [company]="company"
          *ngIf="featureSwitches.enableShowLocationStatus"
          [useSeparator]="false"
          [singleLine]="false"
          [includeCompanyStatusRow]="false">
        </company-location-status>
        <div class="cf_company-content-header-grp1-companysummary">
          <show-more-less [content]="company.description | nl2br" [maxHeight]="150" [lineHeight]="25"></show-more-less>
        </div>
        <company-tags *ngIf="featureSwitches.enableShowTags"
          class="cf_company-content-header-grp1-tags" [useWhiteBackground]="true"
          [marginTopForWrapping]="22" [company]="company" [elideThreshold]="-1" [tags]="tagsSorted">
        </company-tags>
      </div>
      <div class="cf_company-content-header-grp2">
        <div class="cf_company-content-header-grp2-inner">
          <div class="sector-info">
            <div *ngIf="showPrimaryAreaData" class="sector-group">
              <div class="title">{{Loc(LocalizedTextIds.Primary)}}</div>
              <div class="sectors">
                <div class="headers">
                  <div *ngIf="company.primarySector" class="header cell">{{Loc(LocalizedTextIds.Sector)}}</div>
                  <div *ngIf="company.primarySubSector?.length > 0" class="header cell">{{Loc(LocalizedTextIds.SubSector)}}</div>
                  <div *ngIf="company.primaryIndication?.length > 0" class="header cell">{{Loc(LocalizedTextIds.Indication)}}</div>
                  <div *ngIf="company.primarySectorDetail?.length > 0" class="header cell">{{Loc(LocalizedTextIds.SectorDetail)}}
                  </div>
                </div>
                <div class="values">
                  <div *ngIf="company.primarySector" class="cell elide">{{company.primarySector}}</div>
                  <div *ngIf="company.primarySubSector?.length > 0" class="cell elide">{{company.primarySubSector?.join(', ')}}</div>
                  <div *ngIf="company.primaryIndication?.length > 0" class="cell elide">{{company.primaryIndication?.join(', ')}}</div>
                  <div *ngIf="company.primarySectorDetail" class="cell elide">{{company.primarySectorDetail}}</div>
                </div>
              </div>
            </div>
            <div *ngIf="showSecondaryAreaData" class="sector-group">
              <div class="title">{{Loc(LocalizedTextIds.Secondary)}}</div>
              <div class="sectors">
                <div class="headers">
                  <div *ngIf="company.secondarySector" class="header cell">{{Loc(LocalizedTextIds.Sector)}}</div>
                  <div *ngIf="company.secondarySubSector?.length > 0" class="header cell">{{Loc(LocalizedTextIds.SubSector)}}</div>
                  <div *ngIf="company.secondaryIndication?.length > 0" class="header cell">{{Loc(LocalizedTextIds.Indication)}}</div>
                  <div *ngIf="company.secondarySectorDetail" class="header cell">{{Loc(LocalizedTextIds.SectorDetail)}}
                  </div>
                </div>
                <div class="values">
                  <div *ngIf="company.secondarySector" class="cell elide">{{company.secondarySector}}</div>
                  <div *ngIf="company.secondarySubSector?.length > 0" class="cell elide">{{company.secondarySubSector?.join(', ')}}</div>
                  <div *ngIf="company.secondaryIndication?.length > 0" class="cell elide">{{company.secondaryIndication?.join(', ')}}</div>
                  <div *ngIf="company.secondarySectorDetail" class="cell elide">{{company.secondarySectorDetail}}</div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="showLeadProductStage"
            class="cf_company-content-header-grp2-lead-product-stage">
            <div class="cf_company-content-header-grp2-lead-product-stage-label">
              {{Loc(LocalizedTextIds.CompanyDetailsLeadProductStage)}}
            </div>
            <div class="cf_company-content-header-grp2-lead-product-stage-content">
              {{company.currentRdStage}}
            </div>
          </div>
          <div *ngIf="isInternalView && (priority || hasDealsWithJnJ)" class="cf_company-content-header-grp2-status">
            <div class="cf_company-content-header-grp2-status-container">
              <div *ngIf="priority" class="cf_company-content-header-grp2-status-container-priority">
                <div class="cf_company-content-header-grp2-status-container-priority-icon">
                  <div *ngIf="priority == Priorities.low"
                    class="cf_company-content-header-grp2-status-container-priority-icon-low"></div>
                  <div *ngIf="priority == Priorities.medium"
                    class="cf_company-content-header-grp2-status-container-priority-icon-medium"></div>
                  <div *ngIf="priority == Priorities.high"
                    class="cf_company-content-header-grp2-status-container-priority-icon-high"></div>
                </div>
                {{priorityDisplay}}
              </div>
              <div *ngIf="priority && hasDealsWithJnJ"
                class="cf_company-content-header-grp2-status-container-dividingline-vertical"></div>
              <div *ngIf="hasDealsWithJnJ" class="cf_company-content-header-grp2-status-container-dealwithjnj">
                <div class="cf_company-content-header-grp2-status-container-dealwithjnj-icon"></div>
                {{Loc(LocalizedTextIds.CompanyDetailsDeal)}}
              </div>
            </div>
          </div>
          <div class="cf_company-content-header-grp2-contacts">
            <div *ngIf="company.companyContact" class="company-contact border-spec">
              <contact-component class="cf_company-content-header-grp2-contacts-contact"
                [contact]="company.companyContact" [role]="Loc(LocalizedTextIds.CompanyContact)"
                [showTitle]="true"></contact-component>
            </div>
            <div *ngIf="showAdditionalContacts" class="additional-contacts border-spec">
              <div class="two-columns">
                <contact-component *ngIf="isInternalView && company.jpalContact"
                  class="cf_company-content-header-grp2-contacts-contact jpal" [contact]="company.jpalContact"
                  [role]="Loc(LocalizedTextIds.JPAL)">
                </contact-component>
                <contact-component *ngIf="company.siteHead" class="cf_company-content-header-grp2-contacts-contact"
                  [company]="company" [contact]="company.siteHead" [role]="Loc(LocalizedTextIds.SiteHead)">
                </contact-component>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- If the only information we're showing is the public stuff, don't bother with the tab UI at all. -->
    <ng-container *ngIf="showSimpleTabUi">
      <simple-tab-ui class="tabs" [tabs]="tabs" (tabClickEvent)="handleTabClick($event)"></simple-tab-ui>
    </ng-container>

    <ng-container *ngIf="isShowingTabContent(TabConfigs.PUBLIC)">
      <public-information [company]="company"></public-information>
    </ng-container>

    <ng-container *ngIf="showInternalHeader">
      <div class="internal-title">
        <div>{{internalHeader}}</div>
        <div class="internal-subtitle">
          {{internalSubtitle}}
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isShowingTabContent(TabConfigs.JNJ_ONLY)">
      <jnj-information [company]="company"></jnj-information>
    </ng-container>

    <ng-container *ngIf="isShowingTabContent(TabConfigs.BLUE_KNIGHT)">
      <blue-knight-information [company]="company"></blue-knight-information>
    </ng-container>

    <ng-container *ngIf="isShowingTabContent(TabConfigs.BLUE_KNIGHT_MILESTONES)">
      <blue-knight-milestones [company]="company"></blue-knight-milestones>
    </ng-container>

    <!--
      This can be very useful for validating/debugging, since it shows all data that has come back.
      So, keep be below, but commented out, so it could be periodically restored by developers. 
    <table class="cf_company-content-tmp">
      <tr>
        <th>Property</th>
        <th>Value</th>
      </tr>
      <tr *ngFor="let prop of properties.sort()">
        <td>{{prop}}:</td>
        <td>
          <div *ngIf="isArrayValue(prop); else showItem">
            <div *ngFor="let item of company[prop]">
              {{getDisplayForItem(prop, item)}}
            </div>
          </div>
          <ng-template #showItem>
            <div>{{getDisplayForValue(company[prop])}}</div>
          </ng-template>
        </td>
      </tr>
    </table> 

  </div>
-->
</div>