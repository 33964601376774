import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Logger } from '../logger/logger.js';
import { LogService } from '../../services/log/log.service.js';

import { DeploymentContext } from '../deployment-context/deployment-context.js';
import { LocalizedTextIds } from 'company-finder-common';
import { v4 as uuidv4 } from 'uuid';

export class NavigatorError extends Error {
  error: unknown;
}
@Injectable({
  providedIn: 'root',
})
export class JnjErrorHandler implements ErrorHandler {
  /** Errors thrown with this message will be ignored by JnjErrorHandler */
  public static KNOWN_ERROR_MESSAGE = 'Portfolio Tool expected error condition';

  private unexpectedMsg: string;
  private errorMsg: string;

  private toastCount = 0;
  private readonly MAX_ALLOWABLE_TOASTS = 10;

  private logger = new Logger(this.constructor.name);

  // Use injector to avoid circular dependency at compile time https://stackoverflow.com/a/39767492/1680471
  private get toastr(): ToastrService {
    return this.injector.get(ToastrService);
  }

  private get logService(): LogService {
    return this.injector.get(LogService);
  }

  constructor(
    private injector: Injector,
    private ngZone: NgZone,
    dc: DeploymentContext
  ) {
    // If an error occurs before setting up the localizer with
    // the message strings, we need to
    // a) not crash, and
    // b) render something sensisble.
    this.unexpectedMsg =
      dc?.Loc(LocalizedTextIds.JNJErrorHandlerSomethingUnexpected) ??
      'Something unexpected has occurred.';

    this.errorMsg = dc?.Loc(LocalizedTextIds.JNJErrorHandlerError) ?? 'Error';
  }

  public handleError(
    error: Error,
    title: string = 'Error',
    sendToServer: boolean = true,
    correlationId: string = uuidv4(),
    suppressToast: boolean = false
  ): void {
    // The "Known Error" message content could be buried in a "unhandled promise" or other wrapper expection
    // We will assume the message constant will be sufficiently unique
    if (error.message.indexOf(JnjErrorHandler.KNOWN_ERROR_MESSAGE) >= 0) {
      return;
    } else if (error.message.includes('ApplyRedirects.noMatchError')) {
      // Skip further error handling for NavigationError that is logged in app-routing.module.ts but shows up here as a generic Error
      // Hopefully this pattern is specific enough and doesn't have any false positives.
      return;
    }

    // It seems the message is often embedded in the stack, but sometimes we augment the message later.
    // So, we want to include both if there is unique info, but only stack if it would otherwise be redundant.
    // Note that the sendToServer records both message and stack server-side.
    const msgToLog = error.stack?.includes(error.message)
      ? error.stack
      : error.message + '. ' + error.stack;

    this.logger.error(`${msgToLog ?? error}`); // it seems unlikely the msgToLog would ever be null/emptym, but that logic is preserved

    if (sendToServer) {
      this.saveLog(error, correlationId);
    }

    if (!suppressToast) {
      this.displayToast(title, correlationId);
    }
  }

  private saveLog(error: Error, correlationId: string): void {
    try {
      this.logService.logErrorOnServer(error, correlationId);
    } catch (errRecording) {
      this.logger.error(
        `Unable to push error to server due to: ${errRecording}`
      );
    }
  }

  private displayToast(title: string, correlationId?: string): void {
    this.toastCount++;

    if (this.toastCount === this.MAX_ALLOWABLE_TOASTS) {
      this.saveLog(new Error('Maximum number of toasts raised'), correlationId);
    }

    if (!this.toastr) {
      return;
    }

    if (this.toastCount <= this.MAX_ALLOWABLE_TOASTS) {
      // Need to run in Angular's ngZone to get change detection to update reliably (adjq-556)
      const toastr = this.toastr;
      this.ngZone.run(() => {
        toastr.error(
          `${this.unexpectedMsg}</br></br>${this.errorMsg} ${correlationId}`,
          title,
          {
            enableHtml: true,
            positionClass: 'toast-center-center'
          }
        );
      });
    }
  }
}
