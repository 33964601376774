import { Component, Input } from '@angular/core';
import { ComponentBase } from '@Common';
import { Company, PublicOverrides } from 'company-finder-common';

@Component({
    selector: 'public-information',
    templateUrl: './public-information.component.html',
    standalone: false
})
export class PublicInformationComponent extends ComponentBase {
  @Input()
  public company: Company;

  public get includeInPublicView(): PublicOverrides {
    return this.featureSwitches.includeInPublicView;
  }
}
