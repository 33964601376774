import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';

import { ComponentBase } from '@Common';

// model imports
import {
  Company,
  EventOfInterestType,
  Filter,
  SortType,
  ReviewNotification,
  urlIdentifierForCompany,
} from 'company-finder-common';

// service/utility imports
import { ApplicationContext } from '@Common';
import { BreadcrumbsService } from '@Common';
import { CompanyService } from '@Common';
import { DeploymentContext } from '@Common';
import { EventService } from '@Common';
import { ReviewModalComponent } from '../main/review-modal/review-modal.component.js';
import { SearchService } from '@Common';
import { UserService } from '@Common';
import { WebAnalyticsService } from '@Common';
import escapeStringRegexp from 'escape-string-regexp';
import { AuthnService } from '@Common';

@Component({
    selector: 'search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss'],
    standalone: false
})
export class SearchBarComponent
  extends ComponentBase
  implements OnDestroy, OnInit, AfterViewInit
{
  // public properties
  @Input()
  public logoPath: string;
  @Input()
  public searchOnFilterChange = false;
  @Input()
  public navigateToResultsOnSearch = true;

  @ViewChild('reviewComponent', { static: false })
  public reviewComponent: ReviewModalComponent;
  public showReviewModal = false;

  public reviews: ReviewNotification[] = [];

  @ViewChild('searchBox', { static: false })
  public searchBox: ElementRef;
  public suggestions: string[] = [];
  @ViewChild('typeaheadMenu', { static: false })
  public typeaheadMenu: ElementRef;

  // private properties
  private _cursorPosition: number;
  private _defaultDebounceTimeMilliseconds = 250;
  private _defaultRefocusTimeout = 300;
  private _matchAfterHowManyLetters = 0;
  private _SEPARATOR = ' .js';
  private _typeaheadActiveIndex: number = null;
  private _previousSearchPredicate: string;

  public get enableSearch(): boolean {
    return (
      this.authnService.isAuthenticated ||
      !this._deploymentContext.featureSwitches.enablePaywall
    );
  }

  public constructor(
    dc: DeploymentContext,
    private _applicationContext: ApplicationContext,
    private _breadcrumbsService: BreadcrumbsService,
    private _companyService: CompanyService,
    private _eventService: EventService,
    private _router: Router,
    private _searchService: SearchService,
    private _userService: UserService,
    private _webAnalyticsService: WebAnalyticsService,
    private authnService: AuthnService
  ) {
    super(dc);
  }

  public ngAfterViewInit(): void {
    this.initSubscriptions();
  }

  // public getters/setters
  public get filter(): Filter {
    return this._searchService.filter;
  }

  public get filterSubject(): Subject<Filter> {
    return this._searchService.filterSubject;
  }

  public get badSearchPredicate(): string {
    return this._searchService.badSearchPredicate;
  }

  public set badSearchPredicate(predicate: string) {
    this._searchService.badSearchPredicate = predicate;
  }

  public get badSearchPredicateSubject(): Subject<string> {
    return this._searchService.badSearchPredicateSubject;
  }

  public get currentSearchPredicate(): string {
    return this._searchService.currentSearchPredicate;
  }

  public set currentSearchPredicate(predicate: string) {
    this._searchService.currentSearchPredicate = predicate;
    this._previousSearchPredicate = predicate;
  }

  public get hasSuggestions(): boolean {
    return this.suggestions.length > 0;
  }

  public get isFollowEnabled(): boolean {
    return this._deploymentContext.featureSwitches.enableFollow;
  }
  public get isLoggedIn(): boolean {
    return !!this.authnService.loginInfo;
  }

  public get isCompanyContact(): boolean {
    return this._userService.isCompanyContact;
  }

  public get isReviewModalDismissed(): boolean {
    return this._applicationContext.isReviewModalDismissed;
  }

  public set isReviewModalDismissed(b: boolean) {
    this._applicationContext.isReviewModalDismissed = b;
  }

  public get lastViewedMyUpdatesTimestamp(): Date {
    return this._applicationContext.lastViewedMyUpdatesTimestamp;
  }

  public get searchPredicateSubject(): Subject<string> {
    return this._searchService.searchPredicateSubject;
  }

  public get searchResultsSubject(): Subject<Company[]> {
    return this._searchService.searchResultsSubject;
  }

  public get showBorder(): boolean {
    return this._companyService.currentSearchResults === null;
  }

  public get typeaheadActiveIndex(): number {
    return this._typeaheadActiveIndex;
  }

  public get unseenEventCountsForAll(): number {
    return this._applicationContext.unseenEventCounts
      ? this._applicationContext.unseenEventCounts[
          EventOfInterestType.AllUpdates
        ]
      : undefined;
  }

  public get unseenEventCounts(): number[] {
    return this._applicationContext.unseenEventCounts;
  }

  public set unseenEventCounts(counts: number[]) {
    this._applicationContext.unseenEventCounts = counts;
  }

  public get updateCount(): number {
    return this.reviews?.length ?? 0;
  }

  // public methods
  public async applySuggestion(suggestion: string): Promise<void> {
    this.searchBox.nativeElement.value = suggestion;
    this.suggestions = [];
    this.currentSearchPredicate = this.searchBox.nativeElement.value;
    await this.performSearch();
    setTimeout(() => {
      // The native element seems to get lost during screen transitions, and I'm not sure why.
      // The element is available, but focus() isn't working. Using the DOM, however, works.
      const el = document.getElementById('search');
      if (el) {
        el.focus();
      }
    }, this._deploymentContext.freeTextSearchTypeahead.refocusTimeout || this._defaultRefocusTimeout);
  }

  public containsSearchTerm(suggestion: string): boolean {
    const text = this.searchBox.nativeElement.value.trim();
    return suggestion.toLowerCase().indexOf(text.toLowerCase()) >= 0;
  }

  public equalsSearchTerm(piece: string): boolean {
    const text = this.searchBox.nativeElement.value.trim();
    return piece.toLowerCase() === text.toLowerCase();
  }

  public getSuggestionInPieces(suggestion: string): string[] {
    // NOTE: This is a relatively simple implementation for styling a suggestion within itself
    //       for a relatively simple typeahead feature. The typeahead feature is expected to
    //       change in the future, and this aspect of it might change or go away altogether.
    //       Typeahead currently only works on one term at a time, so the implementation below
    //       should handle that. It might handle more than that, but it hasn't been tested on
    //       anything more than single search terms.

    const text = this.searchBox.nativeElement.value.trim();
    if (!text || text.length === 0) {
      return [];
    }
    const termToComplete = text.toLowerCase();

    // We're given a suggestion (for now we expect a single word), and a search term (also a single word),
    // and we want
    // * the part of the suggestion that matches the search term to be rendered using a normal font-weight
    // * the part of the suggestion that does NOT match the search term to be rendered using a bold font-weight
    // To accomplish this we break off pieces of the suggestion, span them together in the HTML template, and
    // apply the bold style to the parts that don't match the search term, in accordance with the visual design.
    // Even though we are assuming we have single word suggestions & search terms, I don't think we can assume
    // the suggestion has only a single instance of the search term in it, hence the loop below.

    const regEx = new RegExp(escapeStringRegexp(termToComplete), 'gi');
    let regExResult;
    let cursor = 0;
    const pieces = [];

    while ((regExResult = regEx.exec(suggestion)) !== null) {
      if (regExResult.index !== cursor) {
        // The term matched in the middle of the suggestion. Capture the piece before the match.
        pieces.push(suggestion.substring(cursor, regExResult.index));
      }
      // Capture the match itself.
      pieces.push(suggestion.substr(regExResult.index, termToComplete.length));
      // Set the cursor to the next character after the match, and try again.
      cursor = regEx.lastIndex;
    }
    // If the last match didn't go to the end of the suggestion, capture the trailing piece.
    if (cursor < suggestion.length) {
      pieces.push(suggestion.substr(cursor));
    }
    return pieces;
  }

  public handleInputKeyUp(ev: KeyboardEvent): void {
    if (this.typeaheadMenu && this.suggestions?.length > 0) {
      switch (ev.code) {
        case 'ArrowUp':
          if (
            this._typeaheadActiveIndex === null ||
            this._typeaheadActiveIndex === 0
          ) {
            this._typeaheadActiveIndex = this.suggestions.length - 1;
          } else {
            --this._typeaheadActiveIndex;
          }
          break;
        case 'ArrowDown':
          if (
            this._typeaheadActiveIndex === null ||
            this._typeaheadActiveIndex === this.suggestions.length - 1
          ) {
            this._typeaheadActiveIndex = 0;
          } else {
            ++this._typeaheadActiveIndex;
          }
          break;
        case 'Escape':
          this.suggestions = [];
          this._typeaheadActiveIndex = null;
          this.currentSearchPredicate = this.searchBox.nativeElement.value =
            this._previousSearchPredicate;
          this._cursorPosition = this.currentSearchPredicate.length;
          this.setCursorPosition(this.currentSearchPredicate);
          // Don't fall through to the code below.
          return;
      }
      const restoreSearchPredicate = this._previousSearchPredicate;
      this.currentSearchPredicate = this.searchBox.nativeElement.value =
        this.suggestions[this._typeaheadActiveIndex];
      this._previousSearchPredicate = restoreSearchPredicate;
      // Arrowing wants to move the cursor on us, so put it back where it was.
      this.setCursorPosition(this.currentSearchPredicate);
    }
  }

  /*
   * This was the only way I could find to prevent the text input from moving the cursor when arrowing up & down.
   * The concept was borrowed from:
   * https://stackoverflow.com/questions/1080532/prevent-default-behavior-in-text-input-while-pressing-arrow-up
   */
  public inputKeyUpWrapper(ev: KeyboardEvent, isKeyUp?: boolean): void {
    if (
      ev.code === 'ArrowDown' ||
      ev.code === 'ArrowUp' ||
      ev.code === 'Escape'
    ) {
      ev.preventDefault();
      if (isKeyUp) {
        setTimeout(() => this.handleInputKeyUp(ev), 10);
      }
    }
  }

  public async ngOnInit(): Promise<void> {
    if (!this.enableSearch) {
      // We're not rendering the search aspects of the component, so no need
      // do do anything in here.
      return;
    }
    this.manageReviewNotifications();
    await this.initUnseenCounts();
  }

  private async initUnseenCounts(): Promise<void> {
    if (
      this._deploymentContext.featureSwitches.enableFollow &&
      !this.unseenEventCounts &&
      this.authnService.isInternal
    ) {
      // Get the JnJ user, so we know when they last viewed their updates
      const user = this._userService.getCachedUserSync();

      if (user) {
        // Set it in the application context so other interested parties can use it.
        this._applicationContext.lastViewedMyUpdatesTimestamp =
          user.lastViewedMyUpdatesTimestamp;
        const counts = await this._eventService.getUnseenEventCounts();
        this.unseenEventCounts = counts;
      }
    }
  }

  private initSubscriptions(): void {
    // If we already have subscriptions, we don't want to duplicate them
    // If a child component is undefined (because the user is logged out
    // and has no search) there is nothing to subscribe to.
    if (this._subscriptions || !this.searchBox) {
      return;
    }

    this.addSubscription(
      this.badSearchPredicateSubject.subscribe((predicate) => {
        this.badSearchPredicate = predicate;
      })
    );

    this.addSubscription(
      this.filterSubject.subscribe(() => {
        // If we're on the search screen, and the filter has changed, execute another search.
        if (this.searchOnFilterChange) {
          this.performSearch();
        }
      })
    );
    if (this.reviewComponent) {
      this.addSubscription(
        this.reviewComponent.closingSubject.subscribe(async () => {
          this.showReviewModal = false;
          this.isReviewModalDismissed = true;
        })
      );
    }
    // free text predictions
    fromEvent(this.searchBox.nativeElement, 'input')
      .pipe(
        debounceTime(
          this._deploymentContext.freeTextSearchTypeahead
            .debounceTimeMilliseconds || this._defaultDebounceTimeMilliseconds
        ),
        map((e: KeyboardEvent) => (e.target as HTMLInputElement).value),
        filter((text) => {
          if (
            text.trim() === '' ||
            text.charAt(text.length - 1) === this._SEPARATOR
          ) {
            this.suggestions = [];
            return false;
          }
          const termToComplete = text.trim();
          return (
            termToComplete.length >=
            (this._deploymentContext.freeTextSearchTypeahead
              .matchAfterHowManyLetters || this._matchAfterHowManyLetters)
          );
        }),
        distinctUntilChanged(),
        switchMap(async (text: string) => {
          const termToComplete = text.trim();
          return await this._searchService.getTypeahead(termToComplete);
        }),
        tap((suggestions) => (this.suggestions = suggestions))
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.unsubscribe();
  }

  public goHome(): void {
    this._breadcrumbsService.rootBreadcrumb.action();
  }

  public goToCompanyUpdate(index: number): void {
    if (this._userService.companiesForUpdate.length - 1 < index || index < 0) {
      return;
    }
    const company = this._userService.companiesForUpdate[index];
    this._router.navigate([
      'company',
      'update',
      urlIdentifierForCompany(company),
    ]);
  }

  public goToMyUpdates(): void {
    this._router.navigate(['my-updates']);
  }

  public goToPreferences(): void {
    this._router.navigate(['user', 'preferences']);
  }

  @HostListener('window:click', ['$event'])
  public onClick(): void {
    this.suggestions = [];
    this._typeaheadActiveIndex = null;
  }

  public async performSearch(): Promise<void> {
    // If we saved a scroll on search, reset it for new searches
    this._breadcrumbsService.setSearchPageOffset(0);

    // ensure a user-initiated search always returns a fresh result set
    this._searchService.clearCachedSearch();
    this._webAnalyticsService.trackEvent('search', {
      label: this.currentSearchPredicate
        ? this.currentSearchPredicate.trim()
        : '',
    });
    return this.search();
  }

  public async refreshReviewNotifications(): Promise<void> {
    this.manageReviewNotifications();
  }

  public showPendingUpdates(): void {
    this.isReviewModalDismissed = false;
    this.showReviewModal = true;
  }

  // private methods
  private async manageReviewNotifications(): Promise<void> {
    if (!this._userService.isReviewer) {
      return;
    }

    this.reviews = await this._companyService.reviews(
      this._userService.companiesForReview
    );

    // sort company updates for review by oldest to newest
    this.sortReviewsByDate();
    this.showReviewModal =
      this.reviews?.length > 0 && !this._router.url.includes('/update/');
  }

  private async search(): Promise<void> {
    if (this.currentSearchPredicate?.trim().length > 0) {
      // The user initiated a search with a search predicate, so force to ranked search result
      this._searchService.filter.sort = SortType.Ranking;
    }

    if (this.navigateToResultsOnSearch) {
      this._searchService.navigateToSearchResults();
    } else {
      this._searchService.drilldownSubject.next(this._searchService.filter);
    }
  }

  private setCursorPosition(text: string): void {
    const position =
      this._cursorPosition < text?.length
        ? this._cursorPosition
        : text?.length ?? this._cursorPosition;
    this.searchBox.nativeElement.selectionStart =
      this.searchBox.nativeElement.selectionEnd = position;
  }

  private sortReviewsByDate() {
    this.reviews?.sort(function (a, b) {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });
  }
}
