import {
  Component,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import _ from 'lodash-es';
import { ComponentBase } from '@Common';

@Component({
    template: '',
    standalone: false
})
export class SavedSearchCriteriaBaseComponent<T>
  extends ComponentBase
  implements OnChanges {
  @Input() compareProperty: string;
  @Input() criteria: T[] = [];
  @Input() optionData: any;

  @Output() criteriaChange = new EventEmitter<T[]>();

  protected _selectedItems: T[] = [];

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.criteria) {
      this._selectedItems = [];
      changes.criteria.currentValue?.forEach((item) =>
        this.selectItem(item)
      );
    }
  }

  public findSelected(item: T): T {
    return this._selectedItems.find((anItem) => {
      if (!anItem) {
        return !item;
      }
      if (!item) {
        return false;
      }
      return this.compareItemsByProperty(anItem, item, this.compareProperty);
    });
  }

  public isSelected(item: T): boolean {
    return this.findSelected(item) != null;
  }

  public selectItem(item: T): void {
    if (!this.isSelected(item)) {
      this._selectedItems.push(item);
    }
  }

  public deselectItem(selected: T): void {
    if (selected) {
      _.pull(this._selectedItems, selected);
    }
  }

  public compareItemsByProperty(
    item1: unknown | string,
    item2: unknown | string,
    compareProperty: string
  ): boolean {
    if (_.isNil(item1)) {
      return _.isNil(item2);
    }
    if (_.isNil(item2)) {
      return false;
    }

    const comparator1 =
      typeof item1 === 'string' ? item1 : item1[compareProperty];
    const comparator2 =
      typeof item2 === 'string' ? item2 : item2[compareProperty];
    if (!comparator1 || !comparator2) {
      return false;
    }
    return typeof comparator1 === 'string'
      ? comparator1.toLowerCase() === comparator2.toLowerCase()
      : comparator1 === comparator2;
  }
}
