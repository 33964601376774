<div class="cf_by-the-numbers_community-and-diversity">
    <div class="cf_by-the-numbers_community-and-diversity-title">
        {{title}}
    </div>
    <div class="cf_by-the-numbers_community-and-diversity-subtitle">
        {{Loc(LocalizedTextIds.CommunityAndDiversitySubtitle)}}
    </div>
    <div *ngIf="summary">
        <div id="entrepeneurElement" class="cf_by-the-numbers_community-and-diversity-entrepreneurs">
            <div class="cf_by-the-numbers_community-and-diversity-entrepreneurs-content">
                <div class="cf_by-the-numbers_community-and-diversity-entrepreneurs-content-percentage">
                    {{serialEntrepreneurPercentage | percent}}
                </div>
                <div class="cf_by-the-numbers_community-and-diversity-entrepreneurs-content-title">
                    {{Loc(LocalizedTextIds.CommunityAndDiversitySerial)}}
                </div>
            </div>
            <annulus [color]="'dark'" [scale]="entrepeneurSize" [value]="firstTimeEntrepreneurPercentage"></annulus>
            <div class="cf_by-the-numbers_community-and-diversity-entrepreneurs-content-right">
                <div class="cf_by-the-numbers_community-and-diversity-entrepreneurs-content-percentage-right">
                    {{firstTimeEntrepreneurPercentage | percent}}
                </div>
                <div class="cf_by-the-numbers_community-and-diversity-entrepreneurs-content-title">
                    {{Loc(LocalizedTextIds.CommunityAndDiversityFirst)}}
                </div>
            </div>
        </div>
        <!-- Removed for ADJQ-184 but left in as status is unclear and they may return soon -->
<!--   <div class="cf_by-the-numbers_community-and-diversity-leadership"> --!
            <div class="cf_by-the-numbers_community-and-diversity-leadership-main">
                <div class="cf_by-the-numbers_community-and-diversity-leadership-content">
                    <div class="cf_by-the-numbers_community-and-diversity-leadership-content-percentage">
                        {{femaleLedPercentage | percent}}
                    </div>
                    <div class="cf_by-the-numbers_community-and-diversity-leadership-content-title">
                        <div class="cf_by-the-numbers_community-and-diversity-info-icon" touchTooltip (showTooltip)="showTooltip('femaleLed')" (hideTooltip)="hideTooltip('femaleLed')">
                            <div class="cf_by-the-numbers_community-and-diversity-info-icon-tooltip femaleLed" *ngIf="isShowingTooltip['femaleLed']">
                                {{Loc(LocalizedTextIds.CommunityAndDiversityAnyCompany)}}
                            </div>
                        </div>
                        <div class="cf_by-the-numbers_community-and-diversity-leadership-content-title-text">{{Loc(LocalizedTextIds.CommunityAndDiversityFemaleLed)}}</div>
                    </div>
                </div>
                <annulus [color]="'light'" [scale]="'small'" [value]="femaleLedPercentage"></annulus>
            </div>
            <div class="cf_by-the-numbers_community-and-diversity-leadership-main">
                <annulus [color]="'light'" [scale]="'small'" [value]="minorityLedPercentage"></annulus>
                <div class="cf_by-the-numbers_community-and-diversity-leadership-content-right">
                    <div class="cf_by-the-numbers_community-and-diversity-leadership-content-percentage" *ngIf="includesNorthAmericanLocations">
                        {{minorityLedPercentage | percent}}
                    </div>
                    <div class="cf_by-the-numbers_community-and-diversity-leadership-content-title"  *ngIf="includesNorthAmericanLocations">
                        <div class="cf_by-the-numbers_community-and-diversity-leadership-content-title-text">{{Loc(LocalizedTextIds.CommunityAndDiversityMinorityLed)}}</div>
                        <div class="cf_by-the-numbers_community-and-diversity-info-icon" touchTooltip (showTooltip)="showTooltip('minorityLed')" (hideTooltip)="hideTooltip('minorityLed')">
                            <div class="cf_by-the-numbers_community-and-diversity-info-icon-tooltip minorityLed" *ngIf="isShowingTooltip['minorityLed']">
                                {{Loc(LocalizedTextIds.CommunityAndDiversityIndividuals)}}
                            </div>
                        </div>
                    </div>
                    <ng-container class="cf_by-the-numbers_community-and-diversity-leadership-content-title" *ngIf="!includesNorthAmericanLocations">
                        <div class="cf_by-the-numbers_community-and-diversity-leadership-content-continent-text">{{Loc(LocalizedTextIds.CommunityAndDiversityNorthAmerica)}}</div>                    
                        <div class="cf_by-the-numbers_community-and-diversity-leadership-content-not-applicable-text">{{Loc(LocalizedTextIds.CommunityAndDiversityNotApplicable)}}</div>                    
                        <div class="cf_by-the-numbers_community-and-diversity-leadership-content-minority-text">{{Loc(LocalizedTextIds.CommunityAndDiversityMinorityLed)}}</div>
                        <div class="cf_by-the-numbers_community-and-diversity-info-icon disabled" ></div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>-->
</div>
