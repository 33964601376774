import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import _ from 'lodash-es';
import { ApproveDeclineComponent } from '../components/approve-decline/approve-decline.component.js';
import {
  CompanyKey,
  CompanyUpdatePropertyRelationship,
  Deal,
  Funding,
  getDependentFields,
  ICompanyUpdate,
  IDealUpdate,
  IFundingUpdate,
  IUpdateField,
  UpdateStatus,
} from 'company-finder-common';
import { ReviewedModel, ReviewedProperty } from '../company-update.interface.js';
import { CompanyService } from '@Common';

@Injectable({providedIn: 'root'})
export class ReviewEditsService {
  public approveDeclineComponents: ApproveDeclineComponent[] = [];
  public approveDeclineAllSubject: Subject<UpdateStatus> =
    new Subject<UpdateStatus>();
  public showReviewModalSubject: Subject<Deal | Funding> = new Subject<
    Deal | Funding
  >();
  private _currentEditItemProperty: string;
  private _propertiesReviewed: ReviewedProperty[] = [];
  private _modelsReviewed: ReviewedModel[] = [];
  private _editsApproved = 0;
  private _editsDeclined = 0;
  private _editsToReview = 0;

  constructor(private _companyService: CompanyService) {}

  // public getters
  public get currentEditItemProperty(): string {
    return this._currentEditItemProperty;
  }

  public set currentEditItemProperty(propertyName: string) {
    this._currentEditItemProperty = propertyName;
  }

  public get editsApproved(): number {
    return this._editsApproved;
  }

  public get editsDeclined(): number {
    return this._editsDeclined;
  }

  public get editsToReview(): number {
    return this._editsToReview;
  }

  public get totalEditsReviewed(): number {
    return this.editsApproved + this.editsDeclined;
  }

  public approveDeal(deal: Deal): void {
    this.updateReviewedModel(deal.dealId, UpdateStatus.Approved);
    this.updateCounts();
  }

  public approveFunding(funding: Funding): void {
    this.updateReviewedModel(funding.fundingId, UpdateStatus.Approved);
    this.updateCounts();
  }

  public approveProperty(propertyName: string): void {
    this.updatePropertyStatus(propertyName, UpdateStatus.Approved);
  }

  private updatePropertyStatus(
    propertyName: string,
    status: UpdateStatus
  ): void {
    // If other items are dependent, approve this and those
    // We don't need to worry about the parent since it will
    // require an approve or deny and that will flow to the children,
    // so it does not seem worth mapping in the company objects to
    // be able to traverse the entire tree as in a revert.

    const key = propertyName as CompanyKey;

    // If the item is a parent, approve its children
    const children = getDependentFields(key);
    const itemsToApprove = [propertyName, ...children];

    itemsToApprove.forEach((property) => {
      this.updateReviewedProperty(property, status);
    });

    this.updateCounts();
  }

  public approveRelatedProperties(
    relationship: CompanyUpdatePropertyRelationship,
    includeKey = true
  ): void {
    if (includeKey) {
      this.updateReviewedProperty(relationship.key, UpdateStatus.Approved);
    }
    relationship.relatedProperties.forEach((p) =>
      this.updateReviewedProperty(p, UpdateStatus.Approved)
    );
  }

  public approveAll(): void {
    this.approveDeclineAllSubject.next(UpdateStatus.Approved);
    this._editsApproved = this.approveDeclineComponents.filter(
      (adc) => adc.includeInSummary
    ).length;
    this._editsDeclined = 0;
    this._editsToReview = 0;
    this.approveDeclineComponents.forEach((adc) => {
      if (adc.propertyName) {
        this.updateReviewedProperty(adc.propertyName, UpdateStatus.Approved);
      } else if (adc.deal) {
        this.updateReviewedModel(adc.deal.dealId, UpdateStatus.Approved);
      } else if (adc.funding) {
        this.updateReviewedModel(adc.funding.fundingId, UpdateStatus.Approved);
      }
    });
  }

  public cancel(): void {
    this.clear();
  }

  public clear(): void {
    this._propertiesReviewed = [];
    this._modelsReviewed = [];
    this.approveDeclineAllSubject.next(UpdateStatus.Pending);
    this.updateCounts();
  }

  public declineDeal(deal: Deal): void {
    this.updateReviewedModel(deal.dealId, UpdateStatus.Declined);
    this.updateCounts();
  }

  public declineFunding(funding: Funding): void {
    this.updateReviewedModel(funding.fundingId, UpdateStatus.Declined);
    this.updateCounts();
  }

  public declineProperty(propertyName: string): void {
    this.updatePropertyStatus(propertyName, UpdateStatus.Declined);
  }

  public declineRelatedProperties(
    relationship: CompanyUpdatePropertyRelationship,
    includeKey = true
  ): void {
    if (includeKey) {
      this.updateReviewedProperty(relationship.key, UpdateStatus.Declined);
    }
    relationship.relatedProperties.forEach((p) =>
      this.updateReviewedProperty(p, UpdateStatus.Declined)
    );
  }

  public declineAll(): void {
    this.approveDeclineAllSubject.next(UpdateStatus.Declined);
    this._editsDeclined = this.approveDeclineComponents.filter(
      (adc) => adc.includeInSummary
    ).length;
    this._editsApproved = 0;
    this._editsToReview = 0;
    this.approveDeclineComponents.forEach((adc) => {
      if (adc.propertyName) {
        this.updateReviewedProperty(adc.propertyName, UpdateStatus.Declined);
      } else if (adc.deal) {
        this.updateReviewedModel(adc.deal.dealId, UpdateStatus.Declined);
      } else if (adc.funding) {
        this.updateReviewedModel(adc.funding.fundingId, UpdateStatus.Declined);
      }
    });
  }

  public getPropertyStatus(propertyName: string): UpdateStatus {
    if (this.isPropertyApproved(propertyName)) {
      return UpdateStatus.Approved;
    } else if (this.isPropertyDeclined(propertyName)) {
      return UpdateStatus.Declined;
    } else {
      return UpdateStatus.Pending;
    }
  }

  public getModelStatus(modelId: string): UpdateStatus {
    if (this.isModelApproved(modelId)) {
      return UpdateStatus.Approved;
    } else if (this.isModelDeclined(modelId)) {
      return UpdateStatus.Declined;
    } else {
      return UpdateStatus.Pending;
    }
  }

  public isPropertyApproved(propertyName: string): boolean {
    const reviewedProperty = this.findReviewedProperty(propertyName);
    return reviewedProperty && reviewedProperty.isApproved;
  }

  public isPropertyDeclined(propertyName: string): boolean {
    const reviewedProperty = this.findReviewedProperty(propertyName);
    return reviewedProperty && !reviewedProperty.isApproved;
  }

  public isPropertyInNeedOfReview(propertyName: string): boolean {
    const reviewedProperty = this.findReviewedProperty(propertyName);
    return !reviewedProperty;
  }

  public isModelApproved(modelId: string): boolean {
    const reviewedModel = this._modelsReviewed.find(
      (modelReviewed) => modelReviewed.modelId === modelId
    );
    return reviewedModel?.isApproved;
  }

  public isModelDeclined(modelId: string): boolean {
    const reviewedModel = this._modelsReviewed.find(
      (modelReviewed) => modelReviewed.modelId === modelId
    );
    return reviewedModel && !reviewedModel.isApproved;
  }

  public register(comp: ApproveDeclineComponent): void {
    const index = this.approveDeclineComponents.findIndex((adc) => {
      if (comp.propertyName) {
        return adc.propertyName === comp.propertyName;
      } else if (comp.deal) {
        return adc.deal && adc.deal.dealId === comp.deal.dealId;
      } else if (comp.funding) {
        return adc.funding && adc.funding.fundingId === comp.funding.fundingId;
      }
    });
    if (index < 0) {
      this.approveDeclineComponents.push(comp);
    } else {
      this.approveDeclineComponents[index] = comp;
    }
    this.updateCounts();
  }

  public resetPropertyStatus(propertyName: string): void {
    this.updatePropertyStatus(propertyName, UpdateStatus.Pending);
  }

  private setUpdatesSingleStatus(
    companyUpdate: ICompanyUpdate,
    status: UpdateStatus
  ): void {
    this.setStatusForUpdateFields(companyUpdate.updateFields, status);
    this.setStatusForDealOrFundingUpdates(companyUpdate.dealUpdates, status);
    this.setStatusForDealOrFundingUpdates(companyUpdate.fundingUpdates, status);
    companyUpdate.status = status;
  }

  public async submitReview(companyUpdate: ICompanyUpdate): Promise<void> {
    this.setAllStatuses(companyUpdate);
    await this._companyService.reviewCompanyUpdate(companyUpdate);
  }

  private setAllStatuses(companyUpdate: ICompanyUpdate): void {
    if (this._editsDeclined === 0) {
      this.setUpdatesSingleStatus(companyUpdate, UpdateStatus.Approved);
      return;
    }

    if (this._editsApproved === 0) {
      this.setUpdatesSingleStatus(companyUpdate, UpdateStatus.Declined);
      return;
    }
    companyUpdate.updateFields.map((updateField) => {
      const reviewedProperty = this.findReviewedProperty(updateField.name);
      this.setFieldStatus(updateField, reviewedProperty?.isApproved);
    });

    this.setStatusesForDealorFundingUpdates(companyUpdate.dealUpdates);
    this.setStatusesForDealorFundingUpdates(companyUpdate.fundingUpdates);
    // If anything was approved, then the CompanyUpdate as a whole is considered to be approved.
    companyUpdate.status = UpdateStatus.Approved;
  }

  private setFieldStatus(updateField: IUpdateField, isApproved: boolean): void {
    updateField.status = isApproved
      ? UpdateStatus.Approved
      : UpdateStatus.Declined;
  }

  // private methods
  private findReviewedProperty(name: string): ReviewedProperty {
    return this._propertiesReviewed.find((prop) => prop.name === name);
  }

  private setStatusForUpdateFields(
    updateFields: IUpdateField[],
    status: UpdateStatus
  ): void {
    updateFields.map((updateField) => {
      updateField.status = status;
    });
  }

  private setStatusForDealOrFundingUpdates(
    updates: IDealUpdate[] | IFundingUpdate[],
    status: UpdateStatus
  ): void {
    updates?.map((update: { updateFields: IUpdateField[] }) => {
      this.setStatusForUpdateFields(update.updateFields, status);
    });
  }

  private setStatusesForDealorFundingUpdates(
    updates: IDealUpdate[] | IFundingUpdate[]
  ) {
    updates?.map((update) => {
      const reviewedModel = this._modelsReviewed.find(
        (modelReviewed) => modelReviewed.modelId === update.modelId
      );
      update.updateFields?.map((updateField) => {
        this.setFieldStatus(updateField, reviewedModel?.isApproved);
      });
    });
  }

  private updateCounts(): void {
    this._editsApproved = this.approveDeclineComponents.filter(
      (adc) => adc.status === UpdateStatus.Approved
    ).length;
    this._editsDeclined = this.approveDeclineComponents.filter(
      (adc) => adc.status === UpdateStatus.Declined
    ).length;
    this._editsToReview = this.approveDeclineComponents.filter(
      (adc) => adc.status === UpdateStatus.Pending
    ).length;
  }

  private updateReviewedModel(modelId: string, status: UpdateStatus): void {
    const reviewedModel = this._modelsReviewed.find(
      (item) => item.modelId === modelId
    );
    const isApproved = status === UpdateStatus.Approved;
    if (reviewedModel) {
      reviewedModel.isApproved = isApproved;
    } else {
      this._modelsReviewed.push({
        modelId: modelId,
        isApproved: isApproved,
      });
    }
  }

  public isModelReviewed(modelId: string): boolean {
    return this._modelsReviewed.some(
      (item: { modelId: string }) => item.modelId === modelId
    );
  }

  private updateReviewedProperty(
    propertyName: string,
    status: UpdateStatus
  ): void {
    const reviewedProperty = this.findReviewedProperty(propertyName);
    if (status === UpdateStatus.Pending) {
      _.remove(this._propertiesReviewed, (item) => item.name === propertyName);
      return;
    }

    const isApproved = status === UpdateStatus.Approved;
    if (reviewedProperty) {
      reviewedProperty.isApproved = isApproved;
    } else {
      this._propertiesReviewed.push({
        name: propertyName,
        isApproved: isApproved,
      });
    }
  }
}
