import { Component, EventEmitter, Input, Output } from '@angular/core';

import { DeploymentContext } from '@Common';
import { ComponentBase } from '@Common';
import { type CompanyKey, LocalizedTextIds } from 'company-finder-common';

@Component({
    selector: 'multi-picker-modal',
    templateUrl: './multi-picker-modal.component.html',
    styleUrls: ['./multi-picker-modal.component.scss'],
    standalone: false
})
export class MultiPickerModalComponent extends ComponentBase {
  @Input()
  public options: string[] = [];

  @Input()
  public blurb = '';

  @Input()
  public itemType: CompanyKey;

  @Input()
  public showModal: boolean;

  @Output() action = new EventEmitter<string[]>();

  public constructor(dc: DeploymentContext) {
    super(dc);
  }

  public get filterText(): string {
    return `${this.Loc(LocalizedTextIds.FilterListBy)} ${this.header}...`;
  }

  public get title(): string {
    return `${this.Loc(LocalizedTextIds.Add)} ${this.header}`;
  }

  public get header(): string {
    return this.getHeaderForProperty(this.itemType);
  }

  public addItems(itemsToAdd: string[]): void {
    this.action.emit(itemsToAdd);
  }

  public doFilter = this.filter.bind(this);

  public filter(terms: string): string[] {
    const cleanTerms = terms.toLowerCase().trim();

    return this.options.filter(
      (option) =>
        cleanTerms.length === 0 || option.toLowerCase().includes(cleanTerms)
    );
  }

  public compareOptions(item1: string, item2: string): boolean {
    return item1.toLowerCase() === item2.toLowerCase();
  }
}
