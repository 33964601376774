<div class="cf_overview_deals" *ngIf="summary">
  <div class="cf_overview_deals-title">{{title}}</div>
  <div class="cf_overview_deals-title-total-count-note">
    <localized-text-snippet text="{{localizedCountString}}"></localized-text-snippet>
    <span class="dealsBetweenDescription">{{Loc(LocalizedTextIds.DealsBetween)}}</span>
  </div>
  <div class="cf_overview_deals-spacer">
      <div class="cf_overview_deals-spacer-display"></div>
  </div>
  <div class="cf_overview_deals-content">
    <div *ngFor="let primarySectorCount of sortedPrimarySectorCounts">
      <sector-details [barPercentage]="calculateSectorBarPercentage(primarySectorCount)" [primarySectorCount]="primarySectorCount"></sector-details>
    </div>
  </div>
</div>
