import { Component, HostListener, Input } from '@angular/core';

import { ComponentBase } from '@Common';

// model imports
import { LocalizedTextIds } from 'company-finder-common';
import { DeploymentContext } from '@Common';
import { Summary } from '@Common';

@Component({
    selector: 'community-and-diversity',
    templateUrl: './community-and-diversity.component.html',
    styleUrls: ['./community-and-diversity.component.scss'],
    standalone: false
})
export class CommunityAndDiversityComponent extends ComponentBase {
  // public properties
  public isShowingTooltip = {
    femaleLed: false,
    minorityLed: false,
  };
  @Input()
  public summary: Summary;

  @Input()
  public includesNorthAmericanLocations: boolean;
  public title = this.Loc(LocalizedTextIds.CommunityAndDiversityTitle);
  public entrepeneurSize = 'large';

  public constructor(dc: DeploymentContext) {
    super(dc);
  }

  @HostListener('window:resize', ['$event'])
  public onResize(): void {
    const entrepeneurElement = document.querySelector(
      '#entrepeneurElement'
    ) as HTMLElement;
    let contentWidth = 0;

    if (!entrepeneurElement) {
      return;
    }

    for (let i = 0; i < entrepeneurElement.children.length; i++) {
      contentWidth += entrepeneurElement.children[i].clientWidth;
    }

    const isOverflowing =
      entrepeneurElement.scrollWidth > entrepeneurElement.offsetWidth;

    const freeSpace = entrepeneurElement.scrollWidth - contentWidth;

    if (isOverflowing && this.entrepeneurSize === 'large') {
      this.entrepeneurSize = 'small';
    }
    // The difference between the small and large annulus sizes is 174px - 104px = 70px;
    // This width is determined by the hardcoded sizes in annulus.component.scss
    if (!isOverflowing && freeSpace > 70) {
      this.entrepeneurSize = 'large';
    }
  }

  // public getters
  public get serialEntrepreneurPercentage(): number {
    return this.summary.serialEntrepreneurPercentage;
  }

  public get firstTimeEntrepreneurPercentage(): number {
    return this.summary.firstTimeEntrepreneurPercentage;
  }

  public get femaleLedPercentage(): number {
    return this.summary.femaleLedPercentage;
  }

  public get minorityLedPercentage(): number {
    return this.summary.minorityLedPercentage;
  }

  // public methods
  public showTooltip(whichTooltip: string): void {
    this.isShowingTooltip[whichTooltip] = true;
  }

  public hideTooltip(whichTooltip: string): void {
    this.isShowingTooltip[whichTooltip] = false;
  }
}
