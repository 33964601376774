<action-modal
  modalTitle="Notifications"
  [actionOptions]="[]"
  [width]="680"
  [maxHeight]="680"
  [actionOptions]="ActionOptions"
  [hideClose]="true"
  (action)="onClose()"
  >
  <div class="preferences-content">
    <div class="frequency-section">
      <div class="frequency-description">
        <div class="frequency-header">{{Loc(LocalizedTextIds.NotificationsModalJLABSNavigator)}}</div>
        <p>{{frequencyLongDescription}}</p>
      </div>
      <select-input class="frequency-select" name="email-frequency" variant="follow" [(ngModel)]="userEmailFrequency"
        [options]="emailFrequencyOptions" [getSerializedValue]="toDisplayString"></select-input>
    </div>
    <div class="toggle-notification-section">
      <div class="header">
        <span class="header-text">
          {{Loc(LocalizedTextIds.NotificationsModalNotificationsYouWill)}}
        </span>
        <span class="column-email">
          <img src="/assets/common/group-6b.svg" width="28px" height="20px" />
        </span>
      </div>
      <div class="updates-section">
        <div class="company-provided updates-section-item">
          <span class="update-text-upper">
            {{Loc(LocalizedTextIds.NotificationsModalCompanyProvided)}}
          </span>
            <div class="toggles">
              <toggle-input name="company-provided-feed" labelPosition="bottom"
                [(ngModel)]="userNewsUpdatePreferenceData.emailUpdates"></toggle-input>
            </div>
          <div class="update-text-lower">
            {{Loc(LocalizedTextIds.NotificationsModalJLABSCompany)}}
          </div>
        </div>
        <div *ngIf="featureSwitches.enableNews" class="news-updates updates-section-item">
          <span class="update-text-upper">
            {{Loc(LocalizedTextIds.NotificationsModalJLABSCompaniesIn)}}
          </span>
          <div class="toggles">
            <toggle-input name="news-updates-feed" labelPosition="bottom" [(ngModel)]="userNewsUpdatePreferenceData.emailNews">
            </toggle-input>
          </div>
          <div class="update-text-lower">
            {{Loc(LocalizedTextIds.NotificationsModalCompanyMentions)}}
          </div>
        </div>
        <div class="new-companies updates-section-item">
          <span class="update-text-upper">
            {{Loc(LocalizedTextIds.NotificationsModalNewCompanies)}}
          </span>
          <div class="toggles">
            <toggle-input name="new-companies-feed" labelPosition="bottom"
              [(ngModel)]="userNewsUpdatePreferenceData.emailNewCompanies"></toggle-input>
          </div>
          <div class="update-text-lower">
            {{Loc(LocalizedTextIds.NotificationsModalCompanyOfficially)}}
          </div>
        </div>
      </div>
    </div>
  </div>
</action-modal>
