import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

// eslint-disable-next-line @angular-eslint/use-pipe-transform-interface
@Pipe({
    name: 'largeNumberCurrency',
    standalone: false
})
export class LargeNumberCurrencyPipe
  extends CurrencyPipe
  implements PipeTransform
{
  transform(
    value: number | string,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string
  ): string | null;
  transform(
    value: null | undefined,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string
  ): null;
  transform(
    value: number | string | null | undefined,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string
  ): string | null {
    if (typeof value === 'string') {
      value = Number.parseFloat(value);
    }
    if (isNaN(value) || value === null) {
      return null;
    }
    if (value === 0) {
      return '0';
    }
    let abs = Math.abs(value);
    const rounder = Math.pow(10, 1);
    const isNegative = value < 0;
    let key = '';

    const powers = [
      { key: 'Q', value: Math.pow(10, 15) },
      { key: 'T', value: Math.pow(10, 12) },
      { key: 'B', value: Math.pow(10, 9) },
      { key: 'M', value: Math.pow(10, 6) },
      { key: 'K', value: 1000 },
    ];

    for (let i = 0; i < powers.length; i++) {
      let reduced = abs / powers[i].value;
      reduced = Math.round(reduced * rounder) / rounder;
      if (reduced > 1) {
        abs = reduced;
        key = powers[i].key;
        break;
      }
    }

    const currency = super.transform(
      abs,
      currencyCode,
      display,
      digitsInfo,
      locale
    );

    return (isNegative ? '-' : '') + currency + ' ' + key;
  }
}
