<section class="preferences-section">
  <div class="section-title">
    <div>{{sectionTitle}}</div>
    <div class="add" (click)="addNewItem()">+ {{Loc(LocalizedTextIds.Add)}}</div>
  </div>
  <div class="section-main">
    <div class="section">
      <ng-content></ng-content>
    </div>
  </div>
</section>
