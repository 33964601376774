<div class="company-update-confirmation">
    <div class="card">
        <ng-container *ngIf="isEditMode">
            <div class="title">{{Loc(LocalizedTextIds.CompanyUpdateConfirmationSuccessfullySubmitted)}}</div>
        </ng-container>
        <ng-container *ngIf="isReviewMode">
            <ng-container *ngIf="status === statuses.All">
                <div class="title">{{LocPluralize(LocalizedTextIds.CompanyUpdateConfirmationSuccessfullyApproved, numberApproved, numberApproved)}}</div>
            </ng-container>
            <ng-container *ngIf="status === statuses.None">
                <div class="title">{{LocPluralize(LocalizedTextIds.CompanyUpdateConfirmationSuccessfullyDeclined, numberDeclined, numberDeclined)}}</div>
            </ng-container>
            <ng-container *ngIf="status === statuses.Partial">
                <div class="title">{{LocPluralize(LocalizedTextIds.CompanyUpdateConfirmationSuccessfullyApprovedAndDeclined, numberApproved, numberApproved, numberDeclined)}}</div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="isReviewMode" class="card-footer">
            <div class="company-name">{{company.name}}</div>
            <div class="location">({{location}})</div>
        </ng-container>
        <div *ngIf="showUpdateList" class="updates">
            <div *ngIf="isReviewMode" class="updates-title">{{Loc(LocalizedTextIds.CompanyUpdateConfirmationApprovedEdits)}}</div>
            <ng-container *ngFor="let simpleUpdate of submittedUpdate.simpleUpdates">
                <div 
                    class="update" 
                    [ngClass]="{'update-top-approved': isReviewMode}"
                    *ngIf="showPropertyUpdate(simpleUpdate.propertyName)">
                    <div class="update-field">{{Loc(simpleUpdate.displayName)}}</div>
                    <div class="update-value">
                        <div *ngIf="simpleUpdate.title" class="update-value-title">
                            {{simpleUpdate.title}}
                        </div>
                        <div *ngIf="!simpleUpdate.isLogo">
                            {{simpleUpdate.content}}
                        </div>
                        <div *ngIf="simpleUpdate.isLogo">
                            <company-logo [logoBase64]="simpleUpdate.content" show="true"></company-logo>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngFor="let addDeleteUpdate of submittedUpdate.addDeleteUpdates">
                <div class="update" [ngClass]="{'update-top-approved': isReviewMode}" *ngIf="showPropertyUpdate(addDeleteUpdate.propertyName)">
                    <div class="update-field">{{Loc(addDeleteUpdate.displayName)}}</div>
                    <div class="update-content">
                        <ng-container *ngIf="addDeleteUpdate.added && addDeleteUpdate.added.length">
                            <div class="update-value-heading">
                                {{Loc(LocalizedTextIds.Added)}}:
                            </div>
                            <div class="update-values">
                                <ng-container *ngFor="let added of addDeleteUpdate.added; last as isLast">
                                    <div class="update-value">
                                        <div>{{added}}<span *ngIf="!isLast">,&nbsp;</span></div>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="addDeleteUpdate.deleted && addDeleteUpdate.deleted.length">
                            <div class="update-value-heading">
                                {{Loc(LocalizedTextIds.Deleted)}}:
                            </div>
                            <div class="update-values">
                                <ng-container *ngFor="let deleted of addDeleteUpdate.deleted; last as isLast">
                                    <div class="update-value">
                                        <div class="update-value-deleted">{{deleted}}<span *ngIf="!isLast">,&nbsp;</span></div>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="showAddDeleteEditUpdates">
                <div *ngFor="let addDeleteEditUpdate of submittedUpdate.addDeleteEditUpdates" class="update" [ngClass]="{'update-top-approved': isReviewMode}">
                    <ng-container *ngIf="showModelUpdate(addDeleteEditUpdate.modelId)">
                        <div class="update-field">
                            {{Loc(addDeleteEditUpdate.displayName)}}
                        </div>
                        <div class="update-content">
                            <ng-container *ngIf="addDeleteEditUpdate.added && addDeleteEditUpdate.added.length">
                                <div class="update-value-heading">
                                    {{Loc(LocalizedTextIds.Added)}}:
                                </div>
                                <div class="update-values-column">
                                    <ng-container *ngFor="let added of addDeleteEditUpdate.added">
                                        <div class="update-value-column">
                                            <div>{{added}}</div>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="addDeleteEditUpdate.deleted && addDeleteEditUpdate.deleted.length">
                                <div class="update-value-heading">
                                    {{Loc(LocalizedTextIds.Deleted)}}:
                                </div>
                                <div class="update-values-column">
                                    <ng-container *ngFor="let deleted of addDeleteEditUpdate.deleted">
                                        <div class="update-value-column">
                                            <div class="update-value-deleted">{{deleted}}</div>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="addDeleteEditUpdate.edited && addDeleteEditUpdate.edited.length">
                                <div class="update-value-heading">
                                    {{Loc(LocalizedTextIds.Edited)}}:
                                </div>
                                <div class="update-values-column">
                                    <ng-container *ngFor="let edited of addDeleteEditUpdate.edited">
                                        <div class="update-value-column">
                                            <div>{{edited.title}}</div>
                                            <ul class="update-value-list">
                                                <li *ngFor="let editUpdate of edited.updates">
                                                    <div>
                                                        <span class="update-value-list-title">
                                                            {{Loc(editUpdate.displayName)}}:
                                                        </span> {{editUpdate.content}}
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
        <div *ngIf="isEditMode" class="button" (click)="returnToProfile()">{{Loc(LocalizedTextIds.CompanyUpdateConfirmationReturnToMyCompany)}}</div>
        <div *ngIf="isReviewMode" class="button" (click)="returnToExplore()">{{Loc(LocalizedTextIds.CompanyUpdateConfirmationReturnToExplore)}}</div>
        <div *ngIf="isReviewMode && reviewsAvailable" class="button" (click)="reviewNextUpdate()">{{Loc(LocalizedTextIds.CompanyUpdateConfirmationReviewNext)}}</div>
    </div>
</div>
