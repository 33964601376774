<div class="cd_public-information-update">
  <div class="info">
    <p>
      {{header}}
    </p>
  </div>
  <edit-item-group
    [propertyGroup]="propertyGroup"            
    [companyEditForm]="companyEditForm">
  </edit-item-group>
</div>