<!-- This is how to structure the menu -->
<ng-template #menuStructureTemplate let-dropdownMenuComponent="dropdownMenuComponent" let-menuOptions="menuOptions">
  <ng-container *ngFor="let menuOption of menuOptions">
    <div class="dropdown-menu-option">
      <ng-container
        *ngTemplateOutlet="optionDisplayTemplate;
        context: { menuOption: menuOption, dropdownMenuComponent: dropdownMenuComponent, content:content }">
      </ng-container>
    </div>
  </ng-container>
</ng-template>
<!-- This is how to render each menu option -->
<ng-template #optionDisplayTemplate let-dropdownMenuComponent="dropdownMenuComponent" let-menuOption="menuOption">
    <label [for]="menuOption.id" (click)="dropdownMenuComponent.handleSingleSelect(menuOption); dropdownMenuComponent.toggleMenu()">{{Loc(menuOption.label)}}</label>
</ng-template>

<div #cf_overview_stage class="cf_overview_stage">
  <div class="cf_overview_stage-title">
    <label class="cf_overview_stage-title-label">{{title}}</label>
    <div class="cf_overview_stage-title-dropdown">
      <dropdown-menu
        label="{{dropdownValue}}"
        defaultValue="{{dropdownValue}}"
        [menuOptions]="sectorOptions"
        [menuStructureTemplate]="menuStructureTemplate"
        [optionDisplayTemplate]="optionDisplayTemplate"
        (select)="handleSectorSelectedEvent($event)">
      </dropdown-menu>
    </div>
  </div>
  <div class="cf_overview_stage-bubbles">
    <div class="cf_overview_stage-bubbles-line" [ngStyle]="bubbleLineStyle"></div>
    <div *ngFor="let stageCount of activeStageCounts; index as i">
      <div class="cf_overview_stage-bubbles-bubble" [ngStyle]="getBubbleStyles(stageCount, i)" title="{{getTooltip(stageCount)}}" (click)="handleClick(stageCount, true)"></div>
    </div>
  </div>
  <div class="cf_overview_stage-details">
    <div class="cf_overview_stage-details-content">
      <div *ngFor="let stageCount of activeStageCounts; index as i">
        <div *ngIf="stageCount.count > 0" class="cf_overview_stage-details-content-item" (click)="handleClick(stageCount, false)">
          <div class="cf_overview_stage-details-content-item-label">
            {{stageCount.stage}}
          </div>
          <div class="cf_overview_stage-details-content-item-count">{{stageCount.count}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
