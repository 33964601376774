<div class="cf_by-the-numbers_strategic-collaboration" [ngClass]="{narrowScreen: narrowScreen}">
    <div class="cf_by-the-numbers_strategic-collaboration-title">
        {{title}}
    </div>
    <div class="cf_by-the-numbers_strategic-collaboration-subtitle">
        {{Loc(LocalizedTextIds.StrategicCollaborationSubtitle)}}
    </div>
    <div *ngIf="summary">
        <div class="cf_by-the-numbers_strategic-collaboration-upper">
            <div class="cf_by-the-numbers_strategic-collaboration-circle-outer">
                <div class="cf_by-the-numbers_strategic-collaboration-circle">
                    <div class="cf_by-the-numbers_strategic-collaboration-value">{{summary.numberOfPubliclyOfferedCompanies}}</div>
                    <div class="cf_by-the-numbers_strategic-collaboration-value-subtitle">{{Loc(LocalizedTextIds.StrategicCollaborationPubliclyTraded)}}</div>
                    <div class="cf_by-the-numbers_strategic-collaboration-info-icon" touchTooltip (showTooltip)="showTooltip('publiclyOffered')" (hideTooltip)="hideTooltip('publiclyOffered')">
                        <div class="cf_by-the-numbers_strategic-collaboration-info-icon-tooltip top-tooltip" *ngIf="isShowingTooltip['publiclyOffered']">
                            {{Loc(LocalizedTextIds.StrategicCollaborationIPOs)}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="cf_by-the-numbers_strategic-collaboration-circle-outer">
                <div class="cf_by-the-numbers_strategic-collaboration-circle">
                    <div class="cf_by-the-numbers_strategic-collaboration-value">{{summary.numberOfAcquiredCompanies}}</div>
                    <div class="cf_by-the-numbers_strategic-collaboration-value-subtitle">{{Loc(LocalizedTextIds.StrategicCollaborationAcquired)}}</div>
                    <div class="cf_by-the-numbers_strategic-collaboration-spacer"></div>
                </div>
            </div>
        </div>
        <div class="cf_by-the-numbers_strategic-collaboration-lower">
            <div class="cf_by-the-numbers_strategic-collaboration-value-sum">
                <div class="cf_by-the-numbers_strategic-collaboration-value-sum-amount">
                    {{getSumSplit(summary.sumTotalSecuredAndContingentAmount | largeNumberCurrency:'USD':'symbol-narrow':'1.1-1', 0)}}
                </div>
                <div class="cf_by-the-numbers_strategic-collaboration-value-sum-scale">
                    {{getSumSplit(summary.sumTotalSecuredAndContingentAmount | largeNumberCurrency:'USD':'symbol-narrow':'1.1-1', 1)}}
                </div>
            </div>
            <div class="cf_by-the-numbers_strategic-collaboration-value-subtitle">{{Loc(LocalizedTextIds.StrategicCollaborationFinancing)}}</div>
            <div class="cf_by-the-numbers_strategic-collaboration-info-icon-lower" touchTooltip (showTooltip)="showTooltip('strategicRelationships')" (hideTooltip)="hideTooltip('strategicRelationships')">
                <div class="cf_by-the-numbers_strategic-collaboration-info-icon-tooltip bottom-tooltip" *ngIf="isShowingTooltip['strategicRelationships']">
                    {{Loc(LocalizedTextIds.StrategicCollaborationValue)}}
                </div>
            </div>
        </div>
    </div>
</div>
