import { Component, Input } from '@angular/core';
import { ComponentBase } from '@Common';
import { Company } from 'company-finder-common';

@Component({
    selector: 'jnj-information',
    templateUrl: './jnj-information.component.html',
    styleUrls: ['./jnj-information.component.scss'],
    standalone: false
})
export class JnjInformationComponent extends ComponentBase {
  @Input()
  public company: Company;

  public get hasInternalCompanyDetails(): boolean {
    return !!this.company.keyMgmtAndAdvBm || !!this.company.keyDifferentiation;
  }

  public get hasProgressUpdate(): boolean {
    return (
      !!this.company.progressCreatedDate ||
      !!this.company.progressSubject ||
      !!this.company.progressUpdate
    );
  }

  public get hasRelatedRecords(): boolean {
    return (
      this.company.deals?.length > 0 ||
      this.company.opportunities?.length > 0 ||
      this.company.funding?.length > 0
    );
  }
}
