<funding-modal #fundingModalComponent [showModal]='showFundingModal'></funding-modal>
<deal-modal #dealModalComponent  [showModal]='showDealModal'></deal-modal>
<div class="info">
  <p>
    {{Loc(LocalizedTextIds.JnjInformationItsImportant)}}
  </p>
</div>
<edit-item-group
    [propertyGroup]="miscProperties"            
    [companyEditForm]="companyEditForm">
</edit-item-group>
<div class="info-section-header" [ngClass]="{ 'pending': haveDealsChanged }">
  {{Loc(LocalizedTextIds.CompanyDetailsDeals)}}
  <div *ngIf="selfUpdateMode === SelfUpdateModes.Edit" class="add-edit-review-button" (click)="openDeal()">
    +{{Loc(LocalizedTextIds.Add)}}
  </div>
</div>
<table *ngIf="company.deals?.length > 0" class="deals-funding-table">
  <tr>
    <th class="deal-party-column">{{Loc(LocalizedTextIds.DealModalDealParty)}}</th>
    <th class="currencycolumn">{{Loc(LocalizedTextIds.CompanyDetailsAmount)}}</th>
    <th class="datecolumn">{{Loc(LocalizedTextIds.CompanyDetailsAnnouced)}}</th>
    <th class="typecolumn">{{Loc(LocalizedTextIds.CompanyDetailsType)}}</th>
    <th class="publicly-shared-column">{{Loc(LocalizedTextIds.JnjInformationPubliclyShared)}}</th>
    <th class="editcolumn">&nbsp;</th>
  </tr>
  <tr *ngFor="let deal of company.deals; trackBy: dealTrackBy">
    <td class="deal deal-party-column">
      <div [ngClass]="{'strikethrough': deal.isDeleted}">{{deal.dealParty}}</div>
      <div class="added" *ngIf="isAdded(deal, 'dealId')">{{Loc(LocalizedTextIds.Added)}}</div>
      <div class="added" *ngIf="isApproved(deal, 'dealId')">{{Loc(LocalizedTextIds.Approved)}}</div>
      <div class="deleted" *ngIf="deal.isDeleted">{{Loc(LocalizedTextIds.Deleted)}}</div>
      <div *ngIf="selfUpdateMode === SelfUpdateModes.Edit && updateForReview.dealUpdates[deal.dealId]" class="modified-flag"></div>
      <review-deal-funding
        [hidden]="selfUpdateMode !== SelfUpdateModes.Review || !updateForReview.dealUpdates[deal.dealId]"
        [item]="deal">
      </review-deal-funding>
    </td>
    <td class="currencycolumn">
      <div class="dealamounts">
        <div *ngIf="deal.amount" class="deal">
          <div class="header" [ngClass]="{'strikethrough': deal.isDeleted}">{{Loc(LocalizedTextIds.Secured)}}</div>
          <div *ngIf="IsNumberOrNumberString(deal.amount); else dealAmountNotANumber"
            [ngClass]="{'strikethrough': deal.isDeleted}">
            {{deal.amount | currency:deal.amountCurrency:'symbol-narrow':'1.0-0'}}
            {{deal.amountCurrency}}
          </div>
          <ng-template #dealAmountNotANumber>
            <span [ngClass]="{'strikethrough': deal.isDeleted}"> {{deal.amount}}</span>
          </ng-template>
        </div>
        <div *ngIf="deal.contingentAmount" class="deal">
          <div class="header" [ngClass]="{'strikethrough': deal.isDeleted}">{{Loc(LocalizedTextIds.Contingent)}}</div>
          <div *ngIf="IsNumberOrNumberString(deal.contingentAmount); else dealContingentAmountNotANumber"
            [ngClass]="{'strikethrough': deal.isDeleted}">
            {{deal.contingentAmount | currency:deal.amountCurrency:'symbol-narrow':'1.0-0'}}
            {{deal.amountCurrency}}
          </div>
          <ng-template #dealContingentAmountNotANumber>
            <span [ngClass]="{'strikethrough': deal.isDeleted}">{{deal.contingentAmount}}</span>
          </ng-template>
        </div>
      </div>
    </td>
    <td class="deal datecolumn">
      <span [ngClass]="{'strikethrough': deal.isDeleted}">{{deal.announcementDate | date:'mediumDate' : 'GMT'}}</span>
    </td>
    <td class="deal typecolumn">
      <span [ngClass]="{'strikethrough': deal.isDeleted}">{{deal.dealType}}</span>
    </td>
    <td class="publicly-shared-column deal">
      <div>
        <div *ngIf="deal.isConfidential" class="value">
          {{Loc(LocalizedTextIds.No)}}
        </div>
        <div *ngIf="!deal.isConfidential" class="value">
          {{Loc(LocalizedTextIds.Yes)}}
        </div>
        <approve-decline
          *ngIf="selfUpdateMode === SelfUpdateModes.Review && updateForReview.dealUpdates[deal.dealId]"
          [deal]="deal" [includeInSummary]="updateForReview.dealUpdates[deal.dealId]">
        </approve-decline>
      </div>
    </td>
    <td class="editcolumn">
      <div *ngIf="selfUpdateMode === SelfUpdateModes.Edit || updateForReview.dealUpdates[deal.dealId]" (click)="openDeal(deal)">
        <div *ngIf="selfUpdateMode === SelfUpdateModes.Edit" class="add-edit-review-button">+{{Loc(LocalizedTextIds.Edit)}}</div>
        <div *ngIf="selfUpdateMode === SelfUpdateModes.Review" class="add-edit-review-button">+{{Loc(LocalizedTextIds.Review)}}</div>
      </div>
    </td>
  </tr>
</table>
<div class="section-spacer"></div>
<div class="info-section-header" [ngClass]="{ 'pending': haveFundingsChanged }">
  {{Loc(LocalizedTextIds.Funding)}}
  <div *ngIf="selfUpdateMode === SelfUpdateModes.Edit" class="add-edit-review-button" (click)="openFunding()">
    +{{Loc(LocalizedTextIds.Add)}}
  </div>
</div>
<table *ngIf="company.funding?.length > 0" class="deals-funding-table">
  <tr>
    <th class="roundcolumn">{{Loc(LocalizedTextIds.CompanyDetailsFundingRound)}}</th>
    <th class="currencycolumn">{{Loc(LocalizedTextIds.CompanyDetailsAmount)}}</th>
    <th class="datecolumn">{{Loc(LocalizedTextIds.CompanyDetailsDateRaised)}}</th>
    <th class="funding-party-column">{{Loc(LocalizedTextIds.CompanyDetailsFundingParty)}}</th>
    <th class="publicly-shared-column">{{Loc(LocalizedTextIds.JnjInformationPubliclyShared)}}</th>
    <th class="editcolumn">&nbsp;</th>
  </tr>
  <tr *ngFor="let item of company.funding; trackBy: fundingTrackBy">
    <td class="funding roundcolumn">
      <div [ngClass]="{'strikethrough': item.isDeleted}">{{item.fundingRound}}</div>
      <div class="added" *ngIf="isAdded(item, 'fundingId')">{{Loc(LocalizedTextIds.Added)}}</div>
      <div class="added" *ngIf="isApproved(item, 'fundingId')">{{Loc(LocalizedTextIds.Approved)}}</div>
      <div class="deleted" *ngIf="item.isDeleted">{{Loc(LocalizedTextIds.Deleted)}}</div>
      <div *ngIf="selfUpdateMode === SelfUpdateModes.Edit && updateForReview.fundingUpdates[item.fundingId]" class="modified-flag"></div>
      <review-deal-funding
        [hidden]="selfUpdateMode !== SelfUpdateModes.Review || !updateForReview.fundingUpdates[item.fundingId]"
        [item]="item">
      </review-deal-funding>
    </td>
    <td class="currencycolumn">
      <div *ngIf="IsNumberOrNumberString(item.raised); else fundingAmountNotANumber"
        [ngClass]="{'strikethrough': item.isDeleted}">
        {{item.raised | currency:item.raisedCurrency:'symbol-narrow':'1.0-0'}}
        {{item.raisedCurrency}}
      </div>
      <ng-template #fundingAmountNotANumber>
        <span [ngClass]="{'strikethrough': item.isDeleted}">{{item.raised}}</span>
      </ng-template>
    </td>
    <td class="datecolumn" [ngClass]="{'strikethrough': item.isDeleted}">
      {{item.dateRaised | date : 'mediumDate' : 'GMT'}}
    </td>
    <td class="funding-party-column" [ngClass]="{'strikethrough': item.isDeleted}">{{item.fundingParty}}</td>
    <td class="publicly-shared-column">
      <div>
        <div *ngIf="item.isConfidential" class="value">
          {{Loc(LocalizedTextIds.No)}}
        </div>
        <div *ngIf="!item.isConfidential" class="value">
          {{Loc(LocalizedTextIds.Yes)}}
        </div>
        <approve-decline
          *ngIf="selfUpdateMode === SelfUpdateModes.Review && updateForReview.fundingUpdates[item.fundingId]"
          [funding]="item" [includeInSummary]="updateForReview.fundingUpdates[item.fundingId]">
        </approve-decline>
      </div>
    </td>
    <td class="editcolumn">
      <div *ngIf="selfUpdateMode === SelfUpdateModes.Edit || updateForReview.fundingUpdates[item.fundingId]" (click)="openFunding(item)">
        <div *ngIf="selfUpdateMode === SelfUpdateModes.Edit" class="add-edit-review-button">+{{Loc(LocalizedTextIds.Edit)}}</div>
        <div *ngIf="selfUpdateMode === SelfUpdateModes.Review" class="add-edit-review-button">+{{Loc(LocalizedTextIds.Review)}}</div>
      </div>
    </td>
  </tr>
</table>
<div class="section-spacer"></div>
<edit-item-group 
  [propertyGroup]="deiProperties"
  [companyEditForm]="companyEditForm">
</edit-item-group>
