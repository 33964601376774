<div class="sector-selector">
  <input type="text" autofocus (input)="onSearchChange($event)" class="filter" placeholder="{{Loc(LocalizedTextIds.FilterListBySector, isPrimary ? '' : 'secondary ')}}">
  <ng-container *ngFor="let sectorAndCount of filteredData; trackBy: trackBySector" >
    <label *ngIf="isVisible(sectorAndCount)" class="item-info">
      <div class="item-name-and-company-count" [ngClass]="{'is-sub-sector': !!sectorAndCount.parentSector}"  (click)="handleLabelClick(sectorAndCount, $event)">
        <div class="item-name-expander-and-company-name" >
          <button
            *ngIf="!sectorAndCount.parentSector"
            class="child-toggle"
            [ngClass]="{ 'open': openChildOptions[sectorAndCount.sector] }">
          </button>
          <div class="item-name selected-text-color" >{{sectorAndCount.sector}}</div>
        </div>
        <div *ngIf="includeCounts" class="company-count" [ngClass]="{'for-sectors': !sectorAndCount.parentSector}">
          {{LocPluralize(LocalizedTextIds.CompanyCount, sectorAndCount.count, sectorAndCount.count)}}
        </div>
      </div>
      <div class="select-checkbox">
        <input
          type="checkbox"
          class='checkbox'
          [ngClass]="{ 'indeterminate': isInderminate(sectorAndCount) }"
          [checked]="isSelected(sectorAndCount)"
          (click)="updateSelection(sectorAndCount, $event)"/>
      </div>
    </label>
  </ng-container>
</div>
