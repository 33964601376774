import { Component } from '@angular/core';
import { DisplayItem } from 'company-finder-common';
import { SavedSearchCriteriaBaseComponent } from '../saved-search-criteria-base/saved-search-criteria-base.component.js';

@Component({
    selector: 'saved-search-simple-selector',
    templateUrl: './saved-search-simple-selector.component.html',
    styleUrls: ['./saved-search-simple-selector.component.scss'],
    standalone: false
})
export class SavedSearchSimpleSelectorComponent<
  T extends DisplayItem
> extends SavedSearchCriteriaBaseComponent<T> {
  // public methods
  public getDisplayName(obj: any): string {
    // Duck-typing :-\
    return obj['location']
      ? obj.location.name
      : obj.statusItem.displayName;
  }

  public updateSelection(item: T): void {
    const selected = this.findSelected(item);

    if (selected == null) {
      this.selectItem(item);
    } else {
      this.deselectItem(selected);
    }
    this.criteriaChange.emit(this._selectedItems);
  }
}
