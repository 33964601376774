import { Component } from '@angular/core';
import { UpdateComponentBaseWithEditItems } from '../../UpdateComponentBaseWithEditItems.js';
import { LocalizedTextIds } from 'company-finder-common';
import { EditablePropertyGroup } from '../../company-update.interface.js';

@Component({
    selector: 'blue-knight-milestones-update',
    templateUrl: './blue-knight-milestones-update.component.html',
    styleUrls: ['./blue-knight-milestones-update.component.scss'],
    standalone: false
})
export class BlueKnightMilestonesUpdateComponent extends UpdateComponentBaseWithEditItems {
  public partnershipProperties: EditablePropertyGroup = {
    header: LocalizedTextIds.BlueKnightPartnerships,
    properties: [
      'commercialPartnerships',
      'rAndDPartnerships',
      'otherPartnerships',
    ],
    subheader: {
      subheaderId: LocalizedTextIds.BlueKnightPartnershipsQuestion,
    },
  };

  public ipProperties: EditablePropertyGroup = {
    header: LocalizedTextIds.IPLandscape,
    properties: [
      'compositionOfMatter',
      'securedIP',
      'filedIP',
      'addtlIPDetails',
    ],
  };

  public fundingProperties: EditablePropertyGroup = {
    header: LocalizedTextIds.Funding,
    properties: ['fundingStage', 'fundingStageDetails'],
  };

  public capitalProperties: EditablePropertyGroup = {
    header: LocalizedTextIds.CapitalRaised,
    properties: ['nonDilutiveFunding', 'dilutiveFunding'],
  };

  public rdProperties: EditablePropertyGroup = {
    header: LocalizedTextIds.RDPlansAndMilestones,
    properties: [
      'majorMilestones',
      'currentPharmaStage',
      'currentMedTechStage',
      'rndStageDetails',
      'regulatoryStatus',
      'anticipatedCommercialProductYr',
      'nonLeadRegStatus',
      'entryExitStrategy',
    ],
  };

  public propertyGroups: EditablePropertyGroup[] = [
    this.partnershipProperties,
    this.ipProperties,
    this.fundingProperties,
    this.capitalProperties,
    this.rdProperties,
  ];
}
