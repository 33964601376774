import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Company, Filter, TagWithCount } from 'company-finder-common';
import _ from 'lodash-es';
import { SearchService } from '@Common';
import { DeploymentContext } from '../utilities/deployment-context/deployment-context.js';
import { Summary } from '../utilities/summary/summary.js';

export interface SearchResolverResult {
  companies: Company[];
  totalCompanies: number;
  tagCounts: TagWithCount[];
  summary: Summary;
}

@Injectable({providedIn: 'root'})
export class SearchResultsResolver  {
  constructor(
    private _searchService: SearchService,
    private _deploymentContext: DeploymentContext
  ) {}

  public async resolve(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Promise<SearchResolverResult> {
    const searchServiceFilter = this._searchService.filter;

    // ADJQ-187: This was a quick approach to deep linking to the set of companies for a location, per Chris Dubois' request
    // ADJQ-777: The location/ & filter/ routes are now deprecated, and their use discouraged, but we keep them around
    //           for backwards compatibility with any existing links in various JnJ material that may be in use.
    const location: string = route.params['location'];
    if (location) {
      searchServiceFilter.locations = location.split('+').map((l) => l.trim());
    }
    // Similar to the rationale for ADJQ-187, but could potentially support multiple filters
    const filter: string = route.params['filter'];
    if (filter) {
      searchServiceFilter[filter.trim()] = true;
    }

    if (!location && !filter) {
      if (route.queryParams['text']) {
        const predicate: string = decodeURIComponent(route.queryParams['text']);
        this._searchService.currentSearchPredicate = predicate;
      }

      const partialFilter = {};
      Object.keys(route.queryParams)
        .filter((qp) => qp !== 'text')
        .map((queryParam) => {
          const queryParamValue = route.queryParams[queryParam];
          if (Array.isArray(searchServiceFilter[queryParam])) {
            const queryParamValueAsArray = decodeURIComponent(
              queryParamValue
            ).split(this._searchService.ARRAY_QUERY_PARAM_DELIMITER);
            partialFilter[queryParam] = queryParamValueAsArray;
          } else {
            if (queryParamValue === 'false') {
              partialFilter[queryParam] = false;
            } else if (queryParamValue === 'true') {
              partialFilter[queryParam] = true;
            } else {
              partialFilter[queryParam] = decodeURIComponent(queryParamValue);
            }
          }
        });
      // Merge the query string's filter args in with the current filter
      _.merge(searchServiceFilter, partialFilter);

      // Remove unsupported search terms explicitly added but which are not allowed:
      this.removeDisabledOptions(searchServiceFilter);
    }

    const fetchSize =
      route.routeConfig.path === `login/debug`
        ? this._deploymentContext.comprehensiveSummary.numberOfCompanies
        : this._searchService.initialFetchSize;

    const { companies, totalCompaniesCount: totalCompanies } =
      await this._searchService.getCompanies(0, fetchSize);
    const tagCounts = await this._searchService.getTagCounts();
    const summary = this._deploymentContext.comprehensiveSummary;

    return {
      companies,
      totalCompanies,
      tagCounts,
      summary,
    };
  }

  public removeDisabledOptions(searchServiceFilter: Filter): void {
    const featureSwitches = this._deploymentContext.featureSwitches;
    if (!featureSwitches.enableBlueKnight) {
      delete searchServiceFilter.isBlueKnight;
    }
    if (!featureSwitches.enableQfc) {
      delete searchServiceFilter.isQFCWinner;
    }
    if (!featureSwitches.enableSectors) {
      delete searchServiceFilter.isCrossSector;
      searchServiceFilter.primarySectors = [];
      searchServiceFilter.primarySubSectors = [];
      searchServiceFilter.secondarySectors = [];
      searchServiceFilter.secondarySubSectors = [];
    }
    if (!featureSwitches.enableJPAL) {
      delete searchServiceFilter.isMyJPALCompaniesOnly;
    }
    if (!featureSwitches.enableFollow) {
      delete searchServiceFilter.isFollowedCompaniesOnly;
    }
  }
}
