<div class="cf_overview_summary" *ngIf="shouldShowSummary">
  <div class="cf_overview_summary-container c_left">
    <div class="cf_overview_summary-container-tile companies" (click)="handleClick(DrilldownTypes.Count)">
      <div class="cf_overview_summary-container-tile-content companies">
          <div class="cf_overview_summary-container-tile-content-count companies-count" (mouseenter)="showTotalTooltip()" (mouseleave)="hideTotalTooltip()">
            <div>{{currentCompanyCountDisplay}}<span class="cf_overview_summary-container-tile-content-count-denominator" *ngIf="!showingAllCompanies">/{{totalCompaniesCountDisplay}}</span></div>
            <div class="cf_overview_summary-container-tile-content-count-tooltip" *ngIf="isShowingTotalTooltip">
                {{Loc(LocalizedTextIds.SummaryGlad)}}
            </div>
        </div>
        <div class="cf_overview_summary-container-tile-content-label">{{Loc(LocalizedTextIds.SummaryJLABSCompanies)}}</div>
      </div>
    </div>
  </div>
  <div class="cf_overview_summary-container c_middle" *ngIf="featureSwitches.enableSectors">
    <div class="cf_overview_summary-container-tile" *ngFor="let sector of _deploymentContext.sectorNames; let i = index" (click)="handleClick(DrilldownTypes.Sector, sector)">
      <div class="cf_overview_summary-container-tile-content">
        <div class="cf_overview_summary-container-tile-content-count">{{summary.companyCountBySector(sector)}}</div>
        <div class="cf_overview_summary-container-tile-content-label">{{LocWithPrefix(sector, LocPrefix.SectorShortName)}}</div>
      </div>
    </div>
    <ng-container *ngIf="shouldShowCrossSector">
      <div class="cf_overview_summary-container-tile-separator left"></div>
      <div class="cf_overview_summary-container-tile cross-sector" (click)="handleClick(DrilldownTypes.CrossSector)">
        <div class="cf_overview_summary-container-tile-content cross-sector">
          <div class="cf_overview_summary-container-tile-content-count cross-sector">{{summary.numberOfCrossSectorCompanies}}</div>
          <div class="cf_overview_summary-container-tile-content-label">{{Loc(LocalizedTextIds.SummaryCrossSector)}}</div>
        </div>      
      </div>
    </ng-container>
    <div *ngIf="shouldShowRightSeparator" 
    class="cf_overview_summary-container-tile-separator right"></div>
  </div>
  <div class="cf_overview_summary-container c_right" *ngIf="shouldShowRightContainer">
    <div *ngIf="shouldShowNewCompanyCount" class="cf_overview_summary-container-tile" (click)="handleClick(DrilldownTypes.New)">
      <div class="cf_overview_summary-container-tile-content left">
        <div class="cf_overview_summary-container-tile-content-count">{{summary.numberOfNewCompanies}}</div>
        <div class="cf_overview_summary-container-tile-content-label">{{Loc(LocalizedTextIds.New)}}</div>
      </div>
    </div>
    <div  *ngIf="shouldShowShowStatusCount" 
      class="cf_overview_summary-container-tile"
      (mouseenter)="showTileTooltip()"
      (mouseleave)="hideTileTooltip()"
      (click)="handleClick(DrilldownTypes.Status)"
    >
      <div class="cf_overview_summary-container-tile-content">
        <div class="cf_overview_summary-container-tile-content-count special">{{numberOfResidentsForStatusTile}}
          <div class="cf_overview_summary-container-tile-content-count-tooltip tile" *ngIf="isShowingTileTooltip">
            {{tileTooltip}}
          </div>
        </div>
        <div class="cf_overview_summary-container-tile-content-label">{{tileLabel}}</div>        
      </div>
    </div>
    <div *ngIf="shouldShowQfcCount" class="cf_overview_summary-container-tile" (click)="handleClick(DrilldownTypes.QfcWinner)">
      <div class="cf_overview_summary-container-tile-content">
        <div class="cf_overview_summary-container-tile-content-count special">{{summary.numberOfQfcWinners}}</div>
        <div class="cf_overview_summary-container-tile-content-label">{{Loc(LocalizedTextIds.QFCAwardees)}}</div>
      </div>
    </div>
    <div *ngIf="shouldShowBlueKnightCount" class="cf_overview_summary-container-tile" (click)="handleClick(DrilldownTypes.BlueKnight)">
      <div class="cf_overview_summary-container-tile-content">
        <div class="cf_overview_summary-container-tile-content-count special">{{summary.numberOfBlueKnights}}</div>
        <div class="cf_overview_summary-container-tile-content-label">{{Loc(LocalizedTextIds.BlueKnight)}}</div>
      </div>
    </div>
  </div>
</div>
