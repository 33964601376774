<div
  class="cf_location-indicator"
  [ngClass]="positionAndSizeClasses()"
  [ngStyle]="ngStyle"
  *ngIf="locationMarker"
>
  <div class="cf_location-indicator-marker"
    touchTooltip
    (click)="handleClick()"
    (wheel)="redirectWheelEvent($event)"
    (showTooltip)="showTooltip($event)"
    (hideTooltip)="hideTooltip()">
    <svg xmlns="http://www.w3.org/2000/svg" 
      attr.width="{{diameter}}" 
      attr.height="{{diameter}}" 
      attr.viewBox="{{viewBox}}">
      <circle 
        cx="0"
        cy="0"
        [attr.r]="radius" 
        [attr.fill]="background"
        [attr.fill-opacity]="opacity">
      </circle>
      <g *ngFor="let segment of segments" stroke="none" attr.fill="{{segment.color}}">
        <path attr.d="M{{segment.pt1}} A{{radius}} {{radius}} 0 {{segment.largeArc}} 1 {{segment.pt2}} L{{segment.pt3}} A{{innerRadius}} {{innerRadius}} 0 {{segment.largeArc}} 0 {{segment.pt4}} L{{segment.pt1}} Z"/>
      </g>
      <text x="0" y="0" font-family="var(--map-marker-font-family)"
        attr.font-size="{{fontSize}}" text-anchor="middle" alignment-baseline="central" attr.fill="{{textColor}}">{{labelString}}
      </text>
    </svg>
  </div>
</div>
