<ng-template #categorizedListMenuStructureTemplate let-dropdownMenuComponent="dropdownMenuComponent">
    <sector-selector
        [includeCounts]="false"
        [(criteria)]="criteria"
        (criteriaChange)="relaySelect($event)"
        [optionData]="sectorData"
        [isPrimary]="isPrimary"
        [showFilter]="true"
    ></sector-selector>
</ng-template>

<dropdown-menu 
    class="sectors"
    label="{{label}}"
    [menuStructureTemplate]="categorizedListMenuStructureTemplate">
</dropdown-menu>