import { LogLevel } from './log-level.js';

export class Logger {
  private _namespace: string;
  private _level: LogLevel = LogLevel.debug;

  constructor(namespace?: string) {
    // set the namespace
    this._namespace = namespace ? namespace : 'default';
  }

  public log(msg: any, ...params: any[]): void {
    msg = this.formatMessage(msg);
    if (this._level <= LogLevel.debug) {
      this.logToConsole(console.log, msg, ...params);
    }
  }

  public info(msg: any, ...params: any[]): void {
    msg = this.formatMessage(msg);
    if (this._level <= LogLevel.info) {
      this.logToConsole(console.log, msg, ...params);
    }
  }

  public warn(msg: any, ...params: any[]): void {
    msg = this.formatMessage(msg);
    if (this._level <= LogLevel.warn) {
      this.logToConsole(console.warn, msg, ...params);
    }
  }

  public error(error: any, ...params: any[]): void {
    const msg = this.formatMessage(error);

    if (this._level <= LogLevel.error) {
      this.logToConsole(console.error, msg, ...params);
    }
  }

  private formatMessage(msgObj: any): string {
    const msgStr =
      typeof msgObj === 'string'
        ? msgObj
        : this.formatMessage(JSON.stringify(msgObj));
    return `${new Date()} - ${this._namespace} - ${msgStr}`;
  }

  private logToConsole(
    // eslint-disable-next-line @typescript-eslint/ban-types
    consoleFunc: Function,
    msg: string,
    ...params: any[]
  ): void {
    if (params?.length === 0) {
      consoleFunc(msg);
    } else {
      consoleFunc(msg, params);
    }
  }
}
