<div class="company-download" [ngClass]="{ 'follow-not-enabled': !isFollowEnabled }">
    <div class="button" [ngClass]="{'menu-open' : showMenu}" (click)="toggleDownloadMenu($event)">
        <div class="label" [ngClass]="{'menu-open' : showMenu}">
            {{Loc(LocalizedTextIds.Download)}}</div>
        <div class="icon" [ngClass]="{'menu-open' : showMenu}"></div>
    </div>
    <div class="menu" *ngIf="showMenu">
        <div class="item" (click)="downloadPdf()">
            <div class="icon-pdf"></div>
            <div class="label">{{Loc(LocalizedTextIds.DownloadPDF)}}</div>
        </div>
        <div class="item" (click)="downloadWordDocument()"
            *ngIf="isInternalView">
            <div class="icon-word"></div>
            <div class="label">{{Loc(LocalizedTextIds.DownloadWord)}}</div>
        </div>
    </div>
</div>