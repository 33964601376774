<div class="cf_breadcrumbs cf_breadcrumbs-outer" *ngIf="isShowingBreadcrumbs">
    <div *ngFor="let breadcrumb of breadcrumbs; index as i" (click)="performBreadcrumbAction(breadcrumb)">
        <div class="cf_breadcrumbs-item" [ngClass]="{'cf_breadcrumbs-parent': i !== breadcrumbs.length - 1}">
            <div class="cf_breadcrumbs-breadcrumb">
                <div class="cf_breadcrumbs-breadcrumb-name">
                    <div [ngClass]="{'cf_breadcrumbs-breadcrumb-name-explore' : i === 0}">{{breadcrumb.name}}</div>
                </div>
            </div>
            <div class="cf_breadcrumbs-breadcrumb" *ngIf="i !== breadcrumbs.length - 1">
                <img src="{{separatorIconPath}}" class="cf_breadcrumbs-separator"/>
            </div>
        </div>
    </div>
</div>
