import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ComponentBase } from '@Common';
import {
  LocalizedTextIds,
  LocPrefix,
  VisualizationType,
} from 'company-finder-common';
import { ApplicationContext } from '@Common';
import { DeploymentContext } from '@Common';
import { WebAnalyticsService } from '@Common';
import { LocationIndicatorComponent } from './components/location-indicator/location-indicator.component.js';
import _ from 'lodash-es';

import {
  LocationGroup,
  LocationInfo,
} from '@Common';
import { Summary } from '@Common';
import { Location } from 'company-finder-common';
import { TooltipHandler } from './models/tooltip-handler.js';

@Component({
    selector: 'explore',
    templateUrl: './explore.component.html',
    styleUrls: ['./explore.component.scss'],
    standalone: false
})
export class ExploreComponent
  extends ComponentBase
  implements OnChanges, OnInit
{
  // public properties
  public static readonly SMALL_INDICATOR_THRESHOLD: number = 1 / 3;
  public static readonly MEDIUM_INDICATOR_THRESHOLD: number = 2 / 3;
  public locationInfos = new LocationGroup();
  public virtualLocationInfo: LocationGroup;
  @Input()
  public summary: Summary;
  public title = this.Loc(LocalizedTextIds.ExploreCompanies);
  public VisualizationTypes = VisualizationType;
  public visualizationType: VisualizationType;

  public tooltipHandler: TooltipHandler;

  public constructor(
    dc: DeploymentContext,
    private _applicationContext: ApplicationContext,
    private _webAnalyticsService: WebAnalyticsService
  ) {
    super(dc);

    this.visualizationType =
      this._applicationContext.activeVisualizationType ||
      this._deploymentContext.initialVisualization;

    this.tooltipHandler = {
      locationIndicator: null,
      left: 0,
      bottom: 0,
      markerElement: null,
      show: (locationIndicator: LocationIndicatorComponent) => {
        this.registerWindowHandlerSubscription('click', (_ev) =>
          this.tooltipHandler.hide()
        );
        this.tooltipHandler.locationIndicator = locationIndicator;
      },
      hide: () => {
        this.tooltipHandler.locationIndicator = undefined;
        this.unregisterWindowHandlerSubscription('click');
      },
      reposition: () => {
        const locIndicator = this.tooltipHandler.locationIndicator;
        if (locIndicator) {
          locIndicator.positionTooltip();
        }
      },
    };
  }

  // public getters
  public get isSector(): boolean {
    return this.visualizationType === VisualizationType.Sector;
  }

  public get isTags(): boolean {
    return this.visualizationType === VisualizationType.Tags;
  }

  public get isGeography(): boolean {
    return this.visualizationType === VisualizationType.Geography;
  }

  public get tooltipStyle(): Partial<CSSStyleDeclaration> {
    return {
      left: `${this.tooltipHandler.left}px`,
      bottom: `${this.tooltipHandler.bottom}px`,
    };
  }

  // public methods
  public async ngOnChanges(): Promise<void> {
    if (!this.summary) {
      return;
    }

    // Reset the markers prior to calculations
    this.locationInfos = new LocationGroup();
    this.virtualLocationInfo = new LocationGroup();

    this.summary.locations
      .map((location) => this.getLocationInfo(location))
      .forEach((info) => {
        if (!info.location.excludeFromMap) {
          this.locationInfos.push(info);
        } else {
          this.virtualLocationInfo.push(info);
        }
      });
  }

  public ngOnInit(): void {
    // Note: When entering this component from a different route, ngOnChanges is called with an undefined Summary
    //  then ngOnInit, and then ngOnChanges is called as a result of having a Summary object provided from a service call.
    //  When toggling between Explore and Search Results, going back to Explore triggers ngOnChanges WITH Summary
    //  data prior to the ngOnInit call, and then not again.  So, don't attempt to initialize variables here that might
    //  otherwise step over the previous data manipultion that might have already been done in ngOnChanges.
  }

  public sectorShortName(sector: string): string {
    return this.LocWithPrefix(sector, LocPrefix.SectorShortName);
  }

  private get sectorsAreConfigured(): boolean {
    return this._deploymentContext.sectorNames.length > 0;
  }

  private getLocationInfo(location: Location): LocationInfo {
    const sectors = this.sectorsAreConfigured
      ? this._deploymentContext.sectorNames
      : [null]; // If no sectors, group all into one "sector" with no name

    const sectorCounts = sectors.map(
      (sector) =>
        this.summary.numberOfCompaniesAtLocationBySector(
          location?.name,
          sector
        ) || 0
    );
    return {
      location,
      sectorCounts: this.sectorsAreConfigured
        ? sectorCounts
        : [_.sum(sectorCounts)],
    };
  }

  public setVisualizationType(visualizationType: VisualizationType): void {
    this.visualizationType = this._applicationContext.activeVisualizationType =
      visualizationType;
    this._webAnalyticsService.trackEvent('select-visualization', {
      label: this.visualizationType,
    });
  }
}
