<picker-modal
    (action)="doAction($event)"
    (select)="doSelect($event)"
    (wizardAction)="doWizardAction($event)"
    [user]="user"
    [totalMatchedCompanies]="totalMatchedCompanies"
    [(showModal)]="showModal"
    modalTitle={{Loc(LocalizedTextIds.SectorPickerModalAddSectors)}}
    blurb={{Loc(LocalizedTextIds.SectorPickerModalTheseSectors)}}
    objectsTypeName="sectors"
    filterPlaceholder="{{Loc(LocalizedTextIds.FilterListBySector, '')}}"
    [compareFunc]="compareSectors"
    [filterFunc]="doFilter"
    [itemVisibilityFunc]="isVisible"
    [isOnBoardingWizard]="isOnBoardingWizard"
    [wizardStep]="0">
    <ng-template #itemTemplate let-sectorAndCount="item">
        <div class="item-name-and-company-count" [ngClass]="{'is-sub-sector': !!sectorAndCount.parentSector}">
            <div class="item-name-expander-and-company-name">
                <button class="child-toggle" [ngClass]="{ 'open': openChildOptions[sectorAndCount.sector] }"
                    *ngIf="!sectorAndCount.parentSector" (click)="toggleOpenChildOptions(sectorAndCount.sector)"></button>
                <div class="item-name selected-text-color">{{sectorAndCount.sector}}</div>
            </div>
            <div class="company-count">{{LocPluralize(LocalizedTextIds.CompanyCount, sectorAndCount.count, sectorAndCount.count)}}</div>
        </div>
    </ng-template>
</picker-modal>
