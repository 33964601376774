<company-update-modal
  modalTitle={{Loc(LocalizedTextIds.Deal)}}
  [isItemDeleted]="deal.isDeleted"
  [isItemNew]="!deal.dealId"
  [isSaveDisabled]="!isSaveEnabled"
  [showModal]="showModal"
  [maxHeight]="modalMaxHeight"
  [heightPercent]="modalHeightPercent"
  [updateStatus]="dealUpdateStatus"
  (action)="handleAction($event)">
  <div class="scroll-area" [ngClass]="{ 'approved-is-jnj': isApprovedDeal && deal.isJnjDeal, 'approved-not-jnj': isApprovedDeal && !deal.isJnjDeal }">
    <form [formGroup]="dealForm" class="fields"
      [ngClass]="{ 'deleted': deal.isDeleted, 'expanded': isApprovedDeal && !deal.isJnjDeal }">      
      <div class="field-row" [ngClass]="{ 'expanded': isApprovedDeal && !deal.isJnjDeal }">
        <div>
          <div class="label">
            <div class='text'
              [ngClass]="{ 'modified': selfUpdateMode === SelfUpdateModes.Review && hasPropertyBeenModified('dealParty')}"
              *ngIf="!deal.isJnjDeal"> {{Loc(LocalizedTextIds.DealModalDealParty)}}</div>
            <div class='text' *ngIf="deal.isJnjDeal"> {{Loc(LocalizedTextIds.DealModalJJDivision)}}</div>
            <div class="tooltip" (mouseenter)="showTooltip('party')" (mouseleave)="hideTooltip('party')">
              <div class="bubble below" *ngIf="isShowingTooltip['party']">
                {{Loc(LocalizedTextIds.DealModalOrganizationTheDeal)}}</div>
            </div>
          </div>
          <input 
            id="deal-modal-dealParty"
            name="deal-modal-dealParty"
            formControlName="dealParty"
            maxlength="255">
        </div>
        <div>
          <div class="label">
            <div class='text'
              [ngClass]="{ 'modified': selfUpdateMode === SelfUpdateModes.Review && hasPropertyBeenModified('dealType')}">
              {{Loc(LocalizedTextIds.DealModalDealType)}}
            </div>
          </div>
          <select-input *ngIf="!deal.isDeleted" name="deal-modal-dealType"
            [formControl]="dealForm.controls['dealType']" variant="update" placeholder={{Loc(LocalizedTextIds.DealModalSelectType)}}
            [options]="dealTypes" [getSerializedValue]="getReferenceValueDisplayString">
          </select-input>
          <div class="deleted-select" *ngIf="deal.isDeleted">{{deal.dealType}}</div>
        </div>
      </div>
      <div class="field-row" [ngClass]="{ 'expanded': isApprovedDeal && !deal.isJnjDeal }" *ngIf="!deal.isJnjDeal">
        <div>
          <div class="label">
            <div class='text'
              [ngClass]="{ 'modified': selfUpdateMode === SelfUpdateModes.Review && hasPropertyBeenModified('contingentAmount')}">
              {{Loc(LocalizedTextIds.DealModalContingentFunding)}}
            </div>
            <div class="tooltip" (mouseenter)="showTooltip('contingent')" (mouseleave)="hideTooltip('contingent')">
              <div class="bubble" *ngIf="isShowingTooltip['contingent']">
                {{Loc(LocalizedTextIds.DealModalDollarsThatAreContingent)}}</div>
            </div>
          </div>
          <input 
            type="number"
            min="0"
            oninput="validity.valid||(value=Math.abs(value))"
            (keydown.arrowup)="false"
            (keydown.arrowdown)="false"
            id="deal-modal-contingentAmount"
            name="deal-modal-contingentAmount"
            formControlName="contingentAmount">
        </div>
        <div>
          <div class="label">
            <div class='text'
              [ngClass]="{ 'modified': selfUpdateMode === SelfUpdateModes.Review && hasPropertyBeenModified('amount')}">
              {{Loc(LocalizedTextIds.DealModalSecureFunding)}}
            </div>
            <div class="tooltip" (mouseenter)="showTooltip('secured')" (mouseleave)="hideTooltip('secured')">
              <div class="bubble" *ngIf="isShowingTooltip['secured']">
                {{Loc(LocalizedTextIds.DealModalDollarsThatAreGuaranteed)}}</div>
            </div>
          </div>
          <input type="number"
            min="0"
            oninput="validity.valid||(value=Math.abs(value))"
            (keydown.arrowup)="false"
            (keydown.arrowdown)="false"
            id="deal-modal-amount"
            name="deal-modal-amount"
            formControlName="amount">
        </div>
      </div>
      <div *ngIf="deal.isJnjDeal" class="note">
        <img src="/assets/update/folder-cash-1.svg" />
        <div class="note-text">{{Loc(LocalizedTextIds.DealModalNoNeedToEnter)}}</div>
      </div>
      <div class="field-row" [ngClass]="{ 'expanded': isApprovedDeal && !deal.isJnjDeal }">
        <div>
          <div class="label">
            <div class='text'
              [ngClass]="{ 'modified': selfUpdateMode === SelfUpdateModes.Review && hasPropertyBeenModified('announcementDate')}">
              {{Loc(LocalizedTextIds.DealModalAnnoucementDate)}}
            </div>
            <div class="tooltip" (mouseenter)="showTooltip('date')" (mouseleave)="hideTooltip('date')">
              <div class="bubble" *ngIf="isShowingTooltip['date']">
                {{Loc(LocalizedTextIds.DealModalDateOrApproximate)}}</div>
            </div>
          </div>
          <div class='date-wrapper'>
            <!--
              Browsers manage the Date's yyyy entry differently, but they seem to need a max value to at least behave similarly.
              The change handler prevents Firefox from seeing a > 4-digit date as an "Invalid Date" which seemed to cause ADJQ-936.
            -->
            <input class='date' type="date" id="deal-modal-announcementDate" name="deal-modal-announcementDate"
              formControlName="announcementDate" *ngIf="!deal.isDeleted"
              (ngModelChange)="handleDateChange()"
              [ngClass]="{ 'no-value': !deal.announcementDate }"
              max="9999-12-31">
            <div class='date' *ngIf="deal.isDeleted">{{deal.announcementDate | date:'mediumDate' : 'GMT'}}</div>
            <div class="open-button"></div>
          </div>
        </div>
        <div *ngIf="!deal.isJnjDeal">
          <div class="label">
            <div class='text'
              [ngClass]="{ 'modified': selfUpdateMode === SelfUpdateModes.Review && hasPropertyBeenModified('amountCurrency')}">
              {{Loc(LocalizedTextIds.Currency)}}
            </div>
          </div>
          <select-input *ngIf="!deal.isDeleted" name="deal-modal-amountCurrency"
            [formControl]="dealForm.controls['amountCurrency']" variant="update" placeholder={{Loc(LocalizedTextIds.DealModalSelectCurrency)}}
            [options]="currencyTypes" [getSerializedValue]="getReferenceValueDisplayString">
          </select-input>
          <div class="deleted-select" *ngIf="deal.isDeleted">{{deal.amountCurrency}}</div>
        </div>
      </div>
      <div class="comments-row">
        <div>
          <div class="label">
            <div class='text'
              [ngClass]="{ 'modified': selfUpdateMode === SelfUpdateModes.Review && hasPropertyBeenModified('comments')}">
              {{Loc(LocalizedTextIds.DealModalDealComments)}}
            </div>
          </div>
          <input           
            class="comments"
            id="deal-modal-comments"
            name="deal-modal-comments"
            formControlName="comments"
            maxlength="32768">
        </div>
        <div class="public">
          <input type="checkbox" class="checkbox" id="deal-modal-isConfidential" name="deal-modal-isConfidential"
            formControlName="isConfidential" />
          <label for="deal-modal-isConfidential" class="public-label"
            [ngClass]="{ 'modified': selfUpdateMode === SelfUpdateModes.Review && hasPropertyBeenModified('isConfidential')}">
            {{Loc(LocalizedTextIds.DealModalThisInformation)}}
          </label>
        </div>
      </div>
    </form>
  </div>
</company-update-modal>
