<div class="select-wrapper" [ngClass]="cssClass">
  <select [id]="name" [name]="name" [ngClass]="{ 'no-selection': noSelection, 'full-width': fullWidth }" [(ngModel)]="value" [disabled]="disabled"
    (blur)="onTouched(); inFocus = false" (focus)="inFocus = true">
    <option *ngIf="placeholder" disabled [ngValue]="null" class="disabled">
      {{placeholder}}
    </option>
    <option *ngFor="let item of options; trackBy:getSerializedValue;" [ngValue]="getSerializedValue(item)">
      {{getSerializedValue(item)}}
    </option>
  </select>
</div>
