<div class="cf_preferences">
  <search-bar
    [searchOnFilterChange]="false"
    [navigateToResultsOnSearch]="true"
  >
  </search-bar>

  <sectors-picker-modal
    [showModal]="showSectorsPickerModal"
    [isOnBoardingWizard]="isOnBoardingWizard"
    [user]="user"
    [totalMatchedCompanies]="matchedTotal"
    (action)="addSectors($event)"
    (wizardAction)="handleWizardAction(WizardStep.Sectors)"
  >
  </sectors-picker-modal>

  <companies-picker-modal
    [showModal]="showCompaniesPickerModal"
    [isOnBoardingWizard]="isOnBoardingWizard"
    [user]="user"
    [totalMatchedCompanies]="matchedTotal"
    (action)="addCompanies($event)"
    (wizardAction)="handleWizardAction(WizardStep.Companies)"
  >
  </companies-picker-modal>

  <locations-picker-modal
    [showModal]="showLocationsPickerModal"
    [isOnBoardingWizard]="isOnBoardingWizard"
    [user]="user"
    [totalMatchedCompanies]="matchedTotal"
    (action)="addLocations($event)"    
    (wizardAction)="handleWizardAction(WizardStep.Locations)"
  >
  </locations-picker-modal>

  <notifications-modal
    [showModal]="showPreferencesModal"
    [userObj]="_user"
    (start)="getFieldOptions($event)"
    (close)="closePreferences()"
  >
  </notifications-modal>

  <saved-search-modal
    [defaultSearchName]="defaultSearchName"
    [search]="savedSearchToEdit"
    (add)="addSavedSearch($event)"
    (delete)="deleteSavedSearch($event)"
  >
  </saved-search-modal>

  <div class="cf_preferences-content">
    <div class="preferences-header">
      <div class="title">
        <a class="back-link" routerLink="/my-updates"
          ><span class="back-arrow">←</span>&nbsp;{{Loc(LocalizedTextIds.PreferencesBackToMy)}}</a
        >
        <h2 class="screen-title">{{Loc(LocalizedTextIds.Preferences)}}</h2>
      </div>
    </div>
    <div class="notifications">
      <div class="title">
        <div>{{Loc(LocalizedTextIds.Notifications)}}</div>
        <div class="edit" (click)="showPreferences()">+ {{Loc(LocalizedTextIds.Edit)}}</div>
      </div>
      <div class="knobs">
        <div class="left">
          <div class="knob">
            <div>{{Loc(LocalizedTextIds.NotificationsModalJLABSNavigator)}}:</div>
            <div class="value">{{userEmailFrequency}}</div>
          </div>
          <div class="knob">
            <div>{{Loc(LocalizedTextIds.NotificationsModalCompanyProvided)}}</div>
            <div class="value">{{companyProvidedUpdates ? Loc(LocalizedTextIds.On) : Loc(LocalizedTextIds.Off)}}</div>
          </div>
        </div>
        <div>
          <div *ngIf="featureSwitches.enableNews" class="knob">
            <div>{{Loc(LocalizedTextIds.NotificationsModalJLABSCompaniesIn)}}:</div>
            <div class="value">{{inTheNewsUpdates ? Loc(LocalizedTextIds.On) : Loc(LocalizedTextIds.Off)}}</div>
          </div>
          <div class="knob">
            <div>{{Loc(LocalizedTextIds.NotificationsModalNewCompanies)}}:</div>
            <div class="value">{{newCompaniesUpdates ? Loc(LocalizedTextIds.On) : Loc(LocalizedTextIds.Off)}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="build">
      <div class="title">{{Loc(LocalizedTextIds.PreferencesBuildYourFollowing)}}</div>
      <div class="subtitle">{{LocPluralize(LocalizedTextIds.PreferencesYoullReceive, countOfCompaniesForWhichYoullSeeUpdates, countOfCompaniesForWhichYoullSeeUpdates)}}</div>
      <div class="info">{{Loc(LocalizedTextIds.PreferencesThisNumber)}}</div>
    </div>

    <preferences-section
      sectionTitle={{Loc(LocalizedTextIds.MyUpdatesSectorsSubSectors)}}
      *ngIf="featureSwitches.enableSectors"
      (addItem)="showAddSectors()"
    >
      <ng-container *ngIf="sectorsFollowed && sectorsFollowed.length">
        <mutable-collection
          [items]="sectorsFollowed"
          [displayNameProperty]="sectorToDisplayString"
          (removeItem)="deleteSector($event)"
        >
        </mutable-collection>
      </ng-container>
    </preferences-section>
    <ng-template *ngIf="featureSwitches.enableSectors && !(sectorsFollowed && sectorsFollowed.length)">
      <div class="no-items">{{Loc(LocalizedTextIds.PreferencesNoSectors)}}</div>
    </ng-template>

    <preferences-section
      sectionTitle={{Loc(LocalizedTextIds.Companies)}}
      (addItem)="showAddCompanies()"
    >
      <ng-template #companyItem let-company>
        <div class="company-logo icon-with-overlay">
          <div class="icon-overlay-top-right">
            <div *ngIf="!company?.isJPAL" class="remove-icon" (click)="deleteCompany(company)">
              <img src="/assets/common/close-white-on-gradient-strong.svg"/>
            </div>
            <img *ngIf="company?.isJPAL" class="is-jpal" src="/assets/common/Group-2.svg"/>
          </div>
          <company-logo [company]="company" [size]="logoSize"></company-logo>
          <div class="company-name">{{ company?.name }}</div>
        </div>
      </ng-template>
      <ng-container *ngIf="companiesFollowed && companiesFollowed.length; else noCompaniesAdded">
        <mutable-collection
          [items]="companiesFollowed"
          [itemTemplate]="companyItem"
        ></mutable-collection>
      </ng-container>
      <div class="checkbox-controls">
        <div class="control" *ngIf="featureSwitches.enableJPAL">
          <input
            type="checkbox"
            class="checkbox company-preferences"
            id="includeJpalCompanies"
            name="includeJpalCompanies"
            [(ngModel)]="followCompaniesImResponsibleFor" />
          <label for="includeJpalCompanies">
            {{Loc(LocalizedTextIds.PreferencesAutomaticallyInclude)}}
          </label>
        </div>
        <div class="control" *ngIf="featureSwitches.enableBlueKnight">
          <input
            type="checkbox"
            class="checkbox company-preferences"
            id="blueKnight"
            name="blueKnight"
            [(ngModel)]="followBlueKnightCompanies" />
          <label class="bk-label" for="blueKnight">
            {{Loc(LocalizedTextIds.BlueKnightTm)}}
          </label>
        </div>
      </div>
    </preferences-section>
    <ng-template #noCompaniesAdded>
      <div class="no-items">{{Loc(LocalizedTextIds.PreferencesNoCompanies)}}</div>
    </ng-template>

    <preferences-section
      sectionTitle={{Loc(LocalizedTextIds.Locations)}}
      (addItem)="showAddLocations()"
    >
      <ng-container *ngIf="locationsFollowed && locationsFollowed.length; else noLocationsAdded">
        <mutable-collection
          [items]="locationsFollowed"
          (removeItem)="deleteLocation($event)"
        ></mutable-collection>
      </ng-container>
    </preferences-section>
    <ng-template #noLocationsAdded>
      <div class="no-items">{{Loc(LocalizedTextIds.PreferencesNoLocations)}}</div>
    </ng-template>

    <preferences-section
      sectionTitle={{Loc(LocalizedTextIds.SavedSearches)}}
      (addItem)="createSavedSearch()"
    >
      <ng-template #savedSearchItem let-savedSearch>
        <div class="preferences-saved-search">
          <saved-search-summary
            [viewType]="'summary'"
            (editSavedSearch)="editSavedSearch(savedSearch)"
            [search]="savedSearch"
            [searchTitle]="savedSearch?.name"
          >
          </saved-search-summary>
        </div>
        <div class="dashed-separator"></div>
      </ng-template>
      <ng-container *ngIf="searchesFollowed && searchesFollowed.length; else noSearchQueriesAdded">
        <div class="saved-search-blurb">{{Loc(LocalizedTextIds.PreferencesGetNotified)}}</div>
        <mutable-collection
          [items]="searchesFollowed"
          [itemTemplate]="savedSearchItem"
          displayType="vertical"
        ></mutable-collection>
      </ng-container>
    </preferences-section>
    <ng-template #noSearchQueriesAdded>
      <div class="no-items">{{Loc(LocalizedTextIds.PreferencesNoSearchQueries)}}</div>
    </ng-template>
  </div>
</div>
