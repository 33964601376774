import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ReviewNotification } from 'company-finder-common';
import { ComponentBase, DeploymentContext, LogoSize} from '@Common';

@Component({
    selector: 'review-modal',
    templateUrl: './review-modal.component.html',
    styleUrls: ['./review-modal.component.scss'],
    standalone: false
})
export class ReviewModalComponent extends ComponentBase {
  @Input()
  public showModal: boolean;

  @Input()
  public reviews: ReviewNotification[];

  public closingSubject = new Subject();

  public size = LogoSize.Medium;

  public constructor(dc: DeploymentContext, private _router: Router) {
    super(dc);
  }

  // public methods
  public close(): void {
    this.closingSubject.next(undefined); // TODO CAB
  }

  public formatDate(date: Date): string {

    // TODO CAB - double check formats
    const datePart = date.toLocaleDateString("en-us", {month: "short", day: "numeric", year: "numeric"});    
    const timePart = date.toLocaleDateString("en-us", {hour: "numeric", minute: "numeric", hour12: true});
    return datePart + ' at ' + timePart;
  }

  public goToCompanyUpdate(companyUrlName: string): void {
    this.close();
    this._router.navigate(['company', 'update', companyUrlName]);
  }

  public get hasReviews(): boolean {
    return this.reviews?.length > 0;
  }
}
