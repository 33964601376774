import { Component } from '@angular/core';
import { AuthnService } from '@Common';
import { ComponentBase } from '@Common';
import { DeploymentContext } from '@Common';

@Component({
    selector: 'disclaimer',
    templateUrl: './disclaimer.component.html',
    styleUrls: ['./disclaimer.component.scss'],
    standalone: false
})
export class DisclaimerComponent extends ComponentBase {
  public constructor(
    dc: DeploymentContext,
    private _authnService: AuthnService
  ) {
    super(dc);
  }

  public isAuthenticated(): boolean {
    return this._authnService.isAuthenticated;
  }

  public get jlabsUrl(): string {
    return this._deploymentContext.hostingSiteUrl;
  }
}
