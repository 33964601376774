<div class="edit-item" 
  [ngClass]="{ 'review': hasReviewableField, 'dim': isInactive, 'bordered': true}"
  [hidden]="hidden">
  <div *ngIf="required" class="required">*{{Loc(LocalizedTextIds.Required)}}</div>
  <div class="edit-item-inner" [ngClass]="{ 'editable': isEditingThisProperty }">
    <div class="flag-and-label-aligner">
      <div *ngIf="showFlagIcon" class="flag-icon"></div>
      <div class="alignBox">
        <div>
          <label
              class="label"
              for="{{id}}"
              [ngClass]="propertyDecorationClass(true)">
              {{labelText}}
          </label>
        </div>
        <div class="labelDetail">{{labelDetail}}</div>
      </div>
    </div>
    <div class="edit-item-inner-input-container" [formGroup]="parentForm">
      <ng-container *ngIf="type === EditItemType.Default">
        <div
          *ngIf="!isEditingThisProperty"
          class="inline"
          [ngClass]="notEditingClass"
          [textContent]="numericValue"
          (click)="makePropertyEditable($event)">
        </div>
        <input
            appTrim
            type="text"
            class="edit-item-inner-input"
            [ngClass]="propertyDecorationClass()"
            id="{{id}}"
            maxlength="{{maxLength}}"
            name="{{id}}"
            formControlName="{{propertyName}}"
            [readonly]="!isAvailableToEdit"
            (click)="makePropertyEditable($event)"/>
        <div class="edit-item-length" *ngIf="showLength && isEditingThisProperty">{{Loc(LocalizedTextIds.EditItemOf, currentLength, maxLength)}}</div>
      </ng-container>
      <ng-container *ngIf="isNumericType">
        <div
          *ngIf="!isEditingThisProperty"
          class="inline"
          [ngClass]="notEditingClass"
          [textContent]="numericValue"
          (click)="makePropertyEditable($event)">
        </div>
        <div class="numeric-wrapper"
         >
           <input
            appTrim
            type="number"
            class="edit-item-inner-input"
            [ngClass]="propertyDecorationClass()"
            id="{{id}}"              
            maxlength="{{maxLength}}"
            max={{max}}
            step="{{step}}"
            name="{{id}}"
            formControlName="{{propertyName}}"
            (keydown.arrowup)="false"
            (keydown.arrowdown)="false"
            [readonly]="!isAvailableToEdit"
            [hidden]="!isEditingThisProperty"
            (click)="makePropertyEditable($event)"/>
        </div>
        <div class="edit-item-length" *ngIf="showLength && isEditingThisProperty">{{Loc(LocalizedTextIds.EditItemOf, currentLength, maxLength)}}</div>
      </ng-container>
      <ng-container *ngIf="type === EditItemType.Multiline">
        <div
          *ngIf="!isEditingThisProperty"
          [ngClass]="notEditingClass"
          [textContent]="currentValue"
          (click)="makePropertyEditable($event)">
        </div>
        <textarea
            appTrim
            [hidden]="!isEditingThisProperty"
            class="edit-item-inner-input-textarea"
            [ngClass]="{ 'jnj-confidential': isJnjConfidentialInfo }"
            id="{{id}}"            
            maxlength="{{maxLength}}"
            name="{{id}}"
            formControlName="{{propertyName}}"
            (click)="makePropertyEditable($event)"></textarea>
        <div class="edit-item-length" *ngIf="showLength && isEditingThisProperty">{{Loc(LocalizedTextIds.EditItemOf, currentLength, maxLength)}}</div>
      </ng-container>
      <ng-container *ngIf="type === EditItemType.ConstrainedValue">
        <div
          *ngIf="prompt"
          class="prompt"
          [ngClass]="{ 'jnj-confidential': isJnjConfidentialInfo }">
          {{prompt}}
        </div>
        <div
          *ngIf="!isEditingThisProperty"
          [ngClass]="notEditingClass"
          [textContent]="LocDbValue(currentValue, true)"
          (click)="makePropertyEditable($event)">
        </div>
        <div *ngIf="valueList && isEditingThisProperty" class="select">
          <select
            class="select-control"
            id="{{id}}"
            name="{{id}}"
            formControlName="{{propertyName}}"
            (click)="stopEventPropagationDuringEditing($event)"
            (change)="onConstrainedValueChange($event.target)"> <!-- TODO CAB-->
            <option
                *ngFor="let item of valueList; trackBy: trackBy"
                [ngValue]="item.value">
                {{Loc(item.label ?? item.value)}}
            </option>
          </select>
        </div>
      </ng-container>
      <ng-container *ngIf="type === EditItemType.MultiChoice">
        <div [ngClass]="{ 'readonly': !isAvailableToEdit }" (click)="makePropertyEditable($event)">
          <div
            *ngIf="prompt"
            class="prompt"
            [ngClass]="{ 'jnj-confidential': isJnjConfidentialInfo, 'with-tooltip': hasTooltip }">
            <div class="info-icon" (mouseenter)="toggleTooltip()" (mouseleave)="toggleTooltip()">
              <div class="tooltip"
                *ngIf="tooltip && isShowingTooltip">
                {{tooltip}}
              </div>
            </div>
            {{prompt}}
          </div>
          <div class="multi-choice">
            <ng-container *ngFor="let item of valueList">
              <div class="edit-item-checkbox" [ngClass]="{ 'editable': isEditingThisProperty }">
                <input
                    type="checkbox"
                    class="tick"
                    [ngClass]="{ 'editable': isEditingThisProperty, 'different': isThisUpdateFieldSet }"
                    id="company-update-company-{{propertyName}}-{{item.value}}"
                    name="company-update-company-{{propertyName}}-{{item.value}}"
                    [value]="item.value"
                    [checked]="isCheckedMultiChoice(item.value)"
                    (click)="stopEventPropagationDuringEditing($event, true)"
                    (change)="onMultiChoiceItemChange(item.value)"/>
                <div
                  class="label"
                  [ngClass]="{ 'review': isReviewMode, 'strikethrough': isPropertyDeclined, 'editable': isEditingThisProperty, 'different': isThisUpdateFieldSet }">
                  {{Loc(item.label ?? item.value)}}
                </div>
              </div>
            </ng-container>
          </div>
          <div *ngIf="showFootnote" class="footnote">{{footnote}}</div>
        </div>
      </ng-container>
      <ng-container *ngIf="type === EditItemType.MultiModal">
        <div class="edit-item-inner-items-container" (click)="stopEventPropagationDuringEditing($event)">
          <multi-picker-modal
            [options]="valuesAsStrings"
            [itemType]="propertyName"
            [blurb]="prompt"
            [showModal]="showMultiPickerModal"
            (action)="addItems($event)"
          >
          </multi-picker-modal>

          <ng-container *ngIf="isAvailableToEdit; else multiPickReview">
            <div class="edit-item-inner-items-pill-area" 
              [ngClass]="{ 'not-for-review': isReviewMode && !isReviewableUpdateFieldSet }">
              <div *ngFor="let item of currentArrValue" class="edit-item-inner-items-pill"
                (click)="makePropertyEditable($event)">
                <div class="edit-item-inner-items-pill-label">{{item}}</div>
                <ng-container *ngIf="isEditingThisProperty">
                  <img
                    class="edit-item-inner-items-pill-icon"
                    src='/assets/update/close.svg'
                    (click)='removeItem(item, $event)'/>
                </ng-container>
              </div>
              <div *ngIf="isEditingThisProperty" class="edit-item-inner-items-add-pill" (click)='launchMultiPickerModal($event)'>
                <img
                  class="edit-item-inner-items-add-icon"
                  src="/assets/update/add-items.svg"/>
                <div class="edit-item-inner-items-add-pill-label">{{addText}}</div>
              </div>
            </div>
          </ng-container>
          <ng-template #multiPickReview>
            <div class="items-review-details">
              <div *ngIf="itemsUnchanged.length > 0" class="items-unchanged">
                <div class="title">{{Loc(LocalizedTextIds.Unchanged)}}</div>
                <div class="edit-item-inner-items-pill-area-wrapper">
                  <div class="edit-item-inner-items-pill-area">
                    <div *ngFor="let item of itemsUnchanged" class="edit-item-inner-items-pill unchanged">
                      <div class="edit-item-inner-items-pill-label">{{item}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="itemsRemoved.length > 0" class="items">
                <div class="title">{{Loc(LocalizedTextIds.Removed)}}</div>
                <div class="subtitle">
                  <localized-text-snippet
                    text="{{removeText}}">
                  </localized-text-snippet>
                </div>
                <div class="edit-item-inner-items-pill-area-wrapper">
                  <div class="edit-item-inner-items-pill-area">
                    <div *ngFor="let item of itemsRemoved" class="edit-item-inner-items-pill removed" [ngClass]="{ 'preserved': isPreservedItem(item) }">
                      <div class="edit-item-inner-items-pill-label removed" [ngClass]="{ 'preserved': isPreservedItem(item) }">{{item}}</div>
                      <img
                        class="edit-item-inner-items-pill-icon removed"
                        src='/assets/update/ic-settings-backup-restore-24px.svg'
                        (click)='toggleItemPreservation(item, $event)'/>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="itemsAdded.length > 0" class="items">
                <div class="title">{{Loc(LocalizedTextIds.Added)}}</div>
                <div class="subtitle">
                  <localized-text-snippet
                    text="{{addedText}}">
                  </localized-text-snippet>
                </div>
                <div class="edit-item-inner-items-pill-area-wrapper">
                  <div class="edit-item-inner-items-pill-area">
                    <div *ngFor="let item of itemsAdded" class="edit-item-inner-items-pill" [ngClass]="{ 'excluded': isExcludedItem(item) }">
                      <div class="edit-item-inner-items-pill-label" [ngClass]="{ 'excluded': isExcludedItem(item) }">{{item}}</div>
                      <img
                        class="edit-item-inner-items-pill-icon"
                        src='/assets/update/close.svg'
                        (click)='toggleItemExclusion(item, $event)'/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
        <div *ngIf="isEditingThisProperty && isEditMode" class="edit-item-inner-items-subtitle">
          {{subtitle}}
        </div>
      </ng-container>
      <ng-container *ngIf="type === EditItemType.Logo">
        <div class="edit-item-inner-logo-container">
          <input type="file" #logoBase64chooser (change)="onLogoUpdated($event)" accept="image/*" style="display: none;" (click)="$event.stopPropagation()"/>
          <company-logo [logoBase64]="company.logoBase64" [size]="logoSize" (click)="makePropertyEditable($event)" show="true"></company-logo>
          <div *ngIf="isEditingThisProperty" class="blurb-and-button">
            <span class="blurb">{{Loc(LocalizedTextIds.EditItemYourLogo, bytesToHumanReadableDisplayString(maxUploadLogoImageSize))}}</span>
            <div class="button" (click)="launchLogoChooser($event)">
              <img src="/assets/update/ic-file-upload-24px.svg"/>
              <div class="label">{{Loc(LocalizedTextIds.EditItemChoose)}}</div>
            </div>
            <div class="validation-errors">
              <div *ngIf="parentForm.get('logoBase64').errors?.badType">
                {{Loc(LocalizedTextIds.EditItemOnlyJPG)}}
              </div>
              <div *ngIf="parentForm.get('logoBase64').errors?.tooLarge">
                {{Loc(LocalizedTextIds.EditItemLogoImage, maxUploadLogoImageSize)}}
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngTemplateOutlet="viewOriginal"></ng-container>
      <div class="edit-button" name="edit-button" [ngClass]="{ 'dim': isEditingThisProperty }" *ngIf="isAvailableToEdit" (click)="makePropertyEditable($event)">
        <img src="/assets/update/undo-circle-2.svg" class="revert-icon" *ngIf="showRevertIcon" (click)="revertEdit($event)"/>
        +{{Loc(LocalizedTextIds.Edit)}}
      </div>
    </div>
  </div>
  <div *ngIf="isReadOnly" class="read-only" [ngClass]="{ 'jnj-confidential': isJnjConfidentialInfo }">
    <div>{{subtitle}}</div>
  </div>
  <ng-template #viewOriginal>
    <div *ngIf="showViewOriginalButton" class="view-original-outer" [ngClass]="{ 'jnj-confidential': isJnjConfidentialInfo }">
      <div class="view-original-inner">
        <div class="view-original" (click)="isShowingOriginalText = !isShowingOriginalText">
          <img src="/assets/update/arrow-down-1.svg" [ngClass]="{ 'arrow-closed': !isShowingOriginalText, 'arrow-opened': isShowingOriginalText }"/>
          <div *ngIf="!isShowingOriginalText" class="text">{{Loc(LocalizedTextIds.ViewOriginal)}}</div>
          <div *ngIf="isShowingOriginalText" class="text">{{Loc(LocalizedTextIds.HideOriginal)}}</div>
        </div>
      </div>
      <div *ngIf="isShowingOriginalText" class="original" [ngClass]="{ 'jnj-confidential': isJnjConfidentialInfo }">
        <div class="text">{{originalValueDisplay}}</div>
      </div>
    </div>
  </ng-template>
</div>
<approve-decline
  *ngIf="showApproveDecline"
  [includeInSummary]="isThisUpdateFieldSet"
  [propertyName]="propertyName">
</approve-decline>
