import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TabInfo } from '../../utilities/tabs/tab.util.js';

@Component({
    selector: 'simple-tab-ui',
    templateUrl: './simple-tab-ui.component.html',
    styleUrls: ['./simple-tab-ui.component.scss'],
    standalone: false
})
export class SimpleTabUIComponent implements OnInit {
  // public properties
  @Input()
  public tabs: TabInfo[];
  @Output()
  public tabClickEvent: EventEmitter<TabInfo> = new EventEmitter<TabInfo>();
  // private properties
  private _activeTab: TabInfo | undefined;

  public tabInfoTrackBy(_index: number, tab: TabInfo): string {
    return tab.value.toString();
  }

  // public methods
  public ngOnInit(): void {
    this._activeTab = this.tabs?.length ? this.tabs[0] : undefined;
  }

  public changeTab(tab: TabInfo): void {
    this._activeTab = tab;
    this.tabClickEvent.emit(tab);
  }

  public highlightWidthStyle(tab: TabInfo): { width: string } {
    return { width: `${tab.highlightWidth}px` };
  }

  public isShowingTab(tab: TabInfo): boolean {
    return tab?.value === this._activeTab?.value;
  }

  public tabErrorInfo(tab: TabInfo): string[] {
    if (!tab.getErrorInfo) {
      return null;
    }
    return tab.getErrorInfo();
  }
}
