import { Component, Input, OnInit } from '@angular/core';
import {
  Company,
  LocalizedTextIds,
  NewsEventOfInterest,
} from 'company-finder-common';
import { ComponentBase } from '@Common';
import { EventService } from '@Common';
import { DeploymentContext } from '@Common';

@Component({
    selector: 'news-component',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss'],
    standalone: false
})
export class NewsComponent extends ComponentBase implements OnInit {
  @Input()
  public company: Company;

  public newsItems: NewsEventOfInterest[] = [];

  public currentPage = 1;
  public itemsPerPage = 6;

  public getDateString(date: Date): string {
    const days = Math.round((new Date().getTime() - new Date(date).getTime())/ (1000 * 60 * 60 *24) ) ;

    if (days === 0) {
      return this.Loc(LocalizedTextIds.Yesterday);
    } else if (days === 1) {
      return this.Loc(LocalizedTextIds.Today);
    } else {
      return days + this.Loc(LocalizedTextIds.NewsDaysAgo);
    }
  }

  public openLink(url: string): void {
    window.open(url, '_blank');
  }

  public getBorderClass(item: number, page: number): string {
    let isLastTwoOnPage = false;

    if (page * this.itemsPerPage <= this.newsItems.length) {
      if (item >= this.itemsPerPage - 2) {
        isLastTwoOnPage = true;
      }
    } else {
      const lastPageCount = this.newsItems.length % this.itemsPerPage;
      if (item >= lastPageCount - 2) {
        isLastTwoOnPage = true;
      }
    }

    if (isLastTwoOnPage) {
      return '';
    } else {
      return 'hasBorder';
    }
  }

  constructor(
    deploymentContext: DeploymentContext,
    private _eventService: EventService
  ) {
    super(deploymentContext);
  }

  async ngOnInit(): Promise<void> {
    this.newsItems = await this._eventService.getCompanyNewsDigest(
      this.company.id
    );
  }
}
