import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  AfterViewChecked,
  SimpleChanges,
} from '@angular/core';
import _ from 'lodash-es';

import { ComponentBase } from '@Common';

// model imports
import { LocPrefix, SectorCounts } from 'company-finder-common';

// utility/service imports
import { SearchService } from '@Common';
import { WebAnalyticsService } from '@Common';
import {
  D3,
  NgxD3Service,
} from '@Common';
import { HierarchyNode } from 'd3';
import { DeploymentContext } from '@Common';
import { Summary } from '@Common';

const maxAppWidth = 1440;
const sectorMargin = 16;
const maxWidthFraction = 0.95;
const maxHeightFraction = 0.5;

@Component({
    selector: 'sector-map',
    templateUrl: './sector-map.component.html',
    styleUrls: ['./sector-map.component.scss'],
    standalone: false
})
export class SectorMapComponent
  extends ComponentBase
  implements AfterViewInit, AfterViewChecked, OnChanges
{
  public readonly svgHeight = 412;
  public readonly svgWidth = 228;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public svgs: any[] = [];
  public d3: D3;
  public sectorMapSvgIdPrefix = 'sector-map-';
  @Input()
  public summary: Summary;
  public swiperConfig = {
    spaceBetween: 5,
  };

  public constructor(
    dc: DeploymentContext,
    private readonly _d3Service: NgxD3Service,
    private _searchService: SearchService,
    private _webAnalyticsService: WebAnalyticsService
  ) {
    super(dc);
    // initialize d3 & dom element
    this.d3 = this._d3Service.getD3();

    this.sectorList = dc.sectorNames.map((sector, index) => {
      const color = this.themeSettings.sectorColors[index];
      return {
        sector: sector,
        color: color,
        label: this.LocWithPrefix(sector, LocPrefix.SectorShortName),
      };
    });
  }

  public sectorList: {
    sector: string;
    color: string;
    label: string;
  }[] = [];

  public ngAfterViewInit(): void {
    if (this.summary) {
      this.queueUpdate();
    }
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.summary && this.summary) {
      this.queueUpdate();
    }
  }

  public ngAfterViewChecked(): void {
    // If any SVG is childless, force update
    if (
      document.querySelector('.swiper-container.sector-map svg:empty') ||
      this.getWindowSize() !== this.lastWindowSize
    ) {
      this.queueUpdate();
    }
  }

  public sectorHasData(sector: string): boolean {
    return this.summary?.sectorGroups?.some(
      (obj) =>
        obj.name === sector &&
        obj.value > 0 &&
        obj.children.some((child) => child.value > 0)
    );
  }

  public removeSpaces(sectorName: string): string {
    return sectorName.replace(' ', '');
  }

  public buildSvgId(sectorName: string): string {
    return `${this.sectorMapSvgIdPrefix}${sectorName
      .replace(' ', '-')
      .replace('/', '')
      .toLocaleLowerCase()}-svg`;
  }

  public renderSvg(
    sectorData: SectorCounts,
    height: number,
    width: number
  ): void {
    const svgId = this.buildSvgId(sectorData.name);
    const sectorIndex = this._deploymentContext.getSectorIndex(sectorData.name);
    const rectangleColor = this.themeSettings.sectorColors[sectorIndex];
    const labelColor =
      this.themeSettings.sectorContrastLabelColors[sectorIndex];

    this.svgs[svgId] = this.d3
      .select(`#${svgId}`)
      .attr('viewBox', `0 0 ${width} ${height}`)
      .attr('height', height)
      .append('g');

    this.render(
      this.svgs[svgId],
      sectorData,
      width,
      height,
      rectangleColor,
      labelColor
    );
  }

  // private members
  private timeout: number;
  private lastWindowSize: string;

  // private methods
  private async handleClick(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    root: HierarchyNode<any>
  ): Promise<void> {
    const filter = this._searchService.filter;
    filter.primarySectors = [root.data.name];
    filter.primarySubSectors = [data.data.name];

    this._searchService.drilldownSubject.next(filter);

    this._webAnalyticsService.trackEvent('sector-map-drilldown', {
      category: root.data.name,
      label: data.data.name,
      value: data.data.value,
    });
  }

  private render(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    svg: any,
    data: SectorCounts,
    width: number,
    height: number,
    rectangleColor: string,
    labelColor: string
  ): void {
    const totalWithSubsectors = data.children
      .map((child) => child.value)
      .reduce((acc, val) => acc + val, 0);

    const root = this.d3.hierarchy({
      ...data,
      value: totalWithSubsectors,
      children: data.children.filter((child) => child.value > 0),
    });
    root
      .sum((d) => d.value)
      .sort((a, b) => b.height - a.height || b.value - a.value);

    const treemapLayout = this.d3.treemap();
    // FUTURE: Note the use of the treemapBinary layout. This is similar to the default tiling method, but
    //         looks, to my eye, slightly better. If GVio prefers a different layout, we'd have to investigate.
    treemapLayout
      .size([width, height])
      .tile(this.d3.treemapBinary)
      .padding(3)
      .paddingOuter(0);
    treemapLayout(root);

    const leaf = svg
      .selectAll('g')
      .data(root.leaves())
      .enter()
      .append('g')
      .attr('transform', (d) => `translate(${d.x0},${d.y0})`);

    leaf.append('title').text((d) => `${d.data.name}: ${d.value}`);

    this.renderRectangles(leaf, root, rectangleColor);
    this.renderLabels(leaf, labelColor);
  }

  private renderRectangles(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    leaf: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    root: HierarchyNode<any>,
    color: string
  ): void {
    // FUTURE: Consider a more sophisticated approach to scaling the colors.
    leaf
      .append('rect')
      .attr('fill', color)
      .attr('fill-opacity', (d) => Math.min(1, d.value / root.value + 0.4))
      .attr('width', (d) => d.x1 - d.x0)
      .attr('height', (d) => d.y1 - d.y0)
      .attr('cursor', 'pointer')
      .on('click', (d) => {
        d.stopPropagation();
        this.handleClick(d, root);  // TODO CAB
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private renderLabels(leaf: any, color: string): void {
    // FUTURE: Labels get cut off when they're too long and/or their cell is too small. Explore
    //         our options with regard to eliding the text, or otherwise handling this issue.
    const indent = 10;
    leaf
      .append('text')
      .attr('fill', color)
      .attr('font-weight', 'bold')
      .attr('pointer-events', 'none')
      .append('tspan')
      .attr('x', indent)
      .attr('y', 25)
      .text((d) => {
        // This uses "6" as an approximation for the pixel width of a character.  Because we aren't rendering
        // as a fixed width font, this is by no means perfect.  It was just quicker to implement than doing this at
        // the rendering tier.  Although, it's possible we can replace this implementation with ADJQ-66 at some point in the future.
        const approxAvailableCharacterWidth = (d.x1 - d.x0 - indent) / 6;
        if (approxAvailableCharacterWidth >= d.data.name.length) {
          return d.data.name;
        } else {
          return (
            d.data.name.substring(0, approxAvailableCharacterWidth - 3) + '...'
          );
        }
      })
      .append('tspan')
      .attr('x', 10)
      .attr('y', 43)
      .attr('font-weight', 'normal')
      .text((d) => d.value);
  }

  private getWindowSize(): string {
    return `${window.outerWidth}x${window.outerHeight}`;
  }

  private queueUpdate(): void {
    if (!this.timeout) {
      this.timeout = window.setTimeout(() => {
        this.update();
        this.timeout = undefined;
      }, 0);
    }
  }

  private update(): void {
    if (this.summary) {
      this.lastWindowSize = this.getWindowSize();

      // Remove any previously rendered svg elements
      for (const field of Object.keys(this.svgs)) {
        const svg = this.svgs[field];
        if (undefined !== svg) {
          svg.remove();
        }
      }

      const maxWidth =
        maxWidthFraction *
        Math.min(screen.width, window.outerWidth, maxAppWidth);
      const maxHeight =
        maxHeightFraction * Math.min(screen.height, window.outerHeight);

      const sectorCount = this.summary.sectorGroups?.length;
      const totalMultFactor = _.sum(
        this.summary.sectorGroups.map((sectorData) =>
          this.calcMultFactor(sectorData)
        )
      );
      const unitWidth =
        (maxWidth - (sectorCount + 1) * sectorMargin) / totalMultFactor;

      this.summary.sectorGroups.forEach((sectorData) => {
        const multFactor = this.calcMultFactor(sectorData);
        const width = this.narrowScreen ? maxWidth : unitWidth * multFactor;
        const height = this.narrowScreen ? maxHeight : this.svgHeight;
        this.renderSvg(sectorData, height, width);
      });
    }
  }

  // This is a heuristic to try to guess appropriate "mult factors"
  // that mimic the original layout (with Pharma sector having twice
  // the area of the other two sectors), but based on the aggregate
  // subsector name lengths.  Not sure if it will work for other
  // instances, but it makes the attempt to divide up the available
  // space into sector boxes of multiples of a unitWidth.  If this does
  // not end up working, we might need to improve the algorithm, or
  // abandon the goal of having them be integral multiples of a unitWidth.
  public calcMultFactor(sectorData: SectorCounts): number {
    const subsectorNameLengths: number[] = sectorData.children.map(
      (ss) => ss.name.length
    );
    const charCount =
      _.sum(subsectorNameLengths) + subsectorNameLengths?.length;
    return Math.max(1, Math.floor(charCount / 100));
  }
}
