<div class="disclaimer">
  <div *ngIf="!isAuthenticated()">
    {{Loc(LocalizedTextIds.Disclaimer1)}}
    <br />
    <br />
    {{Loc(LocalizedTextIds.Disclaimer2)}}
  </div>
  <div *ngIf="isAuthenticated()">
    {{Loc(LocalizedTextIds.DisclaimerAuth1)}}
    <br />
    <br />
    {{Loc(LocalizedTextIds.DisclaimerAuth2)}}
    <br />
    <br />
    <localized-text-snippet text="{{Loc(LocalizedTextIds.DisclaimerAuth3, jlabsUrl, 'mailto:JLABS@jnj.com')}}"></localized-text-snippet>
  </div>
</div>
