import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { ComponentBase } from '@Common';

// model imports
import { LocalizedTextIds, SectorCounts, SectorDisplayItem, StageCount } from 'company-finder-common';
import { MenuOption } from '@Common';

// utility/service imports
import { ApplicationContext } from '@Common';
import { SearchService } from '@Common';
import { WebAnalyticsService } from '@Common';
import { DeploymentContext } from '@Common';
import {
  Summary,
} from '@Common';

@Component({
    selector: 'stage',
    templateUrl: './stage.component.html',
    styleUrls: ['./stage.component.scss'],
    standalone: false
})
export class StageComponent
  extends ComponentBase
  implements OnChanges, AfterViewInit
{
  public activeSector: string;
  @Input()
  public summary: Summary;
  public title = this.Loc(LocalizedTextIds.CompanyDetailsLeadProductStage);
  public sectorGroups: SectorCounts[];
  public bubbleLineStyle: Partial<CSSStyleDeclaration>;

  // TODO CAB = Remove?
  public content = {};

  @ViewChild('cf_overview_stage') stageDiv: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.scaleBubbles();
  }

  // private properties
  private _sectors: string[];
  private _activeStageCounts: StageCount[];
  private _activeColor: string;
  private _bubblesAreaWidth = 442;
  private _bubbleContainerHeight = 70;
  private _defaultBubbleSize = 14;

  public constructor(
    dc: DeploymentContext,
    private _applicationContext: ApplicationContext,
    private _searchService: SearchService,
    private _webAnalyticsService: WebAnalyticsService,
    private renderer: Renderer2
  ) {
    super(dc);
  }

  ngAfterViewInit(): void {
    this.scaleBubbles();
  }

  // public getters
  public get activeStageCounts(): StageCount[] {
    return this._activeStageCounts;
  }

  public get activeStageCountsCollection() {
    return this.sectorGroups?.find(
      (stageCounts) => stageCounts.name === this.activeSector
    );
  }

  public get sectors(): string[] {
    return this.summary ? this._sectors : [''];
  }

  public get sectorOptions(): MenuOption<SectorDisplayItem>[] {
    return this.sectors?.map((sector) => {
      const filter = this._searchService.filter;
      let sectorValue = false;
      if (!filter?.isShowAllSectors()) {
        sectorValue = !!filter.primarySectors.find(
          (primarySector) => primarySector === sector
        );
      }
      const sectorOption: MenuOption<SectorDisplayItem> = {
        id: sector,
        label: sector,
        value: sectorValue,
        dataModel: { sectorId: sector, displayName: sector },
      };
      return sectorOption;
    });
  }

  public get dropdownValue(): string {
    return this.getSectorDisplay(this.activeSector);
  }

  // public methods
  public getBubblesLineStyle(): Partial<CSSStyleDeclaration> {
    if (this.activeStageCounts?.length > 0) {
      const lastIndex = this.activeStageCounts.length - 1;
      const lastSize = this.getBubbleSize(this.activeStageCounts[lastIndex]);
      const lastLeft =
        (lastIndex * this._bubblesAreaWidth) / this.activeStageCounts.length -
        lastSize / 2;
      return { width: `${lastLeft + lastSize / 2}px` };
    }
  }

  public getBubbleStyles(
    stageCount: StageCount,
    index: number
  ): Partial<CSSStyleDeclaration> {
    const style = this.getBubbleBackgroundColor(index);
    const size = this.getBubbleSize(stageCount);
    const left =
      (index * this._bubblesAreaWidth) / this.activeStageCounts.length -
      size / 2;

    style.borderRadius = `${size / 2}px`;
    style.height = style.width = `${size}px`;
    style.left = `${left}px`;
    style.top = `${(70 - size) / 2}px`;

    return style;
  }

  private getSectorDisplay(sector: string): string {
    return sector;
  }

  private getBubbleBackgroundColor(
    index: number
  ): Partial<CSSStyleDeclaration> {
    let color = this._activeColor;
    if (this.activeStageCountsCollection.stageCounts[index].count === 0) {
      color = '$neutral-02';
    }
    return { backgroundColor: `${color}` };
  }

  public getTooltip(stageCount: StageCount): string {
    return `${stageCount.stage}: ${stageCount.count}`;
  }

  public async handleClick(
    stageCount: StageCount,
    clickedBubbleNotText: boolean
  ): Promise<void> {
    const filter = this._searchService.filter;
    filter.primarySectors = [this.activeSector];
    filter.currentRdStage = stageCount.stage;

    this._searchService.drilldownSubject.next(filter);

    this._webAnalyticsService.trackEvent('lead-product-stage-drilldown', {
      category: this.activeSector,
      label: `${stageCount.stage} - ${
        clickedBubbleNotText ? 'bubble' : 'text'
      } clicked`,
      value: stageCount.count,
    });
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.summary && this.summary) {
      this._sectors = this.summary.sectors.sort().reverse();

      // Localize the stage count
      // FUTURE - This should go in the API, but I didn't want to 
      // wire in a localizer (in common) with all the other moving parts right now
      this.sectorGroups = this.summary.sectorGroups;
      this.sectorGroups?.forEach((sectorGroup) => {
        sectorGroup.stageCounts?.forEach((stageCount) => {
          stageCount.stage = this.Loc(stageCount.stage);
        });
      });

      // Preserve the previous selection, or if that is no longer valid, select the first value
      let sectorToSelect = this._applicationContext.activeRdStageSector;
      if (!this.sectors.includes(sectorToSelect)) {
        sectorToSelect = this.sectors[0];
      }
      this.setSector(sectorToSelect);
    }
  }

  public handleSectorSelectedEvent(selectedSectors: SectorDisplayItem[]): void {
    const sector =
      selectedSectors && selectedSectors.length
        ? selectedSectors[0].sectorId
        : this.activeSector;
    this.setSector(sector);
  }

  public setSector(sector: string, ev?: MouseEvent): void {
    this.activeSector = sector;
    this._applicationContext.activeRdStageSector = sector;
    this._activeStageCounts = this.activeStageCountsCollection
      ? this.activeStageCountsCollection.stageCounts
      : undefined;
    const sectorIndex = this._deploymentContext.getSectorIndex(
      this.activeSector
    );
    this._activeColor = this.themeSettings.sectorColors[sectorIndex];

    this.bubbleLineStyle = this.getBubblesLineStyle();

    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }
  }

  // private methods
  private getBubbleSize(stageCount: StageCount): number {
    // eslint-disable-next-line prefer-spread
    const maxCount = Math.max.apply(
      Math,
      this.activeStageCounts.map((sc) => sc.count)
    );
    const percentage = stageCount.count / maxCount;
    const totalMargins = (this.activeStageCounts.length - 1) * 4;
    const maxSize = Math.min(
      this._bubbleContainerHeight,
      (this._bubblesAreaWidth - totalMargins) / this.activeStageCounts.length
    );
    const minSize = 6;
    const size =
      percentage > 0
        ? Math.max(minSize, percentage * maxSize)
        : this._defaultBubbleSize;
    return size;
  }

  private scaleBubbles() {
    const stageWidth = this.stageDiv.nativeElement.offsetWidth;
    const bubblesDiv = this.stageDiv.nativeElement.querySelector(
      '.cf_overview_stage-bubbles'
    );
    if (bubblesDiv) {
      const scaleFactor = Math.min(
        (stageWidth - 50) / this._bubblesAreaWidth,
        1
      );
      this.renderer.setStyle(bubblesDiv, 'transform', `scale(${scaleFactor})`);
    }
  }
}
