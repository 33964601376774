<div class="cf_filter">
  <div class="cf_filter-content" [ngClass]="{'cf_filter-content-no-search-results': !isSearchResultsScreen}">
    <div class="cf_filter-content-controls">
      <div class="cf_filter-content-controls-left">
        <simple-selector
          [hidden]="hideLocationSelector"
          label={{Loc(LocalizedTextIds.Location)}}
          [menuOptions]="locationOptions"
          (select)="updateLocationFilter($event)"
          (afterSelect)="trackForAnalytics('location', $event)">
        </simple-selector>
        <sector-dropdown 
          [hidden]="hidePrimarySectorSelector"
          (optionsSelected)="updateSectorFilter($event)"
          label={{Loc(LocalizedTextIds.Sector)}}
          >
        </sector-dropdown>
        <sector-dropdown 
          [hidden]="hideSecondarySelector"
          [isPrimary]="false"
          (optionsSelected)="updateSecondarySectorFilter($event)"
          label={{Loc(LocalizedTextIds.SecondarySector)}}
          >
        </sector-dropdown>
        <simple-selector
          [hidden]="showIndicationSelector"
          label={{Loc(LocalizedTextIds.Indication)}}
          [menuOptions]="indicationOptions"
          (select)="updateIndicationFilter($event)"
          (afterSelect)="trackForAnalytics('indication', $event)">
        </simple-selector>
        <div class="cf_filter-content-clear" (click)="clearFilter()">
          <span *ngIf="showClearFilterAction">{{Loc(LocalizedTextIds.FilterClearAll)}}</span>
        </div>
      </div>
      <div class="cf_filter-content-controls-right">
        <label *ngIf="isFollowEnabled && isInternalView" for="followedCompaniesOnly">
          {{Loc(LocalizedTextIds.FilterCompaniesImFollowing)}}
          <input type="checkbox" class="checkbox" id="followedCompaniesOnly" name="followedCompaniesOnly"
            [(ngModel)]="followedCompaniesOnly" />
        </label>        
        <ng-container *ngIf="useStatusDropdown && featureSwitches.enableShowLocationStatus">
          <status-selector
            [menuOptions]="statusOptions"
            (select)="updateStatusFilter()"
            (afterSelect)="trackForAnalytics('status', $event)"
            label={{Loc(LocalizedTextIds.Status)}}
          >          
          </status-selector>
      </ng-container>
      <ng-container *ngIf="!useStatusDropdown && featureSwitches.enableShowLocationStatus">
        <ng-container *ngFor="let menuOption of optionsForSingleRow">
          <label 
            [for]="menuOption.id" 
          >
            {{menuOption.label}}
            <input 
              type="checkbox"
              class="checkbox"
              [attr.id]="menuOption.id"
              [attr.name]="menuOption.id"
              [(ngModel)]="menuOption.value"
              (ngModelChange)="updateStatusFilter()"
            />
          </label>
        </ng-container>
      </ng-container>
      </div>
    </div>
  </div>
  <div class="cf_filter-additional-constraints" *ngIf="hasAdditionalFilterConstraints">
    <div class="cf_filter-additional-constraints-item" *ngFor="let item of activeAdditionalFilterConstraints">
      <div class="cf_filter-additional-constraints-item-title">{{item.displayString()}}</div>
      <div class="cf_filter-additional-constraints-item-divider"></div>
      <div class="cf_filter-additional-constraints-item-X" (click)="clearAdditionalFilterConstraint(item)">X</div>
    </div>
  </div>
</div>