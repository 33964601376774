<div class="cf_jnj-logout-success-toast-title-bar">
    <div *ngIf="title" [class]="options.titleClass">
        {{title}}
    </div>
</div>
<div class="cf_jnj-logout-success-toast-handler-message">
    <div [class]="options.messageClass">
        <localized-text-snippet text="{{Loc(LocalizedTextIds.JnjLogoutSuccessToastYouHaveBeen)}}"></localized-text-snippet>
    </div>
</div>
