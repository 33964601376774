import { Directive, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { DeploymentContext } from '../utilities/deployment-context/deployment-context.js';
import { IframeResizerService } from '../services/iframe/iframe.resizer.service.js';
import { iframeResizer } from '@iframe-resizer/child';
import { Subscription } from 'rxjs';

/***
 * Uses JavaScript to position the element just below its previous sibling (its "anchor element").
 * It doesn't seem there is a straightforward way to do this with pure css.
 * This was implemented for the dropdown-menu.component, but maybe could benefit from more generalization.
 * This handles iframed & non-iframed, and swiper-ed and non-swiper-ed.
 */
@Directive({
    selector: '[keepInsideViewport]',
    standalone: false
})
export class KeepInsideViewportDirective implements AfterViewInit, OnDestroy {

    private _iframeResizerEventSubscription: Subscription;
    private scrollListener: () => void;
    private resizeListener: () => void;
    private buffer = 10;

    constructor(private elementRef: ElementRef, private _scrollingService: IframeResizerService, private context: DeploymentContext) { }

    ngAfterViewInit(): void {
        this.calculatePosition(null);

        if (this.context.hosted()) {
            this._iframeResizerEventSubscription =
              this._scrollingService.iframeSubject.subscribe((event) =>
                this.calculatePosition(event)
              );

          } else {
            
            this.resizeListener = () => this.calculatePosition(null);
            window.addEventListener('resize', this.resizeListener);    

            this.scrollListener = () => this.calculatePosition(null);
            window.addEventListener('scroll', this.scrollListener);    
        }

    }

    ngOnDestroy(): void {
        this._iframeResizerEventSubscription?.unsubscribe();
        window.removeEventListener('scroll', this.scrollListener);
        window.removeEventListener('resize', this.resizeListener);
    }

    private calculatePosition(resizerWinProps: iframeResizer.ParentProps): void {
        // Maybe this could have all been done as a relative position, but the calculations
        // were easier for fixed positioning, given the relative reference seemed different
        // for the various filter dropdown triggers (location, sector, status, etc.).
        // The headaches to get this right included: 
        //  1. Get the height calculation to work when in an iframe and standalone, 
        //      and hooking up the right events under each situation to refresh the position calculations.
        //  2. Get the positioning to be correct when in a swipe tile (mobile/narrow width), 
        //      since swiper establishes a new stacking context and offsets for fixed positioning is affected

        const anchor = this.elementRef.nativeElement.previousElementSibling;
        const content = this.elementRef.nativeElement;

        // Capture additional context if in a swiper container
        const swiperContainer = content.closest('.swiper-container');
        const swiperContainerRect = swiperContainer?.getBoundingClientRect();
        const swiperLeftOffset = swiperContainerRect?.left;
        const swiperTopOffset = swiperContainerRect?.top;
        // The 0.8 is hardcoded based on the 10% margins declared in responsive.scss swiper-slide, since content is cut off at that width
        const swiperViewableWidth = swiperContainerRect ? swiperContainerRect.width * 0.8 : undefined;

        const anchorRect = anchor.getBoundingClientRect();
        const desiredLeft = anchorRect.left;
        const desiredTop = anchorRect.top + anchorRect.height + this.buffer;
        const contentWidth = content.offsetWidth;
        const contentHeight = content.offsetHeight;
        const viewportWidth = visualViewport.width;
        const viewportHeight = (resizerWinProps) ? resizerWinProps.viewport.height - resizerWinProps.iframe.top : visualViewport.height;
        const calcdLeft = Math.min(desiredLeft, Math.max(0, (swiperViewableWidth ?? viewportWidth) - contentWidth - this.buffer)) - (swiperLeftOffset ?? 0);
        // This also shifts the element up so as much content is visible as possible (noticeable with Location dropdown, but maybe we should just require the user to scroll...)
        const calcdTop = Math.min(desiredTop, Math.max(0, viewportHeight - contentHeight - this.buffer)) - (swiperTopOffset ?? 0);

        content.style.position = 'fixed';
        content.style.left = `${calcdLeft}px`;
        content.style.top = `${calcdTop}px`;
    }
}