import { SectorAndParentIds } from '../dto/sector.js';

export const determineSectorsAndSubsectors = (
    newSectors: SectorAndParentIds[] = []
): string[][] => {
    // Sets allow us to easily avoid duplicate values
    const sectors = new Set<string>();
    const subsectors = new Set<string>();
    newSectors?.forEach((sector) => {
        if (!sector.parentSectorId) {
            sectors.add(sector.sectorId);
        } else {
            subsectors.add(sector.sectorId);
        }
    });

    return [Array.from(sectors), sortSubsectors(Array.from(subsectors))];
};

export const sectorSubsectorMatches = (sector: string, name: string): boolean =>
    !sector || name?.toLocaleLowerCase() === sector?.toLocaleLowerCase();

/**
 * ADJQ-807: Sectors and Subsectors are NOT reference lists, and are instead inferred from the company data.
 * Therefore, we have no definitive reference for sorting them.  Brittany asked to sort them alphabetically,
 * keeping the "Other (<sector>)" at the end.
 * Note: This logic does not handle relative sort of multiple "Other (...)" values, but that shouldn't be needed.
 * Also, it does an inplace sort.
 */
export const sortSubsectors = (subSectorNames: string[]): string[] =>
    subSectorNames.sort((a, b) =>
        a.startsWith('Other ')
            ? 1
            : b.startsWith('Other ')
            ? -1
            : a.localeCompare(b)
    );

export const sectorToDisplayString = (sector: SectorAndParentIds): string => {
    const qualifier = sector.parentSectorId
        ? ` (${sector.parentSectorId})`
        : null;
    const displayName: string = sector.sectorId;
    if (displayName.includes(qualifier) || !qualifier) {
        return displayName;
    }
    return displayName + qualifier;
};
