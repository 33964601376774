import { StatusMetaData, StatusMetaDataCollection } from "./status.metadata.js";
import { VisualizationType } from "./VisualizationType.js";

// Stolen from jsonwebtoken 8.5
type Algorithm =
    | 'HS256'
    | 'HS384'
    | 'HS512'
    | 'RS256'
    | 'RS384'
    | 'RS512'
    | 'ES256'
    | 'ES384'
    | 'ES512'
    | 'PS256'
    | 'PS384'
    | 'PS512'
    | 'none';

export class DocumentGeneration {
    public documentDownloadEnabled: boolean;
    public verboseMode: boolean;
    public timestampFilenames: boolean;
    public maxCompanyCountAllowedForPdf: number;
}

export enum Environment {
    Development = 'Development',
    Integration = 'Integration',
    Staging = 'Staging',
    Production = 'Production',
}

export class Sector {
    public name = 'Unknown';
    public subsectors: string[] = [];
}

export class GeneralConfig {
    public instanceId: string;
    public defaultLocale: string;
    public appWidth: number;
    public behavior: Behavior;
    public email: EmailConfig;
    public environment: Environment;
    public documentGeneration: DocumentGeneration;
    public featureSwitches: FeatureSwitches;
    public themeSettings: ThemeSettings;
    public freeTextSearchTypeahead: FreeTextSearchTypeahead;
    public hubSpotIntegration: HubSpotIntegration;
    public juniverseIntegration: JuniverseIntegration;
    public jforceIntegration: JforceIntegration;
    public locationMetadata: LocationMetaData[];
    public progressIndicator: ProgressIndicator;
    public referenceValueData: ReferenceValueData;
    public sso: SsoConfig;
    public hostedUrl: string;
    public debug: DebugSettings = new DebugSettings();
    public gtmId: string;
    public responsive: ResponsiveConfig;
    public sectors: Sector[] = [];
    public statusForTile: string;
    public tooltipForTile: string;
    public statusForCompanyIcon: string;
    public tooltipForCompanyIcon: string;
    public companyStatusIcon: string;
    public rawStatusMetadata: StatusMetaData[];
    public statusMetadata: StatusMetaDataCollection;
    public specialDesignationOrder: number;

    constructor(initConfig: GeneralConfig) {
        Object.assign(this, initConfig);
        this.statusMetadata = new StatusMetaDataCollection(
            this.statusForTile,
            this.rawStatusMetadata,
            this.specialDesignationOrder
        );
    }

    private static _instance: GeneralConfig;
    public static get globalInstance(): GeneralConfig {
        return GeneralConfig._instance;
    }
    public static set globalInstance(instance: GeneralConfig) {
        GeneralConfig._instance = new GeneralConfig(instance);
    }

    /** Returns the config-drvien metadata for a location.
     * Note that this is merged with the db data at injest
     * and should never be needed elsewhere, especially in
     * front end.
     */
    public getLocationMetadata(dbLocation: string): LocationMetaData {
        return this.locationMetadata.find((meta) =>
            meta.db.includes(dbLocation)
        );
    }
}

export class ResponsiveConfig {
    public followListBreakpoint: number;
}

export class DebugStatus {
    public status = '';
    public opportunityIds: string[] = [];
}

export class DebugSubsector {
    public subsector = '';
    public opportunityIds: string[] = [];
}

export class DebugSubsectors {
    public primarySubsectors: string[];
    public secondarySubsectors: string[];
}

export class DebugSettings {
    public showHealthInfo: boolean;
    public alwaysUseNarrowScreen: boolean;
    public applyDebugStatuses: boolean;
    public applyDebugSubsectors: boolean;
    public debugStatuses: DebugStatus[];
    public allowAllReloads: boolean;
    public applyOlderUpdates: boolean;
    public applyFailedUpdates: boolean;
    public applyUpdatesSince?: Date;
    public debugPrimarySubsectors: DebugSubsector[];
    public debugSecondarySubsectors: DebugSubsector[];
    public email: {
        debugSmtp: boolean;
        debugEndpointEnabled: boolean;
        includeTimestamp: boolean;
        cc: string;
        preview: boolean;
        onlyRender: boolean;
        lowerEnvironmentEmailAddressOverride: string;
        sendEmailsForAllUsersTest: boolean;
    };
    public caching: {
        bypass: boolean;
        verboseLogs: boolean;
        bypassLocalizedDictCache: boolean;
    };
    public juniverse: {
        bypass: boolean;
        autoSendBypass: boolean;
    };

    // FUTURE - it would be nice for these to be non-static methods,
    // but the constructor isn't loaded for all paths and it was
    // deemed too much effort to keep chasing for now
    public static getEnabledFlags(config: DebugSettings): string {
        const enabledFlags = [
            ...(config.showHealthInfo ? ['showHealthInfo'] : []),
            ...(config.alwaysUseNarrowScreen ? ['alwaysUseNarrowScreen'] : []),
            ...(config.applyDebugSubsectors ? ['applyDebugSubsectors'] : []),
            ...(config.caching.bypass ? ['caching.bypass'] : []),
            ...(config.caching.verboseLogs ? ['caching.verboseLogs'] : []),
            ...(config.caching.bypassLocalizedDictCache
                ? ['caching.bypassLocalizedDictCache']
                : []),
            ...(config.email.debugSmtp ? ['email.debugSmtp'] : []),
            ...(config.email.onlyRender ? ['email.onlyRender'] : []),
            ...(config.email.sendEmailsForAllUsersTest
                ? ['email.sendEmailsForAllUsersTest']
                : []),
            ...(config.email.debugEndpointEnabled
                ? ['email.debugEndpointEnabled']
                : []),
            ...(config.juniverse.bypass ? ['juniverse.bypass'] : []),
            ...(config.juniverse.autoSendBypass
                ? ['juniverse.autoSendBypass']
                : []),
        ];
        return enabledFlags.length > 0 ? enabledFlags.join() : `none`;
    }
}

export class Behavior {
    public clearFilterUponNavigateToExplore: boolean;
    public drillDownNavigatesToSearchResults: boolean;
    public lastViewedMyUpdatesLimit?: number;
    public logoutSuccessToastTimeout?: number;
    public tagsPickerModalLaunchTimeout?: number;
    public virtualScrollToTopTimeout?: number;
    public subsectorsIndependentOnDropdown: boolean;
    public subsectorsPerColumn: number;
    public applyPreLaunchUpdates: boolean;
    public initialVisualization: VisualizationType;
}

export class CompanyUpdatePropertyRelationship {
    public key: string;
    public relatedProperties: string[];
}
export function getRelationshipByPropertyName(
    companyUpdatePropertyRelationships: CompanyUpdatePropertyRelationship[],
    propertyName: string
): CompanyUpdatePropertyRelationship {
    return companyUpdatePropertyRelationships.find(
        (cupr) =>
            cupr.key === propertyName ||
            cupr.relatedProperties.find((p) => p === propertyName)
    );
}

export class JuniverseIntegration {
    public enabled: string;
    public tokenEndpoint: string;
    public tokenIssuer: string;
    public tokenAlgorithms: Algorithm[];
    public signingSecret: string;
    public tokenToSend: string;
    public companyOverrideToken: string;
    public internalOverrideToken: string;
    public internalSuperOverrideToken: string;
    public partnerOverrideToken: string;
    public bardaOverrideToken: string;
    public invalidOverrideToken: string;
    public juniverseUrl: string;
    public juniverseLogin: string;
    public paidUserRoles: string;
    public companyRoles: string;
    public bardaRoles: string;
    public internalRoles: string;
    public internalSuperRoles: string;
}

export class JforceIntegration {
    public jforceUrl: string;
}

export interface SmtpInfo {
    host: string;
    port: number;
    username: string;
    password: string;
    from: string;
    replyTo: string;
    // Not certain how a bunyan-compatible logger instance is passed,
    // but it is an option.  True means console, false means no logging.
    // For now, will only support boolean values.  If we ever want to
    // support Bunyan loggers, we can add that type here
    logger: boolean; // | any;
}

export class GeoPosition {
    public lat: number;
    public lng: number;
}

export class PublicOverrides {
    public currentRdStage = false;
    public investmentSummary = false;
    public deals = false;
    public funding = false;
    public companyContact = false;
    public leadProductStage = false;
}

export class FeatureSwitches {
    public enableLogin: boolean;
    public enableShowNewCompanies: boolean;
    public enableByTheNumbers: boolean;
    public enableUserPrefs: boolean;
    public enableFollow: boolean;
    public enableSitemap: boolean;
    public enablePaywall: boolean;
    public enableQfc: boolean;
    public enableBlueKnight: boolean;
    public enableNews: boolean;
    public enableSectors: boolean;
    public enableSecondarySectors: boolean;
    public enableSectorUpdates: boolean;
    public enableLeadProductStage: boolean;
    public enableIndicationUpdates: boolean;
    public enableIndicationFilter: boolean;
    public enableDiversityQuestions: boolean;
    public enableJPAL: boolean;
    public enableAudienceCheck: boolean;
    public enableShowGeoLocationMap: boolean;
    public enableShowTags: boolean;
    public enableShowLocationStatus: boolean;
    public enableDealsWidget: boolean;
    public enableShowCompanyLogos: boolean;
    // enableShowLocations can be explicitly provided, or it will be derived at startup based on whether multiple locations are defined
    public enableShowLocations?: boolean = null;
    public enableDBValuesForMasterData: boolean;
    public includeInPublicView: PublicOverrides;
}

export class MapSettings {
    public apiKey: string;
    public styleKey: string;
    public aspectRatio: number;
    public maxWidth: string;
    public maxHeight: string;
    public centerLngLat?: [number, number];
    public initialBounds?: [[number, number], [number, number]];
    public maxBounds?: [[number, number], [number, number]];
    public initialZoomFactor?: number;
    public minZoomFactor?: number;
    public emptyRadius: number;
    public minRadius: number;
    public maxRadius: number;
    public radiusScaleFactor: number;
}
export class ThemeSettings {
    public logoImgSrc: string;
    public pdfLogoImgPath: string;
    public faviconSrc: string;
    public stayInTheLoopImgSrc: string;
    public sectorColors: string[];
    public sectorContrastLabelColors: string[];
    public map: MapSettings;
    public vars: { [key: string]: string } = {};
    public dropdownItemsPerColumn: number;
}

export class FreeTextSearchTypeahead {
    public debounceTimeMilliseconds: number;
    public matchAfterHowManyLetters: number;
    public numberOfSuggestionsToShow: number;
    public refocusTimeout: number;
}

export class HubSpotIntegration {
    public formId: string;
    public portalId: string;
}

/** All of the extra metatadata associated with a company.
 *  This is used for reanming locations, merging locations,
 *  and configuring datathat cannot be sourced from JFORCE */
export class LocationMetaData {
    /** A list of values from the database which all are treated as the
     * same location  for Navigator */
    public db: string[];

    /** The name used everywhere in Navigator
     * - including indexing, display, and storing
     * transactional data */
    public name: string;

    /** Only used in By The Numbers - All locations with
     *  the same aggregate name will be grouped together
     *  in the By The Numbers location filter */
    public aggregateName?: string;

    /** The city the location is associated with, if applicable */
    public city: string;

    /** The latitude and longitude to place the location on a map */
    public geoPosition: GeoPosition;

    /** @deprecated - Used to determine DEI numbers until
     *  the widget cuts over to the new values */
    public isNorthAmerican: boolean;

    /** Prevents this location from rendering in the
     *  geographic visualiztion */
    public excludeFromMap?: boolean;
}

export class ProgressIndicator {
    public delay: number;
    public pauseForStaticIndicator: number;
}

export class RoundsBySector {
    [sector: string]: CurrentRdStage[];
}

export class SubsectorsBySector {
    [sector: string]: ReferenceValue[];
}

export type ReferenceValueTypes =
    | ReferenceValue[]
    | RoundsBySector
    | SubsectorsBySector;

export class ReferenceValueData {
    public rdStagesBySector: RoundsBySector;
    public dealConfidentialityList: DealConfidentiality[];
    public dealTypeList: DealType[];
    public fundingConfidentialityList: FundingConfidentiality[];
    public fundingCurrencyList: FundingCurrency[];
    public fundingRoundList: ReferenceValue[];
    public securedFundingCurrencyList: SecuredFundingCurrency[];
    public technologyReadinessLevelList: TechnologyReadinessLevel[];
    public countryForDeiReportingList: CountryForDeiReporting[];
    public deiCountriesAndOptionsList: DeiConfig[];
    public blueKnightGoals: ReferenceValue[];
    public teamSizeOptions: ReferenceValue[];
    public blueKnightFundingRounds: ReferenceValue[];
    public blueKnightPharmaStage: ReferenceValue[];
    public blueKnightMedTechStage: ReferenceValue[];
    public regulatoryStages: ReferenceValue[];
    public sectors: ReferenceValue[];
    public subsectors: SubsectorsBySector;
    public years: ReferenceValue[];
    public indications: ReferenceValue[];
}

export type ReferenceValueKey = keyof ReferenceValueData;

export class ReferenceValue {
    public isdeleted?: boolean = false;
    public label?: string;
    public value: string;
}

export class SsoConfig {
    public acsUrl: string;
    public enabled: boolean;
    public entityID: string;
    public idpLoginUrl: string;
    public idpLogoutUrl: string;
    public idpSigningCertLocation: string;
    public isAuthenticatedAssertion: boolean;
}

export class ContingentFundingCurrency extends ReferenceValue {
    public contingentFundingCurrency: string;
}

// FUTURE: Clean up the typing here. I don't think we're getting much from them,
//         and we're not actually using the properties in each type, we're using
//         value & label instead.
export class CurrentRdStage extends ReferenceValue {
    public rank?: number;
    public sector?: string;
}

export class DealConfidentiality extends ReferenceValue {
    public dealConfidentiality: ConfidentialityType;
}

export class DealType extends ReferenceValue {
    public dealType: string;
}

export class FundingConfidentiality extends ReferenceValue {
    public fundingConfidentiality: ConfidentialityType;
}

export class FundingCurrency extends ReferenceValue {
    public fundingCurrency: string;
}

export class SecuredFundingCurrency extends ReferenceValue {
    public securedFundingCurrency: string;
}

export class TechnologyReadinessLevel extends ReferenceValue {}

export class CountryForDeiReporting extends ReferenceValue {}

export class DeiConfig extends ReferenceValue {
    public diversityOptions: DiversityOption[];
}

export class DiversityOption extends ReferenceValue {
    // FUTURE: Default values don't manifest when the config package reads in the default.json
    //  So, they must default to their typical uninitialized default values
    public omitCountryPrefix = false;
    public isMutuallyExclusive = false;
    public isOnlyForBoardAdvisor = false;
}

export enum ConfidentialityType {
    Confidential = 'Confidential',
    Public = 'Public',
}

export interface ContifyConfig {
    apiUrl: string;
    appId: string;
    appKey: string;
    maxNumberOfQueryItemsPerNewsChunk: number;
    newsLanguageId: string;
    apiMaxRetries: number;
    apiWaitTimeBetweenRetriesMillis: number;
    enableCompanyMatching: boolean;
    enableUpdateCompanyNews: boolean;
    enableExternalNewsCronSchedule: boolean;
    enableDetailedMappingLogs: boolean;
    externalNewsCronSchedule: string;
    signalLookbackInDays: number;
}

export interface EmailConfig {
    sendNewsletterEmailsCronSchedule: string;
    sendNewsletterEmailsHourOfDay: number;
    enableSendNewsletterEmails: boolean;
    enablePendingCompanyEditsEmails: boolean;
    sendPendingCompanyEditsEmailsCronSchedule: string;
    pendingCompanyEditsEmailsLookbackThresholdDays: number;
    weeksForEscalation: number;
    pendingCompanyEditsEscalationAddress: string;
    enableUpdateReminderEmails: boolean;
    allowRepeatUpdateRemindersForNonBKCompanies: boolean;
    sendRemindersToAlumni: boolean;
    sendUpdateReminderEmailsCronSchedule: string;
}

export interface WebsiteConfig {
    baseUrl: string;
    unhostedUrl: string;
    hostingDomains: string;
}

export interface LocalizationDict {
    [key: string]: string;
}
