<ng-template #defaultItemTemplate let-item>
  <div class="mutable-collection-item">
    <span class="item">{{getDisplayName(item)}}</span>
    <img class="remove-item" src="/assets/common/close-white-on-gradient-strong.svg" alt="Remove Item" (click)="deleteItem(item)">
  </div>
</ng-template>

<div class="mutable-collection-container" [ngClass]="containerClassName">
  <ng-container *ngFor="let item of items">
    <ng-container *ngTemplateOutlet="itemTemplate ? itemTemplate : defaultItemTemplate; context: { $implicit: item }"></ng-container>
  </ng-container>
</div>
