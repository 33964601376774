
<div class="public-information">
  <property-detail-list 
    [properties]="['problem', 'solution']" 
    [columns]="true"
    [company]="company">
  </property-detail-list>
  <investment-summary
    *ngIf="includeInPublicView.investmentSummary && company.totalSecuredAndContingentAmount > 0"
    [highestLevelOfFundingSecured]="company.highestLevelOfFundingSecured"
    [totalSecuredAndContingentAmount]="company.totalSecuredAndContingentAmount"
  >
  </investment-summary>
  <deals-table
    *ngIf="includeInPublicView.deals"
    [showConfidentialStatus]="false"
    [deals]="company.deals"
  >
  </deals-table>
  <funding-table
    *ngIf="includeInPublicView.funding"
    [showConfidentialStatus]="false"
    [funding]="company.funding">
  </funding-table>
  <news-component *ngIf="featureSwitches.enableNews" [company]="company"></news-component>
</div>