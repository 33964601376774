import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

// model imports
import {
  CompanyUpdateEventOfInterest,
  EventFilterType,
  EventOfInterestType,
  MatchedCompanyInfo,
  NewCompanyEventOfInterest,
  urlIdentifierForCompany,
} from 'company-finder-common';

import { ComponentBase } from '@Common';
import { DeploymentContext } from '@Common';
import { LogoSize } from '@Common';

interface UpdatePill {
  type: EventFilterType;
  value: string;
}

@Component({
    selector: 'update-summary',
    templateUrl: './update-summary.component.html',
    styleUrls: ['./update-summary.component.scss'],
    standalone: false
})
export class UpdateSummaryComponent extends ComponentBase {
  // public properties
  @Input()
  public company: MatchedCompanyInfo;
  @Input()
  public isNewUpdate = false;
  public logoSize: LogoSize = LogoSize.ExtraLarge;
  @Input()
  public update: NewCompanyEventOfInterest | CompanyUpdateEventOfInterest;
  public EventOfInterestTypes = EventOfInterestType;

  public constructor(dc: DeploymentContext, private _router: Router) {
    super(dc);
  }

  // public getters
  public get dateDisplay(): string {
    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: 'numeric',
    };
    const today = new Date();
    const eventDate = new Date(this.update.eventDate);
    if (eventDate.getFullYear() < today.getFullYear()) {
      options.year = 'numeric';
    }
    return eventDate.toLocaleString('en-US', options);
  }

  public get locationDisplay(): string {
    if (this.update.eventType === EventOfInterestType.NewCompany) {
      const event = this.update as NewCompanyEventOfInterest;
      return event.primaryLocation;
    }
    return undefined;
  }

  public get pills(): UpdatePill[] {
    const pills = [];
    this.company?.matchedLocations.map((matchedLocation) =>
      pills.push({
        type: EventFilterType.JLABSLocation,
        value: matchedLocation,
      })
    );
    this.company?.matchedSectors.map((matchedSector) =>
      pills.push({
        type: EventFilterType.SectorsSubSectors,
        value: matchedSector,
      })
    );

    if (this.company?.matchedBlueKnight) {
      pills.push({ type: EventFilterType.BlueKnight, value: 'Blue Knight' });
    }

    return pills;
  }

  public get fieldsUpdated(): string {
    const event =
      this.update.eventType === EventOfInterestType.CompanyUpdate
        ? (this.update as CompanyUpdateEventOfInterest)
        : undefined;

    if (!event) {
      return undefined;
    }

    return event?.displayString ?? undefined;
  }

  public get sharedUpdate(): string {
    if (this.update.eventType === EventOfInterestType.CompanyUpdate) {
      return (this.update as CompanyUpdateEventOfInterest).sharedUpdate;
    }
    return undefined;
  }

  // public methods
  public handleClick(): void {
    // For InTheNews events this relies on openLink() consuming clicks inside the news item box.
    this._router.navigate([
      'company',
      urlIdentifierForCompany(this.company.name),
    ]);
  }

  public openLink(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (this.update.link) {
      window.open(this.update.link, '_blank');
    }
  }
}
