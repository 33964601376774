<ng-container *ngIf="updateNotification">
  <div class="notificationBar">
    <div *ngIf="isSaved()">
      <div class="textBox">
        <div class="title">{{Loc(LocalizedTextIds.NotificationsSavedEdits)}}</div>
        <div class="description">
          <div class="detail">
            <img class="items-icon" src="/assets/update/pink-flag.svg"/>
            <div>{{Loc(LocalizedTextIds.NotificationsYourEditsSaved, formatDate())}}</div>
          </div>
        </div>
      </div>
      <div class="retractBox" (click)="retract()">
        <div class="retractText">{{Loc(LocalizedTextIds.NotificationsRetractDraft)}}</div>
        <div class="right-arrow"></div>
      </div>
    </div>
    <div *ngIf="isPending()">
      <div class="textBox">
        <div class="title">{{Loc(LocalizedTextIds.NotificationsPending)}}</div>
        <div class="description">
          <div class="detail">
            <img class="items-icon" src="/assets/update/pink-flag.svg"/>
            <div>{{Loc(LocalizedTextIds.NotificationsYourEditsSubmitted, formatDate())}}</div>
          </div>
        </div>
      </div>
      <div class="retractBox" (click)="retract()">
        <div class="retractText">{{Loc(LocalizedTextIds.NotificationsRetractSubmission)}}</div>
        <div class="right-arrow"></div>
      </div>
    </div>
    <div *ngIf="isApproved() || isDeclined()" class="reviewed">
      <div class="textBox">
        <div class="title">{{Loc(LocalizedTextIds.Reviewed)}}</div>
        <div class="description">
          <span *ngIf="isApproved()">{{Loc(LocalizedTextIds.NotificationsYourEditsSubmittedReviewedApproved, formatDate())}}</span> 
          <span *ngIf="isDeclined()">{{Loc(LocalizedTextIds.NotificationsYourEditsSubmittedReviewedDeclined, formatDate())}}</span>
          <span *ngIf="updateNotification.comments"> {{Loc(LocalizedTextIds.NotificationsTheTeam)}}</span>
        </div>
        <div class="comments" *ngIf="updateNotification.comments">{{updateNotification.comments}} </div>
      </div>
    </div>
  </div>
</ng-container>
