import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DisplayItem } from 'company-finder-common';
import _ from 'lodash-es';
import { ComponentBase } from '@Common';

@Component({
    selector: 'saved-search-criteria',
    templateUrl: './saved-search-criteria.component.html',
    styleUrls: ['./saved-search-criteria.component.scss'],
    standalone: false
})
export class SavedSearchCriteriaComponent<T extends DisplayItem>
  extends ComponentBase
  implements OnChanges
{
  public isOpen = false;

  @Input() criteria: T[];
  @Input() selectionType: 'location' | 'sector' | 'status';
  @Input() label: string;
  @Input() optionData: any;
  @Output() criteriaChange = new EventEmitter<T[]>();

  private _selectedItems: T[] = [];

  public get numberSelected(): number {
    return this._selectedItems.length;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.criteria) {
        this._selectedItems = changes.criteria.currentValue ?? [];     
    }
  }

  public handleSelectionUpdate(selectedItems: T[]): void {
    this._selectedItems = selectedItems;
    this.criteriaChange.emit(selectedItems);
  }

  public toggleSelector(): void {
    this.isOpen = !this.isOpen;
  }
}
