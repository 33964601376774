import { Company } from "../model/company.js";
import { LocalizedTextIds } from "./localizedTextIds.js";
import { Localizer } from "./localizer.js";

export class StatusMetaData {
    public groupName: string;
    public selectionType: 'single' | 'multiple';
    public statusType: 'company' | 'location';
    public displayOrder: number;
    public excludeOnCoalsce: boolean;
    public values: MetaDataValue[];
}

export class MetaDataValue {
    public value: string;
    public display: string;
    public tooltip?: string;
    public excludeFromEmail: boolean;
}

export class StatusMetaDataCollection {
    public statusForTile = '';
    public metadata: StatusMetaData[] = [];
    public specialDesignationOrder = 1;

    public constructor(
        statusForTile: string,
        statusMetadata: StatusMetaData[],
        specialDesignationOrder: number
    ) {
        this.statusForTile = statusForTile;
        this.metadata = statusMetadata;
        this.specialDesignationOrder = specialDesignationOrder;
    }

    public getMetadata(status: string): StatusMetaData {
        return this.metadata.find((meta) =>
            meta.values.find((val) => val.value === status)
        );
    }

    public getGroupOrderByDisplay(
        display: string,
        localizer: Localizer
    ): number {
        // ADJQ-1682 We treat Blue Knight and QFC Winner as company
        // statuses in most ways, but they are stored differently
        // and don't have Metadata. Since we need to be able to sort
        // them, return them in a config-defined group
        if (
            display === localizer.Localize(LocalizedTextIds.BlueKnight) ||
            display === localizer.Localize(LocalizedTextIds.QFCAwardee)
        ) {
            return this.specialDesignationOrder;
        }

        return (
            this.metadata.find((meta) =>
                meta.values.find((val) => val.display === display)
            )?.displayOrder ?? 99
        );
    }

    public get tileMetadata(): StatusMetaData {
        return this.getMetadata(this.statusForTile);
    }

    public get tileMetadataDisplayValue(): string {
        return (
            this.getMetadataValue(this.statusForTile)?.display ??
            this.statusForTile
        );
    }

    public getMetadataValue(status: string): MetaDataValue {
        const statusMetadata = this.getMetadata(status);
        return statusMetadata?.values.find((value) => value.value === status);
    }

    public getMetadataDisplayValue(status: string): string {
        const statusMetadata = this.getMetadata(status);
        return (
            statusMetadata?.values.find((value) => value.value === status)
                ?.display ?? status
        );
    }

    public getDisplayNames(statuses: string[]): string[] {
        return (
            statuses?.map((status) => this.getStatusDisplayName(status)) ?? []
        );
    }

    public getStatusDisplayName(status: string): string {
        const value = this.getMetadataValue(status);
        return value?.display ?? status;
    }

    public getStatusTooltip(status: string): string {
        const value = this.getMetadataValue(status);
        return value?.tooltip;
    }

    public isExcludedFromEmail(status: string): boolean {
        const value = this.getMetadataValue(status);
        return value?.excludeFromEmail ?? true;
    }

    public getCompaniesWithStatus(
        searchStatus: string,
        companies: Company[]
    ): Company[] {
        const statusMeta = this.getMetadata(searchStatus);

        if (!statusMeta) {
            return [];
        }

        switch (statusMeta.statusType) {
            case 'location':
                return companies.filter((company) =>
                    company.locations.some((location) =>
                        location.locationStatuses.some(
                            (status) => status === searchStatus
                        )
                    )
                );
            case 'company':
                return companies.filter((company) =>
                    company.statuses.some((status) => status === searchStatus)
                );
        }
    }

    public groupStatuses(statuses: string[]): {
        locationStatuses: string[];
        companyStatuses: string[];
    } {
        const locationStatuses = [];
        const companyStatuses = [];

        statuses.forEach((status) => {
            const meta = this.getMetadata(status);

            switch (meta.statusType) {
                case 'company':
                    companyStatuses.push(status);
                    break;
                case 'location':
                    locationStatuses.push(status);
                    break;
            }
        });

        return { locationStatuses, companyStatuses };
    }
}
