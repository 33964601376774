import { Component, Input } from '@angular/core';
import { ComponentBase } from '@Common';
import { Funding } from 'company-finder-common';
@Component({
    selector: 'funding-table',
    templateUrl: './funding-table.component.html',
    styleUrls: ['./funding-table.component.scss'],
    standalone: false
})
export class FundingTableComponent extends ComponentBase {
  @Input()
  public funding: Funding[];

  @Input()
  public showConfidentialStatus = true;

  public get filteredFunding(): Funding[] {
    return this.funding.filter((funding)=> !funding.isConfidential || this.showConfidentialStatus)
  }
}
