import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

import { DeploymentContext } from '../deployment-context/deployment-context.js';

@Injectable({providedIn: 'root'})
export class TitleAndMetadata {
  public constructor(
    private _titleService: Title,
    private _metaService: Meta,
    private context: DeploymentContext
  ) { }

  public setPageTitle(name: string): void {
    this._titleService.setTitle(name);
    this.upsertMetaTag('og:title', name, 'property');
    this.upsertMetaTag('twitter:title', name);

    this.context.sendMessageToIframe({ for: 'title', operation: 'change', name: name });
  }

  public upsertMetaTagDescription(content: string): void {
    this.upsertMetaTag('description', content);
    this.upsertMetaTag('og:description', content, 'property');
    this.upsertMetaTag('twitter:description', content);
  }

  public upsertMetaTag(
    name: string,
    content: string,
    attribute: string = 'name'
  ): void {
    const attrSelector = `${attribute}='${name}'`;
    const tag = {
      content: content,
    };
    tag[attribute] = name;
    if (this._metaService.getTag(attrSelector)) {
      this._metaService.updateTag(tag, attrSelector);
    } else {
      this._metaService.addTag(tag, false);
    }

    this.context.sendMessageToIframe(
      {
        for: 'meta',
        operation: 'change',
        attribute: attribute,
        name: name,
        content: content,
      }
    );
  }
}
