@import "site";

companies-picker-modal {
  .modal-title-icon {
    @include iconAsBackgroundImage(
      url("/assets/common/building-1.svg"),
      24px,
      18px
    );
  }

  company-logo,
  .company-name-and-sectors {
    margin: auto;
  }
  company-logo {
    flex: 0 1 auto;
  }
  company-logo {
    border: 1px solid $neutral-04;
    border-radius: $small-border-radius;
  }
  .company-name-and-sectors {
    flex: 1 1 auto;
    margin-left: 10px;
    margin-right: 10px;

    .company-sectors {
      color: $neutral-08;
      font-size: $font-size-small;
      font-weight: 300;
      line-height: 17px;
      max-width: 400px;
    }
    .company-name {
      color: $black;
      font-size: $font-size-normal;
      font-weight: 300;
      line-height: 22px;
    }
  }
  .bottom-form label.item-info {
    padding-left: 0px !important;
  }
}
