import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentBase } from '@Common';
import {
  Filter,
  Location,
  SectorAndParentIds,
  StatusDisplayItem,
  determineSectorsAndSubsectors,
  LocalizedTextIds,
} from 'company-finder-common';
import { DeploymentContext } from '@Common';
import { SearchService } from '@Common';
import { WebAnalyticsService } from '@Common';
import { MenuOptionService } from '@Common';
import {
  GroupedMenuOptions,
  MenuOption,
} from '@Common';
import { AuthnService } from '@Common';
import { BreadcrumbsService } from '@Common';

@Component({
    selector: 'filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss'],
    providers: [MenuOptionService],
    standalone: false
})
export class FilterComponent
  extends ComponentBase
  implements OnDestroy, OnInit
{
  public title = this.Loc(LocalizedTextIds.FilterFilters);
  @Input()
  public isSearchResultsScreen = false;
  public filter: Filter;

  /** An explicit list of the "hidden" filter constraints (ADJQ-199).
   * Note that this is dynamically augmented with 0 or more potential tag values.
   */
  private additionalFilterConstraints: AdditionalFilterConstraint[] = [];

  public constructor(
    dc: DeploymentContext,
    private _searchService: SearchService,
    private _router: Router,
    private _webAnalyticsService: WebAnalyticsService,
    private _menuOptionService: MenuOptionService,
    private _authnService: AuthnService,
    private _breadcrumbsService: BreadcrumbsService
  ) {
    super(dc);

    if (this.featureSwitches.enableSectors) {
      this.additionalFilterConstraints.push(
        new AdditionalFilterConstraint(
          () => this.Loc(LocalizedTextIds.FilterCrossSector),
          () => this.filter.isCrossSector,
          () => (this.filter.isCrossSector = false)
        )
      );
    }
    this.additionalFilterConstraints.push(
      new AdditionalFilterConstraint(
        () => this.Loc(LocalizedTextIds.FilterLastDays),
        () => this.filter.isNewInLast90Days,
        () => (this.filter.isNewInLast90Days = false)
      )
    );
    this.additionalFilterConstraints.push(
      new AdditionalFilterConstraint(
        () => this.Loc(LocalizedTextIds.FilterLastQuarter),
        () => this.filter.isNewInLastQuarter,
        () => (this.filter.isNewInLastQuarter = false)
      )
    );
    if (this.featureSwitches.enableBlueKnight) {
      this.additionalFilterConstraints.push(
        new AdditionalFilterConstraint(
          () => this.Loc(LocalizedTextIds.BlueKnight),
          () => this.filter.isBlueKnight,
          () => (this.filter.isBlueKnight = false)
        )
      );
    }
    if (this.featureSwitches.enableQfc) {
      this.additionalFilterConstraints.push(
        new AdditionalFilterConstraint(
          () => this.Loc(LocalizedTextIds.QFCAwardee),
          () => this.filter.isQFCWinner,
          () => (this.filter.isQFCWinner = false)
        )
      );
    }
    this.additionalFilterConstraints.push(
      new AdditionalFilterConstraint(
        () => this.filter.currentRdStage,
        () => !!this.filter.currentRdStage,
        () => (this.filter.currentRdStage = undefined)
      )
    );
  }

  public get followedCompaniesOnly(): boolean {
    return this.filter && this.filter.isFollowedCompaniesOnly;
  }

  public set followedCompaniesOnly(value: boolean) {
    this.filter.isFollowedCompaniesOnly = value;
    this._searchService.filterSubject.next(this.filter);
    this.trackForAnalytics('isFollowedCompaniesOnly', value);
  }

  public get enableNavigateToSearch(): boolean {
    return this._deploymentContext.rawConfig.behavior
      .drillDownNavigatesToSearchResults;
  }

  public get hasSearchPredicate(): boolean {
    return !!this._searchService.currentSearchPredicate;
  }

  public get showClearFilterAction(): boolean {
    return this.filter && !this.filter.isShowAll();
  }

  public get isFollowEnabled(): boolean {
    return this._deploymentContext.featureSwitches.enableFollow;
  }

  public get isInternalView(): boolean {
    return this._authnService.isInternal;
  }

  public get locationOptions(): MenuOption<Location>[] {
    return this._menuOptionService.locationOptions;
  }

  public get indicationOptions(): MenuOption<string>[] {
    return this._menuOptionService.indicationOptions;
  }

  public get statusOptions(): GroupedMenuOptions<StatusDisplayItem>[] {
    return this._menuOptionService.statusOptions;
  }

  public get hasAdditionalFilterConstraints(): boolean {
    return this.activeAdditionalFilterConstraints.length > 0;
  }

  public get optionsForSingleRow(): MenuOption<StatusDisplayItem>[] {
    return this.statusOptions[0]?.options ?? [];
  }

  /** This will augment the base set of AdditionalFilterConstraints with any selected tag values */
  public get activeAdditionalFilterConstraints(): AdditionalFilterConstraint[] {
    if (!this.filter) {
      return [];
    }

    const tagFilterConstraints = new Array<AdditionalFilterConstraint>();
    this.filter.tags?.forEach((tagValue) => {
      tagFilterConstraints.push(
        new AdditionalFilterConstraint(
          () => tagValue,
          () => true,
          () =>
            (this.filter.tags = this.filter.tags.filter(
              (value) => value !== tagValue
            ))
        )
      );
    });

    return [
      ...this.additionalFilterConstraints,
      ...tagFilterConstraints,
    ].filter((item) => item.hasValue());
  }

  public get hideLocationSelector(): boolean {
    return this.locationOptions.length <= 2;
  }

  public get hidePrimarySectorSelector(): boolean {
    return !this.featureSwitches.enableSectors;
  }

  public get showIndicationSelector(): boolean {
    return !this.featureSwitches.enableIndicationFilter;
  }

  public get hideSecondarySelector(): boolean {
    return (
      !this.featureSwitches.enableSectors ||
      !this.featureSwitches.enableSecondarySectors
    );
  }

  async ngOnInit(): Promise<void> {
    this.filter = this._searchService.filter;

    this._menuOptionService.initMenus(this.filter);
    this._searchService.filter = this._searchService.filter ?? new Filter();

    this.addSubscription(
      this._searchService.drilldownSubject.subscribe(() => {
        const currentlyOnSearchResultsView = this._router.isActive(
          '/search-results',
          {
            paths: 'subset',
            queryParams: 'subset',
            fragment: 'ignored',
            matrixParams: 'ignored',
          }
        );

        // Whenever we drilldown, reset the saved position in the search results page
        this._breadcrumbsService.setSearchPageOffset(0);

        // FUTURE: The quick implementation of ADJQ-210 (and maybe partly due to ADJQ-184) resulted in this logic getting complicated!
        //  But it avoids an infinite recursion when drillDownNavigatesToSearchResults is off and filtering when on the search-results page.
        //  Seems to be due to an interaction between filterSubject and drilldownSubject subscribers,
        //  but I didn't have the time to unravel that when implementing ADJQ-210, hence the logic tree below.
        if (
          (this._authnService.isAuthenticatedUser ||
            !this._deploymentContext.featureSwitches.enablePaywall) &&
          (this.enableNavigateToSearch ||
            currentlyOnSearchResultsView ||
            this.hasSearchPredicate)
        ) {
          this._searchService.navigateToSearchResults();
        } else {
          // This seems sufficient/necessary to refresh the Explore view,
          // but it would infinitely recurse if called when already on the search results view
          this._searchService.filterSubject.next(this.filter);
        }
      })
    );

    // listen for message indicating click from parent frame
    if (this._deploymentContext?.hosted()) {
      this._deploymentContext.listenForClick();
    }
  }

  public ngOnDestroy(): void {
    if (this._subscriptions) {
      this._subscriptions.unsubscribe();
      this._subscriptions = undefined;
    }
  }

  public updateLocationFilter(selectedLocations: Location[]): void {
    this.filter.locations = selectedLocations.map((loc) => loc.name);
    this._searchService.filterSubject.next(this.filter);
  }

  public updateIndicationFilter(selectedIndications: string[]): void {
    this.filter.indications = selectedIndications;
    this._searchService.filterSubject.next(this.filter);
  }

  public updateSectorFilter(selectedSectors: SectorAndParentIds[]): void {
    const [sectors, subsectors] =
      determineSectorsAndSubsectors(selectedSectors);
    this.filter.primarySectors = sectors;
    this.filter.primarySubSectors = subsectors;
    this._searchService.filterSubject.next(this.filter);
  }

  public updateSecondarySectorFilter(
    selectedSectors: SectorAndParentIds[]
  ): void {
    const [sectors, subsectors] =
      determineSectorsAndSubsectors(selectedSectors);
    this.filter.secondarySectors = sectors;
    this.filter.secondarySubSectors = subsectors;
    this._searchService.filterSubject.next(this.filter);
  }

  public updateStatusFilter(): void {
    const allOptions = [];

    this.statusOptions.forEach((group) => {
      allOptions.push(...group.options);
    });

    const selectedStatuses = allOptions
      .filter((statusOption) => statusOption.value)
      .map((selectedOption) => selectedOption.dataModel.status as string);

    const statuses = this._deploymentContext.groupStatuses(selectedStatuses);

    this.filter.locationStatuses = statuses.locationStatuses.toString();
    this.filter.companyStatuses = statuses.companyStatuses.toString();
    this._searchService.filterSubject.next(this.filter);
  }

  public get useStatusDropdown(): boolean {
    return this._deploymentContext.statusMetadata?.length > 1;
  }

  public clearAdditionalFilterConstraint(
    item: AdditionalFilterConstraint
  ): void {
    item.clearValue();
    this._searchService.filterSubject.next(this.filter);
  }

  public clearFilter(): void {
    this.filter.clear();
    this._menuOptionService.initMenus(this.filter);
    this._searchService.filterSubject.next(this.filter);
    this._webAnalyticsService.trackEvent('clear-filter');
    if (this.isSearchResultsScreen) {
      this._searchService.navigateToSearchResults();
    }
  }

  public trackForAnalytics(
    trackingCriteria: string,
    value: boolean | MenuOption<unknown>
  ): void {
    this._webAnalyticsService.trackEvent('filter', {
      category: `Filter by ${trackingCriteria}`,
      label:
        typeof value === 'boolean' || !value
          ? `${value}`
          : `${(value as MenuOption<unknown>).label} - ${
              (value as MenuOption<unknown>).value
            }`,
      // FUTURE: This feels a bit fragile, but if we don't have a count yet from a previous search, assume we are working off the full set
      value:
        this._searchService.companyCountFromLastSearch ||
        this._deploymentContext.comprehensiveSummary?.numberOfCompanies,
    });
  }
}

class AdditionalFilterConstraint {
  constructor(
    public displayString: () => string,
    public hasValue: () => boolean,
    public clearValue: () => void
  ) {}
}
