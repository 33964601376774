<!-- Default template for displaying a menu option with a checkbox -->
<ng-template #defaultOptionDisplayTemplate let-menuOption="menuOption">
  <input type="checkbox" class="checkbox" [ngClass]="{ 'indeterminate': indeterminateOptions[menuOption.id] }"
    [attr.id]="menuOption.id" [attr.name]="menuOption.id" [(ngModel)]="menuOption.value"
    (change)="updateSelection(menuOption)">
  <label [for]="menuOption.id" [ngClass]="{'selected': menuOption.value}">{{menuOption.label}}</label>
</ng-template>

<div class="dropdown-menu" [ngClass]="{narrowScreen: narrowScreen}">
  <div class="dropdown-menu-button-container">
    <label *ngIf="label" class="dropdown-menu-label" (click)="toggleMenu()">{{Loc(label)}}</label>
    <label *ngIf="!label" class="dropdown-menu-label">&nbsp;</label>
    <button class="dropdown-menu-value" (click)="toggleMenu()">
      <span class="dropdown-menu-caret" [ngClass]="{'open': isMenuOpen, 'closed': !isMenuOpen}"></span>
    </button>
  </div>
  <div #dropdownMenuContent class="dropdown-menu-content client" keepInsideViewport *ngIf="isMenuOpen">
    <!-- extra div provides some padding around the scrollable area -->
    <div class="dropdown-menu-options-container">
      <ng-template
        *ngTemplateOutlet="menuStructureTemplate;
        context: {
          menuOptions: menuOptions,
          parent: true,
          dropdownMenuComponent: this,
          columns: columns,
          itemsPerColumn: itemsPerColumn
        }"
      >
      </ng-template>
    </div>
  </div>
</div>
