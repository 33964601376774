<div *ngIf="showBanner" class="cf_log-in-out-banner">
    <div class="content">
        <div class="message">
            <div *ngIf="!isInternal">
                <span class="emphasized">{{Loc(LocalizedTextIds.LogInOutBannerYouAreCurrently)}}</span>
                {{Loc(LocalizedTextIds.LogInOutBannerNeedToGoBack)}}
            </div>
            <div *ngIf="isInternal">
                <div *ngIf="name">
                    {{Loc(LocalizedTextIds.LogInOutBannerAuthenticatedAs)}}
                    <span class="emphasized">{{name}}</span>
                    {{Loc(LocalizedTextIds.LogInOutBannerToInternalVersion)}}
                </div>
                <div *ngIf="!name">
                    {{Loc(LocalizedTextIds.LogInOutBannerAutheticatedToInternal)}}
                </div>
            </div>
        </div>
        <div>
            <div *ngIf="!isInternal" class="action" (click)="login()">
                <div>{{Loc(LocalizedTextIds.LogIn)}}</div>
            </div>
            <div *ngIf="isInternal" class="action" (click)="logout()">
                <div>{{Loc(LocalizedTextIds.LogOut)}}</div>
            </div>
        </div>
    </div>
</div>
