<div class="cf_simple-tab-ui">
    <ng-container *ngFor="let tab of tabs; trackBy: tabInfoTrackBy">
        <div class="tab" (click)="changeTab(tab)">
            <div class="label">
                <div class="title" [ngClass]="{ 'current': isShowingTab(tab) }">{{ tab.label }}</div>
                <div *ngIf="tab.lockIcon" class="lock"></div>
            </div>
            <div class="errorInfo">
                <ng-container *ngIf="tabErrorInfo(tab) as errorInfo">
                    <span *ngIf="errorInfo.length > 0" title="{{errorInfo.join('\n')}}">{{errorInfo.length}} error(s)</span>
                </ng-container>
            </div>
            <div class="highlight" [ngClass]="{ 'current': isShowingTab(tab) }" [ngStyle]="highlightWidthStyle(tab)"></div>
        </div>
    </ng-container>
</div>
