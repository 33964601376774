import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthnService } from '@Common';
import { CompanyResolver } from '@Common';
import { CompanyService } from '@Common';
import { Company, CompanyResolutionType } from 'company-finder-common';
import { UserService } from '../services/user/user.service.js';
import { WebAnalyticsService } from '../services/web-analytics/web.analytics.js';
import { ApplicationContext } from '../utilities/application-context/application-context.js';

@Injectable({providedIn: 'root'})
export class CompanyUpdateResolver extends CompanyResolver {
  constructor(
    protected _companyService: CompanyService,
    protected _authService: AuthnService,
    protected _userService: UserService,
    protected webAnalyticsService: WebAnalyticsService,
    protected context: ApplicationContext,
    router: Router
  ) {
    super(
      _companyService,
      _authService,
      _userService,
      CompanyResolutionType.Update,
      webAnalyticsService,
      context,
      router
    );
  }

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<Company> {
    return super.resolve(route, state);
  }
}
