<div class="cf_navigation-panel">
  <ng-container>
    <div class="explore" (click)="emitGoHome()">
      <div class="title">{{Loc(LocalizedTextIds.Explore)}}</div>
      <div class="highlight"></div>
    </div>
  </ng-container>
  <ng-container *ngIf="isFollowEnabled && isInternal">
    <div class="following" (click)="emitGoToMyUpdates()">
      <div class="count-badge" *ngIf="unseenEventCount > 0">{{unseenEventCount}}</div>
      <div class="title">{{Loc(LocalizedTextIds.Following)}}</div>
      <div class="highlight"></div>
    </div>
  </ng-container>
  <ng-container *ngIf="isFollowEnabled && isInternal">
    <div class="preferences" (click)="emitGoToPreferences()">
      <div class="title">{{Loc(LocalizedTextIds.Preferences)}}</div>
      <div class="highlight"></div>
    </div>
  </ng-container>
  <ng-container *ngIf="isCompanyContact">
    <div class="profile" (click)="emitGoToCompanyUpdate(0)">
      <div class="title">{{Loc(LocalizedTextIds.CompanyProfile)}}</div>
      <div class="highlight"></div>
    </div>
  </ng-container>
  <ng-container *ngIf="isReviewer">
    <div class="review" [ngClass]="{'no-updates': reviews.length === 0}" (click)="emitShowPendingUpdates()">
      <div class="count-badge" *ngIf="updateCount > 0">{{updateCount}}</div>
      <div class="title">{{Loc(LocalizedTextIds.Review)}}</div>
      <div class="highlight"></div>
    </div>
  </ng-container>
</div>
