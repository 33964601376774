import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'select-input',
    templateUrl: './select-input.component.html',
    styleUrls: ['./select-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectInputComponent),
            multi: true,
        },
    ],
    standalone: false
})
export class SelectInputComponent<T> implements ControlValueAccessor {
  @Input()
  public name: string;

  @Input()
  public options: T[] = [];

  /**
   * Convert a value of type T into a string that can be used as a DOM value attribute.
   * Also used to label the option for the user.
   */
  @Input()
  public getSerializedValue: (value: T) => string;

  @Input()
  public variant: 'update' | 'follow' | undefined;

  @Input()
  public placeholder: string;

  @Input()
  public fullWidth = false;

  public disabled: boolean;
  public inFocus = false;
  private _value: string = null;

  public onTouched: () => unknown = () => {};
  private onChange: (value: T) => unknown = () => {};

  public get cssClass(): string {
    return `${this.variant} ${this.inFocus ? 'focus' : ''}`;
  }
  public get noSelection(): boolean {
    return this.value === null;
  }

  public get value(): string {
    return this._value;
  }

  public set value(val: string) {
    this._value = val;
    const item = this.options.find(
      (option) => this.getSerializedValue(option) === this._value
    );
    this.onChange(item);
  }

  public selectTrackBy(_index: number, item: T): string {
    return this.getSerializedValue(item);
  }

  public writeValue(val: T): void {
    this.value = this.getSerializedValue(val);
  }

  public registerOnChange(changeHandler: (value: T) => unknown): void {
    this.onChange = changeHandler;
  }

  public registerOnTouched(touchHandler: () => unknown): void {
    this.onTouched = touchHandler;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
