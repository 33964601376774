import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { IUser } from 'company-finder-common';
import { UserService } from '../services/user/user.service.js';

@Injectable({providedIn: 'root'})
export class UserResolver  {
  constructor(private _userService: UserService) {}

  public async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<IUser> {
    // Ensure we have established whether the user is a Juniverse user
    // prior to the normal getCachedUser call that returns the Navigator authenticated identity.
    // This was designed to be able to be repeatedly called, but will short circuit
    // all but the first time. It was previously hooked into the AppComponent.ngOnInit
    // but (emperically) determine that route component's ngOnInit could happen in parallel with that.
    // So, it was moved here as part of ADJQ-1702.
    await this._userService.singleAttemptAtJuniverseAuth();

    return this._userService.getCachedUser();
  }
}
