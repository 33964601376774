<div class="jnjinternal">
    <div *ngIf="hasInternalCompanyDetails"
      class="companydetails">
      <property-detail-list [properties]="['keyMgmtAndAdvBm', 'keyDifferentiation']" [company]="company">
      </property-detail-list>
    </div>

    <div *ngIf="hasProgressUpdate"
      class="recentupdate">
      <div class="titlerow">
        <div>{{Loc(LocalizedTextIds.CompanyDetailsRecentUpdate)}}</div>
        <a style="text-decoration: none" target="_blank">
          <div class="jforceLink">
            <div class="text">{{Loc(LocalizedTextIds.CompanyDetailsViewMore)}}</div>
            <div class="right-arrow"></div>
          </div>
        </a>
      </div>
      <div class="dividingline"></div>
      <div class="entry">
        <div class="datetime">
          <div class="date">{{company.progressCreatedDate
            |
            date:'longDate' : 'GMT'}}</div>
        </div>
        <div class="content">
          <div>{{company.progressSubject}}
          </div>
          <div class="details">
            <show-more-less [content]="company.progressUpdate" [maxHeight]="96"></show-more-less>
          </div>
        </div>
      </div>
    </div>
    <investment-summary
      [highestLevelOfFundingSecured]="company.highestLevelOfFundingSecured"
      [totalSecuredAndContingentAmount]="company.totalSecuredAndContingentAmount"
    ></investment-summary>
    <div *ngIf="hasRelatedRecords"
      class="relatedrecords">
      <deals-table
        [deals]="company.deals">
      </deals-table>
      <div *ngIf="company.opportunities?.length > 0"
        class="opportunities">
        <div class="titlerow">
          <div class="section-header">
            {{Loc(LocalizedTextIds.CompanyDetailsOpportunities)}}
          </div>
          <a style="text-decoration: none" target="_blank">
            <div class="jforceLink">
              <div class="text">{{Loc(LocalizedTextIds.CompanyDetailsViewMore)}}</div>
              <div class="right-arrow"></div>
            </div>
          </a>
        </div>
        <table class="table">
          <tr>
            <th>{{Loc(LocalizedTextIds.CompanyDetailsType)}}</th>
            <th>{{Loc(LocalizedTextIds.CompanyDetailsStage)}}</th>
            <th>{{Loc(LocalizedTextIds.CompanyDetailsCloseDate)}}</th>
            <th>{{Loc(LocalizedTextIds.CompanyDetailsDealType)}}</th>
            <th>{{Loc(LocalizedTextIds.Sector)}}</th>
            <th>{{Loc(LocalizedTextIds.SubSector)}}</th>
          </tr>
          <tr *ngFor="let item of company.opportunities">
            <td>{{item.recordType}}</td>
            <td>{{item.stage}}</td>
            <td>{{item.closedDate | date : 'mediumDate' : 'GMT'}}</td>
            <td>{{item.opportunityType}}</td>
            <td>{{item.primarySector}}</td>
            <td>{{item.primarySubSector}}</td>
          </tr>
        </table>
      </div>
    </div>
    <funding-table
      [funding]="company.funding">
    </funding-table>
  </div>