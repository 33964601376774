<picker-modal
    (action)="doAction($event)"
    (wizardAction)="doWizardAction($event)"
    (select)="doSelect($event)"
    [user]="user"
    [totalMatchedCompanies]="totalMatchedCompanies"
    [(showModal)]="showModal"
    modalTitle={{Loc(LocalizedTextIds.CompaniesPickerModalAddCompanies)}}
    blurb={{Loc(LocalizedTextIds.CompaniesPickerModalCompaniesInclude)}}
    objectsTypeName="companies"
    filterPlaceholder={{Loc(LocalizedTextIds.CompaniesPickerModalFilterList)}}
    [compareFunc]="compareCompanies"
    [filterFunc]="doFilter"
    [isOnBoardingWizard]="isOnBoardingWizard"
    [wizardStep]="1">
    <ng-template #itemTemplate let-company="item">
        <company-logo [company]="company" [size]="logoSize"></company-logo>
        <div class="company-name-and-sectors">
            <div class="company-name selected-text-color">{{company.name}}</div>
            <div class="company-sectors">{{company.primarySector}} > {{company.primarySubSector?.join(', ')}} > {{company.primaryIndication}}</div>
        </div>
    </ng-template>
</picker-modal>
