<company-update-modal
  [showModal]="showModal"
  [maxHeight]="710"
  [heightPercent]="85"
  modalTitle={{Loc(LocalizedTextIds.FundingModalFundingStatus)}}
  [isItemDeleted]="funding.isDeleted"
  [isItemNew]="!funding.fundingId"
  [isSaveDisabled]="!isSaveEnabled"
  [updateStatus]="fundingUpdateStatus"
  (action)="handleAction($event)">
  <div class="scroll-area">
    <form [formGroup]="fundingForm" class="fields" [ngClass]="{'deleted': funding.isDeleted}">
      <div class="field-row">
        <div class="field">
          <div class="label">
            <div class='text'
              [ngClass]="{ 'modified': selfUpdateMode === SelfUpdateModes.Review && hasPropertyBeenModified('fundingRound')}">
              {{Loc(LocalizedTextIds.FundingModalFundingRound)}}
            </div>
            <div class="tooltip" (mouseenter)="showTooltip('round')" (mouseleave)="hideTooltip('round')">
              <div class="bubble below" *ngIf="isShowingTooltip['round']">
                {{Loc(LocalizedTextIds.FundingModalOrganization)}} </div>
            </div>
          </div>
          <select-input *ngIf="!funding.isDeleted" name="funding-modal-fundingRound"
            [formControl]="fundingForm.controls['fundingRound']" variant="update" placeholder={{Loc(LocalizedTextIds.FundingModalSelectRound)}}
            [options]="fundingRoundTypes" [getSerializedValue]="getReferenceValueDisplayString">
          </select-input>
          <div class="deleted-select" *ngIf="funding.isDeleted">{{funding.fundingRound}}</div>
        </div>
        <div class="field">
          <div class="label">
            <div class='text'
              [ngClass]="{ 'modified': selfUpdateMode === SelfUpdateModes.Review && hasPropertyBeenModified('fundingParty')}">
              {{Loc(LocalizedTextIds.FundingModalFundingParty)}}
            </div>
            <div class="tooltip" (mouseenter)="showTooltip('party')" (mouseleave)="hideTooltip('party')">
              <div class="bubble below" *ngIf="isShowingTooltip['party']">
                {{Loc(LocalizedTextIds.FundingModalOrganization)}} </div>
            </div>
          </div>
          <input
            id="funding-modal-fundingParty"
            name="funding-modal-fundingParty"
            formControlName="fundingParty" 
            maxlength="255"/>
        </div>
      </div>
      <div class="field-row">
        <div class="field">
          <div class="label">
            <div class='text'
              [ngClass]="{ 'modified': selfUpdateMode === SelfUpdateModes.Review && hasPropertyBeenModified('raised')}">
              {{Loc(LocalizedTextIds.FundingModalAmountRaised)}}
            </div>
            <div class="tooltip" (mouseenter)="showTooltip('amount')" (mouseleave)="hideTooltip('amount')">
              <div class="bubble" *ngIf="isShowingTooltip['amount']">
                {{Loc(LocalizedTextIds.FundingModalDollarsThatWere)}} </div>
            </div>
          </div>
          <input type="number" min="0"
            oninput="validity.valid||(value=Math.abs(value))"
            (keydown.arrowup)="false"
            (keydown.arrowdown)="false"
            id="funding-modal-raised"
            name="funding-modal-raised"
            formControlName="raised" />
        </div>
        <div class="field">
          <div class="label">
            <div class='text'
              [ngClass]="{ 'modified': selfUpdateMode === SelfUpdateModes.Review && hasPropertyBeenModified('raisedCurrency')}">
              {{Loc(LocalizedTextIds.Currency)}}
            </div>
          </div>
          <select-input *ngIf="!funding.isDeleted" name="funding-modal-raisedCurrency"
            [formControl]="fundingForm.controls['raisedCurrency']" variant="update" placeholder="Select currency..."
            [options]="currencyTypes" [getSerializedValue]="getReferenceValueDisplayString">
          </select-input>
          <div class="deleted-select" *ngIf="funding.isDeleted">{{funding.raisedCurrency}}</div>
        </div>
      </div>
      <div class="field-row">
        <div class="field">
          <div class="label">
            <div class='text'
              [ngClass]="{ 'modified': selfUpdateMode === SelfUpdateModes.Review && hasPropertyBeenModified('dateRaised')}">
              {{Loc(LocalizedTextIds.FundingModalDateRaised)}}
            </div>
            <div class="tooltip" (mouseenter)="showTooltip('date')" (mouseleave)="hideTooltip('date')">
              <div class="bubble" *ngIf="isShowingTooltip['date']">
                {{Loc(LocalizedTextIds.FundingModalDateOrApproximate)}} </div>
            </div>
          </div>
          <div class='date-wrapper'>
            <!--
              Browsers manage the Date's yyyy entry differently, but they seem to need a max value to at least behave similarly.
              The change handler prevents Firefox from seeing a > 4-digit date as an "Invalid Date" which seemed to cause ADJQ-936.
            -->
            <input class='date' type="date" id="funding-modal-dateRaised" name="funding-modal-dateRaised"
              formControlName="dateRaised" *ngIf="!funding.isDeleted"
              (ngModelChange)="handleDateChange()"
              [ngClass]="{ 'no-value': !funding.dateRaised }"
              max="9999-12-31">
            <div class="open-button"></div>
            <div class='date' *ngIf="funding.isDeleted">{{funding.dateRaised | date:'mediumDate' : 'GMT'}}</div>
          </div>
        </div>
      </div>
      <div class="comments-row">
        <div class="field">
          <div class="label">
            <div class='text'
              [ngClass]="{ 'modified': selfUpdateMode === SelfUpdateModes.Review && hasPropertyBeenModified('comments')}">
              {{Loc(LocalizedTextIds.FundingModalFundingComments)}}
            </div>
          </div>
          <input
            class="comments"
            id="funding-modal-comments"
            name="funding-modal-comments"
            formControlName="comments"
            maxlength="32768">
        </div>
        <div class="public">
          <input type="checkbox" class="checkbox" id="funding-modal-isConfidential" name="funding-modal-isConfidential"
            formControlName="isConfidential" />
          <label for="funding-modal-isConfidential" class="public-label"
            [ngClass]="{ 'modified': selfUpdateMode === SelfUpdateModes.Review && hasPropertyBeenModified('isConfidential')}">
            {{Loc(LocalizedTextIds.FundingModalThisInformation)}}
          </label>
        </div>
      </div>
    </form>
  </div>
</company-update-modal>
