import { ModelBase } from './_model.base.js';

export class Funding extends ModelBase {
    public fundingId: string;
    public fundingRound: string;
    public raised: string;
    public raisedCurrency: string;
    public dateRaised: Date;
    public amountRaisedUsd: string;
    public fundingParty: string;
    public comments: string;
    public isConfidential: boolean;
    public opportunityIdPrimary: string;
    public accountId: string;
    public isDeleted: boolean;
}
