import { Component, Input } from '@angular/core';
import { Company } from 'company-finder-common';
import { ComponentBase } from '@Common';
import { AuthnService } from '@Common';
import { CompanyService } from '@Common';
import { WebAnalyticsService } from '@Common';
import { ApplicationContext } from '@Common';
import { DeploymentContext } from '@Common';

@Component({
    selector: 'download',
    templateUrl: './download.component.html',
    styleUrls: [
        //    '../../company-details.component.scss',
        './download.component.scss',
    ],
    standalone: false
})
export class DownloadComponent extends ComponentBase {
  @Input() company: Company;

  public showMenu = false;

  public constructor(
    dc: DeploymentContext,
    private _applicationContext: ApplicationContext,
    private _authnService: AuthnService,
    private _companyService: CompanyService,
    private _webAnalyticsService: WebAnalyticsService
  ) {
    super(dc);
  }

  public get isFollowEnabled(): boolean {
    return this._deploymentContext.featureSwitches.enableFollow;
  }

  public async downloadPdf(): Promise<void> {
    this.showMenu = false;
    const response = await this._companyService.getPdfData(this.company);
    this._applicationContext.saveDocument(this.company.name, response, 'pdf');
    this.trackDownload('export-company-details-as-pdf');
  }

  public toggleDownloadMenu(ev: MouseEvent): void {
    ev.preventDefault();
    ev.stopPropagation();

    this.showMenu = !this.showMenu;
  }

  public get isInternalView(): boolean {
    return this._authnService.isInternal;
  }

  public async downloadWordDocument(): Promise<void> {
    this.showMenu = false;
    const response = await this._companyService.getWordData(this.company);
    this._applicationContext.saveDocument(this.company.name, response, 'docx');
    this.trackDownload('export-company-details-as-word');
  }

  private trackDownload(label: string) {
    this._webAnalyticsService.trackEvent('export-company-details-as-word', {
      category: label,
      label: this.company.name,
    });
  }
}
