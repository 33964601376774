import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeploymentContext } from '@Common';
import { AuthnService } from '@Common';
import { ComponentBase } from '@Common';
import { navigateToLogin } from '@Common';

@Component({
    selector: 'login-footer',
    templateUrl: './login-footer.component.html',
    styleUrls: ['./login-footer.component.scss'],
    standalone: false
})
export class LoginFooterComponent extends ComponentBase {
  public constructor(
    dc: DeploymentContext,
    private router: Router,
    private route: ActivatedRoute,
    private authnService: AuthnService,
    private context: DeploymentContext
  ) {
    super(dc);
  }

  public get showLoginButtons(): boolean {
    return !this.authnService.isAuthenticated;
  }

  public loginJnJ(): void {
    navigateToLogin(this.router, this.route, this._deploymentContext);
  }

  public loginJuniverse(): void {
    this.context.setJuniverseHref();
  }
}
