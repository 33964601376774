import { Component } from '@angular/core';

import { DeploymentContext } from '@Common';
import { MenuOptionService } from '@Common';
import { BaseSelectorComponent } from '@Common';
import { StatusDisplayItem } from 'company-finder-common';
import { GroupedMenuOptions } from '@Common';

@Component({
    selector: 'status-selector',
    templateUrl: './status-selector.component.html',
    styleUrls: ['./status-selector.component.scss'],
    providers: [MenuOptionService],
    standalone: false
})
export class StatusSelectorComponent extends BaseSelectorComponent<StatusDisplayItem> {
  public constructor(
    dc: DeploymentContext,
    private _menuOptionService: MenuOptionService
  ) {
    super(dc);
  }

  public get statusOptions(): GroupedMenuOptions<StatusDisplayItem>[] {
    return this._menuOptionService.statusOptions;
  }

  public get label(): string {
    return this.Loc(this.LocalizedTextIds.Status);
  }
}
