<app-modal-overlay [showModal]="showModal" [maxHeight]="maxHeight" [heightPercent]="heightPercent" width="680">
  <div class="header">
    <div class="title">
      <span *ngIf="isItemDeleted">{{Loc(LocalizedTextIds.Deleted)}}</span><br>
      {{title}}
    </div>
    <img src="/assets/common/CloseX.svg" class="close" alt="Close" (click)="close(actionResult.Close)" />
  </div>
  <p class="description" [ngClass]="{ 'deleted': isItemDeleted }">
    {{Loc(LocalizedTextIds.CompanyUpdateModalAnythingYouShare)}}
  </p>
  <ng-content></ng-content>
  <div class="buttonrow" [ngClass]="{ 'align-right': isItemNew }" *ngIf="mode === selfUpdateMode.Edit; else reviewButtons">
    <ng-container *ngIf="!isItemDeleted; else restoreTemplate">
      <action-button class="delete-button" *ngIf="!isItemNew" type="delete" (buttonClick)="close(actionResult.Delete)">
        {{Loc(LocalizedTextIds.Delete)}}
      </action-button>
      <action-button class="secondary-action" type="cancel" (buttonClick)="close(actionResult.Cancel)">{{Loc(LocalizedTextIds.Cancel)}}
      </action-button>
      <action-button type="submit" [disabled]="isSaveDisabled" (buttonClick)="close(actionResult.Submit)">
        {{Loc(LocalizedTextIds.Save)}}
        <div class="right-arrow"></div>
      </action-button>
    </ng-container>
    <ng-template #restoreTemplate>
      <div class="deleted-message">{{Loc(LocalizedTextIds.CompanyUpdateModalThisEntryWillBe)}}</div>
      <action-button type="restore" (buttonClick)="close(actionResult.Restore)">{{Loc(LocalizedTextIds.CompanyUpdateModalRestoreEntry)}}</action-button>
    </ng-template>
  </div>
  <ng-template #reviewButtons>
    <div class="buttonrow align-right" [ngClass]="{ 'approved': updateStatus === updateStatuses.Approved, 'declined': updateStatus === updateStatuses.Declined }">
      <div *ngIf="isItemDeleted" class="deleted-message review">
        {{Loc(LocalizedTextIds.CompanyUpdateModalThisEntryHasBeen)}}
      </div>
      <action-button
        class="secondary-action"
        type="decline"
        (buttonClick)="close(actionResult.Decline)"
        [ngClass]="updateStatus === updateStatuses.Declined ? 'declined' : updateStatus === updateStatuses.Approved ? 'approved' : ''">
          <div *ngIf="updateStatus !== updateStatuses.Declined" class="button-text">{{Loc(LocalizedTextIds.Decline)}}</div>
          <div *ngIf="updateStatus === updateStatuses.Declined" class="button-text">{{Loc(LocalizedTextIds.Declined)}}</div>
      </action-button>
      <action-button
        type="approve"
        (buttonClick)="close(actionResult.Approve)"
        [ngClass]="updateStatus === updateStatuses.Approved ? 'approved' : updateStatus === updateStatuses.Declined ? 'declined' : ''">
          <div *ngIf="updateStatus !== updateStatuses.Approved">{{Loc(LocalizedTextIds.Approve)}}</div>
          <div *ngIf="updateStatus === updateStatuses.Approved">{{Loc(LocalizedTextIds.Approved)}}</div>
      </action-button>
    </div>
  </ng-template>
</app-modal-overlay>
