import { Component, Input } from '@angular/core';
import { Deal } from 'company-finder-common';
import { ComponentBase } from '@Common';
@Component({
    selector: 'deals-table',
    templateUrl: './deals-table.component.html',
    styleUrls: ['./deals-table.component.scss'],
    standalone: false
})
export class DealsTableComponent extends ComponentBase {
  @Input()
  public deals: Deal[];

  @Input()
  public showConfidentialStatus = true;

  public get filteredDeals(): Deal[] {
    return this.deals.filter((deal)=> !deal.isConfidential || this.showConfidentialStatus)
  }
}
